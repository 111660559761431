import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Modal, Radio } from 'react-bootstrap';

import SERVICES, { OUTLAW_SERVICE, SALESFORCE_SERVICE } from '@core/enums/IntegrationServices';
import Automation, { TRIGGER_ORIGIN } from '@core/models/Automation';
import FieldMapping from '@core/models/FieldMapping';
import Integration from '@core/models/Integration';
import { Dt } from '@core/utils';

import { Button, Checkbox, Dropdown, Loader, MenuItem, Setting } from '@components/dmp';

import FieldMapper from '@components/connect/FieldMapper';
import API from '@root/ApiClient';

@autoBindMethods
export default class Automator extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    integration: PropTypes.instanceOf(Integration),
    automation: PropTypes.instanceOf(Automation),
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,

      origin: TRIGGER_ORIGIN.OUTLAW,
      trigger: null,
      action: null,
      enabled: false,
      fieldMap: [],
      objectType: null,
    };
  }

  componentDidMount() {
    const { automation } = this.props;
    this.populate(automation);
  }

  componentDidUpdate(prevProps) {
    const { automation, show } = this.props;
    if (show && !prevProps.show) {
      this.populate(automation);
    }
  }

  get isNew() {
    return !this.props.automation;
  }

  get service() {
    return _.find(SERVICES, { key: this.props.integration.config.service });
  }

  get serviceName() {
    return _.get(this.service, 'name', 'Service');
  }

  get triggers() {
    const { origin } = this.state;
    const { integration } = this.props;
    const service = origin === TRIGGER_ORIGIN.OUTLAW ? OUTLAW_SERVICE : this.service;
    const triggers = {};
    _.forEach(service.triggers, (trigger) => {
      triggers[trigger.key] = { ...trigger };
    });
    return triggers;
  }

  get actions() {
    const { origin } = this.state;
    const service = origin === TRIGGER_ORIGIN.OUTLAW ? this.service : OUTLAW_SERVICE;
    return _.get(service, 'actions', []);
  }

  get triggerName() {
    const trigger = _.find(this.triggers, { key: this.state.trigger });
    return _.get(trigger, 'name', 'Select Trigger');
  }

  get actionName() {
    const action = _.find(this.actions, { key: this.state.action });
    return _.get(action, 'name', 'Select Action');
  }

  populate(automation) {
    if (_.get(automation, 'fieldMap')) {
      automation.fieldMap.sort((a, b) => a.outlaw.localeCompare(b.outlaw));
    }
    this.setState({
      origin: _.get(automation, 'origin', TRIGGER_ORIGIN.OUTLAW),
      trigger: _.get(automation, 'trigger', ''),
      action: _.get(automation, 'action', ''),
      enabled: _.get(automation, 'enabled', false),
      fieldMap: _.get(automation, 'fieldMap', []),
      objectType: _.get(automation, 'objectType', null),
    });
  }

  updateOrigin(e) {
    // If the origin changes, we need to reset both trigger and action because they will point to the wrong service
    this.setState({
      origin: e.target.value,
      trigger: null,
      action: null,
      objectType: null,
    });
  }

  async saveAutomation() {
    const { automation, integration, onClose } = this.props;
    const { templateID } = integration;
    const service = integration.config.service;

    const json = _.pick(this.state, ['origin', 'trigger', 'action', 'enabled', 'fieldMap', 'objectType']);
    json.key = _.get(automation, 'key', null);
    json.service = _.get(automation, 'service', null);
    await this.setState({ loading: true });
    await API.call('saveAutomation', { templateID, service, automation: json });
    await this.setState({ loading: false });
    onClose();
  }

  updateFieldMapping(fieldMapping, field, value) {
    const { fieldMap } = this.state;
    fieldMapping[field] = value;
    return this.setState({ fieldMap });
  }

  deleteFieldMapping(fieldMapping) {
    const { fieldMap } = this.state;
    const idx = fieldMap.indexOf(fieldMapping);
    if (idx > -1) {
      fieldMap.splice(idx, 1);
      this.setState({ fieldMap });
    }
  }

  addFieldMapping({ outlaw, service }) {
    const { fieldMap } = this.state;
    const fm = new FieldMapping({ outlaw, service });
    fieldMap.push(fm);
    this.setState({ fieldMap });
  }

  render() {
    const { show, onClose, integration, onDelete } = this.props;
    const { loading, origin, enabled, fieldMap, objectType } = this.state;

    if (!integration) return null;

    return (
      <Modal backdrop="static" keyboard={false} dialogClassName="automator" show={show} onHide={onClose}>
        <Modal.Header>
          <span className="headline">{this.isNew ? 'Add' : 'Update'} Automation</span>
        </Modal.Header>
        <Modal.Body>
          <div className="wrapper">
            <Setting title="Origin" subtitle="Select the source system to watch for triggers">
              <Radio
                name="origin"
                checked={origin === TRIGGER_ORIGIN.OUTLAW}
                disabled={loading}
                onChange={this.updateOrigin}
                value={TRIGGER_ORIGIN.OUTLAW}
              >
                Outlaw
              </Radio>
              <Radio
                name="origin"
                checked={origin === TRIGGER_ORIGIN.SERVICE}
                disabled={loading}
                onChange={this.updateOrigin}
                value={TRIGGER_ORIGIN.SERVICE}
              >
                {this.serviceName}
              </Radio>
            </Setting>

            <Setting title="Trigger" subtitle="Select the event which should trigger an automated action">
              <Dropdown
                id="dd-automation-triggers"
                width={220}
                title={this.triggerName}
                onSelect={(trigger) => this.setState({ trigger })}
                disabled={loading}
              >
                {_.map(this.triggers, (trigger) => (
                  <MenuItem key={trigger.key} eventKey={trigger.key} disabled={trigger.disabled}>
                    {trigger.name}
                  </MenuItem>
                ))}
              </Dropdown>
            </Setting>

            {this.service.key === SALESFORCE_SERVICE.key && (
              <Setting title="Object Type" subtitle="You must specify what is the root object type in Salesforce.">
                <Dropdown
                  id="dd-automation-sf-object-type"
                  width={220}
                  title={objectType || 'Select'}
                  onSelect={(objectType) => this.setState({ objectType })}
                  disabled={loading}
                >
                  <MenuItem key="Account" eventKey="Account">
                    Account
                  </MenuItem>
                  <MenuItem key="Contract" eventKey="Contract">
                    {Dt}
                  </MenuItem>
                  <MenuItem key="Opportunity" eventKey="Opportunity">
                    Opportunity
                  </MenuItem>
                </Dropdown>
              </Setting>
            )}

            <Setting title="Action" subtitle="Select the action to take when the trigger occurs">
              <Dropdown
                dmpStyle="default"
                id="dd-automation-actions"
                width={220}
                title={this.actionName}
                onSelect={(action) => this.setState({ action })}
                disabled={loading}
              >
                {_.map(this.actions, (action) => (
                  <MenuItem key={action.key} eventKey={action.key} disabled={action.disabled}>
                    {action.name}
                  </MenuItem>
                ))}
              </Dropdown>
            </Setting>

            <Setting title="Enabled" subtitle="Do not enable until field mappings are configured">
              <Checkbox
                id="chk-automation-enabled"
                checked={enabled}
                onChange={() => this.setState({ enabled: !enabled })}
                disabled={loading}
              >
                Enabled
              </Checkbox>
            </Setting>

            <Setting title="Field Mappings" subtitle="Map all the things!">
              <div className="field-mappings">
                <div className="headings">
                  <div className="outlaw">Outlaw</div>
                  <div className="service">{this.serviceName}</div>
                  <div className="actions" />
                </div>
                <div className="mappings">
                  {_.map(fieldMap, (fieldMapping, idx) => (
                    <FieldMapper
                      key={idx}
                      fieldMapping={fieldMapping}
                      onUpdate={this.updateFieldMapping}
                      onDelete={this.deleteFieldMapping}
                      disabled={loading}
                    />
                  ))}
                  <FieldMapper
                    onAdd={this.addFieldMapping}
                    onUpdate={this.updateFieldMapping}
                    onDelete={this.deleteFieldMapping}
                    disabled={loading}
                  />
                </div>
              </div>
            </Setting>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {loading && <Loader />}
          {this.isNew ? null : (
            <Button dmpStyle="danger" style={{ float: 'left' }} onClick={onDelete}>
              Delete
            </Button>
          )}
          <Button onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button dmpStyle="primary" disabled={loading} onClick={this.saveAutomation}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
