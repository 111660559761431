import { filter, find, forEach, get, map } from 'lodash';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';

import Core from '../Core.js';
import DealStatus from '../enums/DealStatus';
import { base64ToArray } from '../utils/Generators';
import Deal from './Deal';
import PDFElement, { ELEMENT_TYPE, FONTS, SIGNATURE_HEIGHT_RATIO } from './PDFElement';
import Party from './Party';
import Variable, { ValueType, VariableType } from './Variable';
import TextLine from '../parsing/TextLine';

// Not sure where this should go
const getFontFormat = ({ font: key, bold, italic }) => {
  const font = find(FONTS, { key });
  let format = 'regular';
  if (bold && italic) {
    format = 'bold-italic';
  } else if (bold) {
    format = 'bold';
  } else if (italic) {
    format = 'italic';
  }

  return font.formats[format];
};

export default class PDFDeal extends Deal {
  pdfElements = [];
  pdf = null;

  omitted = [];
  preamble = null;
  lensParties = [];

  constructor(json) {
    super(json);
    this.pdfElements = map(json.pdfElements, (data, key) => new PDFElement(data, this, key));

    // For each pdfElement, create an "inferred" variable if not explicitly defined
    // This exactly matches the pattern we're using for NATIVE deals in combing through section content (see Deal.findVariables)
    forEach(this.pdfElements, (element) => {
      if (!this.variables[element.variable] && element.elementType !== ELEMENT_TYPE.SIMPLE && element.variable) {
        const inferredVar = new Variable({
          type: [ELEMENT_TYPE.SIGNATURE, ELEMENT_TYPE.INITIALS].includes(element.elementType)
            ? VariableType.PARTY
            : VariableType.SIMPLE,
          name: element.variable,
          displayName: element.variable.replace(/-/g, ' '),
          deal: this,
        });

        // Since this.parties are defined in the Deal constructor, the inferred variables we just picked were not added to this.parties
        if (inferredVar.type === VariableType.PARTY) {
          this.parties.push(
            new Party(
              {
                partyID: inferredVar.name.split('.')[0],
                partyName: inferredVar.displayName,
              },
              this
            )
          );
        }

        this.variables[element.variable] = inferredVar;
      }
    });

    if (get(json, 'omitted.length') > 0) {
      this.omitted = map(json.omitted, (omittedLineJSON) => new TextLine(omittedLineJSON));
      // console.log(this.omitted);
    }

    this.preamble = this.findPreamble();
  }

  get hasSignatures() {
    const signatures = filter(this.pdfElements, (el) =>
      [ELEMENT_TYPE.SIGNATURE, ELEMENT_TYPE.INITIALS].includes(el.elementType)
    );
    return !!signatures.length;
  }

  get hasSignedSignatures() {
    const signedSignatures = filter(this.pdfElements, (el) => {
      return [ELEMENT_TYPE.SIGNATURE, ELEMENT_TYPE.INITIALS].includes(el.elementType) && el.data;
    });
    return !!signedSignatures.length;
  }

  get signing() {
    return this.status.data === DealStatus.SIGNING.data;
  }

  get signed() {
    return this.status.data === DealStatus.SIGNED.data;
  }

  // We're overriding the automation logic to make 3PP fully manual (except when fully signed)
  get locked() {
    return this.status.data === DealStatus.SIGNED.data;
  }

  get status() {
    const currentStatus =
      find(DealStatus, {
        data: get(this.deal.info, 'status', DealStatus.REVIEW.data),
      }) || DealStatus.REVIEW;
    return currentStatus;
  }

  get step() {
    return find(this.workflow, { key: this.status.data }, this.workflow[0]);
  }

  get filename() {
    return this.info.name + '.pdf';
  }

  // Only allowing OWNERS to edit for now
  get canEdit() {
    return this.deal.status.data === DealStatus.SIGNED.data;
  }

  get changes() {
    return filter(this.pdfElements, (el) => el.isFilled);
  }

  get unfilled() {
    return filter(this.pdfElements, (el) => el.elementType !== ELEMENT_TYPE.SIMPLE && !el.isFilled);
  }

  // This is for use when user is configuring a PDF for signing, and drags elements onto the canvas
  // We're mapping elements to variables under the hood,
  // so we want to be sure to suggest NEW ones that don't yet exist
  generateCandidateVariable(elementType, valueType = null) {
    let display = '',
      num = 1,
      type = null;

    if ([ELEMENT_TYPE.SIGNATURE, ELEMENT_TYPE.INITIALS].includes(elementType)) {
      display = 'Party';
      type = VariableType.PARTY;
    } else {
      type = VariableType.SIMPLE;

      switch (valueType) {
        case ValueType.DATE:
          display = 'Date';
          break;
        //may add additional cases here... e.g., lists?? (dropdown?)
        case ValueType.STRING:
        default:
          display = 'Text';
          break;
      }
    }

    // Keep incrementing field count until we find a name of a variable that does NOT exist yet
    while (this.variables[`${display}-${num}`]) num += 1;

    // Now return a simple object that can be used for variable creation
    // In conjunction with PDFElement creation
    return {
      name: `${display}-${num}`,
      displayName: `${display} ${num}`,
      type,
      valueType,
    };
  }

  async loadPDF(raw) {
    const pdf = await PDFDocument.load(raw.data);
    this.pdf = pdf;
  }

  async addElement(type, params = {}) {
    let newVariable = { name: params.variable || null };

    if (!newVariable.name && type !== ELEMENT_TYPE.SIMPLE) {
      // When adding a signature field in FLOW, if there already is a party on the deal, use that one
      // This will happen if user needs to sign in multiple places
      // OR if the user assigned a template to the PDFDeal and it has parties
      if (type === ELEMENT_TYPE.SIGNATURE && this.parties.length > 0) {
        if (get(this, 'currentDealUser.partyID', null)) {
          // The current deal user as a party assigned, use it (template).
          newVariable.name = this.currentDealUser.partyID;
        } else {
          // Here, we should technically have one party (generated in the condition below)
          // It is still possible though that this template has more than 1 party. We will
          // still select the first one and handle picking the right one from the UX for now.
          newVariable.name = this.parties[0].partyID;
        }
      } else {
        newVariable = this.generateCandidateVariable(type);
      }
    }

    const newElement = new PDFElement(
      {
        elementType: type,
        page: params.page,
        x: params.x,
        y: params.y,
        variable: newVariable ? newVariable.name : null,
      },
      this
    );

    const element = await Core.Fire.savePDFElement(newElement);

    return element;
  }

  async applyElements() {
    const pages = this.pdf.getPages();
    const promises = [];
    // Keep track of which fonts have already been embedded
    const fonts = {};

    for (let i = 0; i < this.pdfElements.length; i++) {
      const el = this.pdfElements[i];
      const page = pages[el.page];
      if (!page) continue;

      const { width: pageWidth, height: pageHeight } = page.getSize();

      switch (el.elementType) {
        case ELEMENT_TYPE.SIMPLE:
        case ELEMENT_TYPE.VARIABLE:
          // We can separate this into its own async function when we know more about where this whole thing goes
          const format = getFontFormat(el.options);
          // console.log(`Looking for font: ${format}`, el.options);
          const fontName = StandardFonts[format];
          if (!fontName) {
            console.log(`Could not find font [${format}] in pdf-lib enumeration`);
          }
          let font = fonts[format];
          if (!font) {
            const embedPromise = this.pdf.embedFont(fontName);
            promises.push(embedPromise);
            font = await embedPromise;
            fonts[format] = font;
            console.log(`Embedded font [${format}] in PDF`);
          } else {
            console.log(`Reusing embedded font [${format}]`);
          }

          if (el.elementType === ELEMENT_TYPE.SIMPLE) {
            promises.push(this.drawText(el.data, { el, page, font, pageWidth, pageHeight }));
          } else {
            promises.push(this.drawVariable(el, { page, font, pageWidth, pageHeight }));
          }
          break;
        case ELEMENT_TYPE.SIGNATURE:
          promises.push(this.drawSignature(el, { page }));
          break;
        default:
          break;
      }
    }

    await Promise.all(promises);
    // After manipulation is done, "save" and grab the ArrayBuffer representing the updated raw PDF data
    // This will be used to download the file itself (and maybe to overwrite/store in bucket? TBD...)
    const raw = await this.pdf.save();
    return raw;
  }

  /*
    Even though teh PDF format has a standard about setting their origins to the bottom left of the page,
    some apps do not follow this standard when generating PDF files and rotate pages.
    To avoid drawing text and images sideways, we must detect that rotation and adjust the x and y coordinates.
    https://github.com/Hopding/pdf-lib/issues/65#issuecomment-468064410
  */
  getRealPosition({ page, x, y, fontSize }) {
    const scale = 1;
    const dimensions = page.getSize();
    const pageRotation = page.getRotation(page).angle;
    var rotationRads = (pageRotation * Math.PI) / 180;

    //These coords are now from bottom/left
    var coordsFromBottomLeft = {
      x: x / scale,
    };
    if (pageRotation === 90 || pageRotation === 270) {
      coordsFromBottomLeft.y = dimensions.width - (y + fontSize) / scale;
    } else {
      coordsFromBottomLeft.y = dimensions.height - (y + fontSize) / scale;
    }

    var drawX = null;
    var drawY = null;
    if (pageRotation === 90) {
      drawX =
        coordsFromBottomLeft.x * Math.cos(rotationRads) -
        coordsFromBottomLeft.y * Math.sin(rotationRads) +
        dimensions.width;
      drawY = coordsFromBottomLeft.x * Math.sin(rotationRads) + coordsFromBottomLeft.y * Math.cos(rotationRads);
    } else if (pageRotation === 180) {
      drawX =
        coordsFromBottomLeft.x * Math.cos(rotationRads) -
        coordsFromBottomLeft.y * Math.sin(rotationRads) +
        dimensions.width;
      drawY =
        coordsFromBottomLeft.x * Math.sin(rotationRads) +
        coordsFromBottomLeft.y * Math.cos(rotationRads) +
        dimensions.height;
    } else if (pageRotation === 270) {
      drawX = coordsFromBottomLeft.x * Math.cos(rotationRads) - coordsFromBottomLeft.y * Math.sin(rotationRads);
      drawY =
        coordsFromBottomLeft.x * Math.sin(rotationRads) +
        coordsFromBottomLeft.y * Math.cos(rotationRads) +
        dimensions.height;
    } else {
      //no rotation
      drawX = coordsFromBottomLeft.x;
      drawY = coordsFromBottomLeft.y;
    }

    return [drawX, drawY];
  }

  drawVariable(el, { page, font, pageWidth, pageHeight }) {
    const text = get(el, 'dealVariable.val', null);
    if (!text) return Promise.resolve();

    return this.drawText(text, { el, page, font, pageWidth, pageHeight });
  }

  drawText(text, { el, page, font, pageWidth, pageHeight }) {
    if (!text) return Promise.resolve();

    const hasLineBreaks = text.includes('\n');
    const pageRotation = page.getRotation();

    let x = el.x;
    let y = el.y;
    let width = el.width;

    let textX = x;
    // IMPORTANT
    // There is a bug where we can't call font.widthOfTextAtSize() with line breaks.
    // The fix would be to split the text by '\n', then rendering them on top of the other
    // making sure that we support line height, etc.
    // Note: For now, we're only supporting left aligned text on the FE anyway.
    if (!hasLineBreaks) {
      const textWidth = font.widthOfTextAtSize(text, el.options.size);
      if (el.options.textAlign === 'center') {
        textX = pageWidth / 2 - textWidth / 2;
      } else if (el.options.textAlign === 'right') {
        textX = textX + (width - textWidth);
      }
    }

    let textY = y;

    const [drawX, drawY] = this.getRealPosition({
      page,
      x: textX,
      y: textY,
      fontSize: el.options.size,
    });

    const options = {
      x: drawX,
      y: drawY,
      size: el.options.size,
      // Note: Using maxWidth will convert any 1+ "\n" to 1 "\n".
      //       tldr; multi-line breaks are not possible.
      maxWidth: width,
      font,
      color: rgb(0, 0, 0),
      lineHeight: el.options.lineHeight,
      rotate: pageRotation,
    };

    return page.drawText(text, options);
  }

  /*
    Syncing display with siggy.scss:
    - Signature is rendered using it's original size.
    - If width is larger than the element width, scale it by width.
    - If the upcoming scaled height will be taller than the element height, scale it by height.
    - Then position it: {x: left, y: center}.
  */
  async drawSignature(el, { page }) {
    // Signature does not exist, don't draw it
    if (!el.data) return Promise.resolve();

    const pageRotation = page.getRotation();

    const b64 = el.data.replace('data:image/png;base64,', '');
    const bytes = base64ToArray(b64);

    const png = await this.pdf.embedPng(bytes);

    const sigHeight = el.width * SIGNATURE_HEIGHT_RATIO;

    let scale = 1;
    let scaledPNG = null;

    if (el.width < png.width) {
      scale = +(el.width / png.width);
    }

    // Wether we scale or not, check if height will hit it's max,
    // if so, scale using height ratio instead
    if (png.height * scale > sigHeight) {
      scale = +(sigHeight / png.height);
    }

    scaledPNG = png.scale(scale);

    const [drawX, drawY] = this.getRealPosition({
      page,
      x: el.x,
      y: el.y,
      height: el.options.size,
      fontSize: scaledPNG.height,
    });

    // Get the real draw Y value by moving it up be half of the empty space,
    // created by the signature being smaller than the signature element.
    const imageDrawY = drawY + (sigHeight - scaledPNG.height) / 2;

    page.drawImage(png, {
      x: drawX,
      y: imageDrawY,
      width: scaledPNG.width,
      height: scaledPNG.height,
      rotate: pageRotation,
    });

    return Promise.resolve();
  }

  findPreamble() {
  
    let agreement, party1, party2;
  
    const pre = find(this.sections, (externalSection) => {
      agreement = null, party1 = null, party2 = null;
      const text = get(externalSection, 'flatValue', '');
      
      // TODO: unnumbered / untitled is also a signal
      // TODO: make this a little more robust; include optional agreement name in quotes
      const rxAgreement = /T[Hh][Ii][Ss] ([A-Z][^(]+) \(/ig;
      const resultAgreement = rxAgreement.exec(text);
      // console.log(resultAgreement);
  
      // TODO: first try using rxDefinedTerm to identify party names in quotes (with "between" and "and")
      // then fallback to this (literal) method if not found
  
      if (!resultAgreement) return false;
  
      agreement = resultAgreement[1];
  
      const rxPartiesNamed = /between .+ \(.*[“"]([A-Z].+)["”].*\) .*\band\b.+ \(.*[“"]([A-Z].+)["”].*\)/g;
      const rxPartiesUnnamed = /between ((\b[A-Z][\w,.-]*\s?)+).*\band (\[?(\b[A-Z][\w,.-]*\]?\s?)+)/g;
      let resultParties = null;
  
      resultParties = rxPartiesNamed.exec(text);
      if (resultParties) {
        console.log('[PREAMBLE] - Found named parties');
        party1 = resultParties[1];
        party2 = resultParties[2];
      }
      else {
        resultParties = rxPartiesUnnamed.exec(text);
        if (resultParties) {
          console.log('[PREAMBLE] - Found unnamed parties');
          party1 = resultParties[1].replace(/[\s,]*$/, '');
          party2 = resultParties[3].replace(/[\s,]*$/, '');
        }
      }
  
      if (party1 && party2) return true;
    });

    if (pre) {
      pre.isPreamble = true;

      // TODO: create model or extend ExternalSection
      return {
        section: pre,
        title: agreement,
        parties: [party1, party2],
      }
    }

    return null;
  }
  
}
