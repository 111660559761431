import React, { Component, createRef } from 'react';
import ReactDOM, { createPortal } from 'react-dom';

import { ButtonGroup, Modal } from 'react-bootstrap';

import AIBlockRunner from '@core/utils/AIBlockRunner';

import { Button, Loader } from '@components/dmp';

import AIThreadView from '@components/AIThreadView';
import OverviewSection from '@components/section_types/OverviewSection';
import Fire from '@root/Fire';
import ContractView from '@routes/ContractView';
import trackEvent from '@utils/EventTracking';

const MODES = ['Overview', 'Contract'];

const OutsideModalContent = ({ container, children }) => {
  return ReactDOM.createPortal(<div className="outside-modal-content">{children}</div>, container);
};

export default class DealPreview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      settingUp: false,
      preview: null,
      currentConnectVars: null,
      summary: null,
      source: null,
      toc: null,
      mode: 'Overview',
    };

    this.refModalWrapper = createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    this.setupPreview(this.props);
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setupPreview(props);
  }

  async setupPreview(props) {
    const { deal, show, user } = props;
    const { preview, settingUp } = this.state;

    //We can call setupPreview() frequently, and only create the preview
    //if we have a valid template deal loaded but not a preview copy of it yet
    if (!show || !deal || !deal.template || preview || settingUp) return;

    //Setup could take a bit of time because these are heavy calls, so avoid accidental duplicate calls
    await this.setState({ settingUp: true });

    console.log('[TEMPLATE] Generating temporary deal preview object');

    const branding = await Fire.getBranding(props.user, props.template);
    const rawDeal = await Fire.createDealFromTemplate({
      user,
      branding,
      dealTemplate: deal,
      preview: true,
    });

    Fire.getDeal(
      rawDeal.dealID,
      async (deal) => {
        const newState = {
          preview: deal,
          summary: deal.buildSummary(),
          source: deal.buildSource(true),
          toc: deal.buildTOC(),
          settingUp: false,
        };

        //if there's no overview, default to contract
        if (newState.summary.length == 0) newState.mode = 'Contract';

        // If Deal has connected vars and is not locked, load/sync latest external values
        if (_.keys(deal.syncedVariables).length > 0 && deal.isConnected) {
          this.loadConnectVariables(deal);
        }

        AIBlockRunner.autorun(newState.source);

        this.setState(newState);
      },
      true
    ); //active connection to preview deal
  }

  async loadConnectVariables(deal) {
    const { currentConnectVars, settingUp } = this.state;

    if (!deal.isConnected || !Object.keys(deal.syncedVariables).length) return;

    // If connected var values differ differ from what we've stored in state (e.g., on first Deal load), load latest values via API
    if (settingUp && !_.isEqual(currentConnectVars, deal.syncedValues)) {
      try {
        const latestConnectVars = await API.call('syncConnectVariables', { dealID: deal.dealID });

        // Store values in state so that we don't keep unnecessarily hitting the API for Deal changes that don't involve connected vars
        this.setState({ currentConnectVars: latestConnectVars });
      } catch (error) {
        console.log(error);
      }
    }
  }

  async destroyPreview() {
    if (this.state.preview) {
      if (this.state.preview.template) {
        //should never get here
        console.log('[TEMPLATE] avoiding automatic template deletion');
      } else {
        console.log('[TEMPLATE] Destroying temporary deal preview object');

        //first remove listener so that we don't try to re-read something that's just been deleted
        Fire.db.ref(`deals/${this.state.preview.dealID}`).off();
        await Fire.deleteDeal(this.state.preview.dealID);
        if (this._isMounted)
          this.setState({
            preview: null,
            summary: null,
            source: null,
            toc: null,
            settingUp: false,
          });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.destroyPreview();
  }

  hide() {
    this.props.close();
    this.destroyPreview();
  }

  render() {
    const { deal } = this.props;
    //this case should only happen on direct deep link,
    //where component loads before template has loaded
    if (!deal) return null;

    const { summary, settingUp, preview } = this.state;

    // React Bootstrap 3 makes it extremely difficult to target the appropriate div
    // to get something still inside the modal, but not nested too deeply
    // so we can use React.createPortal to accomplish that
    const portal = _.get(document.getElementsByClassName('preview-modal'), '[0].parentNode', null);

    return (
      <Modal
        show={this.props.show}
        dialogClassName="preview-modal"
        onHide={() => this.hide()}
        enforceFocus={false}
        ref={this.refModalWrapper}
      >
        <Modal.Header>
          {summary != null &&
            summary.length > 0 && ( //only show toggle buttons if there's overview content to show
              <ButtonGroup className="mode-toggle" data-cy="preview-mode-toggle">
                {MODES.map((mode) => (
                  <Button
                    key={mode}
                    active={this.state.mode == mode}
                    onClick={() => this.setState({ mode })}
                    data-cy={mode}
                  >
                    {mode}
                  </Button>
                ))}
              </ButtonGroup>
            )}
        </Modal.Header>

        <Modal.Body className="outer-paper-layout" ref={(r) => (this.viewContainer = r)} data-cy="preview-modal">
          {preview && this.renderPreview()}
          {settingUp && <Loader centered size="large" />}
        </Modal.Body>

        {preview?.hasVinnie && portal && createPortal(<AIThreadView deal={preview} />, portal)}
      </Modal>
    );
  }

  renderPreview() {
    const { mode, preview, source } = this.state;
    const { user, deal, headers, footers } = this.props;

    const { layout } = deal.style;

    //here preview has already been created, so render it -- copy of SummaryView
    switch (mode) {
      case 'Overview':
        const sections = preview.applyConditions(preview.root.children);
        return (
          <div className={`inside-paper-layout`} ref="self" style={layout.PageMargin.getPageWebMargins(window)}>
            {sections.map((sec, idx) => {
              if (!sec.isTemplateHeaderFooter)
                return (
                  <OverviewSection
                    {...this.props}
                    key={sec.id}
                    section={sec}
                    container={this.viewContainer}
                    showBranding={idx === 0 && preview.showLetterhead}
                  />
                );
            })}
          </div>
        );
        break;
      case 'Contract':
        return <ContractView deal={preview} source={source} container={this.viewContainer} hideMenu user={user} />;
      default:
        return null;
    }
  }
}
