import _ from 'lodash';

// Min width (pts) that represents an explicit gap in text (>2 spaces)
export const PT_GAP = 10;

export const PAGE_MIDPOINT = 72 * 8.5 / 2; 

export const MID_MARGIN = {
  left: PAGE_MIDPOINT - PT_GAP/2, 
  right: PAGE_MIDPOINT + PT_GAP/2,
  width: PT_GAP,
}; 

export default class TextFragment {

  x;
  y;
  page;
  value;
  width;
  height;
  
  column = undefined;
  //font ?

  // reference to "parent" TextLine (established in TextLine.push)
  line = null;

  constructor({x, y, page, value, width, height}, line = null) {
    _.assign(this, {x, y, page, value, width, height});
    this.line = line;
  }

  // PDFs insert lots of random spaces; anytime we find 2+ spaces, reduce to 1
  get condensedText() {
    return this.value.replace(/ {2,}/g, ' ');
  }

  get endX() {
    return this.x + this.width;
  }

  get endY() {
    return this.y + this.height;
  }

  get isInLeftColumn() {
    return this.endX <= MID_MARGIN.left; 
  }

  get isInRightColumn() {
    return this.x >= MID_MARGIN.right; 
  }

  get isSpacer() {
    return !this.value.trim() && this.width > 0;
  }

  get json() {
    return _.pick(this, [
      'x',
      'y',
      'page',
      'value',
      'width',
      'height',
    ]);
  }
}