import { assign, get, sumBy } from 'lodash';

import StripePlan from './StripePlan';

export const CUSTOMER_STATUS = {
  FREE: 'free', //customer is not paying and is not an owner of any teams (probably a recipient)
  TEAM_MEMBER: 'teamMember', //customer is on one or more teams owned by another paying subscriber
  TRIAL_MEMBER: 'trialMember', //customer is on one or more teams but none of them are paid (e.g., Outlaw member setup a team)
  PAID: 'paid', //customer is active paying member with sufficient seats
  SEATS_EXCEEDED: 'seatsExceeded', //customer is owner of at least one team and total team membership exceeds paid seats
  OUTLAW: 'outlaw', //customer is one of us, an Outlaw, and can do whatever the fuck they want :-)
};

// TODO -- move this and above to its own model class as part of subscription audit
export const paying = (status) => {
  return [CUSTOMER_STATUS.TEAM_MEMBER, CUSTOMER_STATUS.PAID, CUSTOMER_STATUS.OUTLAW].includes(status);
};

export default class StripeCustomer {
  id = null;
  plan = null;
  sub = null;
  card = null;
  discount = null;

  constructor(json) {
    this.raw = json;
    const { id } = json;
    assign(this, { id });

    //find active subscription if there is one
    if (json.subscriptions && json.subscriptions.total_count > 0) {
      this.sub = json.subscriptions.data[0];
      const { items } = this.sub;

      let plan = null,
        totalQuantity = 0;

      // In case we have multiple plans in the subscription, aggregate the seats (quantity).
      if (items) {
        plan = get(items, 'data[0].plan');
        totalQuantity = sumBy(get(items, 'data', []), 'quantity');
        this.sub.quantity = totalQuantity;
      }

      if (plan) {
        // Set the plan since it doesn't exist at root for multi-plan subscriptions
        // For single ones, the data live n the same place anyway. (items.data[0])
        this.sub.plan = plan;
        this.plan = new StripePlan(plan);
      }
    }

    // Find active payment source (card) if there is one
    if (json.sources && json.sources.total_count > 0) {
      this.card = json.sources.data[0];
    }

    // Easier access to coupon if there is one
    if (this.sub && this.sub.discount) {
      this.discount = this.sub.discount;
    }
  }
}
