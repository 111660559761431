/* eslint-disable no-console */
import axios from 'axios';
import { updateEmail } from 'firebase/auth';
import _ from 'lodash';

import DealAction, { CHECKPOINT_ACTIVITY_MESSAGE } from '@core/enums/DealAction';
import DealRole from '@core/enums/DealRole';
import DealStatus from '@core/enums/DealStatus';
import InviteStatus from '@core/enums/InviteStatus';
import SectionType, { HEADER_FOOTER_SUB_SECTION_TYPES } from '@core/enums/SectionType';
import Attachment from '@core/models/Attachment';
import Branding from '@core/models/Branding';
import { getMarkup } from '@core/models/Content';
import Deal, { BEHAVIOR, DEAL_TYPE, PDF_CHAR_SETS } from '@core/models/Deal';
import DealFactory from '@core/models/DealFactory';
import { makeDefaultNotificationSettings } from '@core/models/NotificationSettings';
import PDFDeal from '@core/models/PDFDeal';
import { ELEMENT_TYPE } from '@core/models/PDFElement';
import Payment from '@core/models/Payment';
import Section, { ROOT_ID } from '@core/models/Section';
import StyleFactory, { DEFAULT_THEME } from '@core/models/StyleFactory';
import Team, { TEAM_ROLES } from '@core/models/Team';
import { EXTRACT_TYPE, ValueType, VariableType } from '@core/models/Variable';
import Version, { BASE_ID, MERGE_TYPE, getVersionID, hasDiffs, redline, sanitize } from '@core/models/Version';
import Workflow, {
  DEFAULT_WORKFLOW,
  DEFAULT_WORKFLOW_STATIC,
  WORKFLOW_LEGAL,
  WORKFLOW_SERVICE_PROVIDER,
} from '@core/models/Workflow';
import { Reporter } from '@core/utils';

import CONFIG from '@root/Config';
import trackEvent from '@utils/EventTracking';

const getReportPath = (report, suffix = '') => {
  let path = '';
  if (report.isUserReport) {
    path += `users/${report.userID}/reports/`;
  } else if (report.isTeamReport) {
    path += `teams/${report.teamID}/reports/`;
  }

  if (!path) return null;

  return path + suffix;
};

const getAPIClient = () => {
  if (typeof window === 'object') {
    return window.API || null;
  } else {
    return null;
  }
};

class OutlawFirebase {
  init(db, auth, storage, utils) {
    this.db = db;
    this.auth = auth;
    this.storage = storage;

    this.utils = utils;
  }

  async token(success, failure) {
    if (!this.auth.currentUser) {
      const message = 'You are not signed in';
      if (typeof failure == 'function') failure(message);
      return Promise.reject(message);
    }
    const token = await this.auth.currentUser.getIdToken();
    if (typeof success === 'function') success(token);
    return Promise.resolve(token);
  }

  load(path, success, failure, realtime) {
    const verb = realtime ? 'on' : 'once';

    //for realtime calls, first clear any existing listeners to avoid duplicate callbacks (memory leaks)
    if (realtime) this.db.ref(path).off();

    //return the promise if needed
    return new Promise((resolve, reject) => {
      this.db.ref(path)[verb](
        'value',
        (snap) => {
          const result = snap.val();
          if (typeof success == 'function') success(result);
          resolve(result);
        },
        (err) => {
          if (typeof failure == 'function') failure(err);
          reject(err);
          Reporter.error(err, 'FIREBASE LOAD ERROR');
        }
      );
    });
  }

  update(updates, success, failure) {
    //todo: ensure that none of the update paths are / or '' (as this would attempt to delete db!!!)
    return this.db.ref().update(updates, (err) => {
      if (err) {
        if (typeof failure == 'function') failure(err);
        Reporter.error(err, 'FIREBASE UPDATE ERROR');
      } else {
        if (typeof success == 'function') success(updates);
      }
    });
  }

  //replace any undefined keys with null values before writing it to firebase (firebase fails on undefined)
  clean(data, emptyStringsNull) {
    if (data == null) return null;
    const keys = data instanceof Array ? [...data.keys()] : typeof data == 'object' ? Object.keys(data) : [];

    keys.forEach((key) => {
      if (data[key] === undefined) delete data[key];
      else if (emptyStringsNull && data[key] === '') data[key] = null;
      //recurse through child arrays/objects
      else if (typeof data[key] == 'object') this.clean(data[key], emptyStringsNull);
    });
    return data;
  }

  createTemplateData(deal, dealID, templateData) {
    deal.template = { dealID };

    _.forEach(this.clean(templateData, true), (val, key) => {
      deal.template[key] = val;
    });

    // also set deal's status and name to template so that we can filter these out in My Deals
    deal.info.status = DealStatus.TEMPLATE.data;
    if (templateData.title) {
      deal.info.name = templateData.title;
    }

    return deal;
  }

  queueForIndexDeletion(type, id) {
    return this.update({
      [`indexing-es/queue/${type}/${id}`]: {
        action: 'delete',
        date: new Date().getTime().toString(),
        priority: 1,
      },
    });
  }

  getUser(uid, realtime, success) {
    return this.load(`users/${uid}`, success, null, realtime);
  }

  async hasUnrestrictedAccess(uid) {
    if (!uid) return false;
    if (!CONFIG.INSTANCE || !CONFIG.UNRESTRICTED_ACCESS_TEAM) return false;

    // Verify that the user is part of the unrestricted access team
    const teamRole = await this.load(`users/${uid}/teams/${CONFIG.UNRESTRICTED_ACCESS_TEAM}`);
    if (!teamRole) return false;

    const teamMember = await this.load(`teams/${CONFIG.UNRESTRICTED_ACCESS_TEAM}/users/${uid}`);
    if (!teamMember) return false;

    return true;
  }

  //only works on server, where auth is instance of firebase-admin
  getAuthUserByID(uid) {
    return new Promise((resolve, reject) => {
      this.auth
        .getUser(uid)
        .then((user) => resolve(user))
        .catch((e) => reject(e));
    });
  }

  //only works on server, where auth is instance of firebase-admin
  lookupUser(email) {
    return new Promise((resolve, reject) => {
      this.auth
        .getUserByEmail(email)
        .then((user) => resolve(user))
        .catch((e) => reject(e));
    });
  }

  //only works on server, where auth is instance of firebase-admin
  deleteUser(uid) {
    return new Promise((resolve, reject) => {
      const deleteFromDB = () => {
        //and now delete from db also
        Fire.update({ [`users/${uid}`]: null }).then(() => {
          console.log(`Deleted user [${uid}] from Firebase DB`);
          resolve(uid);
        });
      };

      this.auth
        .deleteUser(uid)
        .then(() => {
          console.log(`Deleted user [${uid}] from Firebase AUTH`);
          deleteFromDB();
        })
        .catch((e) => {
          if (e.code === 'auth/user-not-found') {
            // UserRecord cannot be found in Firebase.auth,
            // let's deleting the remaining data from the DB
            deleteFromDB();
          } else {
            console.log('Error deleting user:', e.message);
            reject(e);
          }
        });
    });
  }

  //only works on server, where auth is instance of firebase-admin
  createUser({ email, password, fullName }) {
    return new Promise((resolve, reject) => {
      this.auth
        .createUser({
          email,
          emailVerified: false,
          password,
          displayName: fullName,
          disabled: false,
        })
        .then(resolve)
        .catch(reject);
    });
  }

  // It is possible that this new feature took part in breaking CLA users MS SSO linking
  // That is why we will disable it for now until we've found the real source.
  // Anyway, this was only used to help ease email change which we can do manually if needed.
  async handleSSOEmailChange() {
    if (this.auth.currentUser && this.auth.currentUser.providerData.length > 0) {
      const ssoProviders = _.filter(this.auth.currentUser.providerData, ({ providerId }) => {
        return providerId !== 'password';
      });
      const ssoEmail = ssoProviders[0]?.email;
      if (!!ssoEmail && ssoEmail !== this.auth.currentUser.email) {
        console.log(`Email update occured changing email from ${this.auth.currentUser.email} to ${ssoEmail}`);
        try {
          await updateEmail(this.auth.currentUser, ssoEmail);
          return ssoEmail;
        } catch (err) {
          console.log(err);
          return null;
        }
      }
    } else {
      return null;
    }
  }

  //this is a check that runs on every initial user load and registration
  //we make sure to save basic (name/email) data from the 3rd party provider
  //into our User model if nothing is there yet
  async ensureProfileCompleteness(user, firebaseUser) {
    //TODO: this should never be possible, but just to be safe...
    if (!firebaseUser.email || !firebaseUser.uid) return Promise.resolve();

    const uid = user && user.id ? user.id : firebaseUser.uid;
    // See handleSSOEmailChange for more info.
    //const updatedEmail = await this.handleSSOEmailChange();
    const updatedEmail = null;
    const email = !!updatedEmail ? updatedEmail : firebaseUser.email;

    //if there's missing core profile info we need to update
    //this will happen for legacy users (e.g., Oba McMillan!)
    //and for anonymous users upgrading (signing in for the first time)
    //or if the users SSO email was updated
    if (!user.id || !user.email || !user.info || !user.info.email || updatedEmail) {
      console.log('[AUTH] Updating basic user profile data');
      let updates = {
        [`users/${uid}/id`]: firebaseUser.uid,
        [`users/${uid}/email`]: email,
        [`users/${uid}/info/email`]: email,
      };

      updates = this.clean(updates);

      //no need for success callback; getUser() will update in App state via realtime connection
      return this.update(updates);
    }
  }

  /** This is only callable via API
   * @param {object} dealsToMerge - results from API.getDeals() {Search hit object}
   * @param {string} anonUserId - the current residing anonymous user id
   * @param {string} authedUserId - the incoming authenticated user id that we'll merge deals into
   * @param {string} email
   */
  async mergeDeals(dealsToMerge, anonUserId, authedUserId, email) {
    let updates = {}; // updates for Firebase
    const migrated = []; // migrated deals

    // Use a basic for loop here so that we can use await,
    // without needing to deal with collecting promises and "await Promise.all"
    for (let index = 0; index < dealsToMerge.hits.length; index++) {
      const dealRecord = dealsToMerge.hits[index];

      // We need to grab the full Deal object because uids change in a bunch of places
      const deal = await this.getDeal(dealRecord.dealID);
      // This should never be possible, but just to ensure that the call doesn't fail in the case of a search/indexing delay or something
      if (!deal) continue;

      // Copy relevant data from the source deal into the new deal object
      const { sections, users } = _.cloneDeep(deal.raw);

      // First, update the deal user to the new (previously existing authed) uid
      const dealUser = users[anonUserId];
      // If that user is (somehow) not actually on the deal, there's nothing to do
      if (!dealUser) {
        console.log(`User [${anonUserId}] not found on Deal [${deal.dealID}] -- skipping migration`);
        continue;
      }

      // Update key properties to the real user
      dealUser.dealUserId = authedUserId;
      dealUser.key = authedUserId;
      dealUser.uid = authedUserId;
      dealUser.email = email;
      dealUser.inviteStatus = InviteStatus.ACCEPTED;

      // Delete the old guest user
      updates[`deals/${deal.dealID}/users/${anonUserId}`] = null;
      // Set raw json key to the new one
      updates[`deals/${deal.dealID}/users/${authedUserId}`] = dealUser;

      // Next, go through all deal sections as there are a number of key properties we need to migrate
      _.forEach(sections, (section) => {
        // If user had signed anything as guest, keep sig data and migrate to new key
        if (section.sectiontype === SectionType.SIGNATURE && _.get(section, `sigs.${anonUserId}`, null)) {
          updates[`deals/${deal.dealID}/sections/${section.id}/sigs/${anonUserId}`] = null;
          updates[`deals/${deal.dealID}/sections/${section.id}/sigs/${authedUserId}`] = section.sigs[anonUserId];
        }

        // Re-key all section activity to authed uid
        const activity = _.filter(section.activity, { user: anonUserId });
        _.forEach(activity, (action) => {
          updates[`deals/${deal.dealID}/sections/${section.id}/activity/${action.id}/user`] = authedUserId;
        });

        // If guest had edited any content, re-attribute versions to authed uid
        const versions = _.filter(sections.versions, { user: anonUserId });
        _.forEach(versions, (version) => {
          updates[`deals/${deal.dealID}/sections/${section.id}/versions/${version.id}/user`] = authedUserId;
        });
      });

      // Re-key all deal-level activity to authed uid
      const activity = _.filter(deal.activity, { user: anonUserId });
      _.forEach(activity, (action) => {
        updates[`deals/${deal.dealID}/activity/${action.id}/user`] = authedUserId;
      });

      //...anything else??

      // Do all the updates for THIS one deal at a time
      // Again update() calls shouldn't ever really fail, but try/catch just to ensure the call completes
      try {
        await this.update(updates);
        migrated.push(deal.dealID);
      } catch (err) {
        console.log(err);
      }
    }

    console.log(`Transferred ${migrated.length} deals from [${anonUserId}] to [${authedUserId}]`);

    // Finally, delete the guest user
    try {
      await this.deleteUser(anonUserId);
    } catch (err) {
      console.log(err);
    }

    console.log(`Deleted unused guest account [${anonUserId}]`);
    return Promise.resolve(migrated);
  }

  async saveTemplate(dealID, template) {
    const path = `deals/${dealID}/template`;
    const data = this.clean(template, true);
    const updates = {};

    _.forEach(data, (val, key) => {
      updates[`${path}/${key}`] = val;
    });

    //also set deal's status and name to template so that we can filter these out in My Deals
    updates[`deals/${dealID}/info/status`] = 'template';
    if (template.title) {
      updates[`deals/${dealID}/info/name`] = template.title;
    }

    await this.update(updates);
    return template;
  }

  async saveFailedDocumentAIMessage(dealID, message) {
    const path = `deals/${dealID}/extracted`;
    const updates = {};

    updates[`${path}/error`] = message;
    await this.update(updates);
  }

  async saveExtractedData(dealID, sections, variables, pageCount, needsReview, saveableFormFields) {
    const path = `deals/${dealID}/extracted`;
    const updates = { ...saveableFormFields };

    updates[`${path}/sections`] = this.clean(sections);
    updates[`${path}/variables`] = this.clean(variables);
    updates[`${path}/analyzedOn`] = Date.now();
    updates[`${path}/pages`] = pageCount;
    updates[`${path}/needsReview`] = needsReview;

    //if needs review move the deal status back to review.
    if (needsReview) {
      updates[`deals/${dealID}/info/status`] = 'review';
      const deal = await this.getDeal(dealID);
      updates[`deals/${dealID}/versions/${deal.currentVersion.key}/dealStatus`] = 'review';
    }

    await this.update(updates);
  }

  async saveLens(dealID, lens, deleteLens = false) {
    const path = `deals/${dealID}/template/lenses`;
    const updates = {};

    // Establish id if new
    if (!lens.id) {
      lens.id = this.db.ref(path).push().key;
    }

    updates[`${path}/${lens.id}`] = deleteLens ? null : this.clean(lens.json);

    await this.update(updates);
    return lens;
  }

  async generateLensInstance(dealID, type) {
    const path = `deals/${dealID}/template/lenses`;
    const updates = {};

    const id = this.db.ref(path).push().key;
    const lensInstance = { id, type };
    updates[`${path}/${id}`] = lensInstance;
    await this.update(updates);
    return lensInstance;
  }

  async saveTemplateScoring(dealID, scoring) {
    const path = `deals/${dealID}/template/scoring`;
    const updates = {};

    updates[`${path}`] = scoring;

    return this.update(updates);
  }

  async saveScoringMatrix(teamID, matrix) {
    const path = `teams/${teamID}/scoringMatrices/${matrix.id}`;
    const updates = {};

    updates[`${path}`] = matrix;

    return this.update(updates);
  }

  deleteScoringMatrix(teamID, matrix) {
    const updates = { [`teams/${teamID}/scoringMatrices/${matrix.id}`]: null };
    return this.update(updates);
  }

  async saveLensChecks(dealID, lensChecks) {
    const path = `deals/${dealID}/lensChecks`;
    const updates = {};

    updates[`${path}`] = lensChecks;

    await this.update(updates);
    return lensChecks;
  }

  togglePublicity(dealID, isTemplate, publish, success) {
    const updates = { [`deals/${dealID}/${isTemplate ? 'template' : 'info'}/public`]: publish };
    return this.update(updates, success);
  }

  exists(path) {
    return new Promise((resolve, reject) => {
      try {
        this.db.ref(path).once('value', function (snapshot) {
          return resolve(snapshot.exists());
        });
      } catch (err) {
        reject();
      }
    });
  }

  async getDealStyle(deal, teamThemes = undefined) {
    const teamID = deal.team;
    const themeKey = deal.isTemplate ? deal.template.theme : deal.theme;
    let defaultTheme = null,
      dealStyle = null;

    // The first time we call Fire.getDeal, we also need to load in the available themes on the team
    // On subsequent realtime Deal loads (when Deal data changes),
    // we pass this dataset in to improve performance and avoid unnecessary Firebase calls,
    // because the source theme data never changes as a result of Deal interactions so we don't need to keep reloading.
    // We can also differentiate between first load (undefined) vs a team with no themes defined (null)
    if (teamThemes === undefined) {
      teamThemes = await Fire.load(`teams/${teamID}/themes`);
    }
    defaultTheme = _.find(teamThemes, { isDefault: true });

    // If there's no themeKey specified for this deal/template use default, or fall back to Outlaw default
    if (!themeKey) {
      if (defaultTheme) {
        dealStyle = StyleFactory.create(`${teamID}:${defaultTheme.themeKey}`, defaultTheme.dealStyle, deal.raw.style);
      } else {
        dealStyle = StyleFactory.create(DEFAULT_THEME, null, deal.raw.style);
      }
    }
    // If there IS a themeKey specified, use that as long as it actually exists
    else {
      const [, customKey] = themeKey.split(':');
      if (customKey) {
        const targetTheme = _.find(teamThemes, { themeKey: customKey });
        if (targetTheme) {
          dealStyle = StyleFactory.create(themeKey, targetTheme.dealStyle, deal.raw.style);
        }
        // We'll hit this case if there is a custom theme speficied but it has been deleted,
        // and a different theme is available as default on the team
        else if (defaultTheme) {
          dealStyle = StyleFactory.create(`${teamID}:${defaultTheme.themeKey}`, defaultTheme.dealStyle, deal.raw.style);
        } else {
          dealStyle = StyleFactory.create(DEFAULT_THEME, null, deal.raw.style);
        }
      }
      // We get here if themeKey is one of the built-in Outlaw ones (Default / Classic)
      else {
        dealStyle = StyleFactory.create(themeKey, null, deal.raw.style);
      }
    }

    return { dealStyle, teamThemes };
  }

  async getWorkflow(deal, teamWorkflows = undefined) {
    const teamID = deal.team;
    const workflowKey = deal.isTemplate ? deal.template.workflow : deal.workflowKey;
    let defaultWorkflowData = null,
      teamCheckpointGroups = null,
      workflow = null;

    // The first time we call Fire.getDeal, we also need to load in the available workflows on the team
    // On subsequent realtime Deal loads (when Deal data changes),
    // we pass this dataset in to improve performance and avoid unnecessary Firebase calls,
    // because the source workflow data never changes as a result of Deal interactions so we don't need to keep reloading.
    // We can also differentiate between first load (undefined) vs a team with no workflows defined (null)
    if (teamWorkflows === undefined) {
      [teamWorkflows, teamCheckpointGroups] = await Promise.all([
        Fire.load(`teams/${teamID}/workflows`),
        Fire.load(`teams/${teamID}/checkpointGroups`),
      ]);
    }
    defaultWorkflowData = _.find(teamWorkflows, { isDefault: true });

    // If there's no workflowKey specified for this deal/template use default, or fall back to Outlaw default
    if (!workflowKey) {
      if (defaultWorkflowData) {
        workflow = new Workflow({ ...defaultWorkflowData, teamCheckpointGroups });
      }
    }
    // If there IS a workflowKey specified, use that as long as it actually exists
    else {
      const pieces = workflowKey.split(':');
      const isPredefined = pieces.length === 1;
      const [workflowTeamID, customKey] = pieces;
      if (customKey) {
        const teamCheckpointGroups = await Fire.load(`teams/${teamID}/checkpointGroups`);
        const targetWorkflowData = _.find(teamWorkflows, { workflowKey: customKey });
        if (targetWorkflowData) {
          workflow = new Workflow({ ...targetWorkflowData, teamCheckpointGroups });
        }
        // We'll hit this case if there is a custom workflow specified but it has been deleted,
        // and a different workflow is available as default on the team
        else if (defaultWorkflowData) {
          workflow = new Workflow({ ...defaultWorkflowData, teamCheckpointGroups });
        }
      }
      // "Predefined" workflows are hardcoded into Outlaw and designated by a string without a ":", i.e. no team is specified
      // The default workflow (DEFAULT_WORKFLOW) has now been given a key of "default" (instead of null)
      // so that it's possible to still designate that as the workflow, even if the team has a designated custom workflow
      else if (isPredefined) {
        switch (workflowKey) {
          case DEFAULT_WORKFLOW.workflowKey:
            workflow = new Workflow(DEFAULT_WORKFLOW);
            break;
          case WORKFLOW_LEGAL.workflowKey:
            workflow = new Workflow(WORKFLOW_LEGAL);
            break;
          case WORKFLOW_SERVICE_PROVIDER.workflowKey:
            workflow = new Workflow(WORKFLOW_SERVICE_PROVIDER);
            break;
        }
      }
    }

    // If nothing specified above, use standard logic
    // We're using either the full 5-step automated workflow for native deals that have signature blocks
    // Or a simplified 2-step workflow (basically start/finish) if they don't
    // (This logic was ported from old Deal.workflow getter)
    if (!workflow) {
      if (deal.native) {
        if (deal.requiresSigning) {
          workflow = new Workflow(DEFAULT_WORKFLOW);
        } else {
          workflow = new Workflow(DEFAULT_WORKFLOW_STATIC);
        }
      }
      // EXTERNAL (PDF) deals use standard workflow too
      else {
        workflow = new Workflow(DEFAULT_WORKFLOW);
      }
    }

    return { workflow, teamWorkflows };
  }

  getDeal(dealID, success, realtime, failure) {
    const path = `deals/${dealID}`;
    let previousDeal = null;
    let dealStyle = null,
      teamThemes = undefined;
    let workflow = null,
      teamWorkflows = undefined;

    return new Promise((resolve, reject) => {
      this.load(
        path,
        async (json) => {
          try {
            let deal = json ? DealFactory.create(json) : null;

            if (deal) {
              // Load DealStyle in the first time,
              // but only reload if the Deal instance's custom style changes (e.g., number format updates)
              const styleChanged =
                previousDeal && !_.isEqual(_.get(previousDeal, 'raw.style', null), _.get(deal, 'raw.style', null));
              const themeChanged = previousDeal && previousDeal.theme !== deal.theme;
              if (styleChanged) {
                console.log('[DealView] Deal.style instance data changed - reloading');
              }
              if (themeChanged) {
                console.log('[DealView] Deal.theme changed - reloading');
              }
              if (!dealStyle || styleChanged || themeChanged) {
                const styleStore = await this.getDealStyle(deal, teamThemes);
                dealStyle = styleStore.dealStyle;
                // Capture a reference to the fetched data store of team themes so that we don't need to keep reloading them
                // We still subsequently call this.getDealStyle for the logic / merging / instantiation, but we pass in the already loaded themes
                teamThemes = styleStore.teamThemes;
              }
              deal.style = dealStyle;

              // Follow same pattern for workflows, but nothing can change about the workflow while Deal is loaded
              // So it's a little simpler
              if (!workflow) {
                const workflowStore = await this.getWorkflow(deal, teamWorkflows);
                workflow = workflowStore.workflow;
              }
              deal._workflow = workflow;
              // This is important: always give the Workflow a reference back to the (latest) Deal instance,
              // So that getters on Workflow/WorkflowSteps can be dynamic and dependent on Deal state
              workflow.deal = deal;
            }

            // Capture reference for style comparison above on subsequent reloads
            previousDeal = deal;

            if (typeof success == 'function') success(deal);
            resolve(deal);
          } catch (err) {
            console.error(`Fire.getDeal() error for deal ${dealID}`, err);
            reject(err);
          }
        },
        (err) => {
          if (typeof failure == 'function') failure(err);
          reject(err);
        },
        realtime
      );
    });
  }

  async updateDealInfo(info, newData, success) {
    const path = `deals/${info.dealID}/info`;
    const data = _.assign({}, info, newData);

    const updates = {};
    _.forEach(data, (val, key) => {
      if (!['dealID', 'connected'].includes(key)) updates[`${path}/${key}`] = val;
    });

    updates[`${path}/updated`] = new Date().getTime().toString();
    await this.update(updates);

    // When called this from front-end on a connected deal, sync updates via API
    // but don't await response as it can be slow
    const api = getAPIClient();
    if (info.connected && api) {
      api.call('syncConnectedDealInfo', { dealID: info.dealID });
    }

    if (typeof success === 'function') success();
  }

  /*
    We should standardize this a bit more soon, doing something like BEHAVIORS is doing
    but for Formatting ?
  */
  updateDealPDFCharSet(dealID, charSet) {
    // Only allow defined CHARSETS
    if (!dealID || !PDF_CHAR_SETS[charSet]) {
      return Promise.reject();
    }

    const path = `deals/${dealID}`;
    const updates = {
      [`${path}/pdfCharSet`]: charSet,
      [`${path}/updated`]: new Date().getTime().toString(),
    };

    return this.update(updates);
  }

  //NB: this is no longer used anywhere in app, but preserving for now just in case
  //as it has a (weird) special case where when the contract/template title is renamed,
  //it also synchonizes the title of the first summary section
  renameDeal(dealID, name, success) {
    //we need to retrieve the full deal object so that we have both section content and user role info
    return new Promise((resolve) => {
      this.getDeal(dealID, (deal) => {
        //first, set name at deal info level
        const updates = { [`deals/${dealID}/info/name`]: name };

        //also, look for the first top-level summary section
        const blocks = _.sortBy(_.filter(deal.sections, { sectiontype: SectionType.SUMMARY, indentLevel: 0 }), 'order');
        if (blocks.length > 0) updates[`deals/${dealID}/sections/${blocks[0].id}/displayname`] = name;

        this.update(updates, () => {
          if (typeof success == 'function') success();
          resolve();
        });
      });
    });
  }

  updateDealBehavior(dealID, aspect, value) {
    //restrict root-level deal aspect updates to the supported list in BEHAVIOR
    if (!BEHAVIOR[aspect]) return;

    const updates = { [`deals/${dealID}/${aspect}`]: value };

    // Make sure autoGuest can't be true if guestSigning is disabled
    if (aspect === 'guestSigning' && !value) {
      updates[`deals/${dealID}/autoGuest`] = false;
    }

    return this.update(updates);
  }

  async addActivity(log, user, action, message = null, meta = null) {
    const activity = {
      id: new Date().getTime().toString(),
      action,
      message,
      user: user.id || null,
      ip: user.ip || null,
      location: user.location || null,
      userAgent: user.userAgent || null,
      meta,
    };

    let path;

    if (log instanceof Deal) {
      path = `deals/${log.deal.dealID}/activity/${activity.id}`;
    } else if (log instanceof Payment) {
      path = `deals/${log.deal.dealID}/payments/${log.id}/activity/${activity.id}`;
    }
    // Note: Section, Item and Source are all instanceof Section (inheritance! yay!)
    else if (log instanceof Section) {
      path = `deals/${log.deal.dealID}/sections/${log.id}/activity/${activity.id}`;
    }

    if (path) {
      const updates = { [path]: activity };
      // Update deal's last updated stamp, as long as it's not a CREATE/READ/SEND event
      if ([DealAction.CREATE, DealAction.READ, DealAction.SEND].indexOf(action) === -1) {
        updates[`deals/${log.deal.dealID}/info/updated`] = activity.id;
      }
      await this.update(updates);

      // Kick Integration Triggers
      if (log instanceof Deal) {
        // We cannot do this !!! it simply does NOT work, that Fire frontend/backend is messing it up
        // Note: don't wait for it, it might take too much time
        // We must skip webook for now because it is trigerred from the backend and ApiClient.call() will fail.
        /* if (!isWebhook) {
          await this.triggerIntegration({dealID: log.dealID, user, action});
        } */
      }

      return Promise.resolve(activity);
    } else {
      return Promise.resolve();
    }
  }

  async updateExtractedClause(dealID, extractedClause, clauseName) {
    const path = `deals/${dealID}/extracted/sections/${clauseName}`;
    const updates = {};
    updates[`${path}/extractedClause`] = extractedClause;

    return this.update(updates);
  }

  async bulkUpdateVariableExtract(dealID, variables, enable) {
    const path = `deals/${dealID}/variables`;
    const updates = {};

    _.forEach(variables, ({ name }) => {
      updates[`${path}/${name}/extract`] = enable;
      updates[`${path}/${name}/extractType`] = enable ? EXTRACT_TYPE.STRUCTURED : null;
    });

    return this.update(updates);
  }

  async saveExtractedClauseLens(dealID, update) {
    const path = `deals/${dealID}/extracted/sections`;
    const updates = {};

    updates[`${path}/${update.key}/lensID`] = update.lensID;

    return this.update(updates);
  }

  async markVariableReviewed(variable) {
    const dealID = variable.deal.dealID;
    const path = `deals/${dealID}/extracted/variables/${variable.name}`;
    const updates = {};
    updates[`${path}/needsReview`] = false;

    const extracted = variable.deal.extracted;
    if (extracted) {
      const needsReview = !!_.find(extracted.variabales, { needsReview: true });

      if (needsReview !== extracted.needsReview) {
        updates[`deals/${dealID}/extracted//needsReview`] = needsReview;
      }
    }

    return this.update(updates);
  }

  editActivity(log, activity, newMessage, success) {
    const path = `deals/${log.deal.dealID}/sections/${log.id}/activity/${activity.id}`;
    const updates = { [`${path}/message`]: newMessage };
    return this.update(updates, success);
  }

  clearComments(section) {
    const updates = {};

    //only include comment activity (REJECT/RESOLVE)
    //this way log of section edits will remain intact
    section.comments.map(
      (c) => (updates[`deals/${section.deal.dealID}/sections/${section.id}/activity/${c.id}`] = null)
    );
    return this.update(updates);
  }

  saveSection(section, newData, success, failure) {
    const { id, order, parentid, sourceorder, sourceparentid } = section;

    // allow empty/whitespace strings to delete values
    if (newData.content && newData.content.trim() == '') newData.content = null;
    if (newData.displayname && newData.displayname.trim() == '') newData.displayname = null;

    const data = _.merge({ id, order, parentid, sourceorder, sourceparentid }, newData);
    const path = `deals/${section.deal.dealID}/sections/${section.id}`;

    // avoid attempting to write undefined values
    this.clean(data);

    const updates = {};
    //compile individual field updates so that we don't accidentally blow away any other section properties
    _.forEach(data, (val, key) => {
      updates[`${path}/${key}`] = val;
    });
    return this.update(updates, success, failure);
  }

  saveSectionStyle(section, styleProps, syncSibs = false, type = null) {
    const updates = {};
    const sectionsToUpdate = syncSibs ? section.sourceParent.sourceChildren : [section];
    _.forEach(sectionsToUpdate, (s) => {
      let path = `deals/${s.deal.dealID}/sections/${s.id}/style`;
      if (type) path += `/${type}`;
      _.forEach(styleProps, (val, key) => {
        updates[`${path}/${key}`] = val;
      });
    });

    return this.update(updates);
  }

  deleteSectionStyle(section, syncSibs = false, type) {
    const updates = {};
    const sectionsToUpdate = syncSibs ? section.sourceParent.sourceChildren : [section];
    if (!type) return;
    _.forEach(sectionsToUpdate, (s) => {
      const path = `deals/${s.deal.dealID}/sections/${s.id}/style/${type}`;
      updates[`${path}`] = null;
    });

    return this.update(updates);
  }

  saveSectionVersion(section, user, title, body, success) {
    const version = {
      id: getVersionID(),
      user: user.id,
      title,
      body,
    };

    //avoid writing null values:
    this.clean(version);

    const path = `deals/${section.deal.dealID}/sections/${section.id}/versions/${version.id}`;
    const updates = {
      [path]: version,
      //saving a version updates the deal's last updated stamp
      [`deals/${section.deal.dealID}/info/updated`]: new Date().getTime().toString(),
    };
    return this.update(updates, success);
  }

  // https://firebase.google.com/docs/database/web/offline-capabilities
  // https://firebase.google.com/docs/firestore/solutions/presence
  lockSection(dealID, sectionID, { uid, displayName }) {
    const lockRef = this.db.ref(`locks/deals/${dealID}/${sectionID}`);

    // This is a bit roundabout but we actually need to queue up the routine that will happen on disconnect FIRST
    // This tells Firebase what to do *server-side* in the event of a disconnect, but does not execute until disconnection
    // So this promise resolves (causing the lock to be established) once the server has received the instructions
    // In other words, the code fires in reverse order from how it appears:
    // 1. The lock is established right now
    // 2. When client disconnects, server auto-clears the lock
    const promise = new Promise((resolve) => {
      lockRef
        .onDisconnect()
        .set(null)
        .then(() => {
          lockRef
            .set({
              uid,
              displayName,
              date: new Date().getTime(),
            })
            .then(resolve);
        });
    });
    return promise;
  }

  unlockSection(dealID, sectionID) {
    return this.update({ [`locks/deals/${dealID}/${sectionID}`]: null });
  }

  watchSectionLocks(dealID, onUpdate) {
    this.load(`locks/deals/${dealID}`, onUpdate, null, true);
  }

  unwatchSectionLocks(dealID) {
    this.db.ref(`locks/deals/${dealID}`).off();
  }

  updateSectionDeletion(section, deleted, deletionApproved, success) {
    const path = `deals/${section.deal.dealID}/sections/${section.id}`;
    const updates = {
      [`${path}/deleted`]: deleted || null,
      [`${path}/deletionApproved`]: deletionApproved || null,
    };
    this.update(updates, success);
  }

  moveSection(section, dir, source) {
    if (!section.canMove(dir, source)) return;

    const path = `deals/${section.deal.dealID}/sections`;
    const updates = {};
    let sibs = [],
      currentParent = null,
      newParent = null,
      newIndex = null,
      parentKey,
      orderProp;

    if (source) {
      sibs = section.sourceParent.sourceChildren;
      currentParent = section.sourceParent;
      parentKey = 'sourceparentid';
      orderProp = 'sourceorder';
    } else {
      sibs = section.parent.children;
      currentParent = section.parent;
      parentKey = 'parentid';
      orderProp = 'order';
    }

    switch (dir) {
      //moving "up" simply decrements current child index (same parent)
      case 'up':
        newParent = currentParent;
        newIndex = sibs.indexOf(section) - 1;
        break;
      //moving "down" simply increments current child index (same parent)
      case 'down':
        newParent = currentParent;
        newIndex = sibs.indexOf(section) + 1;
        break;
      //moving "left" (outdenting) assigns this section to it's parent's parent
      //i.e., makes this a sibling of its former parent
      //at the next index after parent
      case 'left':
        newParent = source ? currentParent.sourceParent : currentParent.parent;
        newIndex = source
          ? newParent.sourceChildren.indexOf(currentParent) + 1
          : newParent.children.indexOf(currentParent) + 1;
        //also, current later siblings are then assigned to the current parent
        //if outdenting a LIST, avoid accidentally giving it sourceChildren (which it can't have)
        if (section.sectiontype !== SectionType.LIST) {
          sibs.map((sib, idx) => {
            if (idx > sibs.indexOf(section)) {
              updates[`${path}/${sib.id}/${parentKey}`] = section.id;
            }
          });
        }
        break;
      // Moving "right" (indenting) makes this section a child of its former prior sibling at the last index
      case 'right':
        // However, certain edge cases allow some SUMMARY sections to have non-SUMMARY children, e.g., LIST sections
        // Also, LIST sections' children are ITEMs which are also not SOURCE
        // So for the purposes of moving, we need to make sure we're looking at same-type siblings
        // Without this filtering in place, indenting an orphaned SUMMARY can cause it to disappear
        if (!source) {
          sibs = _.filter(section.parent.children, { sectiontype: section.sectiontype });
        }
        newParent = sibs[sibs.indexOf(section) - 1];
        newIndex = source ? newParent.sourceChildren.length : newParent.children.length;
        break;
    }
    if (newParent != null && newIndex != null) {
      //now, remove from current array and then add to new parent (even if it's the same) at new index

      sibs.splice(sibs.indexOf(section), 1);
      const newSibs = source ? newParent.sourceChildren : newParent.children;

      // Look at existing list before inserting child into it.
      // If there are custom SectionStyles there, adopt them;
      // otherwise maintain custom styles that may have been present on THIS section
      // For example, this ensures that a bulleted section will transform to its parent list style when outdented
      if (['left', 'right'].includes(dir) && newSibs.length > 0) {
        const sibsStyle = newSibs[0].style.raw || { numbering: null };
        const movedSectionStyle = section.style.raw || {};

        const mergedStyles = _.assign(movedSectionStyle, sibsStyle);

        updates[`${path}/${section.id}/style`] = _.size(mergedStyles) > 0 ? mergedStyles : null;
      }

      newSibs.splice(newIndex, 0, section);
      //collect updates for sourceOrder indices of all new siblings
      updates[`${path}/${section.id}/${parentKey}`] = newParent.id;

      newSibs.map((child, idx) => (updates[`${path}/${child.id}/${orderProp}`] = idx));
      return this.update(updates);
    }
  }

  // Turning a normal (SOURCE or HEADER) section into an APPENDIX is a special case
  // Because we want to automatically read forward and nest subsequent source content
  // into the new APPENDIX (so as not to orphan it)...
  // BUT we want to stop once we find either another APPENDIX or SIGNATURE section
  async appendicize(section) {
    // Before making any updates, gather subsequent siblings that will be nested
    const sibs = section.sourceParent.sourceChildren;
    let newChildren = [],
      updates = {};
    let idx = _.findIndex(sibs, { id: section.id });
    while (idx > -1 && idx < sibs.length) {
      idx += 1;
      const nextSibling = sibs[idx];
      if (!nextSibling || [SectionType.APPENDIX, SectionType.SIGNATURE].indexOf(nextSibling.sectiontype) > -1) break;
      newChildren.push(nextSibling);
    }

    // Now turn the current section into an APPENDIX
    await this.saveSection(section, { sectiontype: SectionType.APPENDIX, hideOrder: true });

    // Finally, if we have children to nest, compile those updates too and then make them in one batch
    if (newChildren.length > 0) {
      _.map(newChildren, (child) => (updates = _.merge({}, updates, this.moveSection(child, 'right', true, true))));
    }
    if (!_.isEmpty(updates)) return this.update(updates);
    else return Promise.resolve();
  }

  getNewSectionID(dealID) {
    return this.db.ref(`deals/${dealID}/sections`).push().key;
  }

  async ingestDeal(deal, user, { sections, title, numbering, omitted }, mergeType) {
    const promises = [];

    //sections should always be present -- first promise is for core contract data
    const updates = {},
      flat = [];
    const path = `deals/${deal.dealID}/sections`;
    const p = new Promise((r) => {
      //recursive function to do 3 things to prep update:
      //1. create IDs and necessary parent/child relationships in data
      //2. clean unnecessary properties from passed in data
      //3. flatten into simple array that we can loop through again in various cases below to build update
      const buildFlat = (sections, parent) => {
        _.forEach(sections, (sectionJSON, idx) => {
          //create a new ID if one was not found from import
          if (sectionJSON.id == null) sectionJSON.id = this.db.ref(path).push().key;

          const json = _.merge(
            {},
            _.pick(sectionJSON, ['id', 'sectiontype', 'displayname', 'content', 'hideOrder', 'activity']),
            {
              sourceparentid: parent?.id || ROOT_ID,
              sourceorder: idx,
            }
          );

          if (json.sectiontype === 'external') {
            if (_.get(sectionJSON, 'lines.length') > 0) {
              json.lines = sectionJSON.lines;
            }
            if (sectionJSON.scrapedNumber) {
              json.scrapedNumber = sectionJSON.scrapedNumber;
            }
            if (sectionJSON.subType) {
              json.subType = sectionJSON.subType;
            }
          }

          flat.push(json);

          if (sectionJSON.children) buildFlat(sectionJSON.children, sectionJSON);
        });
      };

      buildFlat(sections, deal.root);

      switch (mergeType) {
        //OVERWRITE means delete all existing source content and replace with new
        case MERGE_TYPE.OVERWRITE:
          //first go through the existing deal sections and null everything out
          _.forEach(deal.sections, (s) => {
            updates[`${path}/${s.id}`] = null;
          });
          //recreate root
          updates[`${path}/root`] = Section.createRoot();

          //then prep update payload from new data
          _.forEach(flat, (s) => {
            updates[`${path}/${s.id}`] = s;
          });
          break;

        //MERGE, VERSION and REDLINE mean to look for existing sections from IDs and overwrite content if they are found
        case MERGE_TYPE.MERGE:
        case MERGE_TYPE.VERSION:
        case MERGE_TYPE.REDLINE:
          const mergeStatus = {};

          flat.map((s) => {
            //for each inbound section, first see if that section already existed
            const existing = deal.sections[s.id];
            let newVersion;

            if (existing) {
              //for MERGE, we don't care about changes, just overwrite whatever is there
              if (mergeType == MERGE_TYPE.MERGE) {
                updates[`${path}/${s.id}/displayname`] = s.displayname || null;
                updates[`${path}/${s.id}/content`] = s.content || null;
                mergeStatus[s.id] = 'merged';
              }

              //for VERSION, compare each section content with what's there
              else {
                newVersion = new Version({
                  user: user.id,
                  title: s.displayname || null,
                  body: s.content || null,
                });

                //only update if something has changed!
                const changes = hasDiffs(existing.currentVersion.body, newVersion.body);

                if (changes) {
                  // Updating to use the new Diff model for now, but this code is going away very soon either way
                  if (mergeType == MERGE_TYPE.REDLINE) {
                    const newEntityData = {
                      version: existing.currentVersion.id,
                      user: user.id,
                      id: new Date().getTime().toString(),
                    };
                    newVersion.body = redline(existing.currentVersion.body, newVersion.body, newEntityData);
                  }

                  updates[`${path}/${s.id}/versions/${newVersion.id}`] = newVersion.json;
                  mergeStatus[s.id] = 'updated';
                  console.log(`Updated ${s.id} with new version`);
                } else {
                  // updates[`${path}/${s.id}/versions/${newVersion.id}`] = newVersion;
                  mergeStatus[s.id] = 'unchanged';
                  // console.log(`Skipped ${s.id} -- no changes`);
                }
              }

              //whether there are text changes or not, we may also need to update order
              if (existing.sourceorder != s.sourceorder) {
                updates[`${path}/${s.id}/sourceorder`] = s.sourceorder;
              }
            } else {
              //new section. we still need to store it a base section without content and then add a version
              newVersion = new Version({
                user: user.id,
                title: s.displayname || null,
                body: s.content || null,
              });

              s.content = null;
              s.displayname = null;

              if (mergeType == MERGE_TYPE.REDLINE) {
                newVersion.body = redline('', newVersion.body, { version: BASE_ID });
              }

              s.versions = { [`${newVersion.id}`]: newVersion.json };
              updates[`${path}/${s.id}`] = s;

              // console.log(`Added ${s.id}`, s);

              mergeStatus[s.id] = 'added';
            }
          });

          //now, loop through existing sections to see if anything was NOT found on the import
          const source = deal.applyConditions(deal.buildSource(true));
          source.map((s) => {
            if (!mergeStatus[s.id]) {
              //mark section as deleted
              updates[`${path}/${s.id}/deleted`] = true;

              //if NOT redlining, then auto-approve so that section disappears
              if (mergeType != MERGE_TYPE.REDLINE) {
                updates[`${path}/${s.id}/deletionApproved`] = true;
              }

              mergeStatus[s.id] = 'removed';

              // console.log(`Removed ${s.id}`, s);
            }
          });

          break;

          //TODO
          break;
        default:
          break;
      }

      //store omitted content if we have any
      if (deal.isExternal && omitted.length > 0) {
        updates[`deals/${deal.dealID}/omitted`] = _.map(omitted, 'json');
      }

      //uploading a new version updates the deal's last updated stamp
      updates[`deals/${deal.dealID}/info/updated`] = new Date().getTime().toString();
      this.update(updates, () => r());
    });

    promises.push(p);

    //add promise for renaming deal
    if (title) promises.push(this.renameDeal(deal.dealID, title));

    //add promise for style update
    if (numbering) promises.push(this.saveDealStyle(deal, 'numbering', numbering));

    Promise.all(promises)
      .then((results) => {
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  addSourceBatch(atSection, batch, success) {
    //if nothing is passed in, add to root
    const sibs = atSection.sourceParent.sourceChildren;
    const newSections = [];
    const path = `deals/${atSection.deal.dealID}/sections`;
    const replace = !atSection.content && !atSection.displayname;
    const baseIndex = sibs.indexOf(atSection) + (replace ? 0 : 1);

    batch.map((json, idx) => {
      //if current section is blank, replace it with first item in batch instead of just appending all
      const newIndex = sibs.indexOf(atSection) + idx + (replace ? 0 : 1);
      const newData = json;

      newData.sectiontype = 'SOURCE';
      newData.sourceparentid = atSection.sourceparentid;
      //if current section isn't numbered, ensure all pasted ones remain unnumbered
      if (atSection.hideOrder) newData.hideOrder = true;

      //replace current (atSection) with first item in batch
      const newID = replace && idx == 0 ? atSection.id : this.db.ref(path).push().key;
      newData.id = newID;

      //always push (even in replace) so that it will get overwritten in db
      newSections.push(newData);
    });

    const updates = {};

    //still only write order for some and full data for new ones
    var newOrder = 0;
    const writeNewSections = () => {
      newSections.map((s) => {
        s.sourceorder = newOrder;
        updates[`${path}/${s.id}`] = s;
        // console.log('NEW', s.displayname, newOrder);
        newOrder += 1;
      });
    };
    sibs.map((sib, idx) => {
      if (idx == baseIndex) writeNewSections();
      if (replace && sib == atSection) return;

      updates[`${path}/${sib.id}/sourceorder`] = newOrder;
      // console.log(sib.displayname, newOrder);
      newOrder += 1;
    });
    //if we're appending to end, the above loop will never write the new sections
    if (!replace && baseIndex == sibs.length) writeNewSections();

    const onAdd = typeof success == 'function' ? () => success(replace) : null;
    return this.update(updates, onAdd);
  }

  // For bulk copy/pasting from external sources (html/word) into ITEM sections
  // Similar to addSourceBatch above but the parent/structure is different enough
  // that a separate function for this purpose is more readable
  async addItemBatch(atSection, batch, success, addAsChildren = false) {
    const sibs = addAsChildren ? atSection.children : atSection.parent.children;
    const newItems = [];
    const updates = {};
    const path = `deals/${atSection.deal.dealID}/sections`;
    const replace = !atSection.content && !atSection.displayname && !addAsChildren;
    const baseIndex = sibs.indexOf(atSection) + (replace ? 0 : 1);

    batch.map((json, idx) => {
      const newData = _.cloneDeep(json);

      newData.sectiontype = SectionType.ITEM;
      newData.parentid = addAsChildren ? atSection.id : atSection.parentid;
      // If current section isn't numbered, ensure all pasted ones remain unnumbered
      if (atSection.hideOrder) newData.hideOrder = true;

      // Replace current (atSection) with first item in batch
      newData.id = replace && idx === 0 ? atSection.id : this.db.ref(path).push().key;

      //always push (even in replace) so that it will get overwritten in db
      newItems.push(newData);
    });

    //still only write order for some and full data for new ones
    let newOrder = 0;
    const writeNewItems = () => {
      newItems.map((item) => {
        item.order = newOrder;
        updates[`${path}/${item.id}`] = item;
        newOrder += 1;
      });
    };
    sibs.map((sib, idx) => {
      if (idx === baseIndex) writeNewItems();
      if (replace && sib === atSection) return;
      updates[`${path}/${sib.id}/order`] = newOrder;
      newOrder += 1;
    });
    //if we're appending to end, the above loop will never write the new sections
    if (!replace && baseIndex == sibs.length) writeNewItems();

    await this.update(updates);
    if (typeof success === 'function') success(replace);
  }

  clearList(list) {
    const items = list.items;
    if (items.length > 0) {
      const updates = {};
      _.forEach(items, (item) => {
        updates[`deals/${list.deal.dealID}/sections/${item.id}`] = null;
      });
      return this.update(updates);
    }
  }

  async addHeaderFooterSection(
    atSection,
    sectionType,
    subSectionType = HEADER_FOOTER_SUB_SECTION_TYPES.ONE_COL,
    success,
    json = {},
    updatedContent,
    before,
    applyDefault = false,
    teamFooter = null
  ) {
    const sec = atSection;
    const addingToRoot = sec.isRoot;
    const isFooter = sectionType === SectionType.TEMPLATE_FOOTER;
    const isHeader = sectionType === SectionType.TEMPLATE_HEADER;

    let sibs = null;
    if (addingToRoot) {
      sibs = sec.children;
    } else {
      sibs = sec.parent.children;
    }
    let sibFooters = sibs.filter((s) => s?.sectiontype === SectionType.TEMPLATE_FOOTER);
    let sibHeaders = sibs.filter((s) => s?.sectiontype === SectionType.TEMPLATE_HEADER);
    const newIndex = before ? sibs.indexOf(sec) : sibs.indexOf(sec) + 1;
    const sectionTypeOrderProp = isFooter ? 'footerOrder' : 'headerOrder';
    const newData = json || {};
    const orderProp = 'order';

    if (!newData.sectiontype) newData.sectiontype = sectionType;
    if (!newData.parentid) newData.parentid = addingToRoot ? sec.id : sec.parentid;

    const path = `deals/${sec.deal.dealID}/sections`;
    const newID = this.db.ref(path).push().key;
    newData.id = newID;
    newData.subSectionType = subSectionType;
    newData.isDefault = applyDefault ? true : null;
    newData.headerFooterConfigKey = applyDefault ? 'allPages' : null;

    //push the new section into the children here so that the indices are correct for update below
    const newSection = Deal.createSection(newData, sec.deal);
    sibs.splice(newIndex, 0, newSection);

    //we need to insert this section in part of a list of child updates,
    //because insertion changes the order of any later siblings
    //re-order all siblings to be safe
    const updates = {};
    sibs.map((child, idx) => {
      //for the section being added, we set the order here and its whole node will be added to the update below
      if (child == newSection) newData[orderProp] = idx;
      //for other sibs, we only need the order update
      else updates[`${path}/${child.id}/${orderProp}`] = idx;
    });

    if (isHeader) {
      sibHeaders.splice(newIndex, 0, newSection);
      sibHeaders.map((child, idx) => {
        if (child.id === newSection.id) {
          newData[sectionTypeOrderProp] = idx;
          newData.displayname = `Header ${idx + 1}`;
        } else {
          updates[`${path}/${child.id}/${sectionTypeOrderProp}`] = idx;
          updates[`${path}/${child.id}/displayname`] = `Header ${idx + 1}`;
        }
      });
    }

    if (isFooter) {
      sibFooters.splice(newIndex, 0, newSection);
      sibFooters.map((child, idx) => {
        if (child.id === newSection.id) {
          newData[sectionTypeOrderProp] = idx;
          newData.displayname = applyDefault ? 'Default Footer' : `Footer ${idx + 1}`;
        } else {
          updates[`${path}/${child.id}/${sectionTypeOrderProp}`] = idx;
          updates[`${path}/${child.id}/displayname`] = `Footer ${idx + 1}`;
        }
      });
    }

    updates[`${path}/${newID}`] = newData;
    const isTwoCol = subSectionType === HEADER_FOOTER_SUB_SECTION_TYPES.TWO_COL;
    const isThreeCol = subSectionType === HEADER_FOOTER_SUB_SECTION_TYPES.THREE_COL;

    if (isTwoCol || isThreeCol) {
      const firstColID = this.db.ref(path).push().key;
      const secondColID = this.db.ref(path).push().key;

      //So if applyDefault is pased in then we will set (legacy) the left hand side to team footer and right hand side to Page # of #
      updates[`${path}/${firstColID}`] = {
        id: firstColID,
        sectiontype: sectionType,
        hideOrder: true,
        sourceparentid: newID,
        sourceorder: 0,
        content: applyDefault && teamFooter ? `<p>${teamFooter}</p>` : null,
        isDefault: applyDefault ? true : null,
      };
      updates[`${path}/${secondColID}`] = {
        id: secondColID,
        sectiontype: sectionType,
        hideOrder: true,
        sourceparentid: newID,
        sourceorder: 1,
        content: applyDefault ? '<p>[~PageCount]</p>' : null,
        style: applyDefault
          ? {
              align: 'right',
            }
          : null,
        isDefault: applyDefault ? true : null,
      };

      if (isThreeCol) {
        const thirdColID = this.db.ref(path).push().key;
        updates[`${path}/${thirdColID}`] = {
          id: thirdColID,
          sectiontype: sectionType,
          hideOrder: true,
          sourceparentid: newID,
          sourceorder: 2,
          content: null,
          style: null,
        };
      }
    }

    //if updated content for the *current* ("atSection") section is specified, update that too
    if (updatedContent && !addingToRoot) updates[`${path}/${atSection.id}/content`] = updatedContent;
    try {
      await this.update(updates);
      if (typeof success === 'function') success(newID);
      return Promise.resolve(newID);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async addSection(atSection, source, success, json, updatedContent, before) {
    //if nothing is passed in, add to root
    const sec = atSection;
    const addingToRoot = sec.isRoot;

    let sibs = null;
    if (addingToRoot) {
      sibs = source ? sec.sourceChildren : sec.children;
    } else {
      sibs = source ? sec.sourceParent.sourceChildren : sec.parent.children;
    }
    const newIndex = before ? sibs.indexOf(sec) : sibs.indexOf(sec) + 1;
    const orderProp = source ? 'sourceorder' : 'order';
    const newData = json || {};

    if (source) {
      if (!newData.sectiontype) newData.sectiontype = SectionType.SOURCE;
      if (!newData.sourceparentid) newData.sourceparentid = addingToRoot ? sec.id : sec.sourceparentid;
      if (sec && sec.hideOrder) newData.hideOrder = true;
    } else {
      if (!newData.sectiontype) newData.sectiontype = SectionType.SUMMARY;
      if (!newData.parentid) newData.parentid = addingToRoot ? sec.id : sec.parentid;
    }

    // If there's a custom style (numbering, alignment, columns, etc) already on the currently selected section,
    // apply it to the new section as long as it's a normal SOURCE section (contract body)
    const customStyle = _.get(atSection, 'style.raw');
    if (customStyle && newData.sectiontype === SectionType.SOURCE && !atSection.isList) {
      newData.style = atSection.style.raw;
    }

    const path = `deals/${sec.deal.dealID}/sections`;
    const newID = this.db.ref(path).push().key;
    newData.id = newID;

    //push the new section into the children here so that the indices are correct for update below
    const newSection = Deal.createSection(newData, sec.deal);
    sibs.splice(newIndex, 0, newSection);

    //we need to insert this section in part of a list of child updates,
    //because insertion changes the order of any later siblings
    //re-order all siblings to be safe
    const updates = {};
    sibs.map((child, idx) => {
      //for the section being added, we set the order here and its whole node will be added to the update below
      if (child == newSection) newData[orderProp] = idx;
      //for other sibs, we only need the order update
      else updates[`${path}/${child.id}/${orderProp}`] = idx;
    });
    updates[`${path}/${newID}`] = newData;

    //when we add a CAPTION section, we also need to concurrently add 2 child SOURCE sections
    //1 for lhs and 1 for rhs -- this is ALWAYS the structure of a caption (2 columns)
    if (newData.sectiontype === SectionType.CAPTION) {
      const leftID = this.db.ref(path).push().key;
      const rightID = this.db.ref(path).push().key;
      updates[`${path}/${leftID}`] = {
        id: leftID,
        sectiontype: SectionType.SOURCE,
        hideOrder: true,
        sourceparentid: newID,
        sourceorder: 0,
      };
      updates[`${path}/${rightID}`] = {
        id: rightID,
        sectiontype: SectionType.SOURCE,
        hideOrder: true,
        sourceparentid: newID,
        sourceorder: 1,
      };
    }

    //if updated content for the *current* ("atSection") section is specified, update that too
    if (updatedContent && !addingToRoot) updates[`${path}/${atSection.id}/content`] = updatedContent;
    try {
      await this.update(updates);
      if (typeof success === 'function') success(newID);
      return Promise.resolve(newID);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  //specialized (simplified) version of addSection
  addItemSection(parent, atIndex) {
    return new Promise((resolve) => {
      //a scope can have APPENDIX type child sections to tie it to its place in pdf output
      //so we need to only show user-created ITEM type sections here
      const sibs = _.filter(parent.children, { sectiontype: 'ITEM' });

      const path = `deals/${parent.deal.dealID}/sections`;
      const scope = {
        sectiontype: 'ITEM',
        parentid: parent.id,
        order: atIndex,
        id: this.db.ref(path).push().key,
      };

      const updates = { [`${path}/${scope.id}`]: scope };

      //push the new section into the children here so that the indices are correct for update below
      const newSection = Deal.createSection(scope, parent.deal);
      sibs.splice(atIndex, 0, newSection);
      sibs.map((item, idx) => {
        if (item.id != scope.id) updates[`${path}/${item.id}/order`] = idx;
      });

      this.update(updates, () => resolve(scope.id));
    });
  }

  //Deleting an AI block is a special case because we are not actually removing the section
  //we are just clearing the content and versions so the generate button can persist.
  clearAIBlock(section) {
    const path = `deals/${section.deal.dealID}/sections`;
    const updates = {};

    updates[`${path}/${section.id}/content`] = null;
    updates[`${path}/${section.id}/versions`] = null;

    return this.update(updates);
  }

  removeSection(section, success) {
    const src = SectionType.src(section.sectiontype);
    const children = src ? section.sourceChildren : section.children;
    const parent = src ? section.sourceParent : section.parent;
    const sibs = src ? parent.sourceChildren : parent.children;
    const orderProp = src ? 'sourceorder' : 'order';
    const parentProp = src ? 'sourceparentid' : 'parentid';
    const path = `deals/${section.deal.dealID}/sections`;
    const index = sibs.indexOf(section);
    const updates = {};
    //this will delete the section itself
    updates[`${path}/${section.id}`] = null;
    sibs.splice(index, 1);

    //if section had children, make them children of section's parent (if same time) (promote them)
    //or orphan them if they were linked summary
    //the orphaning will not affect source structure because it uses sourceparentid, not parentid
    //at the section's former index
    children.map((child, idx) => {
      const orphan = section.sectiontype == SectionType.SUMMARY && SectionType.src(child.sectiontype);
      if (orphan) {
        updates[`${path}/${child.id}/parentid`] = null;
      }
      // When deleting (deprecated) SCOPE/PAYMENT sections from Overview, also delete their associated APPENDIX sections from Contract
      else if ([SectionType.SCOPE, SectionType.PAYMENT].includes(section.sectiontype) && child.isAppendix) {
        updates[`${path}/${child.id}`] = null;
      } else {
        updates[`${path}/${child.id}/${parentProp}`] = parent.id;
        sibs.splice(index + idx, 0, child);
      }
    });

    // Inverse of deprecation case above; when deleting legacy linked APPENDIX sections from Contract,
    // Also delete associated parent SCOPE/PAYMENT from Overview
    if (
      section.isAppendix &&
      [SectionType.SCOPE, SectionType.PAYMENT].includes(section.appendixType) &&
      section.parent
    ) {
      updates[`${path}/${section.parent.id}`] = null;
    }

    //update ordering of all sibs now that this one has been spliced out
    sibs.map((child, idx) => (updates[`${path}/${child.id}/${orderProp}`] = idx));

    //if there are reference vars in the section being deleted,
    //and they only appear in that section (which should always be the case)
    //also delete the underlying vars so that we don't end up with unused vars
    _.forEach(section.references, (ref) => {
      if (ref.locations.length === 1) {
        Fire.deleteVariable(section.deal, ref);
      }
    });

    return this.update(updates, success);
  }

  removeCaption(section) {
    //Deletion of a CAPTION section is generally called while focusing one of its columns (children)
    //So we need to first ensure that we're properly targeting the parent
    let parent = null;
    if (section.sectiontype === SectionType.CAPTION) {
      parent = section;
    } else if (_.get(section, 'sourceParent.sectiontype') === SectionType.CAPTION) {
      parent = section.sourceParent;
    }
    if (!parent) return;

    const path = `deals/${section.deal.dealID}/sections`;
    const sibs = parent.sourceParent.sourceChildren;
    const index = sibs.indexOf(parent);
    const updates = {};

    // Now remove parent (CAPTION) AND all (both) children
    updates[`${path}/${parent.id}`] = null;
    _.forEach(parent.sourceChildren, (child) => {
      updates[`${path}/${child.id}`] = null;
    });

    // And update ordering of all sibs with the caption removed
    sibs.splice(index, 1);
    _.forEach(sibs, (sib, idx) => {
      updates[`${path}/${sib.id}/sourceorder`] = idx;
    });

    return this.update(updates);
  }

  removeTwoColHeaderFooter(section) {
    //Deletion of a Header/Footer sections are generally called while focusing one of its columns (children)
    //So we need to first ensure that we're properly targeting the parent
    let parent = null;
    if (section.subSectionType === HEADER_FOOTER_SUB_SECTION_TYPES.TWO_COL) {
      parent = section;
    } else if (
      [SectionType.TEMPLATE_FOOTER, SectionType.TEMPLATE_HEADER].includes(_.get(section, 'sourceParent.sectiontype'))
    ) {
      parent = section.sourceParent;
    }
    if (!parent) return;

    const path = `deals/${section.deal.dealID}/sections`;
    const sibs = parent.parent.children.filter((s) => s?.sectiontype === parent.sectiontype);
    const index = sibs.indexOf(parent);
    const updates = {};

    // Now remove parent (Header/Footer) AND all (both) children
    updates[`${path}/${parent.id}`] = null;
    _.forEach(parent.sourceChildren, (child) => {
      updates[`${path}/${child.id}`] = null;
    });

    const sectionTypeOrderProp = SectionType.TEMPLATE_FOOTER === parent.sectionType ? 'footerOrder' : 'headerOrder';

    // And update ordering of all sibs with the header/footer removed
    sibs.splice(index, 1);
    _.forEach(sibs, (sib, idx) => {
      updates[`${path}/${sib.id}/${sectionTypeOrderProp}`] = idx;
    });

    return this.update(updates);
  }

  //link or unlink source content to parent non-source content via parentid
  toggleSectionLink(summaryID, source, link) {
    const path = `deals/${source.deal.dealID}/sections/${source.id}`;
    const updates = {};
    if (link) {
      updates[`${path}/parentid`] = summaryID;
    } else {
      updates[`${path}/parentid`] = null;
    }

    return this.update(updates);
  }

  //link or unlink source content to parent non-source content via parentid
  toggleAIBlockLink(aiBlock) {
    const path = `deals/${aiBlock.deal.dealID}/sections/${aiBlock.id}/aiPrompt`;

    const updates = {};
    updates[path] = this.clean(aiBlock.aiPrompt.json);
    return this.update(updates);
  }

  async createDeal({
    user,
    title,
    dealType,
    teamID,
    templateKey,
    status = DealStatus.DRAFT.data,
    branding,
    users,
    sections,
    variables,
    connections,
    lastModified,
    style,
    creatingSearchDoc = true,
    createdFromBatch = false,
    templateData = null,
    hasLenses = false,
    documentAI = false,
    webhook = false,
    ...restParams
  }) {
    const newID = this.db.ref('deals').push().key;
    let deal = {
      dealID: newID,
      sections: sections || { root: Section.createRoot() },
      users: {},
      dealType: dealType || DEAL_TYPE.NATIVE,
    };

    // Set this to prevent an update running before the search doc is created first
    if (creatingSearchDoc) deal.creatingSearchDoc = Date.now();

    const owner = {
      uid: user.id,
      key: user.id,
      role: DealRole.OWNER,
      inviteStatus: InviteStatus.OWNED,
    };

    // If owner ALSO appears in the users list (from CSV import),
    // use info from that contract instead of user's standard profile
    const csvOwner = users && users.length ? _.find(users, { uid: user.id }) : null;
    if (csvOwner) _.assign(owner, csvOwner);
    else if (user.info) _.assign(owner, user.info);
    // Establish owner DealUser
    deal.users[user.id] = this.clean(owner);

    // Now, if others are passed in (as array), convert to keyed object at creation time
    // This needs to be done here (inside Fire) because we need to get new keys from Firebase
    // If the users are not Outlaw teammates (which will be most of the time)
    _.forEach(users, (dealUser) => {
      // Skip owner -- we just did that one!
      if (dealUser.uid === user.id) return;
      // If we already have a key (which will be identical to uid -- Outlaw user) then just add as is
      if (dealUser.key) deal.users[dealUser.key] = dealUser;
      // Otherwise we need to create one
      else {
        const newKey = this.db.ref(`deals/${newID}/users`).push().key;
        dealUser.key = newKey;
        deal.users[newKey] = this.clean(dealUser);
      }
    });

    const created = new Date().getTime().toString();
    deal.info = {
      dealID: deal.dealID,
      name: title,
      status,
      created,
      updated: created,
    };

    // Flag deal as created from batch, so we can set a lower priority in watcher queue
    if (createdFromBatch) deal.info.createdFromBatch = true;

    // Flag if created by a webhook, to prevent an indexing race condition due to multiple updates
    if (webhook) deal.info.webhook = true;

    if (branding) _.assign(deal.info, branding);

    if (style) _.assign(deal, this.clean({ style }));

    // If we're uploading a file it comes with its own lastModified date so use that
    // Otherwise grab a new timestamp
    if (lastModified) deal.info.updated = lastModified.toString();
    else deal.info.updated = new Date().getTime().toString();

    deal.info.sourceTeam = teamID;
    if (templateKey) {
      deal.info.sourceTemplate = templateKey;
      deal.info.sourceTemplateKey = `${teamID}:${templateKey}`;
    } else {
      deal.info.sourceTemplateKey = teamID;
    }

    if (dealType !== DEAL_TYPE.NATIVE && teamID && !branding) {
      // INGESTED/BESPOKE deals should also get team branding auto applied,
      // and should have an initial status of DRAFT
      if ([DEAL_TYPE.INGESTED, DEAL_TYPE.BESPOKE].indexOf(dealType) > -1) {
        let team = await this.getTeam(teamID);
        if (team) {
          branding = new Branding(team.info);
          _.assign(deal.info, branding.json);
        }
      }
    }

    if (templateData) {
      deal = this.createTemplateData(deal, newID, templateData);
    }

    // Batch uploads (see ImportableCSV) pass in already well-formatted variables (which includes parties) -- take as is
    if (variables) deal.variables = variables;

    // Batch uploads, grab in BEHAVIORs and pdfCharSet
    // Copy the PDF character set
    if (restParams.pdfCharSet) deal.pdfCharSet = restParams.pdfCharSet;

    // merge template BEHAVIORS

    // Transfer behaviors
    Object.values(BEHAVIOR).forEach((behavior) => {
      if (behavior.preventTransfer) return;
      deal[behavior.key] = !_.isNil(restParams[behavior.key]) ? restParams[behavior.key] : behavior.defaultValue;
    });

    if (documentAI) {
      deal.documentAI = documentAI;
    }
    if (hasLenses) {
      deal.hasLenses = hasLenses;
    }

    // Finally, create new deal object with everything intact
    // console.log(deal);
    await this.update({ [`deals/${newID}`]: deal });
    console.log(`Created deal [${newID}]`);

    if (connections) {
      await this.saveDealConnections(deal.dealID, connections);
    }

    return Promise.resolve(newID);
  }

  // https://firebase.google.com/docs/storage/web/upload-files
  // data can be a File, Blob or Uint8Array
  async saveAttachment(attachment, data, onProgress = null) {
    // Establish unique key for attachment
    if (!attachment.key) {
      attachment.key = this.db.ref(`deals/${attachment.deal.dealID}/attachments`).push().key;
    }

    // Save file in storage if it's new
    // This lets us reuse this function for Attachment metadata updates (title/description etc)
    if (data) {
      const ref = this.storage.ref(attachment.bucketPath);
      let uploader = ref.put(data);
      if (typeof onProgress === 'function') {
        uploader.on('state_changed', onProgress);
      }
      await uploader;
    }

    // Save model in db
    await this.update({
      [`deals/${attachment.deal.dealID}/attachments/${attachment.key}`]: attachment.json,
    });

    // And finally return object
    return attachment;
  }

  async copyVariableImageAttachment(variable, attachment, newDeal, onProgress = null) {
    const copiedAttachment = new Attachment(
      {
        extension: attachment.extension,
        title: attachment.title,
        description: attachment.description || null,
        attachmentType: attachment.attachmentType,
        date: attachment.date || null,
      },
      newDeal,
      variable.value.key
    );

    const arrayBuffer = await axios.get(variable.value.downloadURL, { responseType: 'arraybuffer' });
    const attachmentBytes = new Uint8Array(arrayBuffer?.data);

    if (attachmentBytes) {
      if (this.storage.ref) {
        // FrontEnd
        const ref = this.storage.ref(copiedAttachment.bucketPath);
        let uploader = ref.put(attachmentBytes);
        if (typeof onProgress === 'function') {
          uploader.on('state_changed', onProgress);
        }
        await uploader;
      } else {
        // BackEnd (firebase-admin)
        const file = this.storage.bucket().file(copiedAttachment.bucketPath);
        await file.save(attachmentBytes);
      }
    }

    return copiedAttachment;
  }

  async copyImageVariable(variable, sourceAttachments, targetDeal) {
    // Find the attachment for variable
    const attachment = sourceAttachments ? sourceAttachments[variable.value?.key] : null;

    if (!attachment) {
      variable.value = null;
      return variable;
    }

    // Copy image from source to target deal
    try {
      const copiedAttachment = await this.copyVariableImageAttachment(variable, attachment, targetDeal);

      let downloadURL = null;
      if (this.storage.ref) {
        // FrontEnd
        downloadURL = await this.storage.ref(copiedAttachment.bucketPath).getDownloadURL();
      } else {
        // BackEnd
        const file = this.storage.bucket().file(copiedAttachment.bucketPath);
        downloadURL = await this.utils.getDownloadURL(file);
      }

      if (downloadURL) {
        variable.value.downloadURL = downloadURL;
      }
    } catch (err) {
      console.log('Failed to copyImageVariable: ', err);
      variable.value = null;
    }

    // Save attachment to target deal
    attachment.deal.dealID = targetDeal.dealID;
    await Fire.saveAttachment(attachment);

    return variable;
  }

  async deleteAttachment(attachment) {
    if (attachment) {
      // Delete from storage
      const ref = this.storage.ref(attachment.bucketPath);
      // Delete the file
      try {
        await ref.delete();
        console.log(`${attachment.filename} deleted`);
        // File deleted successfully
      } catch (err) {
        // In case file doesn't exist
        console.log(`Error deleting ${attachment.filename}`);
      }

      // Delete from db
      // Save model in db
      return this.update({
        [`deals/${attachment.deal.dealID}/attachments/${attachment.key}`]: null,
      });
    }
  }

  // Same as saveAttachment above, but meant for use by firebase-admin, not firebase.
  async saveAttachmentAsAdmin(attachment, bytes) {
    // Establish unique key for attachment
    if (!attachment.key) {
      attachment.key = Fire.db.ref(`deals/${attachment.deal.dealID}/attachments`).push().key;
    }

    // Save file in storage
    if (bytes) {
      const file = this.storage.bucket().file(attachment.bucketPath);
      await file.save(bytes);
    }

    // Save model in db
    await Fire.update({
      [`deals/${attachment.deal.dealID}/attachments/${attachment.key}`]: attachment.json,
    });

    // And finally return object
    return attachment;
  }

  // Same as deleteAttachment above, but meant for use by firebase-admin, not firebase.
  async deleteAttachmentAsAdmin(attachment) {
    try {
      // Delete from storage
      const file = this.storage.bucket().file(attachment.bucketPath);
      await file.delete();
      console.log(`${attachment.filename} deleted`);
      // File deleted successfully
    } catch (err) {
      // In case file doesn't exist
      console.log(`Error deleting ${attachment.filename}`);
    }

    // Delete from db
    // Save model in db
    return await this.update({
      [`deals/${attachment.deal.dealID}/attachments/${attachment.key}`]: null,
    });
  }

  async saveDealVersion(version) {
    // Establish unique key for attachment
    if (!version.key) {
      version.key = this.db.ref(`deals/${version.deal.dealID}/versions`).push().key;
    }

    // If not yet part of Deal versions list, push in so that deepLink immediately works
    if (version.deal.versions[version.key] !== version) {
      version.deal.versions[version.key] = version;
    }

    // Save model in db; also ensure that deal status stays in sync
    await this.update({
      [`deals/${version.deal.dealID}/versions/${version.key}`]: version.json,
      [`deals/${version.deal.dealID}/info/status`]: version.dealStatus,
    });

    return Promise.resolve(version);
  }

  async deleteDealVersion(version, opts = {}) {
    const { asAdmin = false } = opts;
    const allVersions = _.sortBy(version.deal.versions, 'dateCreated');
    const idx = _.findIndex(allVersions, version);

    const deleteFunc = (asAdmin ? this.deleteAttachmentAsAdmin : this.deleteAttachment).bind(this);

    const promises = [];
    if (version.docx) {
      promises.push(deleteFunc(version.docx));
    }
    if (version.pdf) {
      promises.push(deleteFunc(version.pdf));
    }

    const updates = {
      [`deals/${version.deal.dealID}/versions/${version.key}`]: null,
    };
    if (idx > 0) {
      const priorVersion = allVersions[idx - 1];
      updates[`deals/${version.deal.dealID}/info/status`] = priorVersion.dealStatus;
    }
    promises.push(this.update(updates));
    return Promise.all(promises);
  }

  getBranding(user, template, success) {
    return new Promise((resolve) => {
      //for Outlaw (public) templates, apply no branding
      let branding;
      if (template.team == 'Outlaw') {
        //user branding is disabled so
        // branding = new Branding(user.info);
        branding = new Branding({});
        if (typeof success == 'function') success(branding);
        resolve(branding);
      } else if (template.team != null) {
        //note, if user is not on target team this will fail unless called via API (e.g., for public/inbound deals)
        this.getTeam(template.team)
          .then((team) => {
            branding = new Branding(team.info);
            if (typeof success == 'function') success(branding);
            resolve(branding);
          })
          .catch(() => {
            if (typeof success == 'function') success();
            resolve();
          });
      } else {
        if (typeof success == 'function') success();
        resolve();
      }
    });
  }

  async createDealFromTemplate({
    user,
    dealTemplate,
    branding,
    preview,
    realtime,
    isTemplate = false,
    linkCL = true,
    parentDealID = null,
    connections = null,
    customDealParams = null,
    name = null,
    creatingSearchDoc = true,
    createdFromBatch = false,
    templateData = null,
    webhook = false,
  }) {
    const template = dealTemplate.template;
    const dealID = this.db.ref('deals').push().key;
    let deal = {
      dealID,
      parentDealID,
      users: {},
      dealType: DEAL_TYPE.NATIVE,
    };

    // Set this to prevent an update running before the search doc is created first
    if (creatingSearchDoc) deal.creatingSearchDoc = Date.now();

    // Temporarily set realtime = true until it's indexed
    // So that the watcher doesn't try to index it again
    if (realtime) deal.realtime = true;

    // Copy relevant data from the template into the new deal object
    const { attachments, sections, parties, payments, variables, style } = dealTemplate.raw;
    const { footnoteConfig, watermark } = template;
    // Loop through all sections to do some cleanup
    _.forEach(sections, (s) => {
      // Ensure that we don't pickup any section activity from the template
      s.activity = null;
      // Templates should not have any versions in them, but clear out just in case
      s.versions = null;

      // If we're creating a new template from template, and section is not linked to a different one in CL,
      // Link *every* section to source template. This will ensure correct widespread CL use from the start! 🎉
      if (isTemplate && !s.originCL && linkCL && s.sectiontype !== SectionType.SUMMARY) {
        s.originCL = `${dealTemplate.dealID}|${s.id}`;
      }
      // This ensures that all sections remove linked clauses if linked clauses is unchecked when creating a template from an existing one
      if (isTemplate && s.originCL && !linkCL) {
        delete s.originCL;
      }
    });

    _.merge(
      deal,
      this.clean({ attachments, footnoteConfig, sections, parties, payments, variables, style, watermark })
    );

    const du = {
      uid: user.id,
      key: user.id,
      role: DealRole.OWNER,
      inviteStatus: InviteStatus.OWNED,
    };

    // pull in default user profile properties and add user to this deal
    if (user.info)
      _.assign(du, _.pick(user.info, ['email', 'fullName', 'org', 'title', 'address', 'addressProperties', 'phone']));
    deal.users[user.id] = this.clean(du);

    // Next, there may be users configured who should always be added to deals from this template
    // if so, find and add them
    const autoUsers = dealTemplate.users;
    autoUsers.map((auto) => {
      const autoDealUser = _.merge(
        {
          inviteStatus: InviteStatus.ACCEPTED,
        },
        _.pick(auto, [
          'uid',
          'key',
          'role',
          'partyID',
          'email',
          'fullName',
          'org',
          'title',
          'address',
          'addressProperties',
          'phone',
        ])
      );

      // Make sure we don't overwrite the current user role (owner).
      if (du.uid === auto.uid) {
        autoDealUser.role = DealRole.OWNER;
        autoDealUser.inviteStatus = InviteStatus.OWNED;
      }

      deal.users[autoDealUser.uid] = this.clean(autoDealUser);
    });

    const created = new Date().getTime().toString();
    deal.info = {
      dealID: deal.dealID,
      name: name || template.title,
      type: template.type ?? 'default',
      created,
      updated: created,
    };

    // Flag deal as created from batch, so we can set a lower priority in watcher queue
    if (createdFromBatch) deal.info.createdFromBatch = true;

    // Flag if created by a webhook, to prevent an indexing race condition due to multiple updates
    if (webhook) deal.info.webhook = true;

    // Pull in branding if found
    if (branding) _.assign(deal.info, branding);

    // Capture the source team and template key in the deal for analytics and lookup
    deal.info.sourceTeam = template.team || 'Outlaw';
    deal.info.sourceTemplate = template.key || 'unknown';
    deal.info.sourceTemplateKey = `${deal.info.sourceTeam}:${deal.info.sourceTemplate}`;

    if (_.size(template.lenses) > 0) {
      deal.hasLenses = true;
    }

    // Make sure that the new Deal starts out with the first step of the workflow (which might be custom)
    // This should never be null (Deal template will always have a valid workflow), but using _.get just to be safe
    if (_.get(dealTemplate, 'workflow.steps[0]')) {
      deal.info.status = dealTemplate.workflow.steps[0].key;
    }

    // Preview mode means the deal will be deleted as soon as user navigates away
    deal.preview = preview ? true : false;

    // Copy the PDF character set
    if (template.pdfCharSet) deal.pdfCharSet = template.pdfCharSet;

    // Transfer behaviors
    Object.values(BEHAVIOR).forEach((behavior) => {
      if (behavior.preventTransfer) return;
      deal[behavior.key] = !_.isNil(template[behavior.key]) ? template[behavior.key] : behavior.defaultValue;
    });

    // Special case for *previewing* Filevine-connected deals
    // If the template is setup with a "Debug ID", we'll have a real example project to use in order to test connected vars
    // This way we can use Outlaw template previews instead of constantly needing to re-trigger generation from Filevine
    if (deal.preview && dealTemplate.isConnected) {
      const fvConn = _.find(dealTemplate.connections, { type: 'filevine', objectType: 'projectTemplate' });
      if (fvConn && fvConn.debugID) {
        const key = this.db.ref(`deals/${dealID}/connections`).push().key;
        deal.connections = {
          [key]: {
            key,
            type: 'filevine',
            id: `${fvConn.debugID}_${fvConn.idFields.sectionSelector}`,
            idFields: fvConn.idFields,
          },
        };
        console.log(`Using Debug ID [${fvConn.debugID}] to test Filevine connection in preview`);
      }
    }

    if (customDealParams) {
      const { users, variables } = customDealParams;

      if (variables) deal.variables = variables;

      _.forEach(users, (dealUser) => {
        // Skip owner -- already assigned
        if (dealUser.uid === user.id) return;
        // If we already have a key (which will be identical to uid -- Outlaw user) then just add as is
        if (dealUser.key) deal.users[dealUser.key] = dealUser;
        // Otherwise we need to create one
        else {
          const newKey = this.db.ref(`deals/${deal.dealID}/users`).push().key;
          dealUser.key = newKey;
          deal.users[newKey] = this.clean(dealUser);
        }
      });
    }

    if (deal.variables && typeof deal.variables === 'object') {
      const updatedDealVariables = await Promise.all(
        Object.values(deal.variables)?.map(async (variable) => {
          if (variable.valueType === ValueType.IMAGE && variable.value && _.get(variable.value, 'downloadURL')) {
            const attachment = deal.attachments ? deal.attachments[variable.value.key] : null;
            if (attachment) {
              try {
                const copiedAttachment = await this.copyVariableImageAttachment(variable, attachment, deal);

                let downloadURL = null;
                if (this.storage.ref) {
                  // FrontEnd
                  downloadURL = await this.storage.ref(copiedAttachment.bucketPath).getDownloadURL();
                } else {
                  // BackEnd
                  const file = this.storage.bucket().file(copiedAttachment.bucketPath);
                  downloadURL = await this.utils.getDownloadURL(file);
                }

                if (downloadURL) {
                  variable.value.downloadURL = downloadURL;
                }
              } catch (err) {
                console.log('Failed to copyVariableImageAttachment: ', err);
                variable.value = null;
              }
              return variable;
            }
          }
          return variable;
        })
      );

      deal.variables = updatedDealVariables.reduce((acc, variable) => {
        return { ...acc, [variable.name]: variable };
      }, {});
    }

    if (templateData) {
      if (templateData.lenses) {
        templateData.lenses = _.keyBy(
          _.map(templateData.lenses, (lens) => {
            return lens.json;
          }),
          'id'
        );
      }
      deal = this.createTemplateData(deal, dealID, templateData);
    }

    // Create new deal object with current user (and all auto-users) as members
    await this.update({ [`deals/${dealID}`]: deal });
    console.log(`Deal [${dealID}] created`);

    // Only create new deal connections when connections is explicitly passed in (Batch + createBundle from API).
    if (connections) {
      await this.saveDealConnections(dealID, connections);
    }

    // Track event
    const newDeal = DealFactory.create(deal);
    const eventData = {
      documentType: newDeal.info.type,
      dealID: newDeal.dealID,
      sectionCount: Object.keys(newDeal.sections).length,
      countAI: Object.keys(newDeal.sections).filter((section) => newDeal.sections[section].isAI).length,
      countTimeline: Object.keys(newDeal.sections).filter((section) => newDeal.sections[section].isTimeline).length,
      user: du.email ?? du.uid,
      teamID: template.team,
      templateType: template.type,
      isPreview: false,
      template: template.dealID,
    };

    // If this is being called via webhook, use pendo API as we're in a backend context
    const usePendoAPI = webhook;

    if (usePendoAPI) {
      // Inject environment
      eventData.environment = CONFIG.GCP?.projectId;
    }

    await trackEvent('NewDealFromTemplate', eventData, usePendoAPI);

    if (!templateData) {
      try {
        // User audit log
        const api = getAPIClient();
        if (api) {
          const auditLogData = {
            dealID: newDeal.dealID,
            teamID: template.team,
            eventCategory: 'deal',
            eventType: 'create',
            eventDetails: { templateID: template.dealID, templateName: template.title },
            userEmail: du.email,
          };
          api.call('userAuditLog', auditLogData);
        }
      } catch (err) {
        console.log('Failed to send audit log', err);
      }
    }

    return Promise.resolve(deal);
  }

  async copyDeal(user, sourceDeal, { title, copyVariables, copyUsers, copyVersions }) {
    const dealID = this.db.ref('deals').push().key;
    const deal = {
      dealID,
      users: {},
      dealType: DEAL_TYPE.NATIVE,
    };

    // Copy relevant data from the source deal into the new deal object
    // Notably, don't copy ANY activity from the source deal
    const { attachments, sections, parties, payments, variables, style } = _.cloneDeep(sourceDeal.raw);

    // Iterate through the typed Sections of the source deal so that we have access to getters and convenience methods,
    // but modify data on the target new JSON that will get written to db
    _.forEach(sourceDeal.sections, (section) => {
      const sectionJSON = sections[section.id];
      // Clear all section activity no matter what, which will prevent comments from being copied
      delete sectionJSON.activity;

      // If we're not keeping historical versions,
      // we need to "collapse" the *current* (latest) version down to the core section properties
      if (!copyVersions && section.versions.length > 1) {
        if (section.currentVersion.title) {
          sectionJSON.displayname = section.currentVersion.json.title;
        }
        if (section.currentVersion.body) {
          sectionJSON.content = sanitize(getMarkup(section.currentVersion.body));
        }

        delete sectionJSON.versions;
      }
      // Always clear signatures
      if (section.sectiontype === SectionType.SIGNATURE) {
        delete sectionJSON.sigs;
      }
    });

    // Clear variable data if specified
    if (!copyVariables) {
      _.forEach(variables, (variable) => delete variable.value);
    }

    _.assign(deal, this.clean({ sections, parties, payments, variables, attachments, style }));

    if (copyVariables && deal.variables && typeof deal.variables === 'object') {
      const updatedDealVariables = await Promise.all(
        Object.values(deal.variables)?.map(async (variable) => {
          if (variable.valueType === ValueType.IMAGE && variable.value && variable.hasAttachment) {
            const attachment = deal.attachments ? deal.attachments[variable.value.key] : null;
            if (attachment) {
              try {
                const copiedAttachment = await this.copyVariableImageAttachment(variable, attachment, deal);

                let downloadURL = null;
                if (this.storage.ref) {
                  // FrontEnd
                  downloadURL = await this.storage.ref(copiedAttachment.bucketPath).getDownloadURL();
                } else {
                  // BackEnd
                  const file = this.storage.bucket().file(copiedAttachment.bucketPath);
                  downloadURL = await this.utils.getDownloadURL(file);
                }

                if (downloadURL) {
                  variable.value.downloadURL = downloadURL;
                }
              } catch (err) {
                console.log('Failed to copyVariableImageAttachment: ', err);
                variable.value = null;
              }
              return variable;
            }
          }
          return variable;
        })
      );

      deal.variables = updatedDealVariables.reduce((acc, variable) => {
        return { ...acc, [variable.name]: variable };
      }, {});
    }

    // Next, there may be "auto" users configured who should always be added to deals from this template
    // if so, find and add them
    if (copyUsers) {
      sourceDeal.users.map((auto) => {
        const dealUser = _.merge(
          {
            inviteStatus: InviteStatus.ACCEPTED,
          },
          _.pick(auto, [
            'uid',
            'key',
            'role',
            'partyID',
            'email',
            'fullName',
            'org',
            'title',
            'address',
            'addressProperties',
            'phone',
          ])
        );

        // Don't attempt to copy invited (non-user) users
        if (!dealUser.uid) return;

        // Make sure we don't overwrite the current user role (owner).
        if (user.id === auto.uid) {
          dealUser.role = DealRole.OWNER;
          dealUser.inviteStatus = InviteStatus.OWNED;
        }

        deal.users[dealUser.uid] = this.clean(dealUser);
      });
    }
    // If we're not keeping users, just setup current user as owner, just like when we create from template
    else {
      const owner = {
        uid: user.id,
        key: user.id,
        role: DealRole.OWNER,
        inviteStatus: InviteStatus.OWNED,
      };

      // Pull in default user profile properties and add user to this deal, same as a normal new contract
      if (user.info)
        _.assign(owner, _.pick(user.info, ['email', 'fullName', 'org', 'title', 'address', 'addressProperties']));
      deal.users[user.id] = this.clean(owner);
    }

    const created = new Date().getTime().toString();

    // Setup top-level properties
    deal.info = {
      dealID: deal.dealID,
      name: title,
      created,
      updated: created,
      // Keep same source template in copied deal
      sourceTeam: sourceDeal.info.sourceTeam,
      sourceTemplateKey: sourceDeal.info.sourceTemplateKey,
    };
    // Pull in branding if found
    if (!sourceDeal.branding.empty) _.assign(deal.info, sourceDeal.branding.json);

    // Transfer behaviors
    _.forEach(BEHAVIOR, (behavior) => {
      if (behavior.preventTransfer) return;
      deal[behavior.key] = !_.isNil(sourceDeal.raw[behavior.key])
        ? sourceDeal.raw[behavior.key]
        : behavior.defaultValue;
    });

    // Create new deal object with current user (and all auto-users) as members
    await this.update({ [`deals/${dealID}`]: deal });
    console.log(`Deal [${dealID}] created`);
    return Promise.resolve(deal);
  }

  async applyTemplate(deal, templateID) {
    const updates = {};
    const basePath = `deals/${deal.dealID}`;
    const template = await this.load(`deals/${templateID}`);

    // There may be "auto" users configured who should always be added to deals from this template
    // if so, find and add them IF they are not on the deal yet.
    const autoUsers = template.users;
    _.forEach(autoUsers, (auto) => {
      if (!_.find(deal.users, { uid: auto.uid })) {
        const autoDU = _.merge(
          { inviteStatus: InviteStatus.ACCEPTED },
          _.pick(auto, [
            'uid',
            'key',
            'role',
            'partyID',
            'email',
            'fullName',
            'org',
            'title',
            'address',
            'addressProperties',
            'phone',
          ])
        );
        updates[`${basePath}/users/${autoDU.uid}`] = this.clean(autoDU);
      }
    });

    // In case we are applying a template on an existing deal, make sure that we do not end up with
    // parties that does not exist on the template.
    // Note: Prevent doing it for autoUsers (not needed + will crash)
    _.forEach(deal.users, (user) => {
      if (user.partyID && !_.get(template, `variables.${user.partyID}`, null)) {
        if (!user.uid || !_.find(autoUsers, { uid: user.uid })) {
          updates[`${basePath}/users/${user.uid}/partyID`] = null;
        }
      }
    });

    //capture the source team and template key in the deal for analytics and lookup
    updates[`${basePath}/info/sourceTeam`] = `${template.template.team || 'Outlaw'}`;
    updates[`${basePath}/info/sourceTemplate`] = `${template.template.key || 'unknown'}`;
    updates[`${basePath}/info/sourceTemplateKey`] = `${template.template.team || 'Outlaw'}:${
      template.template.key || 'unknown'
    }`;

    //apply custom workflow if one is set on the template
    if (template.template.workflow) {
      updates[`${basePath}/workflow`] = template.template.workflow;
    }

    if (template.variables) {
      updates[`${basePath}/variables`] = template.variables;
    }

    if (_.size(template.template.lenses) > 0) {
      updates[`${basePath}/hasLenses`] = true;
    }

    if (template.template.documentAI) {
      updates[`${basePath}/documentAI`] = template.template.documentAI;
    }

    return this.update(updates);
  }

  async clearTemplate(dealID, teamID) {
    const updates = {};
    const basePath = `deals/${dealID}`;
    updates[`${basePath}/info/sourceTemplateKey`] = teamID;
    updates[`${basePath}/variables`] = null;
    return this.update(updates);
  }

  deleteDeal(dealID, success, error) {
    if (!dealID) return;
    const updates = { [`deals/${dealID}`]: null };
    return this.update(updates, success, error);
  }

  softDeleteDeal(dealID, uid) {
    if (!dealID) return;
    const updates = { [`deals/${dealID}/deleted`]: { userID: uid, time: Date.now() } };
    return this.update(updates);
  }

  restoreDeal(dealID) {
    if (!dealID) return;
    const updates = { [`deals/${dealID}/deleted`]: null };
    return this.update(updates);
  }

  leaveDeal(uid, dealID) {
    const updates = { [`deals/${dealID}/users/${uid}`]: null };
    return this.update(updates);
  }

  saveTag(uid, tag) {
    // Generate a tagID via Firebase if it's a new tag
    if (!tag.tagID) tag.tagID = this.db.ref(`users/${uid}/tags`).push().key;
    const updates = { [`users/${uid}/tags/${tag.tagID}`]: tag.json };
    return this.update(updates);
  }

  deleteTag(uid, tagID) {
    const updates = { [`users/${uid}/tags/${tagID}`]: null };
    return this.update(updates);
  }

  updateDealUserTags(uid, dealID, tags) {
    const updates = { [`deals/${dealID}/users/${uid}/tags`]: tags.length > 0 ? tags.join(',') : null };
    return this.update(updates);
  }

  getInvite(inviteID) {
    return this.load(`invites/${inviteID}`);
  }

  async updateInviteStatus(inviteID, status, statusMessage) {
    try {
      const invite = await this.getInvite(inviteID);

      if (!invite) return null;

      const dealID = invite.deal.dealID;
      const deal = await this.getDeal(dealID);
      const dealUser = deal.users.find((user) => user.inviteID === inviteID);

      if (!dealUser) return null;

      let updates = {};

      //Invite updates
      updates[`invites/${invite.inviteID}/inviteStatus`] = status;

      //Deal User updates
      updates = {
        ...updates,
        [`deals/${dealID}/users/${dealUser.key}/inviteStatus`]: status,
        [`deals/${dealID}/users/${dealUser.key}/inviteStatusMessage`]: statusMessage,
      };

      await this.update(updates);

      return { ...invite, inviteStatus: status, inviteStatusMessage: statusMessage };
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async saveVariable(deal, variable, value, multilineValueOptions) {
    let updates = {};
    //empty strings are actually null
    const val = value === '' ? null : value;
    //variables with properties need to be saved under the variable name not name.property
    const variableName = variable.name.split('.')[0];

    updates[`deals/${deal.dealID}/variables/${variableName}/value`] = val;
    updates[`deals/${deal.dealID}/variables/${variableName}/type`] = variable.type;

    if (multilineValueOptions) {
      updates[`deals/${deal.dealID}/variables/${variableName}/multilineValueOptions`] = multilineValueOptions;
    }

    if (deal.isBundleParent) {
      const bundleUpdates = await this.syncBundleVariables(deal, { [variableName]: val });
      updates = { ...bundleUpdates, ...updates };
    }

    return this.update(updates);
  }

  async saveVariables(dealID, variables, success, externalIDs, multilineValueOptionsMap, multilineValueLabelsMap) {
    let updates = {};
    const deal = await this.getDeal(dealID);

    if (!deal) {
      return Promise.reject(new Error(`Deal[${dealID}] does not exist.`));
    }

    _.forEach(variables, async (value, key) => {
      updates[`deals/${dealID}/variables/${key}/value`] = value;
    });

    // Connected variables can now also include a map of external IDs,
    // to support linking with Filevine contact properties (and maybe other connected objects in the future)
    // However, this method is used in several places so we needed a new optional param so as not to create regressions
    _.forEach(externalIDs, (externalObjectID, key) => {
      updates[`deals/${dealID}/variables/${key}/externalObjectID`] = externalObjectID;
    });

    _.forEach(multilineValueOptionsMap, (multilineValueOptions, key) => {
      updates[`deals/${dealID}/variables/${key}/multilineValueOptions`] = multilineValueOptions;
    });

    _.forEach(multilineValueLabelsMap, (multilineValueLabels, key) => {
      updates[`deals/${dealID}/variables/${key}/multilineValueLabels`] = multilineValueLabels;
    });

    if (deal?.isBundleParent) {
      const bundleUpdates = await this.syncBundleVariables(deal, variables);
      updates = { ...bundleUpdates, ...updates };
    }

    return this.update(updates, success);
  }

  // When variable values are updated in a Deal that is a parent in a Bundle,
  // Propagate the value to all children to keep them in sync and allow child docs to have shared variables!
  // We only want to sync the variable if a variable with the same name and maybe valueType match on the children.
  async syncBundleVariables(deal, variableUpdates) {
    const updates = {};
    const links = _.filter(deal.sections, 'linkedDealID');

    if (links) {
      const Promises = [];
      _.forEach(links, async ({ linkedDealID }) => {
        Promises.push(
          new Promise(async (resolve) => {
            const linkedDeal = await Fire.getDeal(linkedDealID);
            if (linkedDeal && linkedDeal.variables) {
              _.forEach(variableUpdates, async (val, key) => {
                const variable = _.get(deal.variables, key, null);
                const found = !!_.find(linkedDeal.variables, {
                  name: variable.name,
                  valueType: variable.valueType,
                  type: VariableType.SIMPLE,
                });
                if (found) {
                  updates[`deals/${linkedDealID}/variables/${key}/value`] = val;
                }
                resolve();
              });
            }
          })
        );
      });
      await Promise.all(Promises);
    }
    return updates;
  }

  saveDealConnections(dealID, connections) {
    const updates = {};

    _.forEach(connections, (connection) => {
      if (!connection.key) {
        const connectionId = this.db.ref(`deals/${dealID}/connections`).push().key;
        connection.key = connectionId;
      }
      //don't save empty strings
      this.clean(connection, true);
      updates[`deals/${dealID}/connections/${connection.key}`] = connection;
    });
    return this.update(updates);
  }

  deleteDealConnection({ dealID, connectionId }) {
    return this.update({
      [`deals/${dealID}/connections/${connectionId}`]: null,
    });
  }

  deleteVariable(deal, variable, success) {
    const updates = {};
    updates[`deals/${deal.dealID}/variables/${variable.name}`] = null;

    // If variable being deleted is a Party and there are users assigned to that Party,
    // we also need to unassign them
    if (variable.type === VariableType.PARTY) {
      const users = deal.getUsersByParty(variable.name);
      users.map((du) => {
        updates[`deals/${deal.dealID}/users/${du.key}/partyID`] = null;
      });
    }

    return this.update(updates, success);
  }

  buildVariableUpdates(dealID, varDef) {
    const updates = {};
    //don't save empty strings
    this.clean(varDef, true);

    //special case for NEW section references
    //we need to generate a unique key for that is not related to either the linked section or the display name
    //because both of these can change
    if (varDef.type === VariableType.REF && !varDef.name) {
      varDef.name = 'ref' + this.db.ref(`deals/${dealID}/variables`).push().key;
    }

    //ensure that we don't try to write new variables that are actually derived properties
    //i.e., variable names can't have . in them (also will result in a Firebase error anyway)
    if (varDef.name) varDef.name = varDef.name.split('.')[0];

    const path = `deals/${dealID}/variables/${varDef.name}`;

    //compile individual property updates so that we don't accidentally blow away any existing properties
    _.forEach(varDef, (val, key) => {
      updates[`${path}/${key}`] = val;
    });

    return updates;
  }

  saveVariableDefinition(deal, varDef, success) {
    const updates = this.buildVariableUpdates(deal.dealID, varDef);

    return this.update(updates, () => {
      if (typeof success === 'function') success(varDef.name);
    });
  }

  async renameVariable(deal, variable, previousName) {
    const updates = {};
    const sectionsUpdated = [];
    //don't save empty strings
    this.clean(variable, true);

    if (deal instanceof PDFDeal) {
      if (variable.type !== VariableType.SIMPLE) {
        return Promise.reject('Only PDFDeals and simple Variables can be renamed.');
      }

      // Find all pdfElements using the previousName and re-assign their variable value
      const pdfElements = _.filter(deal.pdfElements, { variable: previousName });
      _.forEach(pdfElements, (pdfElement) => {
        updates[`deals/${deal.dealID}/pdfElements/${pdfElement.key}/variable`] = variable.name;
      });
    } else {
      const rxRename = new RegExp(`\\[${variable.type}${previousName}(?=[.\\]])`, 'g');
      const replace = `[${variable.type}${variable.name}`;
      const sections = _.filter(deal.sections, (section) => !!section.variables[previousName]);

      _.forEach(sections, (section) => {
        let updated = false;
        if (section.displayname) {
          const newTitle = section.displayname.replace(rxRename, replace);
          if (newTitle !== section.displayname) {
            updates[`deals/${deal.dealID}/sections/${section.id}/displayname`] = newTitle;
            section.displayname = newTitle;
            updated = true;
          }
        }
        if (section.content) {
          const newBody = section.content.replace(rxRename, replace);
          if (newBody !== section.content) {
            updates[`deals/${deal.dealID}/sections/${section.id}/content`] = newBody;
            section.content = newBody;
            updated = true;
          }
        }
        if (updated) sectionsUpdated.push(section);
      });

      // Handle Conditions
      const conditionSections = Object.values(deal.sections).filter((sec) =>
        _.find(sec.conditions, { variable: previousName })
      );

      if (conditionSections) {
        conditionSections.map((section) => {
          updates[`deals/${deal.dealID}/sections/${section.id}/conditions/${previousName}`] = null;

          const condition = section.conditions.find((condition) => condition.variable === previousName);
          updates[`deals/${deal.dealID}/sections/${section.id}/conditions/${variable.name}`] = {
            ...condition.json,
            variable: variable.name,
          };
        });
      }
    }

    updates[`deals/${deal.dealID}/variables/${variable.name}`] = variable;
    updates[`deals/${deal.dealID}/variables/${previousName}`] = null;

    await this.update(updates);
    return sectionsUpdated;
  }

  async createDealUser(deal, info, success, swapPartyDU = null) {
    const dealID = deal.dealID;
    const newUser = {
      role: info.role || DealRole.VIEWER,
      inviteStatus: InviteStatus.ADDED,
      partyID: info.partyID || null,
      fullName: info.fullName || null,
      org: info.org || null,
      title: info.title || null,
      email: info.email || null,
      address: info.address || null,
      addressProperties: info.addressProperties || null,
      phone: info.phone || null,
      key: this.db.ref(`deals/${dealID}/users`).push().key,
    };

    const updates = {};
    updates[`deals/${dealID}/users/${newUser.key}`] = newUser;

    //When we swap a current deal user with a newly created deal user, remove the old deal user from the signature before adding the new one.
    //This is preferable to do in the same call because we are now guaranteeing we will have the correct users with the updated data with one deal reload.
    if (swapPartyDU) {
      updates[`deals/${dealID}/users/${swapPartyDU.key}/partyID`] = null;
    }

    // Keep Bundle children permissions in sync
    if (deal.isBundleParent && deal.bundle) {
      _.forEach(deal.bundle.children, ({ dealInfo }) => {
        updates[`deals/${dealInfo.dealID}/users/${newUser.key}`] = newUser;
      });
    }

    await this.update(updates);
    if (typeof success == 'function') success(newUser);
    return Promise.resolve(newUser);
  }

  async deleteDealUser(du, success) {
    const updates = {};
    updates[`deals/${du.deal.dealID}/users/${du.key}`] = null;

    // Keep Bundle children permissions in sync
    if (du.deal.isBundleParent && du.deal.bundle) {
      _.forEach(du.deal.bundle.children, ({ dealInfo }) => {
        updates[`deals/${dealInfo.dealID}/users/${du.key}`] = null;
      });
    }

    await this.update(updates, success);
    return Promise.resolve();
  }

  async saveDealUser(du, info, success) {
    const data = this.clean(info, true);
    const updates = {};
    _.forEach(data, (val, key) => {
      updates[`deals/${du.deal.dealID}/users/${du.key}/${key}`] = val;
    });

    // Keep Bundle children info in sync
    if (du.deal.isBundleParent && du.deal.bundle) {
      _.forEach(du.deal.bundle.children, ({ dealInfo }) => {
        _.forEach(data, (val, key) => {
          updates[`deals/${dealInfo.dealID}/users/${du.key}/${key}`] = val;
        });
      });
    }

    await this.update(updates, success);
    return Promise.resolve();
  }

  async swapSigner(duFrom, duTo, partyID) {
    const updates = {};
    if (duFrom) updates[`deals/${duFrom.deal.dealID}/users/${duFrom.key}/partyID`] = null;
    if (duTo) updates[`deals/${duTo.deal.dealID}/users/${duTo.key}/partyID`] = partyID;
    return this.update(updates);
  }

  // Add existing users to a deal or template directly (skip invitation flow)
  // For templates, must be called from API because user may not be on deal
  addTeammatesToDeal(dealID, teammates, isTemplate = false, role = DealRole.VIEWER) {
    const updates = {};

    //we get an array of untyped team member objects from the TeammateSelector
    //convert to DealUser structure and push into db
    const invitees = [];
    teammates.map((tm) => {
      const key = tm.id;
      const json = _.merge(
        {
          key,
          uid: key,
          role: tm.role || role,
          inviteStatus: InviteStatus.ACCEPTED,
        },
        _.pick(tm, ['fullName', 'email', 'address', 'addressProperties', 'phone', 'title', 'org', 'partyID'])
      );

      //for templates, add "auto" property to DealUser, indicating that by default they will automatically be included on contracts
      if (isTemplate) json.auto = true;
      updates[`deals/${dealID}/users/${key}`] = json;
      invitees.push(tm);
    });

    return new Promise((resolve) => {
      this.update(updates, () => resolve(invitees));
    });
  }

  updateUserFeatures(uid, features) {
    const updates = {};
    _.forEach(features, (value, feature) => {
      const route =
        feature === 'isAdmin' || feature === 'isPartner'
          ? `users/${uid}/${feature}`
          : `users/${uid}/features/${feature}`;
      updates[route] = value;
    });
    return this.update(updates);
  }

  updateTeamFeatures(teamID, features) {
    const updates = {};
    _.forEach(features, (value, key) => {
      updates[`teams/${teamID}/features/${key}`] = value;
    });
    return this.update(updates);
  }

  saveUserInfo(user, info, success) {
    info.updated = new Date().getTime().toString();
    let data = this.clean(info, true);
    data = _.pick(data, [
      'org',
      'title',
      'fullName',
      'phone',
      'address',
      'addressProperties',
      'updated',
      'lastLogin',
      'created',
    ]);

    const updates = {};

    _.forEach(data, (val, key) => {
      updates[`users/${user.id}/info/${key}`] = val;
    });

    return this.update(updates, success);
  }

  saveUserStripeID(uid, stripeID) {
    const updates = { [`users/${uid}/stripeID`]: stripeID };
    return this.update(updates);
  }

  saveUserSubscription(uid, subscriptionID, planID, success) {
    const updates = {
      [`users/${uid}/subscriptionID`]: subscriptionID,
      [`users/${uid}/plan`]: planID,
    };
    return this.update(updates, success);
  }

  savePayment(payment, newData, success) {
    const path = `deals/${payment.deal.dealID}/payments`;
    this.clean(newData, true);

    const id = payment.id || this.db.ref(path).push().key;
    newData.id = id;
    payment.id = id;

    const updates = {};
    //save all fields of current payment, which may be new
    _.forOwn(newData, (val, key) => {
      updates[`${path}/${id}/${key}`] = val;
    });
    //and reorder all payment fields from local array
    payment.deal.payments.map((p, idx) => (updates[`${path}/${p.id}/order`] = idx));

    this.update(updates, success);
  }

  movePayment(payment, direction) {
    if (['up', 'down'].indexOf(direction) < 0) return;
    const path = `deals/${payment.deal.dealID}/payments`;
    const updates = {};
    //remove payment from local array, then reinsert
    let idx = payment.deal.payments.indexOf(payment);
    payment.deal.payments.splice(idx, 1);

    if (direction == 'up') idx = Math.max(0, idx - 1);
    else idx = Math.min(payment.deal.payments.length, idx + 1);

    payment.deal.payments.splice(idx, 0, payment);

    //and reorder all payment fields from updated local array
    payment.deal.payments.map((p, idx) => (updates[`${path}/${p.id}/order`] = idx));

    this.update(updates);
  }

  deletePayment(payment) {
    if (!payment.id) return null;
    //add reordering here
    const updates = {};
    const path = `deals/${payment.deal.dealID}/payments`;
    updates[`${path}/${payment.id}`] = null;

    //remove from local array first so we get accurate indices, then update order
    const index = payment.deal.payments.indexOf(payment);
    if (index > -1) payment.deal.payments.splice(index, 1);
    payment.deal.payments.map((p, idx) => (updates[`${path}/${p.id}/order`] = idx));

    this.update(updates);
  }

  saveDealStyle(obj, styleProp, data, success) {
    let path;

    if (obj instanceof Deal) path = `deals/${obj.dealID}/`;
    else if (obj instanceof Section) {
      path = `deals/${obj.deal.dealID}/`;
      //style info (currently just numbering) can be scoped to either the deal
      //or to each individual appendix section
      //if the passed-in section is on an appendix, store it there
      if (obj.appendix) path += `sections/${obj.appendix.id}/`;
    }

    //should never get here
    else return;

    path += `style/${styleProp}`;

    const updates = { [path]: this.clean(data, true) };
    return this.update(updates, success);
  }

  createTeam(uid, teamID, info) {
    const updates = {
      [`teams/${teamID}/teamID`]: teamID,
      [`teams/${teamID}/users/${uid}`]: 'owner',
      [`users/${uid}/teams/${teamID}`]: 'owner',
    };
    const path = `teams/${teamID}/info`;
    this.clean(info, true);
    _.forOwn(info, (val, key) => (updates[`${path}/${key}`] = val));

    return this.update(updates);
  }

  //only called from API
  deleteTeam(team) {
    const updates = {};
    return new Promise((resolve) => {
      //1. remove teamID reference from all users
      _.map(team.users, (val, uid) => (updates[`users/${uid}/teams/${team.teamID}`] = null));

      //2. null out team object
      updates[`teams/${team.teamID}`] = null;
      this.update(updates, resolve);
    });
  }

  updateTeamInfo(teamID, info, success) {
    const path = `teams/${teamID}/info`;
    info.updated = new Date().getTime().toString();
    this.clean(info, true);

    const updates = {};
    _.forOwn(info, (val, key) => (updates[`${path}/${key}`] = val));

    this.update(updates, () => success(info));
  }

  getUserTeams(uid, success) {
    const teams = [],
      promises = [];

    return new Promise((resolve) => {
      this.load(`users/${uid}/teams`, (userTeams) => {
        _.forOwn(userTeams, (role, teamID) => {
          promises.push(
            this.getTeam(teamID, (team) => {
              if (team != null) teams.push(new Team(team));
            })
          );
        });

        Promise.all(promises)
          .then(() => {
            if (typeof success == 'function') success(teams);
            resolve(teams);
          })
          .catch(() => {
            console.log('Some teams loaded; others were denied access');
            if (typeof success == 'function') success(teams);
            resolve(teams);
          });
      });
    });
  }

  lookupApiKey(apiKey) {
    return this.load(`teamApiKeys/${apiKey}`);
  }

  getTeam(teamID, success) {
    return this.load(`teams/${teamID}`, success);
  }

  saveTheme(team, theme) {
    const updates = {};
    const path = [`teams/${team.teamID}/themes/${theme.themeKey}`];

    // Update property by property; this way updating an existing theme meta info doesn't blow away its dealStyles
    _.forEach(theme, (val, prop) => {
      updates[`${path}/${prop}`] = val;
    });

    // If theme is marked as default, make sure we clear it from a different default if one exists
    if (theme.isDefault) {
      const currentDefault = _.find(team.themes, (t) => t.themeKey !== theme.themeKey && t.isDefault);
      if (currentDefault) {
        updates[`teams/${team.teamID}/themes/${currentDefault.themeKey}/isDefault`] = false;
      }
    }

    return this.update(updates);
  }

  saveThemeTypeStyle(team, theme, styleName, data) {
    const updates = {
      [`teams/${team.teamID}/themes/${theme.themeKey}/dealStyle/type/${styleName}`]: data,
    };
    return this.update(updates);
  }

  saveThemeLayoutStyle(team, theme, layoutProperty, data) {
    const updates = {
      [`teams/${team.teamID}/themes/${theme.themeKey}/dealStyle/layout/${layoutProperty}`]: data,
    };
    return this.update(updates);
  }

  saveThemeBorderStyle(team, theme, key, data) {
    const updates = {
      [`teams/${team.teamID}/themes/${theme.themeKey}/dealStyle/border/${key}`]: data,
    };
    return this.update(updates);
  }

  deleteTheme(team, theme) {
    const updates = { [`teams/${team.teamID}/themes/${theme.themeKey}`]: null };
    return this.update(updates);
  }

  saveWorkflow(team, workflow) {
    const updates = {};

    this.clean(workflow, true);

    // Save workflow data
    updates[`teams/${team.teamID}/workflows/${workflow.workflowKey}`] = workflow;

    // If workflow is marked as default, make sure we clear it from a different default if one exists
    if (workflow.isDefault) {
      const currentDefault = _.find(team.workflows, (wf) => wf.workflowKey !== workflow.workflowKey && wf.isDefault);
      if (currentDefault) {
        updates[`teams/${team.teamID}/workflows/${currentDefault.workflowKey}/isDefault`] = false;
      }
    }
    return this.update(updates);
  }

  saveTeamAIConfig(team, aiConfig) {
    const updates = {};

    this.clean(aiConfig, true);

    _.forEach(aiConfig, (val, key) => {
      if (!_.isNil(val)) updates[`teams/${team.teamID}/aiConfig/${key}`] = val;
    });

    if (!_.keys(updates).length) return Promise.resolve();

    return this.update(updates);
  }

  saveTeamAIRules(team, rules) {
    const updates = {};

    this.clean(rules, true);

    _.forEach(rules, (val, key) => {
      if (!_.isNil(val)) {
        //If it is an array of objects, and the object is null, assume new entry (object)
        const rules = Array.isArray(val)
          ? val.map((v) => v ?? { required: '', ruleType: 'SECTION', sectionType: '' })
          : val;

        // This replaces all null values with empty strings
        const updateStrings = JSON.parse(JSON.stringify(rules), (_key, value) => (value === null ? '' : value));

        updates[`teams/${team.teamID}/aiConfig/${key}`] = updateStrings;
      }
    });

    if (!_.keys(updates).length) return Promise.resolve();

    return this.update(updates);
  }

  saveCheckpointGroup(team, checkpointGroup) {
    const updates = {};

    this.clean(checkpointGroup, true);

    // Save checkpointGroup data
    updates[`teams/${team.teamID}/checkpointGroups/${checkpointGroup.checkpointGroupKey}`] = checkpointGroup;

    return this.update(updates);
  }

  deleteWorkflow(team, workflow) {
    const updates = { [`teams/${team.teamID}/workflows/${workflow.workflowKey}`]: null };
    return this.update(updates);
  }

  deleteCheckpointGroup(team, checkpointGroup) {
    const updates = { [`teams/${team.teamID}/checkpointGroups/${checkpointGroup.checkpointGroupKey}`]: null };
    return this.update(updates);
  }

  addTeamMember(
    teamID,
    uid,
    role = TEAM_ROLES.VIEWER.value,
    observer = false,
    observerRole = null,
    canCreateNewDoc = true,
    serviceProvider = null
  ) {
    // May 2021, temporary observer role data structure
    // In order to convert team membership to an object (vs role string)
    // we would need a proper data migration, which is something that
    // must be planned and done properly and well tested.
    // We're doing it the easy way for now (membership) since time is a scarce resource.

    const updates = {
      [`users/${uid}/teams/${teamID}`]: role,
      [`teams/${teamID}/users/${uid}`]: role,
      [`users/${uid}/teamMemberships/${teamID}`]: { observer, observerRole, serviceProvider },
      [`teams/${teamID}/userMemberships/${uid}`]: { observer, observerRole, canCreateNewDoc, serviceProvider },
    };

    return this.update(updates);
  }

  inviteTeamMember(teamID, invite, userMemberships) {
    return this.update({
      [`teams/${teamID}/users/${invite.inviteID}`]: invite,
      [`teams/${teamID}/userMemberships/${invite.inviteID}`]: userMemberships,
    });
  }

  removeTeamMember(teamID, uid, success) {
    const updates = {
      [`users/${uid}/teams/${teamID}`]: null,
      [`teams/${teamID}/users/${uid}`]: null,
      [`users/${uid}/teamMemberships/${teamID}`]: null,
      [`teams/${teamID}/userMemberships/${uid}`]: null,
    };

    return this.update(updates, success);
  }

  lookupTeamApiKey(apiKey) {
    return this.load(`teamApiKeys/${apiKey}`);
  }

  getTeamIntegrations(teamID) {
    return this.load(`teams/${teamID}/integrations`);
  }

  createIntegration(templateID, service) {
    const updates = {
      [`integrations/${templateID}/${service}`]: { config: { service } },
    };
    return this.update(updates);
  }

  deleteIntegration(templateID, service) {
    const updates = {
      [`integrations/${templateID}/${service}`]: null,
    };
    return this.update(updates);
  }

  deleteAutomation(key, templateID, service) {
    const updates = {
      [`integrations/${templateID}/${service}/automations/${key}`]: null,
    };
    return this.update(updates);
  }

  getIntegrations(templateID) {
    return this.load(`integrations/${templateID}`);
  }

  saveIntegrationConfig(templateID, service, config) {
    const { appID, token, url, ownerID } = config;
    const updates = this.clean(
      {
        [`integrations/${templateID}/${service}/config/token`]: token,
        [`integrations/${templateID}/${service}/config/appID`]: appID,
        [`integrations/${templateID}/${service}/config/url`]: url,
        [`integrations/${templateID}/${service}/config/ownerID`]: ownerID,
      },
      true
    );

    return this.update(updates);
  }

  async saveAutomation(templateID, service, automation) {
    console.log('saveAutomation()', templateID, service, JSON.stringify(automation));
    let path = `integrations/${templateID}/${service}/automations`;
    const key = automation.key || this.db.ref(path).push().key;
    automation.key = key;
    const updates = { [`${path}/${key}`]: automation };
    await this.update(updates);

    return automation;
  }

  /** Create or update an existing webhook
   * @param {string} teamID - The ID of the team that the webhook belongs to
   * @param {string} webhook.id - The ID of the webhook
   * @param {string} webhook.url - The url where the webhook data will be posted to
   * @param {boolean} webhook.enabled - If the webhook is enabled
   * @param {string} webhook.eventType - The type of the event that will trigger the webhook
   * @param {array} webhook.subtypes - The subtypes related to the event
   */
  async saveWebhook(teamID, webhook) {
    console.log('saveWebhook()', teamID, JSON.stringify(webhook));

    let path = `webhooks/${teamID}`;
    const id = webhook.id || this.db.ref(path).push().key;
    webhook.id = id;

    const updates = { [`${path}/${id}`]: webhook };
    await this.update(updates);

    return webhook;
  }

  deleteWebhook(key, teamID) {
    const updates = {
      [`webhooks/${teamID}/${key}`]: null,
    };
    return this.update(updates);
  }

  startReadyCheck(dealID, rc) {
    const updates = { [`deals/${dealID}/activity/${rc.id}`]: rc };
    return this.update(updates);
  }

  async updateReadyCheck(deal, uid, rc, status, checkpointActivityMessage) {
    const updates = { [`deals/${deal.dealID}/activity/${rc.id}/status`]: status };
    await Fire.addActivity(deal, { id: uid }, DealAction.CHECKPOINT_ACTIVITY, checkpointActivityMessage);
    return this.update(updates);
  }

  async voteReadyCheck(deal, uid, rc, response, checkpointGroupName) {
    const path = [`deals/${deal.dealID}/activity/${rc.id}/votes/${uid}`];

    if (response === DealAction.APPROVE || response === DealAction.REJECT) {
      const activityMessage =
        response === DealAction.APPROVE
          ? `${CHECKPOINT_ACTIVITY_MESSAGE.APPROVE.action}:${checkpointGroupName}`
          : `${CHECKPOINT_ACTIVITY_MESSAGE.REJECT.action}:${checkpointGroupName}`;
      await Fire.addActivity(deal, { id: uid }, DealAction.CHECKPOINT_ACTIVITY, activityMessage);
    } else {
      await Fire.addActivity(
        deal,
        { id: uid },
        DealAction.CHECKPOINT_ACTIVITY,
        `${CHECKPOINT_ACTIVITY_MESSAGE.REMOVE_VOTE.action}:${checkpointGroupName}`
      );
    }

    const updates = {
      [`${path}/date`]: new Date().getTime().toString(),
      [`${path}/response`]: response,
      [`deals/${deal.dealID}/activity/${rc.id}/status`]: rc.getAutoCloseStatus(uid, response),
    };

    return this.update(updates);
  }

  async saveReport(report) {
    let reportID;

    let path = getReportPath(report);
    if (!path) return Promise.reject({ reportID, error: 'Could not build report path.' });

    reportID = report.reportID || this.db.ref(path).push().key;
    path += reportID;

    const updates = { [path]: this.clean(report.json, true) };
    await this.update(updates);
    return Promise.resolve(reportID);
  }

  async saveReportsOrder(reports) {
    let basePath = getReportPath(reports[0]);
    if (!basePath) return Promise.reject({ error: 'Could not build reports path.' });

    const updates = {};
    _.forEach(reports, (report) => (updates[`${basePath}${report.reportID}/index`] = report.index));

    await this.update(updates);
    return Promise.resolve(reports);
  }

  deleteReport(report) {
    let path = getReportPath(report, report.reportID);
    if (!path) return Promise.reject({ reportID: report.reportID, error: 'Could not build report path.' });

    if (path) {
      const updates = { [path]: null };
      return this.update(updates);
    } else {
      return Promise.resolve();
    }
  }

  async saveFilter(filter) {
    let filterID, path;
    const getFilterPath = (filter, path) => {
      filterID = filter.filterID || this.db.ref(path).push().key;
      path += filterID;
      return path;
    };

    if (filter.isTeamFilter) {
      path = getFilterPath(filter, `teams/${filter.teamID}/filters/`);
    } else if (filter.isUserFilter) {
      path = getFilterPath(filter, `users/${filter.userID}/filters/`);
    }

    const updates = { [path]: this.clean(filter.json, true) };
    await this.update(updates);
    return Promise.resolve(filterID);
  }

  deleteFilter(filter) {
    let path;

    if (filter.isTeamFilter) {
      path = `teams/${filter.teamID}/filters/`;
    } else if (filter.isUserFilter) {
      path = `users/${filter.userID}/filters/`;
    }

    if (path) {
      path += filter.filterID;
      const updates = { [path]: null };
      return this.update(updates);
    }

    return Promise.resolve();
  }

  async shouldSendNotificationEmail(userID, dealID, notificationTypeKey) {
    if (!userID) return Promise.reject();
    if (!dealID) return Promise.reject();
    if (!notificationTypeKey) return Promise.reject();

    const userTeams = await this.getUserTeams(userID);
    const settings = await this.getUserNotificationSettings(userID, userTeams);

    // By default we check the global settings:
    let obj = settings.global;

    // If there is a matching team in 'teams' and it is enabled, then we use those settings instead:
    const deal = await this.getDeal(dealID);
    const dealTeamID = deal.team;
    if (settings.teams[dealTeamID] && settings.teams[dealTeamID].enabled) {
      obj = settings.teams[dealTeamID];
    }

    return obj[notificationTypeKey].email;
  }

  async getUserNotificationSettings(userID, userTeams) {
    if (!userID) return Promise.reject();
    if (!userTeams) return Promise.reject();
    const basePath = `users/${userID}/notifications/settings`;
    const storedSettings = await this.load(basePath);
    const defaultSettings = makeDefaultNotificationSettings(userTeams);
    const settings = _.merge({}, defaultSettings, storedSettings);
    return settings;
  }

  async saveUserNotificationSettings(userID, settings) {
    if (!userID) return Promise.reject();
    if (!settings) return Promise.reject();
    const basePath = `users/${userID}/notifications/settings`;
    settings = this.clean(settings, false);
    const updates = { [basePath]: settings };
    await this.update(updates);
    return Promise.resolve(settings);
  }

  async saveUserNotification(userID, notification) {
    if (!userID) return Promise.reject();
    if (!notification.type) return Promise.reject();

    const basePath = `users/${userID}/notifications`;
    const notificationJSON = this.clean(notification.json, true);

    const updates = { [`${basePath}/${notification.type}`]: notificationJSON };

    await this.update(updates);

    return Promise.resolve(notificationJSON);
  }

  async deleteUserNotification(userID, type) {
    if (!userID) return Promise.reject();
    if (!type) return Promise.reject();

    const updates = { [`users/${userID}/notifications/${type}`]: null };
    await this.update(updates);

    return Promise.resolve();
  }

  async savePDFElement(element) {
    const path = `deals/${element.deal.dealID}/pdfElements`;
    // If there's no key yet, this is being newly created
    if (!element.key) {
      element.key = this.db.ref(path).push().key;
    }
    const updates = {};
    const json = this.clean(element.json, true);
    _.forEach(json, (property, key) => {
      updates[`${path}/${element.key}/${key}`] = property;
    });
    await this.update(updates);
    return element;
  }

  deletePDFElement(element) {
    const updates = { [`deals/${element.deal.dealID}/pdfElements/${element.key}`]: null };

    // When we delete a PDFElement, if there's no template selected and this is the only element mapped to the variable,
    // We should delete that variable too
    // This will be the case 99% of the time in normal slap-a-signature-on eSigning scenarios
    // Make sure that we only dlete simple variables (not party ones)
    if (
      element.elementType === ELEMENT_TYPE.VARIABLE &&
      element.dealVariable.type === VariableType.SIMPLE &&
      !element.deal.hasTemplate
    ) {
      const els = _.filter(element.deal.pdfElements, { variable: element.variable });
      if (els.length === 1) {
        updates[`deals/${element.deal.dealID}/variables/${element.variable}`] = null;
      }
    }

    return this.update(updates);
  }

  // Delete all *filled* PDF elements on a Deal.
  // This should only be used immediately after a new version is created,
  // as they have just been "flattened" into a new PDF -- see CommitVersion component
  // Unfilled elements (empty text boxes and unsigned signature fields) will be untouched,
  // and therefore be carried forward onto next version
  flushPDFElements(deal) {
    const updates = {};
    const filled = _.filter(deal.pdfElements, { isFilled: true });

    if (!filled.length) return Promise.resolve();

    _.forEach(filled, (el) => {
      updates[`deals/${deal.dealID}/pdfElements/${el.key}`] = null;
    });

    return this.update(updates);
  }

  async createTask(task) {
    const taskID = this.db.ref('tasks').push().key;

    const path = `tasks/${taskID}/`;

    const created = new Date().getTime().toString();
    task.created = created;
    task.updated = created;

    const updates = { [path]: this.clean(task, true) };
    await this.update(updates);
    return Promise.resolve(taskID);
  }

  async updateTask(taskID, data) {
    const path = `tasks/${taskID}`;

    const updates = {};
    _.forEach(this.clean(data), (val, key) => {
      if (['status', 'error', 'results'].includes(key)) updates[`${path}/${key}`] = val;
    });

    updates[`${path}/updated`] = new Date().getTime().toString();
    await this.update(updates);
  }
}

const Fire = new OutlawFirebase();

// Since we can't import Fire directly in Cypress, we are providing it through window.
if (typeof window !== 'undefined' && window.Cypress) {
  window.Fire = Fire;
}

export default Fire;
