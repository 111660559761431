import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { Popover as BSPopover, utils } from 'react-bootstrap';

import { classNamePrefixer } from '@core/utils';

import { Icon } from '@components/dmp';

utils.bootstrapUtils.addStyle(BSPopover, 'dark');
utils.bootstrapUtils.addStyle(BSPopover, 'light');

const cl = classNamePrefixer('popover');

const Popover = ({
  children,
  className,
  closeBtn,
  onHide,
  list,
  size,
  title,
  type,
  width,
  id,
  placement,
  dmpPlacement,
  style,
  ...passingProps
}) => {
  // TEMP fix (need to also overwrite <Overlay> omg...)
  placement = dmpPlacement || placement;

  const classNames = cx(
    cl(),
    className,
    cl(type),
    cl(placement),
    { [cl(size)]: size !== 'default' },
    { [cl('list')]: list }
  );

  const outerStyle = { ...style };
  if (width) outerStyle.width = width;
  if (placement === 'topleft') outerStyle.left = 0;

  const bsPlacement = placement === 'topleft' ? 'top' : placement;
  const arrowOffsetLeft = placement === 'topleft' ? 13 : null;

  return (
    <BSPopover
      {...passingProps}
      bsStyle={type}
      className={classNames}
      id={id}
      placement={bsPlacement}
      style={outerStyle}
      arrowOffsetLeft={arrowOffsetLeft}
    >
      <div className={cl('content')}>
        {!!title && <div className={cl('title')}>{title}</div>}
        {closeBtn && (
          <a className="close-popover" onClick={onHide} data-cy="variable-close-popover">
            <Icon name="closeTiny" className="close-icon" size="small" />
          </a>
        )}
        {children}
      </div>
    </BSPopover>
  );
};

Popover.defaultProps = {
  list: false,
  placement: 'right',
  dmpPlacement: null,
  size: 'default',
  type: 'light',
  closeBtn: false,
  onHide: null,
};

Popover.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  list: PropTypes.bool,
  width: PropTypes.number,
  positionTop: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  positionLeft: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  arrowOffsetTop: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  arrowOffsetLeft: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left', 'topleft']),
  dmpPlacement: PropTypes.oneOf(['topleft']),
  size: PropTypes.oneOf(['small', 'default']),
  type: PropTypes.oneOf(['dark', 'light']),
  title: PropTypes.string,
  style: PropTypes.object,
  closeBtn: PropTypes.bool,
  onHide: PropTypes.func,
};

const PopoverListItem = ({ children, className, icon, ...passingProps }) => {
  const classNames = cx(cl('list-item'), { [cl('w-icon')]: !!icon }, className);

  return (
    <div className={classNames} {...passingProps}>
      {!!icon && <div className={cl('list-item-icon')}>{icon}</div>}
      <div className={cl('list-item-content')}>{children}</div>
    </div>
  );
};

PopoverListItem.defaultProps = {
  placement: 'right',
  type: 'light',
  icon: null,
};

PopoverListItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  icon: PropTypes.node,
};

Popover.ListItem = PopoverListItem;

export default Popover;
