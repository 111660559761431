import _ from 'lodash';
import BoundingBox from './BoundingBox';
import TextFragment, { MID_MARGIN, PT_GAP, PAGE_MIDPOINT } from './TextFragment';

export default class TextLine {

  x;
  y;
  page;
  value;

  // Used to still retain (and visualized) raw source pdf content that was omitted from conversion into .vine
  // e.g., headers and footers 
  omit = null;
  omitReason = null;

  fragments = {};

  constructor({x, y, page, value = '', fragments, omit = null, omitReason = null}) {
    _.assign(this, {x, y, page, value, omit, omitReason});

    if (fragments) {
      this.fragments = _.mapValues(fragments, (json) => {
        if (json) return new TextFragment(json, this);
        else return null;
      });
    }
  }

  // Append text into the TextLine, and keep references to source TextFragments
  // For potential later use in fragment highlighting, e.g., to overlay boxes on original pdf's
  push(textFragment) {
    const index = this.value.length;
    this.value += textFragment.value;
    this.fragments[index] = textFragment;
    textFragment.line = this;

    // TODO: auto update width, height based on pushed fragments
  }

  get gaps() {
    const fragments = _.sortBy(this.fragments, 'x');
    let gaps = 0;
    for (let i = 1; i < fragments.length; i++) {
      if (fragments[i].x - fragments[i-1].endX >= PT_GAP) gaps += 1;
    }
    return gaps;
  }

  get spacers() {
    return _.filter(this.fragments, (frag, idx) => idx > 0 && !frag.value.trim() && frag.endX - frag.x > PT_GAP*2);
  }

  get crossesMiddle() {

    return !!_.find(this.fragments, (frag) => 
      // Starts in middle margin
      (frag.x >= MID_MARGIN.left && frag.x <= MID_MARGIN.right) ||
      // Ends in middle margin
      (frag.endX >= MID_MARGIN.left && frag.endX <= MID_MARGIN.right) ||
      // Spans middle margin
      (frag.x < MID_MARGIN.left && frag.endX > MID_MARGIN.right)
    );
  }

  get width() {
    return this.endX - this.x;
  }

  isCenteredOnPage(page) {
    return this.centerOffset <= MID_MARGIN.width && this.width/page.bodyWidth < .8;
  }

  get centerOffset() {
    const midpoint = this.x + (this.endX - this.x)/2;
    const offset = Math.abs(PAGE_MIDPOINT - midpoint);
    return offset;
  }

  get endX() {
    return _.max(_.map(this.fragments, 'endX'));
  }

  get endY() {
    return _.max(_.map(this.fragments, 'endY'));
  }

  get firstFragment() {
    return this.fragments[0];
  }

  get lastFragment() {
    return _.sortBy(this.fragments, 'x').pop();
  }

  get height() {
    return _.max(_.map(this.fragments, 'endY')) - _.min(_.map(this.fragments, 'y'));
  }

  get json() {
    return {
      ..._.pick(this, [
        'x',
        'y',
        'page',
        'value',
        'omit',
        'omitReason',
      ]),
      fragments: _.mapValues(this.fragments, (frag) => frag.json)
    };
  }

  get boundingBox() {
    return new BoundingBox({
      x: this.x,
      y: this.y,
      width: this.endX - this.x,
      height: this.height,
      page: this.page,
    });
  }

}