import { assign, find, forEach, keys, pick, uniqBy } from 'lodash';

import { META_FIELD_TYPES, convertMetaToES } from '../server/search/Meta';
import Address, { ADDRESS_META_FIELDS } from './Address';
import User from './User';

export const META_FIELDS = {
  address: { type: META_FIELD_TYPES.TEXT },
  addressProperties: ADDRESS_META_FIELDS,
  id: { type: META_FIELD_TYPES.STRING },
  email: { type: META_FIELD_TYPES.TEXT },
  features: {
    type: META_FIELD_TYPES.OBJECT,
    children: {
      connect: META_FIELD_TYPES.BOOLEAN,
      batch: META_FIELD_TYPES.BOOLEAN,
      bulkSign: META_FIELD_TYPES.BOOLEAN,
      vineIO: META_FIELD_TYPES.BOOLEAN,
      word: META_FIELD_TYPES.BOOLEAN,
    },
  },
  fullName: { type: META_FIELD_TYPES.TEXT },
  isAdmin: { type: META_FIELD_TYPES.BOOLEAN },
  isPartner: { type: META_FIELD_TYPES.BOOLEAN },
  isBilling: { type: META_FIELD_TYPES.BOOLEAN },
  org: { type: META_FIELD_TYPES.TEXT },
  phone: { type: META_FIELD_TYPES.TEXT },
  created: { type: META_FIELD_TYPES.DATE },
  updated: { type: META_FIELD_TYPES.DATE },
  lastLogin: { type: META_FIELD_TYPES.DATE },
  teams: {
    type: META_FIELD_TYPES.NESTED,
    children: {
      teamID: META_FIELD_TYPES.STRING,
      role: META_FIELD_TYPES.TEXT,
      observer: META_FIELD_TYPES.BOOLEAN,
    },
  },
  title: { type: META_FIELD_TYPES.TEXT },
  teamsCount: { type: META_FIELD_TYPES.FLOAT },
};

export default class UserRecord {
  address;
  addressProperties = {};
  email;
  features = {};
  fullName;
  id;
  isAdmin;
  isPartner = false;
  isBilling;
  org;
  phone;
  teams = [];
  title;
  created;
  updated;
  lastLogin;
  teamsCount = null;
  // createdAt; // Does not exist, retrieve from Firebase Auth and add to users + always at it from now on.

  constructor(user) {
    // We can reuse UserRecords from existing (untyped) search results
    if (!(user instanceof User)) {
      assign(this, user);
      return;
    }

    const { info } = user;

    if (!user.id) {
      throw new Error('User ID is required');
    }

    assign(this, pick(info, ['email', 'fullName', 'org', 'title', 'phone', 'address', 'addressProperties', 'created', 'updated', 'lastLogin']));

    if (
      this.addressProperties &&
      typeof this.addressProperties === 'object' &&
      !(this.addressProperties instanceof Address)
    ) {
      this.addressProperties = new Address(this.addressProperties);
      if (this.addressProperties.fullAddress && this.addressProperties.fullAddress.length) {
        this.address = this.addressProperties.fullAddress;
      }
    }

    this.isAdmin = user.isAdmin === true;
    
    this.isPartner = user.isPartner === true;
    this.isBilling = this.isBilling = !!user.subscriptionID;
    this.id = user.id;

    assign(this.features, user.features);

    if (user.teams) {
      forEach(user.teams, (value, key) => {
        this.teams.push({
          teamID: key,
          role: value,
        });
      });
    }

    if (user.teamMemberships) {
      forEach(user.teamMemberships, (value, key) => {
        const team = find(this.teams, { teamID: key });
        if (team) {
          team.observer = value.observer;
        }
      });
    }

    this.teamsCount = this.teams.length;
  }

  get meta() {
    const meta = pick(this, keys(META_FIELDS));
    return convertMetaToES(meta, META_FIELDS);
  }

  get roles() {
    const roles = [];
    forEach(this.teams, ({ role }) => {
      roles.push(role);
    });

    return uniqBy(roles);
  }
}
