import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ControlLabel, FormControl, FormGroup, InputGroup, Modal } from 'react-bootstrap';

import { FEATURES } from '@core/models/Team';
import User, { isAdmin } from '@core/models/User';
import Webhook from '@core/models/Webhook';
import { DateFormatter } from '@core/utils';

import { Button, CardList, Loader, ModalConfirm, Setting, Switch, Icon } from '@components/dmp';

import WebhookModal from '@components/webhooks/WebhookModal';
import WebhookTester from '@components/webhooks/WebhookTester';
import API from '@root/ApiClient';

@autoBindMethods
export default class Webhooks extends Component {
  static defaultProps = {
    user: null,
    isOwner: false,
  };

  static propTypes = {
    isOwner: PropTypes.bool,
    user: PropTypes.instanceOf(User).isRequired,
    team: PropTypes.object.isRequired,
    reloadTeam: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      webhooks: [],
      webhook: null,

      deleting: false,
      addingWebhook: false,
      loading: false,
      teamID: null,

      testingWebhook: null,
      deleteWebhook: false,
      webhookIDToDelete: null,
      savingWebhookIDs: [],

      isActivating: false,
      showConfirmDeactivate: false,
    };
  }

  async componentDidMount() {
    const { team } = this.props;
    await this.setActiveState();
    await this.loadWebhooks(team.teamID);
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.team.teamID !== this.props.team.teamID) {
      await this.setActiveState();
      await this.loadWebhooks(this.props.team.teamID);
    }
  }

  async setActiveState() {
    const { team } = this.props;
    await this.setState({ isActive: team.isFeatureActive(FEATURES.WEBHOOKS) });
  }

  handleChange(property, event) {
    this.setState({ [property]: event.target.value });
  }

  async loadWebhookSecret() {
    const { isOwner, team } = this.props;
    const { isActive } = this.state;

    if (!isActive || !isOwner) return;

    await this.setState({ isLoading: true });

    const webhooksSecret = await API.call('getTeamWebhookSecret', { teamID: team.teamID });

    this.setState({
      webhooksSecret: webhooksSecret || null,
      isLoading: false,
    });
  }

  async loadWebhooks(teamID) {
    let webhooks = [];

    await this.setState({
      teamID,
      addingWebhook: false,
      webhook: null,
    });

    const json = await API.call('getTeamWebhooks', { teamID });

    if (json) {
      _.forEach(json, (webhook) => {
        webhooks.push(new Webhook(webhook));
      });
    }

    this.setState({ webhooks });
  }

  async setWebhookStatus(webhook, enabled) {
    const { team, user } = this.props;

    await this.setState({ savingWebhookIDs: [...this.state.savingWebhookIDs, webhook.id] });
    await API.call('saveTeamWebhook', { teamID: team.teamID, uid: user.id, webhook: { ...webhook, enabled } });

    webhook.enabled = enabled;

    const updatedSavingWebhookIDs = this.state.savingWebhookIDs.filter((id) => id !== webhook.id);

    await this.setState({ savingWebhookIDs: updatedSavingWebhookIDs });
  }

  copyToClipBoard(ref) {
    ref.select();
    document.execCommand('copy');
    this.setState({ isCopying: true });
    setTimeout(() => {
      this.setState({ isCopying: false });
    }, 1000);
  }

  async generateSecret(event) {
    const { team } = this.props;
    this.setState({ isGeneratingSecret: true });
    event.stopPropagation();

    try {
      const webhooksSecret = await API.call('createTeamWebhookSecret', { teamID: team.teamID });
      this.setState({ webhooksSecret, isGeneratingSecret: false });
    } catch (err) {
      const errorMessage = "Sorry, we're unable to generate a webhook secret right now. Please try again later";
      this.setState({ generateSecretError: errorMessage, isGeneratingSecret: false });
    }
  }

  handleHideModal() {
    this.setState({ showModal: false });
  }

  async setFeature(isActive) {
    const { reloadTeam, team } = this.props;

    this.setState({ isActivating: true });
    await API.call('setTeamFeature', {
      teamID: team.teamID,
      name: FEATURES.WEBHOOKS.key,
      isActive,
    });

    await reloadTeam();
    await this.setActiveState();
    this.setState({ isActivating: false });
  }

  async handleDeactivate() {
    await this.setState({ isActivating: true });
    await this.deleteWebhooks();
    await this.setFeature(false);

    this.setState({ showConfirmDeactivate: false });
  }

  async deleteWebhooks() {
    const { team } = this.props;

    await this.setState({ isDeleting: true });

    await API.call('deleteTeamWebhooks', {
      teamID: team.teamID,
    });

    this.setState({
      isDeleting: false,
      showConfirmDelete: false,
      webhooks: [],
    });
  }

  async deleteWebhookByKey() {
    const { webhookIDToDelete } = this.state;
    const { team } = this.props;
    const teamID = team.teamID;

    if (!webhookIDToDelete || !teamID) return;

    await API.call('deleteTeamWebhook', {
      webhookID: webhookIDToDelete,
      teamID,
    });

    await this.loadWebhooks(teamID);

    this.setState({
      deleteWebhook: false,
      webhookIDToDelete: null,
    });
  }

  renderDeactivateModal() {
    const { showConfirmDeactivate, isActivating } = this.state;

    return (
      <ModalConfirm
        isLoading={isActivating}
        show={showConfirmDeactivate}
        onHide={() => this.setState({ showConfirmDeactivate: false })}
        onConfirm={this.handleDeactivate}
        confirmText="Deactivate"
        title="Really deactivate Webhooks?"
        body={
          <>
            <p>
              This action deactivate Webhook capabilities for this team.
              <br />
              This action removes the Webhooks associated with this team.
              <br />
              Any external integrations binded with these Webhooks will be invalid.
              <br />
              <b>This action cannot be undone.</b>
            </p>
            <p>Are you sure you want to proceed?</p>
          </>
        }
      />
    );
  }

  // TODO: hook into this secret modal somewhere for secret management
  renderModal() {
    const { showModal, generateSecretError, isCopying, isGeneratingSecret, webhooksSecret, webhooks } = this.state;

    const inputFieldValue = webhooksSecret ? webhooksSecret.secret : '';
    const dateCreated = webhooksSecret ? new Date(parseInt(webhooksSecret.dateCreated)) : null;

    return (
      <Modal className="modal-apiKey-info" show={showModal} onHide={this.handleHideModal}>
        <Modal.Header closeButton>
          <span className="headline">Configure Webhooks</span>
        </Modal.Header>

        <Modal.Body>
          <FormGroup>
            <ControlLabel>Webhook Secret</ControlLabel>
            <div className="contents">
              <InputGroup>
                <FormControl
                  className="webhooksSecret-secret"
                  inputRef={(secret) => (this.webhooksSecretField = secret)}
                  placeholder="Tap 'Generate' to create Webhook Secret"
                  readOnly
                  type="text"
                  value={inputFieldValue}
                />
                <InputGroup.Button>
                  <Button
                    className="copy-button"
                    disabled={!webhooksSecret}
                    onClick={() => this.copyToClipBoard(this.webhooksSecretField)}
                  >
                    <Icon name="copy"/>
                  </Button>
                </InputGroup.Button>
              </InputGroup>

              {webhooksSecret && (
                <p className="webhooksSecret-desc date-created">
                  {`Date Created: ${DateFormatter.date(dateCreated)} - ${DateFormatter.time(dateCreated)}`}
                </p>
              )}
              {generateSecretError && <p className="webhooksSecret-desc error">{generateSecretError}</p>}
            </div>
          </FormGroup>
          {isCopying && <span className="copied">Copied</span>}
        </Modal.Body>

        <Modal.Footer>
          {isGeneratingSecret && <Loader inline />}
          {!!webhooksSecret && (
            <Button dmpStyle="link-danger" onClick={() => this.setState({ showModal: false, showConfirmDelete: true })}>
              Delete
            </Button>
          )}
          <Button dmpStyle="primary" disabled={!!webhooksSecret} onClick={this.generateSecret}>
            Generate
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  renderWebhook(webhook) {
    const { savingWebhookIDs } = this.state;

    if (!webhook.subtypes) {
      webhook.subtypes = [];
    }
    return (
      <CardList.Item key={webhook.id}>
        <Setting actions>
          <Switch
            id={`chk-enable-hook-${webhook.id}`}
            checked={webhook.enabled}
            onChange={() => this.setWebhookStatus(webhook, !webhook.enabled)}
            disabled={savingWebhookIDs.includes(webhook.id)}
          />
          <div className="summary">
            {webhook.url}
            <br />
            <small>
              {webhook.eventType}: {webhook.subtypes.join(', ')}
            </small>
          </div>
          <div className="spacer" />
          <Button size="small" onClick={() => this.setState({ testingWebhook: webhook })}>
            Test
          </Button>
          <Button size="small" onClick={() => this.setState({ addingWebhook: true, webhook })}>
            Edit
          </Button>
        </Setting>
      </CardList.Item>
    );
  }

  renderWebhooks() {
    const { isOwner, user } = this.props;
    const { webhooks, isActive, isActivating } = this.state;

    if (!isActive) {
      if (isAdmin(user)) {
        return (
          <CardList.Item>
            <div className="d-flex justify-content-between align-items-center table-inner-disabled">
              Webhooks are currently disabled for this team.
              {isActivating ? (
                <Loader inline />
              ) : (
                <Button size="small" onClick={() => this.setFeature(true)}>
                  Enable
                </Button>
              )}
            </div>
          </CardList.Item>
        );
      } else {
        return (
          <CardList.Item>
            <div className="text-disabled" data-cy="webhooks-disabled-msg">
              Webhook is an advanced feature.
              <br />
              If you want to enable it, please contact your Account Manager.
            </div>
          </CardList.Item>
        );
      }
    }

    if (!isOwner) {
      return (
        <CardList.Item>
          <div className="text-disabled">Only team owners can view and configure Webhooks.</div>
        </CardList.Item>
      );
    }

    return (
      <>
        {!webhooks.length ? (
          <CardList.Item>
            <div className="text-disabled">You do not have any webhooks configured.</div>
          </CardList.Item>
        ) : (
          _.map(webhooks, this.renderWebhook)
        )}
        <CardList.Item>
          <Setting actions>
            <div className="spacer" />
            <Button onClick={() => this.setState({ addingWebhook: true })} data-cy="btn-add-webhook">
              Add Webhook
            </Button>
          </Setting>
        </CardList.Item>
      </>
    );
  }

  render() {
    const { user, team, isOwner } = this.props;
    const { webhook, addingWebhook, testingWebhook, deleteWebhook, isActive } = this.state;

    return (
      <div className="setting-block">
        <div className="block-header">
          <h4>Webhooks</h4>
          <span className="prompt">
            {' '}
            Integrate your systems by configuring webhooks that will automatically alert you when certain events occur
            in Outlaw.
          </span>
        </div>

        <div className="block-content">
          <div className="utility" style={{ minHeight: 33 }}>
            {isActive && isAdmin(user) && (
              <Button dmpStyle="link-danger" onClick={() => this.setState({ showConfirmDeactivate: true })}>
                Deactivate
              </Button>
            )}
            {/* We will put the Secret configuration here */}
            {isActive && isOwner && <div></div>}
          </div>
          <CardList>{this.renderWebhooks()}</CardList>

          <WebhookModal
            show={addingWebhook}
            onClose={() => this.loadWebhooks(team.teamID)}
            onDelete={() => this.setState({ deleteWebhook: true, addingWebhook: false, webhookIDToDelete: webhook.id })}
            webhook={webhook}
            user={user}
            team={team}
          />
        </div>

        <ModalConfirm
          show={deleteWebhook}
          onHide={() => this.setState({ deleteWebhook: false })}
          onConfirm={this.deleteWebhookByKey}
          confirmText="Delete"
          title="Are you sure you want to delete this webhook?"
          body="This is irreversible. Make sure that this webhook is not being used."
        />

        {this.renderDeactivateModal()}

        <WebhookTester
          user={user}
          webhook={testingWebhook}
          teamID={team.teamID}
          onClose={() => this.setState({ testingWebhook: null })}
        />
      </div>
    );
  }
}
