/* eslint-disable max-len */

export const isEmail = (email) => {
  // const reg = /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|capital|vc|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
  //from https://www.regular-expressions.info/email.html
  const reg = /^[A-Z0-9.'_%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return reg.test(email);
};

export const passwordValidated = (password) => {
  const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_+.]).{8,}$/;

  return reg.test(password);
}

/*
  Test a string to know if it matches the firebase unique key format.
  Reference: https://firebase.googleblog.com/2015/02/the-2120-ways-to-ensure-unique_68.html
*/
export const isFirebaseKey = (str) => {
  const reg = /^[A-Za-z0-9-_]{20}$/i;
  return reg.test(str);
};

/*
  Strict version of URL matching which requires either https? or www.
  e.g "getoutlaw.com/terms" won't match.
*/
export const isStrictURL = (url) => {
  const reg = /((http(s)?:\/\/)|(www\.))[a-zA-Z0-9@:%._\+\-~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+\-.~#?&//=]*)/;
  return reg.test(url);
};

//https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
export const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const isDateYmd = (dateString) => {
  const reg = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
  return reg.test(dateString);
};
