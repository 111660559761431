import React from 'react';

import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import Deal from '@core/models/Deal';
import { OPERATORS } from '@core/models/Operator';
import Section from '@core/models/Section';
import { ValueType } from '@core/models/Variable';
import { DYNAMIC_DATES } from '@core/models/VariableFilter';
import { DateFormatter } from '@core/utils';

import { Icon } from '@components/dmp';

const ConditionsView = (props) => {
  const { conditions, deal, section } = props;
  const Variable = ({ className, children }) => <span className={`variable ${className}`}>{children}</span>;
  const Operator = ({ children }) => <span className="operator">{children}</span>;
  const Values = ({ className, children }) => <span className={`value ${className}`}>{children}</span>;
  const ConditionalOperator = ({ children }) => <span className="conditional-operator">{children}</span>;

  let orderedConditions = section && section.complexConditions ? [] : conditions;

  if (section && section.complexConditions) {
    _.forEach(section.conditionGroups, (group) => {
      _.forEach(group.conditions, (condition) => {
        const foundCondition = _.find(section.conditions, ({ variable }) => {
          return condition === variable;
        });
        if (foundCondition) orderedConditions.push(foundCondition);
      });
    });
  }

  const ConditionDisplay = ({ condition }) => {
    const { operator, values, variable, valueType } = condition;
    const { section } = props;

    let displayValues = [...values];
    let complexStart = false;
    let complexEnd = false;
    let complexOperator = null;
    let groupOperator = null;

    // Conditions are no longer limited to SIMPLE variables,
    // but the VariableFilter model (which condition is an instance of) does not include variable type
    // so we can attempt lookup from the actual variable instance, but fallback to # as default just to be safe
    const varType = _.get(section, `deal.variables[${variable}].type`, '#');
    const nativeVariable = section?.deal.variables[variable];

    if (valueType === ValueType.DATE) {
      if (values[0])
        displayValues[0] = DateFormatter.mdy(
          new Date(values[0] * 1000 + DateFormatter.getOffset(new Date(values[0] * 1000)))
        );
      if (values[1])
        displayValues[1] = DateFormatter.mdy(
          new Date(values[1] * 1000 + DateFormatter.getOffset(new Date(values[1] * 1000)))
        );
    }

    let display;

    if (section && section.complexConditions) {
      const group = _.find(section.conditionGroups, (group) => {
        return _.find(group.conditions, (condition) => {
          return condition === variable;
        });
      });

      const groupIndex = _.findIndex(section.conditionGroups, (group) => {
        return _.find(group.conditions, (condition) => {
          return condition === variable;
        });
      });

      if (group) {
        const groupLength = group.conditions.length;
        const index = _.findIndex(group.conditions, (condition) => {
          return condition === variable;
        });

        if (groupLength === 1) {
          complexStart = true;
          complexEnd = true;
        } else if (index === groupLength - 1) {
          complexEnd = true;
        } else if (index === 0) {
          complexStart = true;
        }

        if (index !== groupLength - 1 && groupLength !== 1) {
          complexOperator = _.toUpper(group.individualOperator);
        }

        if (index === 0 && groupIndex !== 0 && groupIndex !== section.conditionGroups.length) {
          groupOperator = _.toUpper(group.groupOperator);
        }
      }
    }

    if (
      condition.errorMsg ||
      (nativeVariable?.isRedacted && ![OPERATORS.KNOWN.key, OPERATORS.UNKNOWN.key].includes(operator.key))
    ) {
      display = (
        <>
          <Variable className="error">
            {varType}
            {variable}
          </Variable>{' '}
          <Values>{condition.errorMsg}</Values>
        </>
      );
    } else {
      switch (operator.key) {
        case OPERATORS.EQUAL.key:
        case OPERATORS.UNEQUAL.key:
        case OPERATORS.LESS.key:
        case OPERATORS.BEFORE.key:
        case OPERATORS.GREATER.key:
        case OPERATORS.AFTER.key:
          display = (
            <>
              {groupOperator && <ConditionalOperator>{groupOperator + ' '}</ConditionalOperator>}
              {complexStart && <ConditionalOperator>{'('}</ConditionalOperator>}
              <Variable>
                {varType}
                {variable}
              </Variable>{' '}
              <Operator>{operator.title.toLowerCase()}</Operator> <Values>{displayValues[0]}</Values>
              {complexOperator && <ConditionalOperator>{' ' + complexOperator}</ConditionalOperator>}
              {complexEnd && <ConditionalOperator>{')'}</ConditionalOperator>}
            </>
          );
          break;
        case OPERATORS.BETWEEN.key:
          display = (
            <>
              {groupOperator && <ConditionalOperator>{groupOperator + ' '}</ConditionalOperator>}
              {complexStart && <ConditionalOperator>{'( '}</ConditionalOperator>}
              <Variable>
                {varType}
                {variable}
              </Variable>{' '}
              <Operator>{operator.title.toLowerCase()}</Operator>{' '}
              <Values>
                [{displayValues[0]}] - [{displayValues[1]}]
              </Values>
              {complexEnd && <ConditionalOperator>{' )'}</ConditionalOperator>}
              {complexOperator && <ConditionalOperator>{' ' + complexOperator}</ConditionalOperator>}
            </>
          );
          break;
        case OPERATORS.KNOWN.key:
        case OPERATORS.UNKNOWN.key:
          display = (
            <>
              {groupOperator && <ConditionalOperator>{groupOperator + ' '}</ConditionalOperator>}
              {complexStart && <ConditionalOperator>{'( '}</ConditionalOperator>}
              <Variable>
                {varType}
                {variable}
              </Variable>{' '}
              <Operator>{operator.title.toLowerCase()}</Operator>
              {complexEnd && <ConditionalOperator>{' )'}</ConditionalOperator>}
              {complexOperator && <ConditionalOperator>{' ' + complexOperator}</ConditionalOperator>}
            </>
          );
          break;
        case OPERATORS.IN.key:
        case OPERATORS.OUT.key:
          display = (
            <>
              {groupOperator && <ConditionalOperator>{groupOperator + ' '}</ConditionalOperator>}
              {complexStart && <ConditionalOperator>{'( '}</ConditionalOperator>}
              <Variable>
                {varType}
                {variable}
              </Variable>{' '}
              <Operator>{operator.title.toLowerCase()}</Operator> <Values>[{displayValues.join(', ')}]</Values>
              {complexEnd && <ConditionalOperator>{' )'}</ConditionalOperator>}
              {complexOperator && <ConditionalOperator>{' ' + complexOperator}</ConditionalOperator>}
            </>
          );
          break;
        case OPERATORS.DYNAMIC.key:
          display = (
            <>
              {groupOperator && <ConditionalOperator>{groupOperator + ' '}</ConditionalOperator>}
              {complexStart && <ConditionalOperator>{'( '}</ConditionalOperator>}
              <Variable>
                {varType}
                {variable}
              </Variable>{' '}
              <Values>is dynamic: {_.get(_.find(DYNAMIC_DATES, { val: Number(values[0]) }), 'title', '')}</Values>
              {complexEnd && <ConditionalOperator>{' )'}</ConditionalOperator>}
              {complexOperator && <ConditionalOperator>{' ' + complexOperator}</ConditionalOperator>}
            </>
          );
          break;
        default:
          display = '';
          break;
      }
    }

    return display;
  };

  return (
    <div className="conditions-view" data-cy="template-condition">
      <Icon name="conditional" size="default" noFill={true} />
      <span>
        {orderedConditions.map((condition, idx) => {
          const invalid = deal ? !_.find(deal.variables, { name: condition.variable }) : false;
          return (
            <span
              className={cx('each-condition', { invalid, hasError: condition.errorMsg })}
              key={idx}
              data-cy="each-condition"
            >
              <ConditionDisplay condition={condition} />
              {idx < conditions.length - 1 && <>&nbsp;</>}
            </span>
          );
        })}
        {conditions.length === 0 && 'no conditions'}
      </span>
    </div>
  );
};

ConditionsView.propTypes = {
  conditions: PropTypes.array.isRequired,
  deal: PropTypes.instanceOf(Deal),
  section: PropTypes.instanceOf(Section),
};

export default ConditionsView;
