import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import PropTypes from 'prop-types';

import { ControlLabel, FormGroup, Modal } from 'react-bootstrap';

import { EXPORTABLE_PARTY_PROPERTIES } from '@core/models/Address';
import { prepCSV } from '@core/models/DealRecord';
import { downloadLink, formatNumber } from '@core/utils';

import { Alert, Button, Dropdown, MenuItem, Switch } from '@components/dmp';

import API from '@root/ApiClient';

@autoBindMethods
export default class ExportCSV extends Component {
  static defaultProps = {
    exportAll: false,
    exportAllCount: 0,
  };

  static propTypes = {
    deals: PropTypes.array.isRequired,
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    searchParams: PropTypes.object.isRequired,
    exportAll: PropTypes.bool,
    exportAllCount: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      exportingCSV: false,
      downloadLink: '',
      option: 'all-columns',
      omitColumns: false,
      partiesColumns: [...EXPORTABLE_PARTY_PROPERTIES],
    };
  }

  async handleExportOptions(option) {
    await this.setState({
      option,
      partiesColumns: [...EXPORTABLE_PARTY_PROPERTIES],
    });
  }

  async handlePartiesColumns(property) {
    const { partiesColumns } = this.state;
    const column = _.find(partiesColumns, { data: property.data });

    if (column) {
      _.remove(partiesColumns, { data: property.data });
    } else {
      partiesColumns.push(property);
    }

    await this.setState({ partiesColumns });
  }

  async exportCSV() {
    const { deals, searchParams, exportAll, onConfirm } = this.props;
    const { option, omitColumns, partiesColumns } = this.state;
    await this.setState({ exportingCSV: true });

    const vaultColumns = searchParams.columns;

    // We already have all the required data in local DealRecords and we know what's selected
    // So "exporting" simply requires putting it into a format ready for csv and then force clicking the hidden link!
    const exportOption = {
      option: option,
      omitColumns: omitColumns,
    };

    if (exportAll) {
      // Pass the timezone of the user to the backend
      exportOption.dateTimeFormat = Intl.DateTimeFormat().resolvedOptions();
      const exportCSVTask = {
        type: 'exportCSV',
        params: {
          apiArgs: searchParams.apiArgs,
          exportOption,
          partiesColumns,
          vaultColumns,
        },
      };

      // Create task for export
      API.request({ functionName: 'tasks', method: 'post', data: exportCSVTask });
      this.setState({
        exportingCSV: false,
        option: 'all-columns',
        omitColumns: false,
        partiesColumns: [...EXPORTABLE_PARTY_PROPERTIES],
      });
    } else {
      // Export selected deals
      const { json, fields } = prepCSV(deals, exportOption, partiesColumns, vaultColumns);
      const csv = downloadLink(json, fields);
      await this.setState({
        downloadLink: csv,
        exportingCSV: false,
        option: 'all-columns',
        omitColumns: false,
        partiesColumns: [...EXPORTABLE_PARTY_PROPERTIES],
      });
      this.refs.download.click();
    }

    onConfirm();
  }

  onClose() {
    if (this.state.exportingCSV) return;
    else this.props.onClose();
  }

  render() {
    const { show, deals, searchParams, exportAll, exportAllCount } = this.props;
    const { downloadLink, option, omitColumns, partiesColumns } = this.state;
    if (!deals.length && !exportAll) return null;

    const hasParties = searchParams.columns.includes('parties');
    const columns = searchParams.columns.join(', ');

    return (
      <>
        <Modal dialogClassName="export-deal" show={show} onHide={this.onClose}>
          <Modal.Header closeButton>
            <span className="headline">Export CSV</span>
          </Modal.Header>
          <Modal.Body>
            <div className="wrapper">
              <FormGroup>
                <ControlLabel className="export-options">Export</ControlLabel>
                <div className="contents export-options" data-cy="export-options">
                  <Dropdown
                    id="dd-select-filter-scope"
                    dataCyToggle="dd-select-filter-scope"
                    onSelect={this.handleExportOptions}
                    title={option === 'all-columns' ? 'All columns' : 'Visible columns in vault view'}
                    size="small"
                    width={380}
                  >
                    <MenuItem eventKey="all-columns" active={option === 'all-columns'}>
                      All columns
                    </MenuItem>
                    <MenuItem eventKey="vault-view" active={option === 'vault-view'}>
                      Visible columns in vault view
                    </MenuItem>
                  </Dropdown>
                  {option === 'vault-view' && <small>{columns}</small>}
                </div>
              </FormGroup>
              {option === 'vault-view' && hasParties && (
                <FormGroup>
                  <ControlLabel>Parties</ControlLabel>
                  <div className="export-parties-properties">
                    {_.map(EXPORTABLE_PARTY_PROPERTIES, (property) => (
                      <>
                        <Switch
                          key={`chk-${property.data}`}
                          className={property.data}
                          id={`chk-${property.data}`}
                          checked={_.find(partiesColumns, { data: property.data })}
                          size="small"
                          onChange={() => this.handlePartiesColumns(property)}
                          data-cy="chk-export-parties-properties"
                        >
                          {property.label}
                        </Switch>
                        {property.data === 'address' && (
                          <small className="address-description">
                            Combined text string containing all address details
                          </small>
                        )}
                      </>
                    ))}
                  </div>
                </FormGroup>
              )}
              <FormGroup>
                <ControlLabel>Options</ControlLabel>
                <Switch
                  id="chk-omit-null"
                  className="omit-null"
                  checked={omitColumns}
                  size="small"
                  onChange={() => this.setState({ omitColumns: !omitColumns })}
                  data-cy="chk-omit-null"
                >
                  Omit column(s) if no variable values are found
                </Switch>
              </FormGroup>
              {exportAll && exportAllCount >= 10000 && (
                <Alert dmpStyle="info">
                  <b>You are about to extract {formatNumber(exportAllCount)} contracts!</b>
                  <br />
                  To reduce export time, we recommend filtering to include only the contracts you need.
                </Alert>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-cancel-export-csv" onClick={this.onClose} data-cy="btn-cancel-export-csv">
              Cancel
            </Button>
            <Button dmpStyle="primary" onClick={this.exportCSV} data-cy="btn-export-csv">
              Export CSV
            </Button>
          </Modal.Footer>
        </Modal>
        <a className="hide csv" ref="download" href={downloadLink} download="export.csv" />
      </>
    );
  }
}
