import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { classNamePrefixer } from '@core/utils';

const cl = classNamePrefixer('key');

const Key = (props) => {
  const { children, label, size, ...rest } = props;

  const className = cx(cl(), cl(size));

  return (
    <div className={className} {...rest}>
      <div className={cl('key')}>{children}</div>
      {!!label && <div className={cl('label')}>{label}</div>}
    </div>
  );
};

Key.defaultProps = {
  label: null,
  size: 'default',
};

Key.propTypes = {
  children: PropTypes.string.isRequired,
  label: PropTypes.string,
  size: PropTypes.oneOf(['xsmall', 'small', 'default', 'large']),
};

export default Key;
