import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { convertPointsToPixels, classNamePrefixer } from '@core/utils';

import { MARGIN_SIDES, RULERS } from '@core/parsing/TextPage';

export const LENS_TYPES = {
  SECTION: 'section',
  OMITTED: 'omitted',
  MARGINS: 'margins',
};

const cl = classNamePrefixer('lens');

const getBoxStyle = (box, scale, pageData) => {
  let style = {
    position: 'absolute',
  };

  const [,, pageWidth, pageHeight] = pageData.view;

  // console.log(boxNumber, externalNumber, externalTitle, pageStart);
  const invertedY = pageHeight - box.y - box.height;

  style.height = box.height * scale;
  style.width = box.width * scale;
  style.left = box.x * scale;

  style.top = (invertedY + 2) * scale;// + style.height/2;
  // style.bottom = (box.y + box.height/4) * scale;
  
  return style;
}

const Lens = ({ candidate, type, scale, page, reviewOptions, pageData }) => {
  const { boxNumber, boxTitle, definedTerms, externalNumber, externalTitle, pageStart } = candidate;

  let boxSection = null, boxOmitted = null;

  if (type === LENS_TYPES.SECTION) {
    boxSection = candidate.getBoxSection(page);
  }

  if (type === LENS_TYPES.OMITTED) {
    boxOmitted = candidate.boundingBox;
  }

  const { 
    showSections, 
    showNumbers,
    showTerms, 
    showTitles, 
    showHeaders, 
    showFooters, 
    showMargins, 
    showDocTitle,
  } = reviewOptions;

  const continuesAfter = boxSection?.continuesAfter;
  const continuesBefore = boxSection?.continuesBefore;
  const isThisPage = candidate.pageStart === page;

  const sectionClass = cx(
    cl(type),
    {[cl(candidate.subType)]: type === LENS_TYPES.SECTION},
    {[cl('continues-after')]: continuesAfter},
    {[cl('continues-before')]: continuesBefore},
    {[cl('preamble')]: candidate.isPreamble},
  );

  let margins = null;

  if (type === LENS_TYPES.MARGINS) {
    margins = {};
    _.forEach(MARGIN_SIDES, (side) => { margins[side] = candidate.getMarginBox(side) })
    // console.log(margins);

    if (candidate.hasColumns) {
      // _.forEach(RULERS, (side) => { margins[side] = candidate.getMarginBox(side) })
    }
  }

  _.forEach(definedTerms, (term) => {
    // console.log(term.boxes);
  });

  return (
    <>
      {boxSection && showSections && 
        <div className={sectionClass} style={getBoxStyle(boxSection, scale, pageData)} />
      }
      {boxNumber && showNumbers && isThisPage &&
        <div className={cl('section-number')} style={getBoxStyle(boxNumber, scale, pageData)} />
      }
      {boxTitle && showTitles && isThisPage &&
        <div className={cl('section-title')} style={getBoxStyle(boxTitle, scale, pageData)} />
      }
      {showTerms && _.map(definedTerms, (term, idx) => 
        <div key={`term-${idx}`} className={cl('defined-term')} style={getBoxStyle(term.boxes, scale, pageData)} />
      )}
      {boxOmitted && showHeaders && candidate.omitReason === 'header' &&
        <div className={cl('header')} style={getBoxStyle(boxOmitted, scale, pageData)} />
      }
      {boxOmitted && showFooters && candidate.omitReason === 'footer' &&
        <div className={cl('footer')} style={getBoxStyle(boxOmitted, scale, pageData)} />
      }
      {boxOmitted && showDocTitle && candidate.omitReason === 'docTitle' &&
        <div className={cl('doc-title')} style={getBoxStyle(boxOmitted, scale, pageData)} />
      }

      {type === LENS_TYPES.MARGINS && showMargins && _.map(MARGIN_SIDES, (side) => 
        <div key={side} className={cl(`margin-${side}`)} style={getBoxStyle(margins[side], scale, pageData)} />
      )}
      {type === LENS_TYPES.MARGINS && showMargins && candidate.hasColumns && _.map(RULERS, (side) => 
        <div key={side} className={cl(`margin-${side}`)} style={getBoxStyle(margins[side], scale, pageData)} />
      )}

    </>
  )
};

Lens.propTypes = {
  type: PropTypes.oneOf(Object.values(LENS_TYPES)).isRequired,
  candidate: PropTypes.object.isRequired,
  scale: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  pageData: PropTypes.object.isRequired,
  reviewOptions: PropTypes.object.isRequired,
};

export default Lens;
