import { filter, findLastIndex, forEach, forOwn, sortBy } from 'lodash';

import DealAction from '../enums/DealAction';
import DealStatus from '../enums/DealStatus';
import Activity from './Activity';
import ReadyCheck from './ReadyCheck';

export default class ActivityLog {
  activity = [];
  deal = null;

  // We can't use "instanceof(Section)" here becuase it would require circular import
  // But we want to capture the sectionID on the Activity object at instantiation
  // So this is the best spot to do it
  // sectionID param will be undefined for the Deal log
  constructor(json, deal, sectionID = null) {
    this.deal = !deal ? this : deal;

    if (json != null) {
      //Activity has sub-classes with additional data (e.g., ReadyCheck)
      //so this is an abbreviated factory class of sorts
      forEach(json, (data) => {
        //no idea how empty data was getting here, but this was causing a crash bug!
        if (!data) return;
        const { action, id, user, message, ip, location, userAgent, meta } = data;
        switch (action) {
          case DealAction.READY_CHECK:
            this.activity.push(new ReadyCheck(data));
            break;
          default:
            this.activity.push(
              new Activity({
                action,
                id,
                user,
                message,
                sectionID,
                ip,
                location,
                userAgent,
                meta,
              })
            );
            break;
        }
      });
    }
  }

  //get a simple json object, optionally including the activity
  json(includeActivity) {
    var obj = {};
    forOwn(this, (val, key) => {
      //omit deal ref
      if (key == 'deal') return;
      //omit activity if desired
      if (key == 'activity' && !includeActivity) return;
      //omit any added functions
      if (typeof val == 'function') return;

      obj[key] = val;
    });
    return obj;
  }

  static get assumedStatus() {
    return DealStatus.NONE;
  }

  get myLastAction() {
    if (this.activity.length == 0 || this.deal.currentDealUser == null) return null;
    const acts = filter(this.activity, {
      user: this.deal.currentDealUser.uid,
    });
    if (acts.length == 0) return null;
    else return sortBy(acts, 'date').pop();
  }

  get latestAction() {
    return sortBy(this.activity, 'date').pop();
  }

  //comment threads can be resolved and re-opened if a subsequent user adds another comment
  //so this fetches the latest comment that STARTED an open thread
  get latestThreadStarter() {
    const comments = this.comments;
    const lastResolve = findLastIndex(comments, {
      action: DealAction.SUBMIT,
    });
    if (lastResolve + 1 < comments.length) return comments[lastResolve + 1];
    else return null;
  }

  get comments() {
    //only REJECT (comment) and SUBMIT (approve) actions are relevant in section activity view
    //in other words, it's a simple comment thread
    return filter(this.activity, (a) => [DealAction.REJECT, DealAction.SUBMIT].indexOf(a.action) > -1);
  }

  get activityStatus() {
    let stat = this.constructor.assumedStatus;

    //deletion flow overrides comments
    if (this.deleted) return this.deletionApproved ? stat : DealStatus.REVIEW;

    const comments = this.comments;

    //if there are comments, first see if there's anything open
    if (comments.length > 0) {
      //if there is activity, status depends on latest action
      const latestAction = sortBy(comments, 'date').pop();

      switch (latestAction.action) {
        //reject action is fired on comments
        case DealAction.REJECT:
          // stat = DealStatus.REVIEW;
          stat = DealStatus.DISCUSS;
          break;
        //submit action is fired when issues are "resolved"
        case DealAction.SUBMIT:
          stat = DealStatus.AGREED;
          break;
        default:
          break;
      }
    }

    //if we're in review already then we can stop here
    if (stat == DealStatus.DISCUSS) return stat;

    //next, if there are multiple versions see if there's anything open
    //currentVersion will only be populated for Section objects
    const v = this.currentVersion;
    if (v != null && v.hasChanges('body')) stat = DealStatus.REVIEW;

    return stat;
  }
}
