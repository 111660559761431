import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';

import { FormControl, Overlay } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import DealAction from '@core/enums/DealAction';
import DealRole from '@core/enums/DealRole';
import DealStatus from '@core/enums/DealStatus';
import { COLORS, initials } from '@core/models/DealUser';
import { timeDifference } from '@core/utils';

import { Alert, Breakable, Button, Loader, Popover } from '@components/dmp';

import TooltipButton from '@components/editor/TooltipButton';
import API from '@root/ApiClient';
import Fire from '@root/Fire';

@autoBindMethods
export default class ActivityView extends Component {
  constructor(props) {
    super(props);
    this.deal = this.props.log.deal;

    this.state = {
      message: '',
      editingComment: null,
      height: 30,
      sending: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  keyPress(e) {
    // if (e.target.scrollHeight < 45 && this.state.expanded) this.setState({expanded: false});
    //handle enter key
    if (e.key == 'Enter' && e.shiftKey && this.state.message != '') {
      e.preventDefault();
      this.saveComment();
    }
  }

  resize() {
    const textarea = ReactDOM.findDOMNode(this.refs.message);
    if (!textarea) return;
    if (textarea.scrollHeight > textarea.offsetHeight) this.setState({ height: textarea.scrollHeight });
  }

  async saveComment() {
    const { log, user, onHide } = this.props;
    const { editingComment, message } = this.state;

    await this.setState({ sending: true });

    //if not editing an existing message, this is a new message
    if (!editingComment) {
      // Hide popover *first* so that UI appears snappier -- we can save in background
      onHide();

      const activity = await Fire.addActivity(log, user, DealAction.REJECT, message);
      //tell server to notify other parties of new action
      //only relevant for deal comments (template discussion does not fire off emails)
      if (!log.deal.template) {
        const args = { dealID: log.deal.dealID, logID: log.id, activityID: activity.id };
        API.call('sendActivity', args);
      }
    }

    //if editing existing, we need a different update call
    else {
      await Fire.editActivity(log, editingComment, message);
      this.cancelEditing();
    }
  }

  async editComment(activity) {
    await this.setState({ message: activity.message, editingComment: activity });
    if (this._isMounted) {
      this.focus();
      this.resize();
    }
  }

  async cancelEditing() {
    await this.setState({ message: '', editingComment: null, sending: false });
    if (this._isMounted) ReactDOM.findDOMNode(this.refs.message).blur();
    return Promise.resolve();
  }

  async resolve() {
    const { log, user, onHide } = this.props;
    const resolveMessage = this.state.message || null;

    // Hide popover *first* so that UI appears snappier -- we can save in background
    onHide();

    //log resolve action
    const activity = await Fire.addActivity(log, user, DealAction.SUBMIT, resolveMessage);

    //if not a template, tell server to notify other parties of new action
    if (!log.deal.template) {
      const args = { dealID: log.deal.dealID, logID: log.id, activityID: activity.id };
      API.call('sendActivity', args);
    }
  }

  clear() {
    const { log, onHide } = this.props;
    // Hide popover *first* so that UI appears snappier -- we can save in background
    onHide();
    Fire.clearComments(log);
  }

  focus() {
    const textarea = ReactDOM.findDOMNode(this.refs.message);
    if (textarea) textarea.focus();
  }

  render() {
    const { log, target, onHide, user, template, container } = this.props;
    const { message, sending, expanded, height, editingComment } = this.state;

    // const isCompact = window ? window.innerWidth < 768 : false;
    const isCompact = window ? window.innerWidth < 1140 : false; //$breakpoint-desktop = 992
    const comments = log.comments;
    const du = log.deal.currentDealUser;

    //any user with permissions to look at a template may comment; if it's a normal deal, use deal permissions
    const canComment = template ? true : log.deal.can(user.id, 'comment');
    //template users can always clear; for deals, only owners can
    const canClear = template ? true : du != null && du.role == DealRole.OWNER && comments.length > 0;
    //comment resolution requires a pending comment thread
    let canResolve = comments.length > 0 && log.activityStatus.data != DealStatus.AGREED.data;
    //don't let guests comment
    const isGuest = user.anonymous;
    const closeBtn = !!isGuest;

    if (canResolve && !template) {
      //we didn't put this check above because for templates, du can be null (user can see other team members' templates)
      if (!du) canResolve = false;
      //we need to further limit comment resolution here.
      //Owners and Editors can always resolve (because they have the power to edit the underlying contract anyway, which could make a comment moot)
      //But viewers (whether signer or not) can only resolve a comment thread that THEY STARTED
      else if ([DealRole.OWNER, DealRole.EDITOR].indexOf(du.role) < 0) {
        const latestThreadStarter = log.latestThreadStarter;
        if (latestThreadStarter != null && latestThreadStarter.user != user.id) canResolve = false;
      }
    }

    return (
      <Overlay
        show={true}
        onHide={onHide}
        target={target}
        container={container || null}
        onEntered={this.focus}
        placement={template ? 'bottom' : isCompact ? 'top' : 'right'}
      >
        <Popover
          id={`pop-activity-${log.id}`}
          className={cx('popover-activity-view', { compact: isCompact })}
          data-cy="pop-activity"
          // Bit of a hack but when logged in as guest, there's no other way to close the popover
          // In normal cases the user will have a "cancel" link at the bottom
          closeBtn={closeBtn}
          onHide={closeBtn ? onHide : null}
        >
          <div className="activity-header" data-cy="activity-header">
            <span className="activity-title" data-cy="activity-title">
              {comments.length === 0 ? 'No Comments' : 'Comments'}
            </span>

            {comments.length > 0 && canClear && !isGuest && (
              <TooltipButton tipID={`activity-clear-${log.id}`} tip="Delete comments and resolve thread">
                <Button
                  className="clear"
                  size="small"
                  dmpStyle="link"
                  onClick={() => this.clear()}
                  data-cy="btn-clear-comment"
                >
                  Clear
                </Button>
              </TooltipButton>
            )}
            {comments.length > 0 && !isGuest && (
              <TooltipButton tipID={`activity-resolve-${log.id}`} tip="Mark this comment thread resolved">
                <Button
                  className="resolve"
                  size="small"
                  dmpStyle="link"
                  disabled={!canResolve}
                  onClick={() => this.resolve()}
                  data-cy="btn-resolve-comment"
                >
                  Resolve
                </Button>
              </TooltipButton>
            )}
          </div>

          {comments.length > 0 && (
            <div className="activity-list" data-cy="activity-list">
              {comments.map((a) => this.renderActivity(a))}
            </div>
          )}

          {isGuest && (
            <Alert icon="nope" inline size="small" title="You are currently a guest" dark>
              In order to comment you need to create a free account on Outlaw.
              <br />
              <Link to={`/login/?redirect=${encodeURIComponent(window.location.pathname)}`}>Tap here</Link> to continue.
            </Alert>
          )}

          {canComment && !isGuest && (
            <div className={`activity-footer${comments.length == 0 ? ' no-comments' : ''}`} data-cy="activity-footer">
              <FormControl
                componentClass="textarea"
                style={{ height }}
                value={this.state.message}
                className={expanded ? 'expanded' : null}
                ref="message"
                disabled={sending}
                placeholder="Add your comment"
                onChange={(e) => this.setState({ message: e.target.value })}
                onKeyPress={(e) => this.keyPress(e)}
                onKeyDown={this.resize}
                data-cy="input-comment"
              />
              <div>
                {sending && <Loader inline />}
                <Button
                  disabled={sending}
                  dmpStyle="link"
                  size="small"
                  className="cancel"
                  onClick={editingComment ? this.cancelEditing : onHide}
                  data-cy="btn-cancel-comment"
                >
                  Cancel
                </Button>
                <Button
                  disabled={!message || sending}
                  dmpStyle="primary"
                  size="small"
                  className="send"
                  onClick={this.saveComment}
                  data-cy="btn-submit-comment"
                >
                  Comment
                </Button>
              </div>
            </div>
          )}
        </Popover>
      </Overlay>
    );
  }

  renderActivity(a) {
    const { template, members } = this.props;

    let du, color;

    //ActivityView can be used on both Contracts and Templates
    //but for Templates, the list of users who can edit (and comment) includes all team members
    //so we need to lookup users from that list in order to show template activity
    if (!template) {
      du = this.deal.getUserByID(a.user);
      color = du ? { backgroundColor: du.color } : null;
    } else {
      du = _.find(members, { id: a.user });
      if (du != null) {
        //mimic DealUser color assignment based on team member list
        const idx = members.indexOf(du) % COLORS.length;
        color = { backgroundColor: COLORS[idx] };
      }
    }

    //if DealUser/team member is not found, it means the user left the deal
    //eventually we will handle this more gracefully
    //for now we don't have any record of that user, so nothing to show
    if (!du) return null;

    const party = du.partyID ? this.deal.getPartyByID(du.partyID) : null;
    //only show full user info (initials etc) for comment actions
    const showUser = a.action == DealAction.REJECT;
    //user can only edit a comment if it's the latest one and their own
    const canEdit =
      !this.state.editingComment &&
      a == this.props.log.latestAction &&
      this.props.user &&
      this.props.user.id == a.user &&
      showUser;

    const editing = a == this.state.editingComment;
    let messageClass = 'message';
    if (canEdit) messageClass += ' editable';
    if (editing) messageClass += ' editing';

    //we can't use DealUser.get here becuase du might be a generic json object (for template team members)
    const fullName = du.fullName || du.email;

    return (
      <div key={a.id} className={`activity${!showUser ? ' auto' : ''}`}>
        {showUser && (
          <div className="activity-meta">
            <div className="initials" data-cy="initials" style={color}>
              {initials(du)}
            </div>
            <div className="name-details">
              <div className="name" data-cy="name">
                {fullName}
              </div>
              {party && (
                <span className="party" data-cy="party">
                  {party.partyName} •{' '}
                </span>
              )}
              <span className="time" data-cy="time">
                {timeDifference(a.date)}
              </span>
            </div>
          </div>
        )}

        {showUser ? (
          <div className={messageClass}>
            <span data-cy="message">
              <Breakable>{a.displayMessage}</Breakable>
            </span>
            {canEdit && (
              <span className="action-edit" data-cy="action-edit" onClick={() => this.editComment(a)}>
                Edit
              </span>
            )}
            {editing && (
              <span className="action-cancel" data-cy="action-cancel" onClick={() => this.cancelEditing()}>
                Cancel
              </span>
            )}
          </div>
        ) : (
          <div className={messageClass}>
            {fullName} {a.displayMessage}
            <div className="time">{timeDifference(a.date)}</div>
          </div>
        )}
      </div>
    );
  }
}
