import React, { Component, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { REALTIME_EVENTS } from '@core/models/DealRecord';
import Team from '@core/models/Team';
import Template, { TEMPLATE_TYPES } from '@core/models/Template';
import { TEMPLATE_STEPS } from '@core/models/Template';
import { FEATURES } from '@core/models/User';
import User from '@core/models/User';
import { dt, generateExportURL } from '@core/utils';

import { ButtonToolbar, DataTable, Dropdown, DropdownDots, MenuItem } from '@components/dmp';

import EmptyState from '@components/EmptyState';
import TemplateInfo, { NEW_TEMPLATE_MODES } from '@components/deal/TemplateInfo';
import DeleteTemplate from '@components/editor/DeleteTemplate';
import TeamSelector from '@components/teams/TeamSelector';
import API from '@root/ApiClient';
import Dealer from '@root/Dealer';
import Fire from '@root/Fire';

const LABEL_PUBLIC = _.find(TEMPLATE_STEPS, { public: true }).name;
const LABEL_PRIVATE = _.find(TEMPLATE_STEPS, { public: false }).name;

@autoBindMethods
export default class TemplatesList extends Component {
  static defaultProps = {
    teams: [],
    team: null,
  };

  static propTypes = {
    user: PropTypes.instanceOf(User).isRequired,
    team: PropTypes.instanceOf(Team),
    teams: PropTypes.array,
    selectTeam: PropTypes.func.isRequired,
    history: PropTypes.any,
    og: PropTypes.any,
  };

  constructor(props) {
    super(props);

    this.state = {
      teamID: props.team?.teamID || null,
      newTemplateMode: null,
      templates: [],
      loading: true,
      deleting: false,
      templateToDelete: null,
      downloadURL: null,
    };

    this.refDownload = createRef();
  }

  componentDidMount() {
    const { og } = this.props;
    const { teamID } = this.state;
    this._isMounted = true;

    if (teamID) this.loadTemplates(teamID);
    og({ title: `Outlaw - Templates` });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.team?.teamID !== this.props.team?.teamID) {
      this.loadTemplates(newProps.team?.teamID || null);
      if (this._isMounted) this.setState({ teamID: newProps.team?.teamID || null });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async loadTemplates(teamID) {
    const { user } = this.props;

    if (teamID) {
      const activeOnly = !user || !user.canEditTeam(teamID);

      const rawTemplates = await API.call('getTeamTemplates', { teamID, activeOnly });
      const templates = _.map(rawTemplates, (template) => new Template(template, template.dealID));

      if (this._isMounted) {
        this.setState({ templates, loading: false });
      }
    }
  }

  realtimeUpdate(deal, type, data) {
    const { templates } = this.state;

    switch (type) {
      case REALTIME_EVENTS.DELETE:
        const idx = _.findIndex(templates, { dealID: deal.dealID });
        if (idx > -1) {
          templates.splice(idx, 1);
          this.setState({ templates });
        }
        break;
      case REALTIME_EVENTS.UPDATE_STATUS:
        deal.public = data;
        this.setState({ templates });
        break;
      default:
        break;
    }
  }

  get columns() {
    return [
      {
        accessor: 'public',
        Header: 'Status',
        Cell: ({ value }) => {
          const statusLabel = value ? LABEL_PUBLIC : LABEL_PRIVATE;
          return (
            <span
              className={`statusLabel template-${statusLabel.toLowerCase()}`}
              data-cy={`template-${statusLabel.toLowerCase()}`}
            >
              {statusLabel}
            </span>
          );
        },
        width: 110,
        resizable: false,
      },
      {
        accessor: 'title',
        Header: 'Title',
        Cell: ({ original: template, value }) => {
          const typeTitle = (template.type && TEMPLATE_TYPES[template.type]?.name) || null;
          return (
            <div className="template-title">
              <div data-cy="template-title">
                {value}
                {typeTitle ? <em>({typeTitle})</em> : ''}
              </div>
              <small className="block">{template.description}</small>
            </div>
          );
        },
        minWidth: 200,
      },
      this.editColumn,
    ];
  }

  get editColumn() {
    const { user } = this.props;
    const { teamID } = this.state;

    const showActions = teamID && user.canEditTeam(teamID);

    return {
      accessor: 'id',
      id: 'actions',
      Cell: ({ original: template }) => {
        return (
          <div>
            {showActions && (
              <DropdownDots
                pullRight
                pushRight={false}
                horizontal={true}
                onClick={(e) => e.stopPropagation()}
                id={`dd-${template.dealID}`}
                onSelect={(action) => this.handleAction(template, action)}
                data-cy="template-actions"
              >
                <MenuItem eventKey="edit">Edit Template</MenuItem>
                {user.can(FEATURES.VINE_IO) && <MenuItem eventKey="download">Download .vine</MenuItem>}
                <MenuItem eventKey={template.public ? 'unlaunch' : 'launch'}>
                  {template.public ? 'Deactivate' : 'Activate'}
                </MenuItem>
                <MenuItem eventKey="delete">Delete</MenuItem>
              </DropdownDots>
            )}
          </div>
        );
      },
      width: 40,
      className: 'col-actions',
      fixed: 'right',
      resizable: false,
      style: { overflow: 'visible' }, // Important, otherwise the dropdown won't show
    };
  }

  async handleAction(template, action) {
    switch (action) {
      case 'edit':
        this.viewTemplate(template);
        break;
      case 'delete':
        this.confirmDelete(template);
        break;
      case 'launch':
        this.togglePublicity(template, true);
        break;
      case 'unlaunch':
        this.togglePublicity(template, false);
        break;
      case 'download':
        // Generate a fresh download URL and assign to hidden download link
        const token = await Fire.token();
        const deal = await Fire.getDeal(template.dealID);
        const downloadURL = generateExportURL({ deal, token, type: 'vine' });
        await this.setState({ downloadURL });

        // // Trigger click to auto download (as long as we're not in testing mode)
        if (typeof window.Cypress === 'undefined') {
          this.refDownload.current.click();
        }
        break;
      default:
        break;
    }
  }

  confirmDelete(template) {
    this.setState({ templateToDelete: template, deleting: true });
  }

  async togglePublicity(template, publish) {
    const { user } = this.props;
    const { teamID } = this.state;

    await Fire.togglePublicity(template.dealID, true, publish);
    this.realtimeUpdate(template, REALTIME_EVENTS.UPDATE_STATUS, publish);

    API.call('userAuditLog', {
      dealID: template.dealID,
      teamID,
      eventCategory: 'template',
      eventType: 'update',
      eventSubtype: publish ? 'activate' : 'deactivate',
      userEmail: user.email,
    });
  }

  viewTemplate(template) {
    let url = `/templates/${template.dealID}/editor`;
    this.props.history.push(url);
  }

  async selectTeam(teamID) {
    const { teams, selectTeam } = this.props;

    const newTeam = _.find(teams, { teamID: teamID || null });
    selectTeam(newTeam);
  }

  renderTemplates() {
    const { user } = this.props;
    const { templates, loading, teamID } = this.state;

    const empty = {
      title: 'Custom Templates',
      subtitle: `Create a new custom ${dt} template`,
      type: 'template',
    };

    // user viewing team with no templates
    if (templates.length == 0 && !this.state.loading) {
      if (teamID && user.canEditTeam(teamID)) {
        return <EmptyState {...empty} />;
      } else {
        return (
          <EmptyState
            title="Custom Templates"
            subtitle="There are not yet any custom templates available on your Team. Please contact your Team owner for help getting setup to use custom templates on Outlaw."
          />
        );
      }
    }

    return (
      <DataTable
        getTrProps={(state, rowInfo) => {
          const dealID = _.get(rowInfo, 'original.dealID');
          return dealID ? { 'data-dealid': dealID } : {};
        }}
        sortable={false}
        resizable={false}
        canScroll={false}
        columns={this.columns}
        data={templates}
        dropshadow
        loading={loading}
        pageSize={100}
        showPaginationTop={true}
        showPageSizeOptions={false}
      />
    );
  }

  render() {
    const { user, teams } = this.props;
    const { teamID, newTemplateMode, templateToDelete, downloadURL } = this.state;

    if (!user) return null;

    return (
      <div className="wrapper">
        <div className="title-bar">
          <h1>Templates</h1>
          {teamID && user && (
            <ButtonToolbar align="right">
              <TeamSelector
                pullRight
                clearable={false}
                disableNew
                user={user}
                teamID={teamID}
                teams={teams}
                onSelect={this.selectTeam}
              />

              {!Dealer.mobile && teamID && user.canEditTeam(teamID) && (
                <Dropdown
                  id="dd-add-template"
                  dmpStyle="primary"
                  title="New Template"
                  className="add-template"
                  onSelect={(mode) => this.setState({ newTemplateMode: mode })}
                  menuWidth={300}
                  pullRight
                  data-cy="add-template"
                >
                  {NEW_TEMPLATE_MODES.map((mode) => (
                    <MenuItem key={mode.key} eventKey={mode.key} info={mode.tip} icon={mode.icon}>
                      {mode.title}
                    </MenuItem>
                  ))}
                </Dropdown>
              )}
            </ButtonToolbar>
          )}
        </div>

        <div className="deal-listing" data-cy="template-listing">
          {this.renderTemplates()}

          <TemplateInfo
            {...this.props}
            teams={teams}
            show={!!newTemplateMode}
            mode={newTemplateMode}
            close={() => this.setState({ newTemplateMode: null })}
          />

          <DeleteTemplate
            user={user}
            template={templateToDelete}
            show={this.state.deleting}
            onDelete={async () => await this.realtimeUpdate(templateToDelete, 'delete')}
            onComplete={() => this.setState({ templateToDelete: null })}
            close={() => {
              this.props.location.pathname = '/dashboard/templates';
              this.setState({ deleting: false });
            }}
            history={this.props.history}
            location={this.props.location}
          />
        </div>
        {/* This link is invisible but needs to be present for triggering .vine file saving from the dot menu */}
        <a ref={this.refDownload} href={downloadURL} download />
      </div>
    );
  }
}
