import { filter, find, forEach, merge } from 'lodash';

import Report from './Report';

export const getReports = ({ user, teams }) => {
  const reports = user.reports;

  const groups = [
    {
      title: 'Private',
      reports,
    },
  ];

  forEach(teams, (team) => {
    if (!team.reports.length) return;

    groups.push({
      title: team.info.name,
      reports: team.reports,
    });
  });

  return groups;
};

export const findReport = ({ teams, user, reportID, teamID }) => {
  if (reportID) {
    if (teamID) {
      const team = find(teams, { teamID });
      if (team) return team.reports.get(reportID);
    } else {
      return user.reports.get(reportID);
    }
  }

  return null;
};

export default class ReportStore extends Array {
  constructor(store) {
    super();

    if (typeof store !== 'undefined') {
      forEach(store, (json, reportID) => {
        this.push(new Report(merge({}, json, { reportID })));
      });
    }
  }

  get(reportID) {
    return find(this, { reportID });
  }

  get dashboardReports() {
    return filter(this, 'showOnDashboard');
  }

  get nonDashboardReports() {
    return filter(this, (report) => !report.showOnDashboard);
  }
}
