import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';

import DealAction from '@core/enums/DealAction';
import DealFactory from '@core/models/DealFactory';
import { Dt, dt } from '@core/utils';

import { Button, Checkbox, Loader } from '@components/dmp';

import API from '@root/ApiClient';
import Dealer, { Category } from '@root/Dealer';
import Fire from '@root/Fire';

@autoBindMethods
export default class CopyMachine extends Component {
  static propTypes = {
    deal: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      title: `${props.deal.name} Copy`,
      copyVariables: true,
      copyUsers: true,
      copyVersions: false,
      copying: false,
    };
  }

  toggleOption(option) {
    this.setState({ [option]: !this.state[option] });
  }

  async copy() {
    const { user, deal } = this.props;
    const options = _.pick(this.state, ['copyVariables', 'copyUsers', 'copyVersions', 'title']);
    options.title = options.title.trim();

    await this.setState({ copying: true });
    Dealer.call({ category: Category.DEAL, action: DealAction.COPY, label: deal.info.sourceTemplateKey });
    let newDeal = await Fire.copyDeal(user, deal, options);
    // Fire.addActivity requires a typed Deal to work properly
    newDeal = DealFactory.create(newDeal);
    await Fire.addActivity(newDeal, user, DealAction.COPY, deal.dealID);
    await API.call('trigger', { dealID: newDeal.dealID, action: DealAction.COPY });

    // Navigating directly from one contract to another doesn't work properly because DealView doesn't unmount/mount
    // So we have to use good old vanilla JS to ensure a full app refresh and land on the new contract
    window.location.pathname = `/deals/${newDeal.dealID}/contract`;
  }

  onClose() {
    if (!this.state.copying) this.props.onClose();
  }

  render() {
    const { title, copyVariables, copyUsers, copyVersions, copying } = this.state;
    const { deal } = this.props;

    const hasSectionVersions = deal.hasSectionVersions;
    const hasSigs = deal.signing || deal.signed;

    return (
      <Modal dialogClassName="copy-machine" show onHide={this.onClose}>
        <Modal.Header closeButton>
          <span className="headline">Duplicate {Dt}</span>
        </Modal.Header>
        <Modal.Body>
          <div className="wrapper">
            <FormGroup>
              <ControlLabel>New {dt} title</ControlLabel>
              <div className="contents deal-title">
                <FormControl
                  type="text"
                  value={title}
                  placeholder={`e.g., Vendor ${Dt}`}
                  disabled={copying}
                  onChange={(e) => this.setState({ title: e.target.value })}
                  data-cy="input-duplicate-deal-title"
                />
              </div>
            </FormGroup>
          </div>
          <div className="wrapper">
            <FormGroup>
              <ControlLabel>Data options</ControlLabel>

              <div className="contents">
                <Checkbox
                  id="check-copy-variables"
                  checked={copyVariables}
                  disabled={copying}
                  onChange={() => this.toggleOption('copyVariables')}
                >
                  Keep variable data
                </Checkbox>
                <small>Copy all variable data into the new {dt}</small>

                <Checkbox
                  id="check-copy-users"
                  checked={copyUsers}
                  disabled={copying}
                  onChange={() => this.toggleOption('copyUsers')}
                >
                  Keep users
                </Checkbox>
                <small>Copy all users and roles into the new {dt}</small>

                <Checkbox
                  id="check-copy-versions"
                  checked={copyVersions && hasSectionVersions}
                  disabled={copying || !hasSectionVersions}
                  onChange={() => this.toggleOption('copyVersions')}
                >
                  Keep version history
                </Checkbox>
                <small>Copy all content edits and version history into the new {dt}</small>

                {hasSigs && (
                  <Checkbox id="check-clear-sigs" checked={true} disabled={true} onChange={_.noop}>
                    Clear signatures
                  </Checkbox>
                )}
                {hasSigs && <small>Signatures will always be removed from the copied {dt}</small>}
              </div>
            </FormGroup>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {copying && <Loader />}

          <Button dmpStyle="primary" disabled={copying || !title.trim()} onClick={this.copy} data-cy="btn-duplicate">
            Duplicate
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
