import { assign, filter, forEach, omit } from 'lodash';

import { DEFAULTS as WATERMARK } from '../enums/Watermark';
import { BEHAVIOR, PDF_CHAR_SETS_DEFAULT } from './Deal';
import Lens from './Lens';

// NB: this is not yet fully implemented, as template "status" is currently just binary based on the 'public' property,
// so these keys are not yet used... but Templates may have more steps soon.
// In the meantime we can lookup the public-facing names based on the value of the public property in this enum
export const TEMPLATE_STEPS = [
  { key: 'draft', public: false, name: 'Draft' },
  { key: 'active', public: true, name: 'Active' },
];

export const TEMPLATE_TYPES = {
  demand: { key: 'demand', name: 'DemandsAI' },
  'settlement-calculator': { key: 'settlement-calculator', name: 'Settlement Calculator' },
};

export default class Template {
  title = null;
  description = null;
  key = null;
  inbound = false;
  batch = false;
  inboundParty = null;
  inboundMulti = false; //whether multiple inbound deals of the same template type can be created
  autoParty = null;
  autoDealUser = null;
  guestSigning = BEHAVIOR.guestSigning.defaultValue;
  autoGuest = BEHAVIOR.autoGuest.defaultValue;
  commenting = BEHAVIOR.commenting.defaultValue;
  readonly = BEHAVIOR.readonly.defaultValue;
  showLetterhead = BEHAVIOR.showLetterhead.defaultValue;
  showTitle = BEHAVIOR.showTitle.defaultValue;
  pdfCharSet = PDF_CHAR_SETS_DEFAULT;
  theme = BEHAVIOR.theme.defaultValue;
  workflow = BEHAVIOR.workflow.defaultValue;
  public = false;
  dealID = null;
  integrations = null;
  team = null;
  excludeCL = false;
  watermark = WATERMARK;
  lenses = {};
  type = null;

  constructor(json, dealID) {
    assign(this, omit(json, 'lenses'));
    this.dealID = dealID;

    const lenses = json.lenses;
    forEach(lenses, (lensJSON, id) => {
      this.lenses[id] = new Lens(lensJSON);
    });
  }

  get sourceTemplateKey() {
    return `${this.team || 'Outlaw'}:${this.key || 'unknown'}`;
  }

  get variableLenses() {
    return filter(this.lenses, { type: 'variable' });
  }

  get clauseLenses() {
    return filter(this.lenses, { type: 'clause' });
  }

  get advancedLenses() {
    return filter(this.lenses, { type: 'advanced' });
  }

  get json() {
    return omit(this, ['sourceTemplateKey', 'json']);
  }
}
