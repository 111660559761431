import { filter, get } from 'lodash';

import SectionType from '../enums/SectionType';
import SectionStyle from './SectionStyle';
import Source from './Source';

export default class Appendix extends Source {
  // Appendices can be assigned to a partyID, just like individual variables
  // This lets that party edit the appendix content independently of their deal-level permissions
  assigned = null;

  // Appendices may have their own custom DealStyles specified
  // If so, use that; if not, the DealStyle (including numbering) for this appendix will be a copy of that of the main Deal
  // NB: for the Appendix, which is a special top-level Section type, this is an instance of DealStyle (or inherited themes)
  // Whereas the normal "style" property on Sections is an instance of SectionStyle
  style = {};

  sectionStyle = {};

  // These properties are populated for LINKED type appendices
  // At the Template level, linkedTemplate will point to a sourceTemplateKey for auto-creation
  // At the individual Deal level, linkedDealID will point to the corresponding contract that got created
  linkedTemplate = null;
  linkedDealID = null;

  constructor(json, deal) {
    super(json, deal);

    this.pageBreak = get(json, 'pageBreak', true);

    this.raw = json;

    this.assigned = json.assigned || null;

    if (json.linkedDealID) {
      this.linkedDealID = json.linkedDealID;
    }
    if (json.linkedTemplate) {
      this.linkedTemplate = json.linkedTemplate;
    }

    if (json.style) {
      this.sectionStyle = new SectionStyle(json.style);
    }
  }

  get isFirstSpecial() {
    const source = this.deal.buildSource(true);
    const specialSections = filter(source, (s) => SectionType.special(s.sectiontype));
    return specialSections.indexOf(this) === 0;
  }

  get appendixType() {
    // Legacy SCOPE and PAYMENT appendices had their subtypes implicitly specified via Section.parent
    const legacyType = get(this.parent, 'sectiontype');
    if ([SectionType.SCOPE, SectionType.PAYMENT].includes(legacyType)) {
      return legacyType;
    }
    // When we launched LINKED types, we started explicitly storing appendix types in the json data
    else if (this.raw.appendixType) {
      return this.raw.appendixType;
    }
    // Normal (inline) appendices will also just have APPENDIX as their appendixType
    else {
      return this.sectiontype;
    }
  }
}
