import { isCypressRunning, isVine } from '@core/utils';

import CONFIG from './Config';

export const ATTRIBUTES = {
  REFERRING_TEAM: 'referring_team', //if user came in as a recipient, team ID of referring team
  DEALS_TOTAL: 'deals_total', //total number of deals user has in account
  DEALS_RECIPIENT: 'deals_recipient', //number of deals user has received
  DEALS_OWNER: 'deals_owner', //number of deals user owns
  DEALS_CLOSED: 'deals_closed', //number of completed deals user is on (regardless of sender or recipient)
  SUBSCRIPTION: 'subscription', //status of user's paid subscription -- see CUSTOMER_STATUS in stripe_customer.js
  PLAN: 'plan', //user's plan if paid (not populated if unpaid)
  SEATS: 'seats', //number of paid seats for paying customers
  TEAMS: 'teams', //comma-separated list of teams user is on
  GUEST: 'guest', //set to 'true' if user signed a deal as guest but never actually logged in
};

export const HELP = {
  CONDITIONALS: 'https://filevine.zendesk.com/knowledge/articles/17343968838299/en-us?brand_id=4415356259995',
  CONNECTED_VARS: 'https://help.getoutlaw.com/hc/en-us/articles/17344637750299-Connected-Variables-Introduction',
  CONNECTION_MGMT:
    'https://help.getoutlaw.com/hc/en-us/articles/17344647644059-Connecting-an-Outlaw-Template-to-a-Filevine-Project',
};

//wrapper singleton class for CRM
class CRM {
  init(id) {
    this.id = id;

    // Disable CRM by default when runing tests
    // and when running as dotvine (inside an iframe)
    if (isCypressRunning || isVine) {
      // Set window.CRM to true
    }

    // if (this.isReady && !isVine) // Boot up CRM here
  }

  get isReady() {
    return typeof window == 'object' && typeof window.CRM == 'function';
  }

  get isDisabled() {
    return window.crmDisabled; //9/12/2023 Disable CRM (2782)
  }

  get users() {
    // Eventually will update to populate with users from CRM
    return [];
  }

  show(padding) {
    if (this.isDisabled) return;

    //for some reason calling it immediately didn't move it up
    //posibly because DOM is still changing and component hasn't rendered
    //delaying by 100ms seems to work perfectly
  }

  hide() {
    if (this.isDisabled) return;
    // Hide CRM here
  }

  contact(message) {
    if (this.isDisabled) return;
    // If message exists, show message. Otherwise create new message
  }

  login(account, user) {
    /** PREVIOUS INTERCOM CRM CODE **/
    // const { info } = user;

    // if (!this.isDisabled && account && !account.isAnonymous && info) {
    //   // Update CRM account with account info from login
    // }

    if (!account.isAnonymous) this.initializeUserEventTracking(user);
  }

  loginAnonymous(account, info = {}) {
    if (this.isDisabled || !account) return;

    this.updateUser({ ...info, guest: true }, account.uid);
  }

  updateUser(attrs = {}, uid) {
    if (this.isDisabled) return;

    const userAttributes = { ...attrs };
    if (uid) userAttributes.user_id = uid;

    // Update CRM with userAttributes
  }

  logout() {
    if (this.isDisabled) return;

    //clear and restart CRM when user logs out
  }

  initializeUserEventTracking(user) {
    if (typeof pendo === 'undefined' || window.Cypress) return;
    const disablePendoGuides = !!CONFIG.HELP_URL;

    console.log(`Initializing Pendo.${disablePendoGuides ? ' Disabled Guides' : ''}`);

    pendo.initialize({
      visitor: {
        id: user.id, // Required if user is logged in, default creates anonymous ID
        email: user.email, // Recommended if using Pendo Feedback, or NPS Email
        // full_name:    // Recommended if using Pendo Feedback
        // role:         // Optional
        organization: user.info.org,
      },
      // TODO: Implement when using Pendo Feedback
      account: {},
      guides: {
        disabled: disablePendoGuides,
      },
    });
  }
}

const crm = new CRM();

export default crm;
