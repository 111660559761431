import _ from 'lodash';
import BoundingBox from './BoundingBox';
import TextFragment from './TextFragment';

export const MARGIN_SIDES = ['top', 'right', 'bottom', 'left'];
export const RULERS = ['middleHorizontal', 'middleVertical'];

export default class TextPage {

  page;
  fragments = [];
  lines = [];
  width;
  height;

  hasColumns = false;

  constructor({page, fragments, width, height}) {
    _.assign(this, {page, width, height});

    // pdfjs' transform object is a 6 item array
    // not sure yet how to understand first 4 elements, or if they're necessary
    // last 2 elements are x,y
    // y is distance from BOTTOM of page
    _.forEach(fragments, (rawFragment, i) => {
      this.fragments.push(new TextFragment({
        x: rawFragment.transform[4],
        y: rawFragment.transform[5],
        page,
        value: rawFragment.str,
        width: rawFragment.width,
        height: rawFragment.height,
      }));
    });
  }

  getMarginBox(side) {
    switch (side) {
      case 'top':
        return new BoundingBox({
          x: 0,
          y: this.height - this.marginTop,
          width: this.width,
          height: 0,
          page: this.page,
        });
      case 'right':
        return new BoundingBox({
          x: this.width - this.marginRight,
          y: 0,
          width: 0,
          height: this.height,
          page: this.page,
        });
      case 'bottom':
        return new BoundingBox({
          x: 0,
          y: this.marginBottom,
          width: this.width,
          height: 0,
          page: this.page,
        });
      case 'left':
        return new BoundingBox({
          x: this.marginLeft,
          y: 0,
          width: 0,
          height: this.height,
          page: this.page,
        });
      case 'middleHorizontal':
        return new BoundingBox({
          x: this.width / 2,
          y: 0,
          width: 0,
          height: this.height,
          page: this.page,
        });
      case 'middleVertical':
        return new BoundingBox({
          x: 0,
          y: this.height / 2,
          width: this.width,
          height: 0,
          page: this.page,
        });
    }
  }

  // TODO: update logic to identify pattens/groupings 
  // to see if there are outliers that cross over the margins

  get bodyLines() {
    return _.filter(this.lines, {omit: null});
  }

  get marginLeft() {
    return _.min(_.map(this.bodyLines, 'x'));
  }

  get marginRight() {
    return this.width - _.max(_.map(this.bodyLines, 'endX'));
  }

  get marginBottom() {
    return _.min(_.map(this.bodyLines, 'y'));
  }

  get marginTop() {
    return this.height - _.max(_.map(this.bodyLines, 'endY'));
  }

  get bodyWidth() {
    return this.width - this.marginLeft - this.marginRight;
  }

  get bodyLineHeights() {
    const bodyLines = this.bodyLines;
    const counts = _.countBy(bodyLines, (line) => {
      const height = line.height;
      const rounded = Math.round(height * 10) / 10;
      return rounded;
    });
    return counts;
    // return _.invert(counts);
  }

  get probableBodyLineHeight() {
    const lineCount = this.bodyLines.length;
    const heights = this.bodyLineHeights;
    let key = _.findKey(heights, (numLines, height) => {
      const numHeight = parseFloat(height);
      if (!isNaN(numHeight) && numLines/lineCount >= .7) return numHeight;
    });

    if (key) {
      return parseFloat(key);
    }
    else {
      return 0;
    }
  }
  
  get json() {
    return {
        ..._.pick(this, [
          'marginTop',
          'marginRight',
          'marginBottom',
          'marginLeft',
        ]
      ),
      lines: _.map(this.lines, 'json'),
    };
  }

}