import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { classNamePrefixer } from '@core/utils';

import { Loader } from '@components/dmp';

const cl = classNamePrefixer('card');

const Card = ({ children, isLoading, title, subtitle, extra, className, condensed, noPadding, ...passingProps }) => {
  const classNames = cx(cl(), { [cl('condensed')]: condensed }, { [cl('no-padding')]: noPadding }, className);

  return (
    <div className={classNames} {...passingProps}>
      {title && (
        <div className={cl('header')}>
          {title && (
            <div className={cl('title')}>
              {title}
              {subtitle && <div className={cl('title-sub')}>{subtitle}</div>}
            </div>
          )}
          {extra && <div className={cl('extra')}>{extra}</div>}
        </div>
      )}
      {isLoading ? <Loader /> : children}
    </div>
  );
};

Card.defaultProps = {
  condensed: false,
  isLoading: false,
  noPadding: false,
};

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  condensed: PropTypes.bool,
  extra: PropTypes.node,
  isLoading: PropTypes.bool,
  noPadding: PropTypes.bool,
  subtitle: PropTypes.node,
  title: PropTypes.node,
};

export default Card;
