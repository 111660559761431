import React, { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';

import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import { ButtonGroup, FormControl } from 'react-bootstrap';

import Deal from '@core/models/Deal';
import { EVENT_TYPES, TimelineEventStatus } from '@core/models/TimelineEvent';
import { isVine } from '@core/utils';

import { Button, ButtonClose, ButtonIcon, Icon, Swatch } from '@components/dmp';

import TimelineFilter from '@components/TimelineFilter';
import Fire from '@root/Fire';
import { parseDateString } from '@root/shared/TimevineShared';

import Timevine from './Timevine';
import useKeySequence from './hooks/useKeySequence';

//-------------------------------------------------------------------------------------------------
// <TimevineOutlaw> COMPONENT
//-------------------------------------------------------------------------------------------------
const TimevineOutlaw_propTypes = {
  deal: PropTypes.instanceOf(Deal).isRequired,
};
const TimevineOutlaw = forwardRef(({ deal }, ref) => {
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const [events, setEvents] = useState(null);

  const [filteredEvents, setFilteredEvents] = useState(null);
  const [activeEventTypes, setActiveEventTypes] = useState(null);

  const [selectedEvent, setSelectedEvent] = useState(null);

  const [showDebugPanel, setShowDebugPanel] = useState(false);
  const [timescale, setTimescale] = useState(0.0);
  const [cameraScale, setCameraScale] = useState(0.0); //TODO: store full camera obj

  const containerRef = useRef();
  const timevineRef = useRef();

  useImperativeHandle(ref, () => ({
    getExportSettings: () => {
      return {
        activeEventTypes: activeEventTypes ? [...activeEventTypes] : undefined,
        timescale,
      };
    },
  }));

  useKeySequence(['`', 'd', 'd', 'd'], () => {
    setShowDebugPanel((prev) => !prev);
  });

  // Handle container resizing:
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const size = {
          width: entry.contentRect.width,
          height: entry.contentRect.height,
        };
        setContainerSize(size);
      }
    });

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        resizeObserver.unobserve(containerRef.current);
      }
    };
  }, []);

  // Get event data from timeline var (via timeline section):
  useEffect(() => {
    setEvents(deal.timeline.timelineVarData);
  }, [deal]);

  // Compute filtered events based on active event types:
  useEffect(() => {
    let filteredEvents = [];
    if (events) {
      if (activeEventTypes) {
        filteredEvents = events.filter((event) => activeEventTypes.includes(event.type));
      } else {
        filteredEvents = events;
      }
    }
    setFilteredEvents(filteredEvents);
  }, [events, activeEventTypes]);

  const handleEventDetailsPanelClose = () => {
    setSelectedEvent(null);
    timevineRef.current.clearSelectedEvent();
  };

  const itemUpdater = async ({ id, newText }) => {
    const tlVar = deal.timeline.timelineVar;
    const evts = JSON.parse(tlVar.value);
    const evt = evts.find((x) => x.id === id);
    if (!evt) {
      console.error('Failed to find editRow!');
      return;
    }
    evt.summary = newText.trim();
    evt.status = TimelineEventStatus.human;
    await Fire.saveVariable(deal, tlVar, JSON.stringify(evts));

    setSelectedEvent(evt);
  };

  const handleFilterChanged = (activeTypes) => {
    // TODO: Currently TimelineFilter when it fires its onChange event it passes along
    // its "activeTypes" which is a state object that never gets replaced (it is mutated in-place).
    // So this causes the useEffect deps to fail to detect changes when I attempt to have behavior
    // based on setting active event types here! React deps checking doesn't do a deep comparison
    // so if it's the same object/array in memory, it will compare as the same regardless of
    // internal contents changing!
    // Truthfully the internal state can be removed from TimelineFilter, we can pass in the
    // activeTypes as a prop, which we continue to store as state here, and then change them
    // the same as now, and it will all work (we have two copies of the same state for no
    // reason right now).
    setActiveEventTypes([...activeTypes]);
  };

  return (
    <div
      ref={containerRef}
      style={{
        top: isVine ? '40px' : '90px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        position: 'absolute',
        overflow: 'hidden',
      }}
    >
      <Timevine
        ref={timevineRef}
        width={containerSize.width}
        height={containerSize.height}
        events={filteredEvents}
        autoFitOnLoad={true}
        onEventSelected={(evt) => setSelectedEvent(evt)}
        onCameraChanged={(cam) => setCameraScale(cam.scale)}
        onTimescaleChanged={(val) => setTimescale(val)}
      />
      <TimelineFilter events={events ?? []} onChange={handleFilterChanged} />
      <EventDetailPanel event={selectedEvent} onClose={handleEventDetailsPanelClose} itemUpdater={itemUpdater} />
      <ViewControls timevineRef={timevineRef} />
      {showDebugPanel && <DebugPanel timevineRef={timevineRef} timescale={timescale} cameraScale={cameraScale} />}
    </div>
  );
});
TimevineOutlaw.displayName = 'TimevineOutlaw';
TimevineOutlaw.propTypes = TimevineOutlaw_propTypes;
export default TimevineOutlaw;

//-------------------------------------------------------------------------------------------------
// <EventDetailPanel> COMPONENT
//-------------------------------------------------------------------------------------------------
function EventDetailPanel({ event, onClose, itemUpdater }) {
  const tabs = [
    {
      key: 'summary',
      text: 'Summary',
    },
    // TODO: not sure we need this tab anymore?
    // {
    //   key: 'source',
    //   text: 'Prose Source',
    // },
  ];
  const [currentTab, setCurrentTab] = useState(tabs[0].key);

  return (
    <div className={`timeline-event-detail-panel ${event ? 'visible' : ''}`}>
      <div className="title-row">
        <div className="title">Event detail</div>
        <ButtonClose onClick={onClose} />
      </div>

      <div className="tabs-row">
        <ButtonGroup className="panel-tabs">
          {tabs.map((tab) => (
            <Button
              key={tab.key}
              dmpStyle="link"
              active={currentTab === tab.key}
              onClick={() => setCurrentTab(tab.key)}
            >
              {tab.text}
            </Button>
          ))}
        </ButtonGroup>
      </div>

      {currentTab === 'summary' && event ? <EventDetailPanelSummary event={event} itemUpdater={itemUpdater} /> : null}
      {/* {currentTab === 'source' && data ? <EventDetailPanelSource data={data} deal={deal} /> : null} */}
    </div>
  );
}

//-------------------------------------------------------------------------------------------------
// <EventDetailPanelSummary> COMPONENT
//-------------------------------------------------------------------------------------------------
const EventDetailPanelSummary = ({ event, itemUpdater }) => {
  const [editing, setEditing] = useState(false);
  const [editedText, setEditedText] = useState('');

  const handleEdit = () => {
    setEditing(true);
    setEditedText(event.summary);
  };
  const handleCancel = () => {
    setEditing(false);
  };
  const handleSave = async () => {
    setEditing(false);
    itemUpdater({ id: event.id, newText: editedText });
  };
  const handleChange = (e) => {
    setEditedText(e.target.value);
  };

  const formatDate = (dateStr) => {
    return parseDateString(dateStr).toLocaleString(DateTime.DATE_FULL);
  };

  const eventType = EVENT_TYPES[event.type];

  return (
    <>
      <div className="details-row">
        <div className="event-date">{formatDate(event.date)}</div>
        <div className="event-type">
          <Swatch color={eventType.color} />
          <span>{eventType.title}</span>
        </div>
      </div>

      {editing ? (
        <div className="summary-editor">
          <FormControl
            componentClass="textarea"
            value={editedText}
            placeholder="Enter the event summary text"
            onChange={(e) => handleChange(e)}
          />
        </div>
      ) : (
        <div className="summary">{event.summary}</div>
      )}

      {editing ? (
        <div className="controls-editing">
          <Button dmpStyle="link" size="small" onClick={handleCancel}>
            Cancel
          </Button>
          <Button size="small" onClick={handleSave}>
            Save
          </Button>
        </div>
      ) : (
        <div className="controls-viewing">
          <div className="status">
            <Icon name={getStatusIconName(event.status)} />
            <span>{getStatusDescription(event.status)}</span>
          </div>
          {/* TODO: not the correct "pencil" icon... see Figma */}
          <ButtonIcon icon="deal" onClick={handleEdit}></ButtonIcon>
        </div>
      )}
    </>
  );
};

//-------------------------------------------------------------------------------------------------
// <ViewControls> COMPONENT
//-------------------------------------------------------------------------------------------------
function ViewControls({ timevineRef }) {
  return (
    <div
      style={{
        position: 'absolute',
        left: 10,
        bottom: 10,
      }}
    >
      <Button
        size="small"
        onClick={() => {
          timevineRef.current.autoFit();
        }}
      >
        Reset view
      </Button>
    </div>
  );
}

//-------------------------------------------------------------------------------------------------
// <DebugPanel> COMPONENT
//-------------------------------------------------------------------------------------------------
function DebugPanel({ timevineRef, timescale, cameraScale }) {
  return (
    <div
      style={{
        position: 'absolute',
        left: 0,
        bottom: 0,
        backgroundColor: 'hsl(210, 100%, 80%)',
        padding: '5px',
      }}
    >
      <p style={{ fontWeight: 'bold' }}>Debug Panel</p>
      <p>Timescale: {timescale.toFixed(3)}</p>
      <p>Camera Scale: {cameraScale.toFixed(3)}</p>
      <div style={{ display: 'flex', gap: '5px' }}>
        <Button
          size="small"
          onClick={() => {
            timevineRef.current.resetCamera();
          }}
        >
          Reset Camera
        </Button>

        <Button
          size="small"
          onClick={() => {
            timevineRef.current.autoTimescale();
          }}
        >
          Auto Timescale
        </Button>

        <Button
          size="small"
          onClick={() => {
            timevineRef.current.zoomToFit();
          }}
        >
          Zoom to Fit
        </Button>

        <Button
          size="small"
          onClick={() => {
            timevineRef.current.autoTimescale();
            setTimeout(() => {
              timevineRef.current.zoomToFit();
            }, 1);
          }}
        >
          Auto Fit
        </Button>
      </div>
    </div>
  );
}

//-------------------------------------------------------------------------------------------------
// LOCAL HELPER FUNCTIONS
//-------------------------------------------------------------------------------------------------
function getStatusIconName(status) {
  return status === TimelineEventStatus.ai ? 'aiAuto' : 'fieldsEdit';
}
function getStatusDescription(status) {
  return status === TimelineEventStatus.ai ? 'AI-generated' : 'Human-edited';
}
