import React from 'react';

import _ from 'lodash';

import { Radio } from 'react-bootstrap';

import { Checkbox, Icon } from '@components/dmp';

import TooltipButton from '@components/editor/TooltipButton';

const getAccessorValue = (item, accessor) => item[accessor] || item._original?.[accessor];

const CheckboxWithTooltip = ({ id, checked, disabled, onChange, tooltipMsg }) =>
  !!tooltipMsg ? (
    <TooltipButton disabled={false} placement="bottom" tip={tooltipMsg}>
      <Checkbox id={id} checked={checked} disabled={disabled} onChange={onChange} />
    </TooltipButton>
  ) : (
    <Checkbox id={id} checked={checked} disabled={disabled} onChange={onChange} />
  );

/*
  Dynamic Selector column used to select items.
  It does not handle state, it's mostly a structure to avoid repetitive code
*/
const selectorColumn = ({
  accessor = 'id',
  selected,
  selectable = null,
  onToggleAll,
  onToggle,
  multiselect = true,
}) => {
  return {
    Header: ({ data }) => {
      console.log(
        'data',
        data,
        data.map((item) => getAccessorValue(item, accessor)),
        selected
      );
      const originalData = data.map((item) => item._original);
      // Single selection is rendered as Radio buttons so no need for a control in the header
      if (!multiselect) return null;

      const selectableArray = selectable || data;

      const selectedIds = selected.map((item) => getAccessorValue(item, accessor));
      console.log('selectedIds', selectedIds);
      const selectableUnSelected = selectableArray.filter((item) => {
        console.log(getAccessorValue(item, accessor), selectedIds.includes(getAccessorValue(item, accessor)));
        return !selectedIds.includes(getAccessorValue(item, accessor));
      });

      console.log('selectableUnSelected', selectableUnSelected);

      return (
        <div>
          <Checkbox
            id="chk-select-all"
            checked={selectableUnSelected.length === 0}
            onChange={() => onToggleAll({ data: originalData })}
            disabled={!selectableArray.length}
          />
        </div>
      );
    },
    headerClassName: 'th-selection',
    accessor,
    id: 'selection',
    Cell: ({ original, tdProps }) => {
      const isLocked = !!_.get(tdProps, 'rest.isLocked');
      const tooltipMsg = _.get(tdProps, 'rest.tooltip');

      const onClick = isLocked
        ? _.noop
        : (e) => {
            // If user clicks the actualy check/radio (instead of row click), prevent a duplicate event
            e.stopPropagation();
            onToggle(original);
          };

      const checked = !!_.find(selected, { [accessor]: original[accessor] });
      return multiselect ? (
        <CheckboxWithTooltip
          id={`chk-selected-${original[accessor]}`}
          checked={checked}
          disabled={isLocked}
          onChange={onClick}
          tooltipMsg={tooltipMsg}
        />
      ) : (
        <Radio checked={checked} disabled={isLocked} onChange={onClick} />
      );
    },
    Filter: () => <Icon name="filterExpand" faded style={{ marginTop: 4 }} />,
    width: 36,
    className: 'col-selection',
    fixed: 'left',
    sortable: false,
    clickable: false,
    resizable: false,
  };
};

export { selectorColumn };
