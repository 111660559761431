import { assign } from 'lodash';

import { CurrencyFormat } from './Payment';

export default class StripePlan {
  constructor(json) {
    const { id, name, amount, interval, metadata } = json;
    const { order, features } = metadata;
    assign(this, { id, name, amount, interval, order, features });
  }

  get price() {
    return (this.amount / 100).toLocaleString('en-US', CurrencyFormat);
  }

  get isFree() {
    return this.amount == 0;
  }

  get monthlyPrice() {
    if (this.interval == 'month') return this.amount / 100;
    if (this.interval == 'year') return this.amount / 1200;
    return 0;
  }

  get monthlyPriceDisplay() {
    return this.monthlyPrice.toLocaleString('en-US', CurrencyFormat);
  }

  get displayInterval() {
    switch (this.interval) {
      case 'month':
        return 'monthly';
      case 'year':
      default:
        return 'annually';
    }
  }
}
