import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import PropTypes from 'prop-types';

import { dt, dts } from '@core/utils';

import { Button } from '@components/dmp';

@autoBindMethods
export default class BatchActionRestore extends Component {
  static propTypes = {
    deals: PropTypes.array.isRequired,
    restoreDeals: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
  }

  async handleRestore() {
    const { deals, restoreDeals } = this.props;
    await restoreDeals(deals);
  }

  render() {
    const { deals } = this.props;

    return (
      <div className="results">
        <Button
          className="restore"
          dmpStyle="link-primary"
          size="xsmall"
          onClick={this.handleRestore}
          data-cy="btn-restore"
        >
          Restore selected {deals.length > 1 ? dts : dt}
        </Button>
      </div>
    );
  }
}
