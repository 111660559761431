import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import Dropzone from 'react-dropzone';

import { classNamePrefixer } from '@core/utils';

import { Icon } from '@components/dmp';

const cl = classNamePrefixer('dropper');

@autoBindMethods
export default class Dropper extends Component {
  static propTypes = {
    className: PropTypes.string,
    acceptedTypes: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    onDrop: PropTypes.func.isRequired,
    requireAim: PropTypes.bool.isRequired,
    suppress: PropTypes.bool,
    children: PropTypes.node,
    icon: PropTypes.string,
  };

  static defaultProps = {
    children: null,
    icon: 'drop',
    requireAim: false,
    suppress: false,
    className: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      dragging: false,
      valid: false,
    };
  }

  componentDidUpdate() {
    if (this.props.suppress && this.state.dragging) {
      this.setState({ dragging: false });
    }
  }

  toggleDrag(dragging) {
    if (this.state.dragging !== dragging) {
      this.setState({ dragging });
    }
  }

  toggleValid(valid) {
    if (this.state.valid !== valid) {
      this.setState({ valid });
    }
  }

  onDrop(files) {
    const { valid, dragging } = this.state;
    const { requireAim, suppress } = this.props;

    if (suppress) {
      this.setState({ dragging: false });
      return;
    }

    const file = _.get(files, '[0]', null);

    this.setState({ dragging: false, valid: false });

    if ((valid || !requireAim) && file) {
      this.props.onDrop(file);
    }
  }

  onDropRejected() {
    this.setState({ dragging: false, valid: false });
  }

  render() {
    const { children, title, subtitle, icon, className, acceptedTypes, suppress } = this.props;
    const { dragging, valid } = this.state;

    const cn = cx(cl(), { dragging: dragging && !suppress });

    return (
      <div
        className={className}
        onDragEnter={() => this.toggleDrag(true)}
        onClick={() => this.setState({ dragging: false })}
      >
        {children}

        <Dropzone
          className={cn}
          onDragLeave={() => this.toggleDrag(false)}
          accept={acceptedTypes}
          disableClick={true}
          onDropAccepted={this.onDrop}
          onDropRejected={this.onDropRejected}
        >
          <div className="overlay">
            <div
              className="valid-area"
              onDragEnter={() => this.toggleValid(true)}
              onDragLeave={() => this.toggleValid(false)}
            >
              <div className={cx('dotted', { valid })}>
                <div className="icon">{<Icon name={icon} />}</div>
                <div className="title">{title}</div>
                <div className="subtitle">{subtitle}</div>
              </div>
            </div>
          </div>
        </Dropzone>
      </div>
    );
  }
}
