import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';
import qs from 'query-string';

import { ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';

import { dt } from '@core/utils';

import { Alert, Button } from '@components/dmp';

import SingleSignOnButton from '@components/SingleSignOnButton';
import { PROVIDERS } from '@root/Auth';
import Dealer, { Category } from '@root/Dealer';

const DEALER_LABEL = 'upgradeGuestPrompt';

@autoBindMethods
export default class UpgradeGuestModal extends Component {
  static defaultProps = {
    isDealSigned: false,
  };

  static propTypes = {
    currentDealUser: PropTypes.object,
    onHide: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
  };

  componentDidMount() {
    Dealer.call({ category: Category.USER, action: 'show', label: DEALER_LABEL });
  }

  handleHide() {
    Dealer.call({ category: Category.USER, action: 'hide', label: DEALER_LABEL });
    this.props.onHide();
  }

  handleSubmit(e) {
    const { currentDealUser, history } = this.props;
    const signupSearchParams = qs.stringify({
      email: currentDealUser.email,
      fullName: currentDealUser.fullName,
      loginTypes: 'Password',
    });

    e.preventDefault();

    Dealer.call({ category: Category.USER, action: 'signup', label: DEALER_LABEL });

    history.push({
      pathname: '/signup',
      search: `?${signupSearchParams}`,
    });
  }

  handleLogin() {
    Dealer.call({ category: Category.USER, action: 'login', label: DEALER_LABEL });
    this.props.history.push('/login');
  }

  renderSSO() {
    return (
      <div className="sso">
        {_.map(PROVIDERS, (provider, key) => (
          <SingleSignOnButton
            block
            dealerLabel={DEALER_LABEL}
            domain={provider.id}
            key={key}
            providerName={provider.name}
            textTemplate="Continue with [PROVIDER]"
          />
        ))}
      </div>
    );
  }

  renderForm() {
    const { currentDealUser } = this.props;

    return (
      <div className="form-fields">
        <div className="or">
          <div>Or</div>
        </div>

        <Form className="form-fields" onSubmit={this.handleSubmit}>
          <FormGroup>
            <div className="contents">
              <ControlLabel>Email address</ControlLabel>
              <FormControl
                disabled
                onChange={(e) => this.setState({ email: e.target.value })}
                placeholder="you@company.com"
                type="text"
                value={currentDealUser.email}
              />
            </div>
          </FormGroup>

          <Button dmpStyle="primary" block type="submit">
            Create account
          </Button>
        </Form>
      </div>
    );
  }

  render() {
    return (
      <Modal show className="modal-guest-upgrade" dialogClassName="dialog-centered">
        <Modal.Header closeButton onHide={this.handleHide} />

        <Modal.Body>
          <div className="wrapper login-wrapper">
            <div className="intro text-center">
              <h3>
                Don't lose access to this {dt}
                <br />
                Create your FREE Outlaw account to enjoy...
              </h3>

              <div className="d-flex justify-content-between align-content-stretch">
                <Alert dmpStyle="success" centered>
                  <b>Lifetime access</b>
                  <br />
                  Your {dt} is stored
                  <br />
                  securely in the cloud
                </Alert>
                <Alert dmpStyle="success" centered>
                  <b>Audit log</b>
                  <br />
                  Version history showing
                  <br />
                  all changes
                </Alert>
              </div>
            </div>

            {this.renderSSO()}
            {this.renderForm()}

            <div className="footer-login">
              Have an Outlaw account? <a onClick={this.handleLogin}>Log in</a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
