import React from 'react';

import { dt } from '@core/utils';

const ProductMessage = (inDraft) => {
  if (inDraft)
    return (
      <span>
        You are currently editing in Draft, Outlaw's professional legal authoring environment. When ready, move this{' '}
        {dt} into Flow for workflow collaboration tools such as redlining, checkpoints, user sharing and real-time
        eSigning.
      </span>
    );
  else
    return (
      <span>
        This {dt} is currently in Flow, Outlaw's streamlined workflow environment. You can take this {dt} back to Draft
        for more advanced {dt} setup and authoring tools.
      </span>
    );
};

export default ProductMessage;
