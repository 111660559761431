import { cloneDeep, find, get, isNil, map } from 'lodash';

import DealRole from '../enums/DealRole';
import AIPrompt from './AIPrompt';
import { rxRepeaterField } from './Content';
import DataSource from './DataSource';
import { BASE_NUMBERING } from './DealStyle';
import Source from './Source';
import { EXTERNAL_TYPES, ValueType } from './Variable';

export const LIST_TYPES = {
  BLOCK: 'BLOCK',
  LIST: 'LIST',
  REPEATER: 'REPEATER',
  AI: 'AI',
  TIMELINE: 'TIMELINE',
};

// LISTs have 2 potential subTypes, BLOCK and LIST (public facing feature-names still TBD)
// These are really just different permutations of the same settings, both using LIST SectionTypes
// But prepackaged settings will simplify things for users and keep things on rails
export const FEATURE_LABEL = {
  BLOCK: 'Block',
  LIST: 'List',
  REPEATER: 'Repeater',
  AI: 'Vine AI',
  TIMELINE: 'AI Timeline',
};

// BLOCKs are useful as a sort of long-form variable on steroids.
// It's basically an assignable region of a doc that can be versioned, redlined, required, etc
// But is really meant to be one paragraph of text,
// eg, a description that a counter-party can be responsible for filling in
// Section titles are disabled so that if a user does add multiple sections,
// They just manifest as inline paragraphs
export const CONFIG_BLOCK = {
  subType: 'BLOCK',
  indent: false,
  titles: false,
  nesting: false,
};

// LISTs are an entire sub-system of BLOCKs, basically a whole region of a contract
// That a counter-party can be responsible for
// eg, an itemized scope of services with nested lists
// They default to titles and numbering (independent from Deal-level numbering but based on same formats)
export const CONFIG_LIST = {
  subType: 'LIST',
  indent: true,
  titles: true,
  nesting: true,
  style: {
    numbering: cloneDeep(BASE_NUMBERING),
  },
};

export const CONFIG_AI = {
  subType: 'AI',
  indent: false,
  titles: false,
  nesting: false,
  redlining: true,
  required: false,
  continuous: false,
};

export const CONFIG_TIMELINE = {
  subType: 'TIMELINE',
  indent: false,
  titles: false,
  nesting: false,
  redlining: false,
  required: false,
  continuous: false,
};

export const CONFIG_REPEATER = {
  subType: 'REPEATER',
  indent: false,
  titles: false,
  nesting: false,
  redlining: false,
  required: false,
  continuous: true,
};

export default class List extends Source {
  subType;
  name;
  assigned;
  required;
  titles;
  clearing;
  redlining;
  indent;
  nesting;
  continuous;

  // for REPEATER types
  dataSource = null;

  // for AI type
  aiPrompt = null;

  constructor(json, deal) {
    super(json, deal);

    this.subType = get(json, 'subType', 'LIST');
    this.name = get(json, 'name', '');
    this.assigned = get(json, 'assigned', null);
    this.required = get(json, 'required', true);
    this.clearing = get(json, 'clearing', true);
    this.titles = get(json, 'titles', true);
    this.redlining = get(json, 'redlining', true);
    this.indent = get(json, 'indent', true);
    this.nesting = get(json, 'nesting', true);
    this.continuous = get(json, 'continuous', false);

    if (this.isAI) {
      this.prompt = get(json, 'prompt', '');
      this.actionName = get(json, 'actionName', 'Generate');
      this.engine = get(json, 'engine', 'openAI');
      if (json.aiPrompt) this.aiPrompt = new AIPrompt(json.aiPrompt, deal);
    }

    if (json.dataSource) {
      this.dataSource = new DataSource(json.dataSource, this);
    }
  }

  // Just like Deal.buildSource -- flatten list of child items for rendering/output
  get items() {
    const arr = [];
    const recurser = (parents) => {
      parents.map((p) => {
        arr.push(p);
        recurser(p.children);
      });
    };
    recurser(this.children);
    return arr;
  }

  get isEmpty() {
    return this.children.length === 0;
  }

  // This is only used as a helper to identify when content is changing in ContentSection.shouldComponentUpdate
  // Once we refactor to remove that, this can go away too
  get flatItemText() {
    return map(this.items, 'flatText').join('|');
  }

  get displayReference() {
    return this.name || super.displayReference;
  }

  // For Section.numberedSibs, which is used as the basis for generating section numbering,
  // We need a special case for empty lists with continuous numbering to hold a spot
  // As soon as the list has 1 child (ITEM), that section will replace this
  get isNumberPlaceholder() {
    return this.continuous && this.isEmpty;
  }

  get isRepeater() {
    return this.subType === LIST_TYPES.REPEATER;
  }

  /*
  TODO: I think this is dead code, we are instead calling the dataSourceAI() method in AIPrompt.
  Should probably remove, but need to make sure we don't need anyhthing in here:
  get dataSourceAI() {
    let table = null,
      text = null,
      source = [];
    if (!this.isAI) return null;

    find(this.children, (child) => {
      table = find(child.variables, (v) => {
        return v.valueType === ValueType.TABLE || v.externalType === EXTERNAL_TYPES.COLLECTION;
      });

      if (table) return true;
      return false;
    });

    if (table) {
      return table.val;
    } else {
      source = this.children;

      // If we're pointing at a LIST, use that list's children as the source content
      if (source.length > 0 && source[0].isList && !source[0].isAI) {
        source = source[0].items;
      }

      text = map(source, (sec) => sec.currentVersion.getText('body', true, this.deal.variables)).join('\n');
      return text.trim() || null;
    }
  }
  */

  // This is used by Repeater sections, which store their item templates in their own bodies (Section.content)
  // We're using regex to replace {field} values with type-specific rendered values
  // It's similar to TableView.renderRow, and the rows have the same data structure (ie defined in TableColumns),
  // but here, each "row" will be saved as its own ITEM Section so we need to generate the text in advance
  generateItem(row) {
    let template = this.content;
    let match;
    let html = template;

    while ((match = rxRepeaterField.exec(template)) !== null) {
      const fieldName = match[1];
      const tableColumn = find(get(this, 'dataSource.columns', []), {
        id: fieldName,
      });
      // Render null/undefined values as empty strings (instead of writing the word "null")
      let value = isNil(row[fieldName]) ? '' : row[fieldName];

      if (tableColumn && value) {
        value = tableColumn.formatValue(value, row);
      }
      html = html.replace(match[0], value);
    }
    // Return simple json content structure expected by Fire.addItemBatch,
    // which is what the Repeater uses to generate child ITEM Sections
    return { content: html };
  }

  can(action) {
    const du = this.deal.currentDealUser;
    if (!du) return false;

    switch (action) {
      case 'add':
      case 'remove':
      case 'edit':
      case 'clear':
        // Nothing is possible if Deal is locked
        if (this.deal.locked) return false;
        // If we're looking at clear action and clearing is not enabled on the list, disallow
        if (action === 'clear' && !this.clearing) return false;
        // For BLOCKs, only 1 item can be added
        if (action === 'add' && this.subType === 'BLOCK' && this.children.length > 0) return false;
        // Otherwise permissions for all these actions are the same; editors/owners can do everything,
        // And also allow current user if they are in an assigned party
        if ([DealRole.EDITOR, DealRole.OWNER].includes(du.role)) return true;
        if (this.assigned && du.partyID === this.assigned) return true;
        return false;
      case 'generate':
        // For REPEATERs -- ensure user permissions, active connection and configured dataSource
        if (this.deal.locked) return false;
        if (![DealRole.EDITOR, DealRole.OWNER].includes(du.role)) return false;
        if (this.subType !== LIST_TYPES.REPEATER) return false;
        if (!this.dataSource || !this.deal.isConnected) return false;
        return true;
      case 'ai':
        // For AI -- ensure user permissions, active connection and configured dataSource
        if (this.deal.locked) return false;
        if (![DealRole.EDITOR, DealRole.OWNER].includes(du.role)) return false;
        if (!this.isAI) return false;
        // TBD, still show link to generate if there's no source content? (for now, yes)
        // if (!this.dataSourceAI) return false;
        // TBD, can we keep generating if there's already content there? (for now, yes)
        // but still disable if user has made manual edits (>1 versions)
        if (this.versions.length > 1) return false;
        return true;
      case 'reviseAI':
        return this.isAI && !!this.content && this.can('edit');
      default:
        return false;
    }
  }
}
