import _ from 'lodash';

export const DEFINED_TERM_TYPES = {
  // Named parties usually laid out in preamble
  // e.g., "Company", "Vendor", etc
  PARTY: 'party',
  // Terms that can have values defined in contract and attempt to parse/map to Outlaw variable values
  // e.g., "Effective Date", "Total Price"
  VARIABLE: 'variable',
  // Prose-based terms that have special definitions which are defined in this doc
  // and referenced by this name subsequent to initial use
  // e.g., "Change Order" or the "Services" 
  DEFINITION: 'definition',
  // References to concepts defined external to this doc (usually public laws, acts and orgs)
  // e.g., "CCPA", "HIPAA"
  EXTERNAL: 'external',
}
export default class DefinedTerm {
  
  value;
  index;

  section;
  type;
  suggestedValue;
  
  constructor({
    value, 
    index,
  }, section) {
    _.assign(this, {value, index, section});
  }

  // TODO: handle multiple boxes when the term is split across multiple lines
  get boxes() {
    return this.section.getBoundingBox(this.index, this.index + this.value.length);
  }

  // Get the surrounding prose that is part of this sentence
  get sentence() {
    const text = _.get(this.section, 'flatValue', '');
    let start = -1;
    let end;
    
    const rxPhraseBreak = /[.:;]/g;
    let match = null;
    while ((match = rxPhraseBreak.exec(text)) !== null) {
      
      if (match.index < this.index) {
        start = match.index;
      }

      if (!end && (this.index + this.value.length) < match.index) {
        end = match.index;
      }
    }
    if (!end) end = text.length;
    
    let sentence = text.slice(start + 1, end + 1);

    if (start === -1 && this.section.externalNumber) {
      sentence = sentence.slice(this.section.externalNumber.length);
    }

    return sentence;
  }
}



