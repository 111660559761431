import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';

import { Form } from '@components/dmp';

import DealPanel, { DealPanelPropTypes } from '@components/deal/DealHeader/DealPanel';
import DealPanelItem from '@components/deal/DealHeader/DealPanelItem';
import Fire from '@root/Fire';

export default class DealIntegrations extends Component {
  static defaultProps = {
    show: false,
  };

  static propTypes = {
    deal: PropTypes.object.isRequired,
    ...DealPanelPropTypes,
  };

  constructor(props) {
    super(props);

    this.state = {
      externalId: props.deal.externalId || '',
      isSaving: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.populate(this.props);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this._isMounted) this.populate(props);
  }

  populate(props) {
    const { deal } = props;

    if (!deal) return;

    this.setState({
      externalId: deal.externalId,
    });
  }

  async save() {
    const { externalId } = this.state;
    const { deal, onSave } = this.props;

    this.setState({ isSaving: true });

    // Must happen first
    if (externalId && externalId !== deal.externalId) {
      await Fire.updateDealIntegration(deal.dealID, externalId);
    }

    this.setState({ isSaving: false });
    onSave({ externalId });
  }

  handleChange(e, prop) {
    const newState = { saved: false };
    newState[prop] = e.target.value;
    this.setState(newState);
  }

  render() {
    const { id, container, show, onHide, target, title } = this.props;
    const { externalId, isSaving } = this.state;

    return (
      <DealPanel id={id} onHide={onHide} show={show} target={target} title={title} container={container}>
        <DealPanelItem borderBottom>
          <Form>
            <FormGroup>
              <ControlLabel>External ID</ControlLabel>
              <FormControl
                disabled={isSaving}
                value={externalId}
                onChange={(e) => this.handleChange(e, 'externalId')}
              />
            </FormGroup>
          </Form>
        </DealPanelItem>
      </DealPanel>
    );
  }
}
