import React, { Component } from 'react';

import autobindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import qs from 'query-string';

import { Link } from 'react-router-dom';

import { findFilter } from '@core/models/FilterStore';
import Report, { REPORT_TYPES } from '@core/models/Report';
import User from '@core/models/User';
import { Dt } from '@core/utils';

import { Button, Card, DragHandle, DropdownDots, Ellipsis, Loader, MenuItem, Icon } from '@components/dmp';

import ConfigureReport from '@components/reports/ConfigureReport';
import API from '@root/ApiClient';
import Fire from '@root/Fire';

import ReportItemDefault from './ReportItemDefault';
import ReportItemPipeline from './ReportItemPipeline';

const REPORT_TYPE_CONFIG = {
  [REPORT_TYPES.DEFAULT]: {
    item: ReportItemDefault,
    subtitle: false,
  },
  [REPORT_TYPES.PIPELINE]: {
    item: ReportItemPipeline,
    subtitle: <span>{Dt}s by deal status</span>,
  },
};

@autobindMethods
class ReportItem extends Component {
  static defaultProps = {
    availableReports: [],
    canEdit: false,
    report: null,
  };

  static propTypes = {
    availableReports: PropTypes.arrayOf(PropTypes.instanceOf(Report)),
    canEdit: PropTypes.bool,
    allowTeamReports: PropTypes.bool,
    report: PropTypes.instanceOf(Report),
    user: PropTypes.instanceOf(User).isRequired,
    teams: PropTypes.array.isRequired,
    getTeams: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      report: props.report,
      filter: null,
      dealTemplate: null,
      isConfiguring: false,
      isLoading: false,
      isLoadingRelations: !!props.report,
      dynamicSubtitle: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;

    this.loadRelations();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /*
    When the app first load, the teams are not set yet so we need to re-load for this specific case.
    There might be a better way, but we're not set up for that at this moment.
  */
  async componentDidUpdate(prevProps) {
    const { report, teams } = this.props;
    if (prevProps.teams.length !== teams.length && _.get(report, 'teamID')) {
      await this.loadRelations();
    }
  }

  get reportLink() {
    const { filter } = this.state;

    if (this.error || !filter) return null;

    let { searchParams } = filter;

    if (filter.isTeamFilter) {
      searchParams = {
        ...searchParams,
        teamID: _.get(filter, 'teamID', null),
      };
    }

    return `/dashboard/contracts?${qs.stringify(searchParams)}`;
  }

  get error() {
    const { report, filter, isLoadingRelations, isLoading } = this.state;

    if (!report) return 'An unknown error occurred on this report. If it persists, delete it and create a new one.';

    if (isLoadingRelations || isLoading) return null;

    if ((REPORT_TYPES.DEFAULT && !report.filterID) || (report.filterID && !filter)) {
      return 'This report is trying to load a filter that no longer exists';
    }

    return null;
  }

  /*
    loadRelations() Will load any "core" related dependencies for the Report
    to allow it to fully load and be customized.
      1- Team Report: Load it if one is linked to the User Report.
      2- Filter: If it's a Team Report, load the Team Filter, if not, load the User Filter.
      3- Deal Template: If the loaded Filter has a sourceTemplate in the searchParam, load it.
  */
  async loadRelations() {
    const { teams, user } = this.props;
    const { report } = this.state;
    const relations = { teamReport: null, filter: null };

    if (!report) return;

    this.setState({ isLoadingRelations: true });

    relations.filter = findFilter({
      teams,
      user,
      filterID: report.filterID,
      teamID: report.teamID,
    });

    if (_.get(relations.filter, 'searchParams.sourceTemplateKey')) {
      const dealTemplate = await this.loadTemplate(relations.filter);
      relations.dealTemplate = dealTemplate;
    }

    if (this._isMounted) this.setState({ isLoadingRelations: false, ...relations });
  }

  /*
    This piece of code exist because compared to the /user/ data, the /teams/ don't auto-reload on changes.
    There might be a better way to handle this, but it's out of scope for this PR (and feature).
    https://trello.com/c/CCaItq5q/218-teams-refreshing-behaviors
  */
  async reloadTeamReports() {
    await this.props.getTeams(this.props.user);
  }

  async handleRemove() {
    const { report } = this.state;

    await this.setState({ isLoadingRelations: true });
    await Fire.deleteReport(report);

    if (report.isTeamReport) {
      await this.reloadTeamReports();
    }
  }

  async handleConfigure() {
    this.setState({ isConfiguring: true });
  }

  loadTemplate(filter) {
    return new Promise(async (resolve) => {
      const { searchParams } = filter;

      // This is somewhat annoying, but since we only have the sourceTemplateKey (via URL),
      // we first need another (very fast) API call to look up the dealID
      const [teamID, key] = searchParams.sourceTemplateKey.split(':');

      const dealRecord = await API.call('getTeamTemplates', { teamID, key });
      const dealID = _.get(dealRecord, '[0].dealID');
      if (!dealID) {
        resolve(null);
        return;
      }

      // Finally, fetch the full Deal object and store in state to pass to children
      const filteredDealTemplate = await Fire.getDeal(dealID);
      resolve(filteredDealTemplate);
    });
  }

  setSubtitle(subtitle) {
    this.setState({ dynamicSubtitle: subtitle });
  }

  async handleSave(newReport) {
    if (!newReport) return;
    const existing = !!newReport.reportID;

    this.setState({ isLoading: true });

    await Fire.saveReport(newReport);
    const newState = {};
    if (existing) newState.report = newReport;

    if (newReport.isTeamReport) {
      await this.reloadTeamReports();
    }

    if (this._isMounted) this.setState({ ...newState, isLoading: false });
  }

  renderEditDropdown() {
    const { canEdit } = this.props;
    const { isLoading, isLoadingRelations, report } = this.state;

    if (!report || this.error || isLoading || isLoadingRelations) return null;

    // Once the state/loading checks are validated above, make sure it can be configured (mainly for team reports)
    if (!canEdit) return null;

    return (
      <DropdownDots id={`dd-edit-report-${report.reportID}`} pullRight>
        <MenuItem eventKey="configure" onSelect={this.handleConfigure}>
          Configure
        </MenuItem>
        <MenuItem eventKey="remove" onSelect={this.handleRemove}>
          Remove from Dashboard
        </MenuItem>
      </DropdownDots>
    );
  }

  renderTitle() {
    const { canEdit } = this.props;
    const { report } = this.state;

    if (!report || this.error) return;

    const TitleNameComponent = <Ellipsis>{_.get(report, 'title')}</Ellipsis>;

    return (
      <>
        {canEdit && <DragHandle />}
        {this.reportLink ? <Link to={this.reportLink}>{TitleNameComponent}</Link> : TitleNameComponent}
      </>
    );
  }

  render() {
    const { availableReports, canEdit, teams, user } = this.props;
    const { dealTemplate, filter, isConfiguring, isLoading, isLoadingRelations, dynamicSubtitle, report } = this.state;
    const configSubtitle = report ? _.get(REPORT_TYPE_CONFIG[report.type], 'subtitle', null) : null;
    const subtitle = configSubtitle || dynamicSubtitle;

    return (
      <>
        <Card
          className={cx('report-item', { loading: isLoading }, { 'report-item-draggable': canEdit })}
          title={this.renderTitle()}
          subtitle={subtitle}
          extra={this.renderEditDropdown()}
          data-cy="report-item"
        >
          {isLoadingRelations ? <Loader centered /> : this.renderReport()}
        </Card>

        <ConfigureReport
          availableReports={availableReports}
          dealTemplate={dealTemplate}
          filter={filter}
          onClose={() => this.setState({ isConfiguring: false })}
          onSave={this.handleSave}
          report={report}
          show={isConfiguring}
          teams={teams}
          user={user}
        />
      </>
    );
  }

  renderReport() {
    const { dealTemplate, report, teams, filter } = this.state;

    if (this.error) {
      return (
        <div className="state error">
          <div className="icon">
            <Icon name="exclamation"/>
          </div>
          <h4>Error loading report</h4>
          <p>{this.error}</p>
          <Button onClick={this.handleRemove}>Remove</Button>
        </div>
      );
    }

    const ReportItemComponent = _.get(REPORT_TYPE_CONFIG[report.type], 'item', ReportItemDefault);
    const reportTeam = report.teamID ? _.find(teams, { teamID: report.teamID }) : null;

    return (
      <ReportItemComponent
        {...this.props}
        dealTemplate={dealTemplate}
        setSubtitle={this.setSubtitle}
        report={report}
        reportLink={this.reportLink}
        reportTeam={reportTeam}
        filter={filter}
      />
    );
  }
}

export default ReportItem;
