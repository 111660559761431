import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';

import { EVENT_TYPES } from '@core/models/TimelineEvent';
import { getSafeKey } from '@core/utils';

import { Button, Icon, Swatch, Switch } from '@components/dmp';

import TooltipButton from '@components/editor/TooltipButton';

@autoBindMethods
export default class TimelineFilter extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    events: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      activeTypes: _.map(EVENT_TYPES, 'key'),
    };
  }

  toggleExpanded() {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  }

  async toggleType(key) {
    const { onChange } = this.props;
    const { activeTypes } = this.state;

    const idx = activeTypes.indexOf(key);
    if (idx > -1) activeTypes.splice(idx, 1);
    else activeTypes.push(key);

    await this.setState({ activeTypes });

    // Communicate the latest/updated list of active types to parent (TimelineView) to re-render timeline
    onChange(activeTypes);
  }

  async reset(e) {
    e.stopPropagation();

    const { onChange } = this.props;
    const activeTypes = _.map(EVENT_TYPES, 'key');
    await this.setState({ activeTypes });
    onChange(activeTypes);
  }

  renderTypeSwitch(type) {
    const { key } = type;
    const { activeTypes } = this.state;
    const { events } = this.props;

    const isActive = activeTypes.includes(key);
    const eventsOfType = _.filter(events, { type: type.key });
    const disabled = !eventsOfType.length;

    return (
      <div
        key={key}
        className={cx('event-type', `type-${type.key}`, { empty: disabled })}
        onClick={() => (!disabled ? this.toggleType(key) : null)}
      >
        <Swatch color={type.color} />
        <div className="type-title" style={{ color: type.color }}>
          {type.title} ({eventsOfType.length})
        </div>
        <div className="spacer" />
        <Switch checked={isActive} size="small" disabled={disabled} />
      </div>
    );
  }

  render() {
    const { expanded, activeTypes } = this.state;
    return (
      <div className="timeline-filter">
        <div className={cx('timeline-toggle', { expanded })} onClick={this.toggleExpanded}>
          <Icon name="chevronSmall" />
          <span>Event Types</span>
          <div className="spacer" />
          {/* Show a quick Reset link of some types are hidden */}
          {expanded && activeTypes.length < _.keys(EVENT_TYPES).length && (
            <Button className="lnk-reset" dmpStyle="link" size="small" onClick={this.reset}>
              Reset
            </Button>
          )}
        </div>
        {expanded && <div className="event-types">{_.map(EVENT_TYPES, this.renderTypeSwitch)}</div>}
      </div>
    );
  }
}
