import React, { Component } from 'react';

import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';
import qs from 'query-string';

import AutoSuggest from 'react-autosuggest';
import { Modal } from 'react-bootstrap';

import { DEAL_TYPE } from '@core/models/Deal';
import User from '@core/models/User';
import { dt } from '@core/utils';

import { Icon } from '@components/dmp';

import API from '@root/ApiClient';

export const SEARCH_PROMPT = 'Search by keywords, parties, etc.';

@autoBindMethods
class SearchBox extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    history: PropTypes.shape({ push: PropTypes.func }).isRequired,
    location: PropTypes.shape({ pathname: PropTypes.string, search: PropTypes.string }).isRequired,
    user: PropTypes.instanceOf(User).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: '', // aka 'search query'
      suggestions: [], // array of suggestions that will be rendered
      totalHits: 0, //total number of available hits reported by search
      loading: false, // toggle to wait for asynchronous behaviours to complete
      isVariableSearch: false,
      instruction: '',
    };

    /** This function returns the suggestion from the user's query. It also returns the totalHits of suggestionSearch results.
     * It also uses _.debounce( fn(), 150 )*/
    this.getSuggestions = _.debounce(({ value }) => {
      const { isVariableSearch } = this.state;
      if (!this._isMounted) return;

      //special cases for variable search
      if (value?.length === 0 || !value) {
        this.setState({ suggestions: [{ type: 'variables', name: '' }] });
        return;
      } else if (isVariableSearch) {
        this.setState({ suggestions: [{ type: 'variables', name: value }] });
        return;
      } else if ((0 < value?.length < 3 && !isVariableSearch) || !value) {
        this.setState({ suggestions: [] });
      }

      //only run search if there are at least 3 characters,
      //OR if there are existing results (i.e., user is hitting backspace to broaden search)
      if (value?.length < 3 && this.state.suggestions.length == 0) return;

      this.setState({ loading: true });
      const teams = _.keys(props.user.teams);
      API.call('searchSuggestions', { query: value, teams }, (results) => {
        const { hits, sections, filters, totalHits } = results;
        let suggestions = filters.concat(hits, sections);

        // Similar feature, but for the title
        suggestions.unshift({ type: 'title', name: this.state.value });

        // This adds both the query and name text search in the front of suggestion array.
        // The reason for this is to make sure 'Press enter to search {query} || {name}' appears
        // as a Suggestion type on the list and users can interact with it.
        suggestions.unshift({ type: 'default', name: this.state.value });

        if (this._isMounted) {
          this.setState({
            loading: false,
            suggestions,
            totalHits,
          });
        }
      });
    }, 150);
  }

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  // Render the suggestion based on its type
  renderSuggestion(suggestion, { query }) {
    switch (suggestion.type) {
      case 'default':
        return this.renderDefault(suggestion);
      case 'title':
        return this.renderTitle(suggestion);
      case 'facet':
        return this.renderFacet(suggestion);
      case 'variables':
        return this.renderVariable();
      case 'meta':
      case 'section':
        return this.renderMeta(suggestion, query, this.props.history);
      default:
        break;
    }
  }

  // Customise the renderSugggestion container.
  renderSuggestionsContainer({ containerProps, children, query }) {
    return <div {...containerProps}>{children}</div>;
  }

  // When user type in input box, the searchBox's states and dealList's states are being updated
  onChange = (event, { newValue }) => {
    if (this._isMounted) {
      const isVariableSearch = newValue?.charAt(0) === '#';
      newValue = isVariableSearch ? this.constructVariableSearch(newValue) : newValue;
      this.setState({ value: newValue, isVariableSearch });
    }
  };

  //helpers to allow the user to easily construct their variable search.
  constructVariableSearch(newValue) {
    let variables = newValue.split('+');
    const index = variables.length - 1;

    if (!!variables[index]?.match(/#(.*?)\s/) && !variables[index]?.match(/#(.*?):/)) {
      variables[index] = variables[index].replace(' ', ':');
      newValue = variables.join('+');
      return newValue;
    } else {
      return newValue;
    }
  }

  /** This will be called when the suggestion on the list is being clicked
   *  If suggestion.type equals 'meta' or 'section', it will redirect the browser to the selected contract page
   *  If the suggestion equals 'facet', it will trigger a facet search */
  onSuggestionSelected(event, { suggestion, method }) {
    const { isVariableSearch, value } = this.state;
    const { history, close } = this.props;
    const { type, facet, dealID } = suggestion;

    // We'll most likely land on a deep-link search on Contracts (DealsPage)
    let path = `/dashboard/contracts?`;

    //Check if we have the correct formatting for the variable search.
    //we must do this here because adding a event for onKeyDown we lose
    //autoSuggests built in flow/functionality when input changes occure.
    if (isVariableSearch) {
      const variables = value.split('+');
      let error = false;
      _.forEach(variables, (variable) => {
        const variableDef = variable.split(':');
        if (
          !variable?.match(/#(.*?):(.*?)/) ||
          variableDef[0].trim().length === 0 ||
          variableDef[1].trim().length === 0
        ) {
          error = true;
        }
      });

      if (error) {
        this.onChange(event, { newValue: value });
        if (this._isMounted) {
          this.setState({ instruction: 'Fix format to search variables' });
          setTimeout(() => {
            this.setState({ instruction: '' });
          }, 2000);
        }
        return;
      }
    }

    // Reset text input for subsequent searches and close modal prior to navigationte
    if (this._isMounted) {
      this.setState({ value: '' });
      close();
    }

    switch (type) {
      case 'default':
        path += qs.stringify({ query: suggestion.name });
        history.push(path);
        break;
      case 'title':
        path += qs.stringify({ name: suggestion.name });
        history.push(path);
        break;

      case 'facet':
        //when a facet is selected, we just merge it into the querystring and navigate to there
        //the actual search will be automatically run outside of this component (in DealsList)
        path += qs.stringify({ [facet]: suggestion.value });
        history.push(path);
        break;
      case 'variables':
        if (value.length > 0) {
          path += qs.stringify({ variableQuery: value });
          history.push(path);
        }
        break;
      case 'meta':
      case 'section':
        // Here, an individual contract has been selected so jump directly to it
        history.push(`/deals/${dealID}/contract`);
        break;
      default:
        break;
    }
  }

  /** This function is used to sync the suggestion value selected with the value on the search input
   * It is only applicable when user does a general search (i.e. presses 'Enter' without selecting any suggestions) */
  getSuggestionValue(suggestion) {
    switch (suggestion.type) {
      case 'default':
      case 'title':
      case 'meta':
      case 'section':
        return suggestion.name;
      case 'facet':
        return suggestion.value;
    }
  }

  /** This is being called by AutoSuggest each time we need to clear suggestions.
   * It will reset the current state and prepare it ready for the next query */
  onSuggestionsClearRequested() {
    if (this._isMounted) {
      this.setState({ suggestions: [], filters: [], totalHits: 0, value: '', isVariableSearch: false });
      this.props.close();
    }
  }

  renderInputComponent(inputProps) {
    const { isVariableSearch } = this.state;

    if (isVariableSearch) {
      inputProps.className += ' variableSearch';
    }

    return (
      <div>
        <input {...inputProps} />
      </div>
    );
  }

  render() {
    const { show } = this.props;
    const { value, suggestions } = this.state;

    // Required by AutoSuggest to pass props into the input
    const inputProps = {
      placeholder: SEARCH_PROMPT,
      value,
      onChange: this.onChange,
      autoFocus: true,
    };

    return (
      <Modal
        dialogClassName="search-modal"
        show={show}
        onHide={this.onSuggestionsClearRequested}
        data-cy="search-modal"
      >
        <Modal.Header closeButton>
          <AutoSuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.getSuggestions}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={this.getSuggestionValue}
            renderSuggestion={this.renderSuggestion}
            renderInputComponent={this.renderInputComponent}
            inputProps={inputProps}
            renderSuggestionsContainer={this.renderSuggestionsContainer}
            onSuggestionSelected={this.onSuggestionSelected}
            highlightFirstSuggestion={true}
            alwaysRenderSuggestions={true}
          />
        </Modal.Header>

        <Modal.Body></Modal.Body>
      </Modal>
    );
  }

  // This function will render the suggestions where type = 'meta' or 'section'
  renderMeta(suggestion, query) {
    const hit = suggestion;

    // Variables required for Autosuggest-Highlight for 'Meta' Searching
    const textHit = hit.name;
    const partiesHit = hit.parties || [];
    const textMatch = match(textHit, query);
    const textParts = parse(textHit, textMatch);
    const dealType = hit.dealType;

    let titleHTML;
    let bodyHTML;
    let titleHigh = hit.content.title;
    let bodyHigh = hit.content.body;
    if (hit.content) {
      const high = hit.content.highlight;
      titleHigh = high.title && high.title.length ? high.title[0] : hit.content.title;
      bodyHigh = high.body && high.body.length ? high.body[0] : hit.content.body;

      titleHTML = { __html: titleHigh };
      bodyHTML = { __html: bodyHigh };
    }

    return (
      <div className="suggestion-wrapper">
        <Icon name="contracts" className="icon" />
        <div className="text-content match-type">
          <div className="title">
            {
              /* Matching and highlighting individual characters between query and suggestions*/
              textParts.map((part, index) => {
                const highlightClassName = part.highlight ? 'highlight' : '';
                return (
                  <span key={index} className={highlightClassName}>
                    {part.text}
                  </span>
                );
              })
            }
            {!!hit.status && <span className={`statusLabel ${hit.status.toLowerCase()}`}>{hit.status}</span>}
            {
              /* Conditional to visually differentiate ingested contracts*/
              dealType === DEAL_TYPE.INGESTED ? <Icon name="docx" className="ingest-docx" /> : ''
            }
          </div>
          <div className="subtitle">
            {/* For each suggestion, show the parties involved */}
            <span className="react-autosuggest-searchbar-suggestionsubtitles">
              {partiesHit.length > 0 ? 'Parties: ' : '(No Parties)'}
            </span>
            {partiesHit.map((party, index) => {
              return (
                <span key={index} className="react-autosuggest-searchbar-suggestionsubtitles">
                  {party.legalName}
                  {index + 1 < partiesHit.length ? ', ' : ''}
                </span>
              );
            })}
          </div>

          {hit.content && (
            <div className="full-text-hit" data-cy="suggestion-full-text-hit">
              {titleHTML && <div className="text-content-highlight" dangerouslySetInnerHTML={titleHTML} />}
              {bodyHTML && (
                <div
                  className="text-content-highlight"
                  dangerouslySetInnerHTML={bodyHTML}
                  data-cy="suggestion-full-text-body"
                />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  // This function will render the suggestions where type = 'facet'
  renderFacet(suggestion) {
    const { value, facet } = suggestion;

    switch (facet) {
      case 'parties':
        return (
          <div className="suggestion-wrapper">
            <Icon name="users" className="icon" />
            <div className="text-content party-type" data-cy="suggestion-party-type">
              <div className="title">
                <span className="highlight" data-cy="suggestion-highlight">
                  {value}
                </span>{' '}
                <span className="suggest-type">Organization</span>
              </div>
            </div>
          </div>
        );
      case 'tags':
        return (
          <div className="suggestion-wrapper">
            <Icon name="tag" className="icon" />
            <div className="text-content tags-type">
              <div className="title">
                <span className={`statusLabel inline contract-tag ${value.toLowerCase()}`}>{value}</span>{' '}
                <span className="suggest-type">Tag</span>
              </div>
            </div>
          </div>
        );
      case 'sourceTemplateKey':
        return (
          <div className="suggestion-wrapper">
            <Icon name="template" className="icon" />
            <div className="text-content template-type">
              <div className="title">
                <span className="highlight">
                  {suggestion.title} ({suggestion.team})
                </span>{' '}
                <span className="suggest-type">Template</span>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  renderDefault(suggestion) {
    return (
      <div className="react-autosuggest-searchbar-default" data-cy="react-autosuggest-searchbar-default">
        <Icon name="search" className="icon" />
        <div className="text-content keyword-type">
          Search {dt}s for <b>&lsquo;{suggestion.name}&rsquo;</b> <span className="suggest-type">Full text</span>
        </div>
      </div>
    );
  }

  renderTitle(suggestion) {
    return (
      <div className="react-autosuggest-searchbar-title" data-cy="react-autosuggest-searchbar-title">
        <Icon name="search" className="icon" />
        <div className="text-content title-type" data-cy="search-suggestion-title-type">
          Search titles for <b>&lsquo;{suggestion.name}&rsquo;</b>
        </div>
      </div>
    );
  }

  renderVariable() {
    const { value } = this.state;
    let prompt = '';
    let instruction = this.state.instruction;

    let variables = value.split('+');

    _.forEach(variables, (variable) => {
      const variableDef = variable.split(':');

      if (variable.length === 0) {
        instruction = this.state.instruction;
        prompt =
          'Tips: Type # for variable search. Place words between quotes to find contracts matching all words (e.g., "my contract").';
      }
      //if we have started typing the variable name but not yet enclosed it
      if (!!variable?.match(/#(.*?)/) && !variable?.match(/#(.*?):/)) {
        instruction = this.state.instruction;
        prompt = (
          <>
            <b className="variable-query-title">#VariableName</b>: Value
          </>
        );
      }
      //if we are at the point of entering a value change prompt
      else if (!!variable?.match(/#(.*?):/)) {
        if (variableDef[1].trim().length > 0) {
          instruction = '[Return] to variable search';
          prompt = '#VariableName: Value';
        } else {
          instruction = this.state.instruction;
          prompt = (
            <>
              #VariableName: <b className="variable-query-title">Value</b>
            </>
          );
        }
      }
    });

    return (
      <div className="react-autosuggest-searchbar-variable" data-cy="react-autosuggest-searchbar-variable">
        <span className="prompt">{prompt}</span>
        <span className="instruction">{instruction}</span>
      </div>
    );
  }
}

export default SearchBox;
