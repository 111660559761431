import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { classNamePrefixer } from '@core/utils';
import { Icon } from '@components/dmp'

const cl = classNamePrefixer('tag');

/*
  <Tag />
*/
const Tag = (props) => {
  const { block, children, className, color, onClick, onRemove, removable, size, ...passingProps } = props;

  const isRemovable = onRemove || removable;

  const classNames = cx(
    cl(),
    cl(size),
    cl(`color-${color}`),
    { [cl('block')]: block },
    { [cl('removable')]: isRemovable },
    className
  );

  return (
    <div className={classNames} onClick={onClick} {...passingProps} data-cy="tag">
      {children}
      {isRemovable && (
        <a className={cl('btn-remove')} onClick={onRemove}>
          <Icon name="close" />
        </a>
      )}
    </div>
  );
};

Tag.defaultProps = {
  block: false,
  color: 'white',
  onClick: null,
  onRemove: null,
  removable: false,
  size: 'default',
};

Tag.propTypes = {
  block: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.any,
  color: PropTypes.oneOf(['gray', 'white', 'error']),
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
  removable: PropTypes.bool, // Only for displaying the X (if onRemove is not set)
  size: PropTypes.oneOf(['xsmall', 'small', 'default', 'large']),
};

/*
  <Tag.Label text="my-label" />
*/
const Label = ({ className, text, ...passingProps }) => (
  <span className={cx(cl('label'), className)} {...passingProps}>
    {text}:
  </span>
);

Label.displayName = 'Tag.Label';
Label.propTypes = {
  className: PropTypes.any,
  text: PropTypes.string.isRequired,
};

Tag.Label = Label;

export default Tag;
