import React, { Component, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import Deal from '@core/models/Deal';
import { ReadyCheckResult, ReadyLabels } from '@core/models/ReadyCheck';
import { FEATURES } from '@core/models/Team';
import { ValueType, VariableType } from '@core/models/Variable';
import { Dt, dt } from '@core/utils';

import { Alert, Button, Icon } from '@components/dmp';

import VariableView from '@components/VariableView';
import DealPanel, { DealPanelPropTypes } from '@components/deal/DealHeader/DealPanel';
import DealPanelItem from '@components/deal/DealHeader/DealPanelItem';
import TooltipButton from '@components/editor/TooltipButton';
import TemplateSelector from '@components/teams/TemplateSelector';
import API from '@root/ApiClient';
import Fire from '@root/Fire';

@autoBindMethods
export default class ElementsView extends Component {
  static propTypes = {
    deal: PropTypes.instanceOf(Deal).isRequired,
    user: PropTypes.object.isRequired,
    ...DealPanelPropTypes,
  };

  constructor(props) {
    super(props);

    this.state = {
      vars: [],
      sourceTemplateKey: null,
      changingTemplate: false,
      assertedTemplate: null,
      sourceTemplateKey: props.deal.info?.sourceTemplateKey || null,
    };
  }

  componentDidMount() {
    const { deal } = this.props;
    this.loadVars(deal);
  }

  // Rerender variables any time they change
  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.deal.variables, this.props.deal.variables)) {
      this.loadVars(this.props.deal);
    }
  }

  loadVars(deal) {
    const vars = [];
    const varViews = {};

    // Go through all the Deal's variables and render the ones we can render inline
    // (exclude parties, derived vars and tables)
    _.forEach(deal.variables, (variable) => {
      const v = deal.variables[variable.name.split('.')[0]];
      if (!!v && vars.indexOf(v) === -1 && v.type === VariableType.SIMPLE && v.valueType !== ValueType.TABLE) {
        // Collect vars for state, and pre-create refs for autoAdvancing
        vars.push(v);
        varViews[v.name] = createRef();
      }
    });
    vars.sort((a, b) => {
      const nameA = (a.displayName || a.name).toLowerCase();
      const nameB = (b.displayName || b.name).toLowerCase();

      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    this.varViews = varViews;
    this.setState({ vars });
  }

  autoAdvance(idx) {
    const { vars } = this.state;
    if (idx + 1 < vars.length) {
      const nextVar = vars[idx + 1];
      const nextRef = this.varViews[nextVar.name];
      if (!!_.get(nextRef, 'current')) {
        nextRef.current.focus();
      }
    }
  }

  async clearSignatures() {
    const { deal, user } = this.props;
    await API.call('clearSignatures', { dealID: deal.dealID, userOrigin: user.userOrigin });
  }

  async assertTemplateChange(template, force) {
    const { deal, user } = this.props;
    const sourceTemplateKey = _.get(template, 'sourceTemplateKey', null);

    await this.setState({
      sourceTemplateKey,
      assertedTemplate: template,
    });

    // If we're trying to apply the same template, don't do it, just return.
    // Otherwise it will overwrite all the variables with hte templates default.
    if (deal.info.sourceTemplateKey === sourceTemplateKey) {
      return;
    }

    if (force || this.canUpdateTemplate) {
      if (template) {
        await Fire.applyTemplate(deal, _.get(template, 'dealID', null));
      } else {
        // Defaulting to "Outlaw" team sucks, but it's consistent with how we're doing it elsewhere
        // so fallback to that for now if user is (somehow) not on a team for sake of consistency
        const teamID = _.get(user, 'team', 'Outlaw');
        await Fire.clearTemplate(deal.dealID, teamID);
      }

      await this.setState({ changingTemplate: false, assertedTemplate: null });
    }
  }

  cancelTemplateChange() {
    const { deal } = this.props;

    this.setState({
      sourceTemplateKey: deal.info.sourceTemplateKey,
      changingTemplate: false,
      assertedTemplate: null,
    });
  }

  get canUpdateTemplate() {
    const { deal } = this.props;
    const { vars, sourceTemplateKey } = this.state;
    return deal.info.sourceTemplateKey === sourceTemplateKey || vars.length === 0;
  }

  // Probably temporary for initial eSigning launch:
  // PDFElements are internally mapped to variables and they auto-create new variables when added
  // Initially there's no way to explicitly choose an existing variable and map it to an element,
  // so showing these variables in the panel would be confusing to users
  // The result is that initially it will look as if templates/variables vs PDFElements are completely unrelated
  get canShowVars() {
    const { deal } = this.props;
    return deal.isExternal ? deal.hasTemplate : true;
  }

  get blocker() {
    const { deal, signed } = this.props;

    const isCheckpoint = deal.currentWorkflowStep?.checkpoint;

    if (deal.isExternal) return null;

    if (signed) {
      return (
        <Alert dmpStyle="dark" size="small" icon="nope" inline title={`${Dt} fully signed`}>
          Elements can not be edited
        </Alert>
      );
    } else if (deal.readyCheck) {
      return (
        <Alert
          dmpStyle="dark"
          size="small"
          icon="nope"
          inline
          title={`${ReadyLabels.EVENT} is currently ${ReadyCheckResult.INCOMPLETE.label.toLowerCase()}`}
        >
          Elements can not be edited
        </Alert>
      );
    } else if (isCheckpoint) {
      return (
        <Alert dmpStyle="dark" size="small" icon="nope" inline title={`${ReadyLabels.EVENT} status`}>
          Elements can not be edited
        </Alert>
      );
    } else if (deal.locked) {
      return (
        <Alert dmpStyle="dark" title={`${Dt} is in signing`} icon="nope" size="small" inline>
          Clear signatures to re-enable variable editing
          <br />
          <a onClick={this.clearSignatures} data-cy="clear-signature">
            Clear signatures
          </a>
        </Alert>
      );
    } else {
      return null;
    }
  }

  renderVariable(v, idx) {
    const { user, deal } = this.props;
    const { extracted } = deal;

    const needsReview =
      extracted?.variables && extracted.variables[v.name] ? !!extracted.variables[v.name].needsReview : false;

    const active = v.val == null || needsReview;
    const type = needsReview ? 'danger' : active ? 'primary' : '';

    return (
      <DealPanelItem active={active} className="element" key={v.name} type={type}>
        <div className="item-label">{v.displayName || v.name}</div>
        <VariableView
          variable={v}
          ref={this.varViews[v.name]}
          section={v.deal.root}
          text={`[#${v.name}]`}
          inline={needsReview ? false : true}
          markReview={needsReview}
          user={user}
          onSave={(variable) => {
            needsReview ? Fire.markVariableReviewed(variable) : this.autoAdvance(idx);
          }}
          deal={deal}
        />
      </DealPanelItem>
    );
  }

  renderTemplateSelector() {
    const { dealTeam, deal, teams } = this.props;
    const { sourceTemplateKey, changingTemplate, assertedTemplate } = this.state;

    // We do not allow selecting a template if a Deal does not belong to a team.
    if (!dealTeam) return;

    const selectedTemplateKey = sourceTemplateKey ? sourceTemplateKey.split(':')[1] : null;

    const teamHasAILens = _.find(teams, (team) => {
      return team.isFeatureActive(FEATURES.PDF_EXTRACTION) || team.isFeatureActive(FEATURES.LENS);
    });

    const coreTip = `The template controls which metadata properties can be associated with this ${dt}.`;
    const disabledExplanation =
      ' Templates with document AI or Lenses enabled can not be selected. Re-upload document and assign template.';
    const tip = teamHasAILens ? coreTip + disabledExplanation : coreTip;

    return (
      <div className="apply-template">
        <div className="info">
          <span className="related">Related Template</span>
          {deal.hasTemplate && (
            <TooltipButton
              tipID="tip-apply-template"
              tip={`The template controls which metadata properties can be associated with this ${dt}`}
            >
              <Icon name="explainer" />
            </TooltipButton>
          )}
          <div className="spacer" />

          {deal.hasTemplate && !deal.documentAI && (
            <Button
              dmpStyle="link"
              size="small"
              onClick={() =>
                changingTemplate ? this.cancelTemplateChange() : this.setState({ changingTemplate: true })
              }
              data-cy="btn-change-template"
            >
              {changingTemplate ? 'Cancel' : 'Change'}
            </Button>
          )}
        </div>
        <TemplateSelector
          id="pdf-template-selector"
          className="margin-top-small"
          team={dealTeam}
          onSelect={this.assertTemplateChange}
          onClear={this.assertTemplateChange}
          selectedTemplateKey={selectedTemplateKey}
          activeOnly={true}
          templateSwap
          disabled={!changingTemplate && deal.hasTemplate}
          size="small"
        />
        {!deal.hasTemplate && (
          <div className="what-it-does">
            Selecting a related template will associate this PDF with that template's corresponding metadata fields and
            team member access list.
          </div>
        )}
        {!this.canUpdateTemplate && (
          <div className="confirm">
            <div className="warning">
              Selecting another template will erase variable data and replace template in this list. This action is
              permanent; are you sure you want to continue?
            </div>
            <div className="actions">
              <Button size="small" onClick={this.cancelTemplateChange}>
                Cancel
              </Button>
              <Button size="small" dmpStyle="danger" onClick={() => this.assertTemplateChange(assertedTemplate, true)}>
                Confirm
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }

  render() {
    const { deal, id, container, show, onHide, target, title } = this.props;
    const { vars } = this.state;

    const blocker = this.blocker;

    return (
      <DealPanel
        id={id}
        onHide={onHide}
        show={show}
        target={target}
        title={title}
        container={container}
        data-cy="elements-view"
      >
        <div className="panel-scroll">
          {blocker}
          {!blocker && deal.isExternal && this.renderTemplateSelector()}
          {this.canShowVars && vars.map(this.renderVariable)}
        </div>
      </DealPanel>
    );
  }
}
