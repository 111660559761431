import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import qs from 'query-string';

import { ButtonGroup } from 'react-bootstrap';

import { STEPS } from '@core/enums/DealStatus';
import Deal from '@core/models/Deal';
import Filter from '@core/models/Filter';
import { getFilters } from '@core/models/FilterStore';
import Team from '@core/models/Team';
import { Dt } from '@core/utils';

import { Button, Checkbox, DropdownDots, MenuItem, Sidebar, Switch } from '@components/dmp';

import ConnectionFilter from '@components/ConnectionFilter';
import DateFilterView from '@components/DateFilterView';
import FacetSearch from '@components/FacetSearch';
import FilterManager from '@components/FilterManager';
import GradeFilterView from '@components/GradeFilterView';
import KeywordFilter from '@components/KeywordFilter';
import LensFilterView from '@components/LensFilterView';
import SaveFilter from '@components/SaveFilter';
import SharingStatusFilter from '@components/SharingStatusFilter';
import VariableFilterView from '@components/VariableFilterView';
import TooltipButton from '@components/editor/TooltipButton';
import FilterList from '@components/reports/FilterList';
import TeamSelector from '@components/teams/TeamSelector';
import TemplateSelector from '@components/teams/TemplateSelector';
import Fire from '@root/Fire';

export const DATE_FILTERS = [
  {
    name: 'updated',
    title: 'Last updated',
    show: () => true,
  },
  {
    name: 'created',
    title: 'Created on',
    show: () => true,
  },
  {
    name: 'signed',
    title: 'Signed on',
    show: (statuses) => !!_.find(statuses, (status) => status === 'Done'),
  },
];

const TABS = [
  {
    key: 'contracts',
    title: `${Dt}s`,
    show: () => true,
  },
  {
    key: 'trash',
    title: 'Trash',
    show: () => true,
  },
];

@autoBindMethods
export default class DealFilters extends Component {
  static defaultProps = {
    selectedFilter: null,
    team: null,
    teams: [],
  };

  static propTypes = {
    dealTemplate: PropTypes.instanceOf(Deal),
    getTeams: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    searchParams: PropTypes.object.isRequired,
    selectedFilter: PropTypes.instanceOf(Filter),
    selectTeam: PropTypes.func.isRequired,
    team: PropTypes.instanceOf(Team),
    teams: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      savingFilter: false,
      managingFilters: false,
    };
  }

  get steps() {
    const { teams, searchParams } = this.props;
    const selectedTeam = _.find(teams, { teamID: searchParams.teamID });

    // If the team has no custom workflows, just use the standard Outlaw steps
    // (at launch this will be 100% of customers, so no visible change)
    let steps = _.cloneDeep(STEPS);

    // If there ARE custom workflows on the selected team,
    // compile all unique steps cross workflow (including standard ones) and sort alphabetically
    // (uniqueness by name because that's what's indexed/searched)
    if (_.get(selectedTeam, 'hasWorkflows')) {
      _.forEach(selectedTeam.workflows, (wf) => {
        _.forEach(wf.steps, ({ key, name }) => {
          if (!_.find(steps, { name })) steps.push({ key, name });
        });
      });
      steps = _.sortBy(steps, 'name');
    }

    return steps;
  }

  get selectedTab() {
    const { searchParams } = this.props;
    if (searchParams.deleted) {
      return TABS[1];
    } else {
      return TABS[0];
    }
  }

  // Here we can't use SearchParams methods because the SearchParams may have changed :-)
  // We need to manually rebuild the url based on stored filter
  // This is used both for initial load (from dropdown) and for "Revert" action
  loadFilter(filter) {
    const { location, history } = this.props;
    const params = _.clone(filter.searchParams);

    params.filterID = filter.filterID;
    if (filter.teamID) params.teamID = filter.teamID;

    const path = `${location.pathname}?${qs.stringify(params)}`;
    history.push(path);
  }

  async handleSaveFilter(filter) {
    const { getTeams, user } = this.props;

    if (filter.isTeamFilter) {
      await getTeams(user);
    }

    this.loadFilter(filter);
  }

  // Update an existing filter with new SearchParams from changes in the UI
  // Note this UI only allows for updates to the filters, not other properties (e.g., title)
  async updateFilter(filter) {
    const { searchParams } = this.props;
    filter.searchParams = searchParams.json;
    await Fire.saveFilter(filter);
    this.loadFilter(filter);
  }

  handleFilterAction() {
    // Right now there's only 1 possible action in the dots menu but there may be more later...
    this.setState({ managingFilters: true });
  }

  hasFilterChanged(filter) {
    const { searchParams } = this.props;
    return (
      !!filter &&
      !_.isEqual(
        _.omit(filter.searchParams, ['filterID', 'teamID']),
        _.omit(searchParams.params, ['filterID', 'teamID'])
      )
    );
  }

  async handleDeleteFilter(filterID, teamID) {
    const { getTeams, user } = this.props;

    if (teamID) {
      await getTeams(user);
    }
  }

  get showNeedsReview() {
    const { teams } = this.props;
    return !!_.find(teams, ({ features }) => {
      return features?.pdfExtraction === true;
    });
  }

  // Only team owners/editors can modify saved filters
  // If a viewer modifies a filter, prompt is to save that filter as a new (personal)
  canEditTeamFilter(filter) {
    const { user } = this.props;
    return user.canEditTeam(filter.teamID);
  }

  handleClear() {
    const { searchParams } = this.props;
    searchParams.clearSearch();
  }

  selectTeam(teamID) {
    const { searchParams, teams } = this.props;
    const team = _.find(teams, { teamID });
    searchParams.selectTeam(team);
  }

  selectTemplate(template) {
    const { searchParams } = this.props;
    searchParams.selectTemplate(template);
  }

  get grades() {
    const { dealTemplate, teams } = this.props;
    const team = _.find(teams, { teamID: dealTemplate?.template.team });
    const matrix = _.get(team, `scoringMatrices[${dealTemplate?.template.scoring}].matrix`, []);
    return _.map(matrix, 'grade');
  }

  get lenses() {
    const { dealTemplate, team } = this.props;
    const lenses = dealTemplate?.template.lenses;
    return lenses || [];
  }

  applyLensFilters(lens, selectedFilter, failedCheck, remove) {
    const { searchParams } = this.props;
    const { id } = lens;

    let newLensFilters = searchParams.lenses;
    const queryLens = _.find(searchParams.lenses, { id: id });
    const queryFilter = { id: selectedFilter.id, failedCheck };

    if (!queryLens && !remove) {
      newLensFilters.push({ id, filters: [queryFilter] });
    }

    if (queryLens) {
      const queryLensFilter = _.find(queryLens.filters, { id: selectedFilter.id });
      const lensIndex = _.findIndex(newLensFilters, { id: id });

      if (!remove) {
        //1.) We are adding a new lens filter
        //2.) We are changing a active lens filter
        if (!queryLensFilter) {
          newLensFilters[lensIndex].filters.push(queryFilter);
        } else {
          const filterIndex = _.findIndex(newLensFilters[lensIndex].filters, { id: selectedFilter.id });
          newLensFilters[lensIndex].filters[filterIndex] = queryFilter;
        }
      } else {
        //1.) We are removing a single filter from a group of defined lens filters
        //2.) If after removal there are no more filters remove the lens entirely

        newLensFilters[lensIndex].filters = _.remove(newLensFilters[lensIndex].filters, ({ id }) => {
          return id !== selectedFilter.id;
        });

        if (newLensFilters[lensIndex].filters.length === 0) {
          newLensFilters = _.remove(newLensFilters, (lens) => {
            return lens.id !== id;
          });
        }
      }
    }

    searchParams.setLenses(newLensFilters, id, selectedFilter.id, remove);
  }

  renderTabs() {
    const { searchParams, history, location } = this.props;

    return (
      <ButtonGroup className="panel-tabs" data-cy="filter-panel-tabs">
        {TABS.map((tab) => (
          <Button
            key={tab.key}
            size="large"
            dmpStyle="link"
            active={this.selectedTab === tab}
            onClick={() => {
              if (tab.key === 'trash') {
                searchParams.searchDeletedDeals();
              } else {
                history.push(location.pathname);
              }
            }}
            data-cy={tab.key}
          >
            {tab.title}
          </Button>
        ))}
      </ButtonGroup>
    );
  }

  renderFilters() {
    const { history, user, teams, searchParams, selectedFilter, dealTemplate } = this.props;
    const { statuses, tags, parties, users, connections } = searchParams;
    const dateFilters = searchParams.dateFilters;
    const { filterID } = searchParams.params;

    const filterableVariables = _.get(dealTemplate, 'filterableVariables', []);
    const variables = _.sortBy(filterableVariables, (variable) =>
      variable.displayName ? variable.displayName.toLowerCase() : variable.name.toLowerCase()
    );
    const selectedTeam = _.find(teams, { teamID: searchParams.teamID });

    const selectedTemplateKey = searchParams.sourceTemplateKey ? searchParams.sourceTemplateKey.split(':')[1] : null;

    const filterGroups = getFilters({ user, teams });
    const hasFilters = filterGroups.length > 1 || !!filterGroups[0].filters.length;
    const customWF = _.get(selectedTeam, 'hasWorkflows', false);

    const tabFitlerGroups = filterGroups.filter((filterGroup) => {
      return filterGroup.filters.some((filter) => {
        return filter.searchParams && searchParams.deleted ? filter.searchParams.deleted : !filter.searchParams.deleted;
      });
    });

    return (
      <div className="filters" data-cy="filters">
        <div className="filter-group team">
          <div className="filter-group saved-filters" data-cy="filter-group-saved-filters">
            <div className="title">
              <div>Saved Filters</div>

              {hasFilters && (
                <DropdownDots
                  id="dd-manage-filters"
                  onSelect={(action) => this.handleFilterAction(action)}
                  menuWidth={100}
                  data-cy="dd-manage-filters"
                >
                  <MenuItem eventKey="manage" data-cy="manage-filters">
                    Manage
                  </MenuItem>
                </DropdownDots>
              )}
            </div>
            <div className="options">
              <FilterList
                title={_.get(selectedFilter, 'title')}
                id="filter-list"
                onSelect={this.loadFilter}
                filterGroups={tabFitlerGroups}
                filterID={filterID}
                trash={searchParams.deleted || false}
              />
            </div>
          </div>
          <div className="title">Team</div>
          <TeamSelector
            user={user}
            teamID={selectedTeam?.teamID || null}
            teams={teams}
            onSelect={this.selectTeam}
            disableNew
            enableEmpty
            placeholder="Select..."
            size="small"
            className="filter-team"
            onClear={() => {
              searchParams.selectTeam(null);
            }}
          />
        </div>

        <div className={cx('filter-group', 'status', { 'custom-wf': customWF })} data-cy="custom-wf">
          <div className="title">Status</div>
          <div className="options">
            {_.map(this.steps, (step, idx) => (
              <Checkbox
                key={idx}
                id={`filter-${step.key}`}
                checked={statuses.indexOf(step.name) > -1}
                onChange={() => searchParams.toggleParam('status', step.name)}
              >
                {step.name}
              </Checkbox>
            ))}
          </div>
        </div>

        {this.showNeedsReview && (
          <div className={cx('filter-group', 'review')} data-cy="custom-wf">
            <div className="options review-container">
              <div className="filter-name">Needs Review</div>
              <Switch
                id="seperator-enabled"
                checked={searchParams.needsReview}
                onChange={() => (searchParams.needsReview = !searchParams.params.needsReview)}
                size="small"
                data-cy="needs-review"
              />
            </div>
          </div>
        )}

        {!searchParams.deleted && (
          <div className="filter-group keyword" data-cy="filter-group-keyword">
            <div className="filter-group">
              <div className="options">
                <KeywordFilter onChange={(query) => (searchParams.query = query)} query={searchParams.query} />
              </div>
            </div>
          </div>
        )}

        <div className="filter-group dates" data-cy="filter-group-dates">
          <div className="title">Date</div>
          {DATE_FILTERS.map((filter, idx) => {
            const filterType = !_.isEmpty(dateFilters) ? _.find(dateFilters, { n: filter.name }) : null;
            const show = filter.show(statuses);

            if (show) {
              return (
                <div className={`filter-group ${filter.title}`} key={filter.name} data-cy={filter.title}>
                  <div className="options">
                    <DateFilterView
                      dateFilterName={filter.name}
                      title={filter.title}
                      onChange={searchParams.toggleDateFilter}
                      date={filterType ? filterType.date : null}
                      dateTo={filterType ? filterType.dateTo : null}
                      filter={filterType}
                    />
                  </div>
                </div>
              );
            }
          })}
        </div>

        <div
          className={cx('filter-group', {
            'scrollbar-tags': user.tags.length > 5,
            tags: user.tags.length <= 5,
          })}
          data-cy="filter-group-tags"
        >
          <div className="title">Tags</div>
          <div className="options">
            {user.tags.map((tag) => (
              <Checkbox
                key={tag.tagID}
                id={`tag-${tag.tagID}`}
                checked={tags.indexOf(tag.tagID) > -1}
                onChange={() => searchParams.toggleParam('tags', tag.tagID)}
                dataCy="tag-filter-checkbox"
              >
                {tag.tag}
              </Checkbox>
            ))}
          </div>
        </div>

        <div className="filter-group parties" data-cy="filter-group-parties">
          <div className="title">Parties</div>
          <FacetSearch
            facet="parties"
            placeholder="Find party"
            empty="No parties found"
            selected={parties}
            toggleSelection={(party) => searchParams.toggleParam('parties', party)}
          />
        </div>

        <div className="filter-group parties" data-cy="filter-group-users">
          <div className="title">Users</div>
          <FacetSearch
            facet="users"
            placeholder="Find user"
            empty="No user found"
            selected={users}
            toggleSelection={(user) => searchParams.toggleParam('users', user)}
          />
        </div>

        <div className="filter-group connections">
          <div className="filter-group" data-cy="filter-group-connections">
            <div className="options">
              <ConnectionFilter
                onChange={searchParams.toggleConnection}
                id={connections[0]?.id}
                type={connections[0]?.type}
                filter={connections[0]}
              />
            </div>
          </div>
        </div>

        <div className="filter-group sharing-status" data-cy="filter-group-sharing-status">
          <div className="filter-group">
            <div className="options">
              <SharingStatusFilter
                type={searchParams.sharingStatus}
                onChange={(status) => (searchParams.sharingStatus = status)}
                filter={searchParams.sharingStatus}
              />
            </div>
          </div>
        </div>

        <div className="filter-group template">
          <div className="title">Template</div>
          <TemplateSelector
            id="template-filter"
            team={selectedTeam}
            onSelect={this.selectTemplate}
            onClear={() => {
              searchParams.selectTemplate(null);
            }}
            selectedTemplateKey={selectedTemplateKey}
            //selectedTemplate={searchParams.sourceTemplateKey}
            activeOnly={false}
            size="small"
            placeholder="Select..."
            disabled={!selectedTeam}
          />
        </div>

        {variables.length > 0 && (
          <div className="filter-group variables" ref="container">
            <div className="title">Variables</div>
            {variables.map((variable, idx) => (
              <VariableFilterView
                key={idx}
                history={history}
                variable={variable}
                onChange={searchParams.toggleVarFilter}
                filter={_.find(searchParams.variables, { variable: variable.name })}
                container={this.refs.container}
                template={dealTemplate}
              />
            ))}
          </div>
        )}

        {this.grades.length > 0 && (
          <div className="filter-group variables" ref="grades-container">
            <div className="title">Scoring Grades</div>
            <GradeFilterView
              grades={searchParams.grades}
              gradeOptions={this.grades}
              onChange={(grades) => searchParams.setGrades(grades)}
              searchParams={searchParams}
            />
          </div>
        )}

        {_.size(this.lenses) > 0 && (
          <div className="filter-group variables" ref="lens-containers">
            <div className="title">Lenses</div>
            {_.map(this.lenses, (lens, idx) => (
              <LensFilterView lens={lens} onChange={this.applyLensFilters} activeLensFilters={searchParams.lenses} />
            ))}
          </div>
        )}

        <hr />

        {this.renderFilterActions(selectedFilter)}
      </div>
    );
  }

  renderFilterActions(selectedFilter) {
    const { searchParams } = this.props;

    let isSaveDisabled = true;
    let saveOnClick = _.noop;
    let showTooltip = true;

    if (selectedFilter) {
      if (this.hasFilterChanged(selectedFilter)) {
        isSaveDisabled = selectedFilter.isTeamFilter ? !this.canEditTeamFilter(selectedFilter) : false;
        showTooltip = !isSaveDisabled;
      }
      saveOnClick = () => this.updateFilter(selectedFilter);
    } else {
      isSaveDisabled = searchParams.isEmpty;
      saveOnClick = () => this.setState({ savingFilter: true });
    }

    return (
      <div className="filters-actions">
        <TooltipButton
          placement="bottom"
          tip="You cannot edit or save changes to a team flter unless you are a team owner or editor."
          disabled={showTooltip}
        >
          <Button
            size="small"
            dmpStyle="primary"
            disabled={isSaveDisabled}
            onClick={saveOnClick}
            data-cy="btn-save-search"
          >
            Save
          </Button>
        </TooltipButton>

        <Button size="small" disabled={searchParams.isEmpty} onClick={this.handleClear} data-cy="btn-clear-search">
          Clear
        </Button>
      </div>
    );
  }

  renderModals() {
    const { user, searchParams, teams, selectTeam } = this.props;
    const { savingFilter, managingFilters } = this.state;
    const selectedTeam = _.find(teams, { teamID: searchParams.teamID });
    return (
      <>
        <SaveFilter
          show={savingFilter}
          close={() => this.setState({ savingFilter: false })}
          user={user}
          searchParams={searchParams}
          teams={teams}
          team={selectedTeam}
          onSave={this.handleSaveFilter}
        />

        <FilterManager
          show={managingFilters}
          close={() => this.setState({ managingFilters: false })}
          onDelete={this.handleDeleteFilter}
          user={user}
          teams={teams}
          trash={searchParams.deleted || false}
        />
      </>
    );
  }

  render() {
    return (
      <Sidebar>
        {this.renderTabs()}
        {this.renderFilters()}
        {this.renderModals()}
      </Sidebar>
    );
  }
}
