import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import ACTION_BUCKETS from '@core/enums/ActionBuckets';
import DealAction from '@core/enums/DealAction';
import { ASPECTS } from '@core/models/Deal';
import { DateFormatter, dt, generateExportURL } from '@core/utils';

import { ButtonIcon, Dropdown, Icon, MenuItem } from '@components/dmp';

import ActionBucketIcon from '@components/deal/ActionBucketIcon';
import DealPanel, { DealPanelPropTypes } from '@components/deal/DealHeader/DealPanel';
import DealPanelItem from '@components/deal/DealHeader/DealPanelItem';
import TooltipButton from '@components/editor/TooltipButton';
import Fire from '@root/Fire';

@autoBindMethods
export default class AuditView extends Component {
  static propTypes = {
    deal: PropTypes.object.isRequired,
    ...DealPanelPropTypes,
  };

  constructor(props) {
    super(props);

    this.state = {
      actionTypes: _.map(ACTION_BUCKETS, 'key'),
      excludedUsers: [],
    };
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.show && !nextProps.show) {
      return false;
    }

    return true;
  }

  toggleActionFilter(key) {
    let { actionTypes } = this.state;
    const idx = actionTypes.indexOf(key);
    if (idx > -1) actionTypes.splice(idx, 1);
    else actionTypes.push(key);
    this.setState({ actionTypes });
  }

  updateUsersFilter(uid) {
    // null uid means show all users; otherwise toggle
    if (!uid) return this.setState({ excludedUsers: [] });

    let { excludedUsers } = this.state;
    const idx = excludedUsers.indexOf(uid);
    if (idx > -1) excludedUsers.splice(idx, 1);
    else excludedUsers.push(uid);
    this.setState({ excludedUsers });
  }

  async export() {
    const { deal } = this.props;
    const token = await Fire.token();
    window.open(generateExportURL({ deal, token, options: { inline: true, aspects: ASPECTS.ACTIVITY } }));
  }

  render() {
    const { deal, id, container, show, onHide, target, title } = this.props;
    const { actionTypes, excludedUsers } = this.state;

    // Get an array of arrays based on the selected activity type buckets
    // Then flatten into one list with spread operator
    // It's possible to do this in 1 line of code but it becomes totally unreadable :-)
    const filteredTypes = [],
      eventTypes = _.map(actionTypes, (key) => _.find(ACTION_BUCKETS, { key }).actions);
    _.forEach(eventTypes, (subTypes) => filteredTypes.push(...subTypes));

    const activityDays = deal.filterActivity({ filteredTypes, excludedUsers, sort: 'desc', groupByDay: true });

    const users = _.filter(deal.users, 'uid');
    const usersShowing = users.length - excludedUsers.length;

    return (
      <DealPanel id={id} onHide={onHide} show={show} target={target} title={title} container={container}>
        <div className="filter-bar">
          <div className="filter-group filter-type">
            {ACTION_BUCKETS.map((typeFilter) => (
              <TooltipButton
                tipID={`tip-filter-type-${typeFilter.key}`}
                key={typeFilter.key}
                tip={typeFilter.tip}
                container={container}
              >
                <div
                  className={cx('action-filter', { active: actionTypes.indexOf(typeFilter.key) > -1 })}
                  onClick={() => this.toggleActionFilter(typeFilter.key)}
                  data-cy={typeFilter.key}
                >
                  {<ActionBucketIcon type={typeFilter.key} />}
                </div>
              </TooltipButton>
            ))}
          </div>
          <div className="filter-group filter-users">
            <Dropdown
              pullRight
              size="small"
              dmpStyle="link"
              id="dd-audit-users-filter"
              title={`${usersShowing} user${usersShowing === 1 ? '' : 's'}`}
              onSelect={this.updateUsersFilter}
              noUnderline
            >
              <MenuItem eventKey={null}>Show all users</MenuItem>
              <MenuItem divider />
              {users.map((du) => {
                const included = excludedUsers.indexOf(du.uid) === -1;
                return (
                  <MenuItem key={du.uid} eventKey={du.uid} className={included ? 'included' : 'excluded'}>
                    <span>{du.fullName || du.email}</span>
                    {included ? <Icon name="included" /> : <Icon name="excluded" />}
                  </MenuItem>
                );
              })}
            </Dropdown>
          </div>
          <div className="export">
            <TooltipButton tipID="tip-audit-export" tip="Export to PDF">
              <span>
                <ButtonIcon icon="export" onClick={this.export}></ButtonIcon>
              </span>
            </TooltipButton>
          </div>
        </div>
        <div className="panel-body">
          <div className="panel-scroll" data-cy="panel-scroll">
            <DealPanelItem borderBottom>
              {_.map(activityDays, this.renderDay)}
              {!_.keys(activityDays).length && (
                <div className="empty">No {dt} activity found for the current filters.</div>
              )}
            </DealPanelItem>
          </div>
        </div>
      </DealPanel>
    );
  }

  renderDay(activityList, day) {
    return (
      <div key={day} className="day">
        <div className="date">{day}</div>
        {_.map(activityList, this.renderActivity)}
      </div>
    );
  }

  renderActivity(activity, idx) {
    const { deal } = this.props;
    const activityMessage = activity.getAuditMessage(deal);
    const bucket = _.find(ACTION_BUCKETS, (bucket) => bucket.actions.includes(activity.action));
    const isWebhook = activity.isWebhook;

    let dealUser = null;
    if (activity.user) dealUser = deal.getUserByID(activity.user);

    // if activity is DealAction.Webhook - do not render name
    // TO FIX: dumb verification
    const fullName = dealUser && !isWebhook && activity.action !== DealAction.COMPLETE ? dealUser.get('fullName') : '';

    //if DealUser is not found, it means the user left the deal
    //eventually we will handle this more gracefully
    //for now we don't have any record of that user, so nothing to show
    if (!dealUser && !isWebhook && activity.action !== DealAction.INTEGRATION) return null;

    if (!activityMessage) return null;

    return (
      <div key={activity.id} className={`activity${idx == 0 ? ' latest' : ''}`}>
        <div className="icon">{bucket && <ActionBucketIcon type={bucket.key} action={activity.action} />}</div>

        <div className="action-time">
          <span className="item-label">
            {fullName ? `${fullName} ` : null}
            {activityMessage}
          </span>
          <span className="item-sub time">{DateFormatter.time(activity.date)}</span>
        </div>
      </div>
    );
  }
}
