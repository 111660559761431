import * as React from 'react';

import { FormControl, FormGroup } from 'react-bootstrap';

import { DEFAULT_FOOTNOTE_CONFIG } from '@core/models/DealStyle';
import { FOOTNOTE_NUMBERING_OPTIONS } from '@core/models/LayoutStyle';
import { FOOTNOTE_NUMBER_STYLES } from '@core/utils';

import { Dropdown, Icon, MenuItem, Switch } from '@components/dmp';

import TooltipButton from '@components/editor/TooltipButton';

const FootnoteConfig = ({ footnoteConfig, updateSetting }) => {
  const { numbering, numberFormat, seperator } = DEFAULT_FOOTNOTE_CONFIG;

  const activeNumbering = footnoteConfig ? footnoteConfig.numbering : numbering;
  const activeNumberFormat = footnoteConfig ? footnoteConfig.numberFormat : numberFormat;
  const activeSeperator = footnoteConfig ? footnoteConfig.seperator : seperator;
  const activeFootnoteConfig = footnoteConfig ? footnoteConfig : DEFAULT_FOOTNOTE_CONFIG;

  return (
    <>
      <label className="property-subtitle">
        Footnotes{' '}
        <TooltipButton tip={'Footnote properties only apply to PDF exports'}>
          <span className="footnote-config-info">
            <Icon name="info" />
          </span>
        </TooltipButton>
      </label>
      <FormGroup>
        <div className="equal-grid width-100">
          <div>
            <div className="item-label">Numbering</div>
            <Dropdown
              id="footnote-number-scheme"
              title={activeNumbering.title}
              size="small"
              onSelect={(numbering) => updateSetting('footnoteConfig', { ...activeFootnoteConfig, numbering })}
              block
              className="footnote-number-scheme"
              dataCyToggle="footnote-number-scheme"
            >
              {FOOTNOTE_NUMBERING_OPTIONS.map((option) => (
                <MenuItem
                  key={option.key}
                  eventKey={option}
                  active={option.key === activeNumbering.key}
                  data-cy="footnote-number"
                >
                  {option.title}
                </MenuItem>
              ))}
            </Dropdown>
          </div>
          <div>
            <div className="item-label">Number Format</div>
            <Dropdown
              id="footnote-number-format"
              title={activeNumberFormat.label}
              size="small"
              onSelect={(numberFormat) => updateSetting('footnoteConfig', { ...activeFootnoteConfig, numberFormat })}
              block
              className="footnote-number-format"
              dataCyToggle="footnote-dd-number-format"
            >
              {FOOTNOTE_NUMBER_STYLES.map((style, idx) => {
                return (
                  <MenuItem
                    key={idx}
                    eventKey={style}
                    active={numberFormat && _.every(style, (val, key) => _.isEqual(val, activeNumberFormat[key]))}
                    data-cy="footnote-number-format"
                  >
                    {style.label}
                  </MenuItem>
                );
              })}
            </Dropdown>
          </div>
        </div>
      </FormGroup>
      <FormGroup>
        <Switch
          id="seperator-enabled"
          checked={activeSeperator}
          onChange={() => updateSetting('footnoteConfig', { ...activeFootnoteConfig, seperator: !activeSeperator })}
          size="small"
        >
          Show Seperator
        </Switch>
      </FormGroup>
    </>
  );
};

export default FootnoteConfig;
