import React, { Component, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import DealAction from '@core/enums/DealAction';
import DealRole from '@core/enums/DealRole';
import DealStatus from '@core/enums/DealStatus';
import InviteStatus from '@core/enums/InviteStatus';
import Deal from '@core/models/Deal';
import DealUser from '@core/models/DealUser';
import PDFElement from '@core/models/PDFElement';
import { ReadyLabels } from '@core/models/ReadyCheck';
import Section from '@core/models/Section';
import { SIGNING_RESULT } from '@core/models/Signature';
import User from '@core/models/User';

import DealUserView from '@components/DealUserView';
import SendDeal from '@components/deal/SendDeal';
import SigPad from '@components/deal/SigPad';
import API from '@root/ApiClient';
import CRM from '@root/CRM';
import Dealer, { Category } from '@root/Dealer';
import Fire from '@root/Fire';

import SignatureMenu, { SignatureActions } from './SignatureMenu';

const Steps = {
  UNASSIGNED: 'unassigned',
  DRAFTING: 'drafting',
  INCOMPLETE: 'incomplete',
  SHARE: 'share',
  SHARED: 'shared',
  REJECTED: 'rejected',
  SIGNING: 'signing',
  WAITING: 'waiting',
  READONLY: 'readonly',
  REVIEWING: 'reviewing',
  SIGNED: 'signed',
  MANUAL_UNREADY: 'manualUnready',
};

@autoBindMethods
export default class Siggy extends Component {
  static defaultProps = {
    readonly: false,
    inline: false,
    onActionClick: _.noop,
    onSignature: _.noop,
    toggleDealStatus: _.noop,
    signatureMaxHeight: null,
    signed: false,
  };

  static propTypes = {
    activity: PropTypes.object,
    container: PropTypes.object,
    currentUser: PropTypes.instanceOf(User),
    deal: PropTypes.instanceOf(Deal).isRequired,
    readonly: PropTypes.bool,
    inline: PropTypes.bool,
    onActionClick: PropTypes.func,
    onSignature: PropTypes.func.isRequired,
    partyID: PropTypes.string,
    pdfElement: PropTypes.instanceOf(PDFElement),
    section: PropTypes.instanceOf(Section),
    signatureMaxHeight: PropTypes.number,
    toggleDealStatus: PropTypes.func,
    user: PropTypes.instanceOf(DealUser),
    signed: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      email: _.get(props, 'user.email'),
      step: this.discoverStep(props),
      showModal: false,
    };

    //refs
    this.refSelf = createRef();
    this.menu = createRef();
    this.editor = createRef();
    this.newEditor = createRef();
    this.sharer = createRef();
  }

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      step: this.discoverStep(newProps),
      email: _.get(newProps, 'user.email', '') || '',
    });
  }

  discoverStep({ user, activity, section, pdfElement, readonly, deal }) {
    if (readonly) {
      return Steps.READONLY;
    }
    // For both types, first make sure that we have a user assigned
    if (!user) {
      return Steps.UNASSIGNED;
    }

    if (pdfElement) {
      if (this.signatureData) {
        return Steps.SIGNED;
      }
    }

    // Legacy (pre June 2018) deals can only have one signature section so status is equivalent to user signing status
    // If not legacy, manage user signature status per signature section (some can be signed and others are not)
    // Make sure that this stays at the top, if it's signed, it's signed.
    // We're checking for section existence because of PDF Elements.
    if (section && section.legacy && user.signed) {
      return Steps.SIGNED;
    } else if (section && !section.legacy && section.sigs[user.key] != null) {
      return Steps.SIGNED;
    }

    // Ensure above all that user has filled in a name and email
    if (!user.isComplete) {
      return Steps.INCOMPLETE;
    }

    // Don't let users start signing if there are other missing vars on the deal
    if (deal.status.data === DealStatus.TODO.data) {
      return Steps.DRAFTING;
    }

    if (activity != null && (activity.open > 0 || activity.changes > 0)) {
      return Steps.REVIEWING;
    }

    // An additional "in-between" scenario is possible for custom workflows,
    // where the deal technically could be ready for eSigning (e.g., above cases all pass)
    // but the owner has not yet activated the eSigning step, so we still need to block it
    if (!deal.workflow.isOutlaw && !_.get(deal.currentWorkflowStep, 'signable')) {
      return Steps.MANUAL_UNREADY;
    }

    if (this.isMe) {
      return Steps.SIGNING;
    } else {
      switch (user.inviteStatus) {
        case InviteStatus.ADDED:
          return Steps.SHARE;
        case InviteStatus.INVITED:
          return Steps.SHARED;
        case InviteStatus.REJECTED:
          return Steps.REJECTED;
        case InviteStatus.ACCEPTED:
        case InviteStatus.OWNED:
          return Steps.WAITING;
        default:
          return null;
      }
    }
  }

  get isMe() {
    const { currentUser, user, pdfElement, deal } = this.props;
    // In PDFDeals, Siggy is passed a pdfElement insteada of a DealUser
    // the PDFElement variable points to a party, meaning the PDFElement:signer is 1:1
    // So if multiple users are in the same party in the contract, they'll still need to be assigned separate parties
    // (e.g., Signer1 and Signer2)
    if (pdfElement) {
      const du = _.get(deal, 'currentDealUser');
      return du && pdfElement.variable && du.partyID === pdfElement.variable;
    } else {
      return !!currentUser && _.get(user, 'uid') === currentUser.id;
    }
  }

  //message that shows on left or on signature line
  get cta() {
    const { pdfElement, user, currentUser, deal, readonly } = this.props;
    const { step } = this.state;

    if (step === Steps.UNASSIGNED || !user) {
      return 'User not yet assigned';
    }

    // Above all, show incomplete message if user is not complete (regardless of sharing status)
    if (step === Steps.INCOMPLETE) {
      return 'Missing user details';
    }

    // Generic message if we're on a custom workflow and a non-signable step
    if (step === Steps.MANUAL_UNREADY) {
      if (_.get(deal, 'currentDealUser.role') === DealRole.OWNER) {
        return 'Advance to eSigning step to continue';
      } else {
        return 'Not ready for eSigning';
      }
    }

    // Otherwise message depends on whether user is looking at self or not
    if (this.isMe) {
      if (readonly) return 'eSigning is disabled';

      const signIfNoRC = !deal.readyCheck
        ? `${Dealer.mobile ? 'Tap' : 'Click'} to eSign`
        : `Wait for ${ReadyLabels.EVENT} before signing`;

      if (pdfElement) {
        if (pdfElement.data) {
          return null;
        } else {
          return signIfNoRC;
        }
      }

      switch (step) {
        case Steps.DRAFTING:
          return 'Fill in all details before signing';
        case Steps.REVIEWING:
          return 'Resolve all open issues before signing';
        case Steps.SIGNING:
          return signIfNoRC;
        default:
          return null;
      }
    } else {
      switch (user.inviteStatus) {
        case InviteStatus.ADDED:
          return `Not yet shared`;
        case InviteStatus.INVITED:
          return 'Shared';
        case InviteStatus.REJECTED:
          return `${user.displayName} rejected the invitation to review this deal`;
        case InviteStatus.ACCEPTED:
          if (pdfElement) {
            if (pdfElement.data) {
              return null;
            } else {
              return 'Awaiting eSignature';
            }
          } else {
            switch (step) {
              case Steps.DRAFTING:
                return 'Fill in all details before signing';
              case Steps.REVIEWING:
                return 'Resolve all open issues before signing';
              case Steps.WAITING:
                return 'Awaiting eSignature';
            }
          }
          break;
        case InviteStatus.OWNED:
          switch (step) {
            case Steps.REVIEWING:
              return 'Resolve all open issues before signing';
            case Steps.WAITING:
              return 'Awaiting eSignature';
          }
          break;
      }
      return null;
    }
  }

  //action that shows on button
  get action() {
    switch (this.state.step) {
      case Steps.SHARE:
        return 'Share';
      case Steps.SHARED:
      case Steps.REJECTED:
        return 'Re-Share';
      case Steps.REVIEWING:
        return this.isMe ? 'Ready' : null;
      default:
        return null;
    }
  }

  get canEdit() {
    const { currentUser, deal, user, readonly } = this.props;

    if (readonly) return false;

    if (user.startedSigning) return false;
    return (
      _.get(deal, 'currentDealUser.role') === DealRole.OWNER || (!!user.uid && _.get(currentUser, 'id') === user.uid)
    );
  }

  handleAction(action, swapDealUser, signatureKey) {
    const { container, user, partyID } = this.props;

    const target = this.menu.current || this.refSelf.current;

    switch (action) {
      case SignatureActions.ADD:
        this.newEditor.current.show(null, true, target);
        break;
      case SignatureActions.SWAP:
        Fire.swapSigner(user, swapDealUser, partyID);
        break;
      case SignatureActions.SWAP_NEW:
        // This is a special edge case for swapping in a newly created user.
        // The DealUserView.save (line 233) saves and handles this case. We save the new deal user and delete the old in fire.createDealUser
        this.newEditor.current.show(null, true, target);
        break;
      case SignatureActions.DETAILS:
        this.editor.current.show(null, true, target);
        break;
      case SignatureActions.SHARE:
        this.sharer.current.show(target, container, 'top');
        break;
      case SignatureActions.REMOVE_SIGNER:
        this.removeSigner(user);
        break;
      case SignatureActions.REMOVE:
        Fire.deleteDealUser(user);
        break;
      case SignatureActions.CLEAR_SIG:
        this.clearSignature(signatureKey);
        break;
      default:
        break;
    }
  }

  // This is called only in the swap case; the newly added user is already in the party,
  // So passing the old one in without a target will just clear the party, effectively completing the swap
  async removeSigner(dealUser) {
    return Fire.swapSigner(dealUser);
  }

  async clearSignature(signatureKey) {
    const { deal, pdfElement, section, currentUser: user } = this.props;
    const intl = Intl.DateTimeFormat().resolvedOptions();
    const { locale, timeZone } = intl;

    // If there was a signature stored, this is actually a deal event
    if (this.signatureData) {
      Dealer.call({
        category: Category.DEAL,
        action: DealAction.UNSIGN,
        label: deal.info.sourceTemplateKey || null,
      });

      // Signing with null clears signature
      await API.call('signDeal', {
        dealID: deal.dealID,
        objectID: _.get(pdfElement, 'key') || _.get(section, 'id'),
        data: null,
        userOrigin: user.userOrigin,
        signatureKey: signatureKey,
        locale,
        timeZone,
      });
    }
  }

  showModal(e) {
    if (!this.canSign) return;

    // Can we pleas prevent only if we're actually doing something ? thanks...
    if (e) e.stopPropagation();
    this.setState({ step: Steps.SIGNING, showModal: true });
  }

  get actionButtonClass() {
    switch (this.state.step) {
      case Steps.REVIEWING:
      case Steps.REJECTED:
        return 'btn-danger';
      case Steps.SHARE:
      // return isEmail(this.state.email) ? 'btn-primary' : 'disabled';
      default:
        return 'default';
    }
  }

  get signatureData() {
    const { pdfElement, user: dealUser, section } = this.props;

    if (pdfElement) {
      return pdfElement.data || null;
    } else {
      if (section.legacy) {
        return _.get(dealUser, 'sig', null);
      } else if (dealUser) {
        return section.sigs[dealUser.key] || null;
      } else {
        return null;
      }
    }
  }

  get canSign() {
    const { deal, readonly } = this.props;
    const { step } = this.state;

    if (!this.isMe || this.signatureData || deal.readyCheck || readonly || step === Steps.INCOMPLETE) {
      return false;
    }

    if (!deal.hasVersions) {
      return step === Steps.SIGNING;
    } else {
      return true;
    }
  }

  async sign(data) {
    const { deal, currentUser: user, section, pdfElement, onSignature, toggleDealStatus } = this.props;
    const { currentDealUser } = deal;
    const intl = Intl.DateTimeFormat().resolvedOptions();
    const { locale, timeZone } = intl;

    Dealer.call({ category: Category.DEAL, action: DealAction.SIGN, label: deal.info.sourceTemplateKey || null });

    // First save the signature
    const result = await API.call('signDeal', {
      dealID: deal.dealID,
      objectID: _.get(pdfElement, 'key') || _.get(section, 'id'),
      data,
      userOrigin: user.userOrigin,
      locale,
      timeZone,
    });

    switch (result.signingResult) {
      case SIGNING_RESULT.SUCCESS_DEAL:
        Dealer.call({
          category: Category.DEAL,
          action: DealAction.COMPLETE,
          label: deal.info.sourceTemplateKey || null,
        });
        break;
      case SIGNING_RESULT.SUCCESS_USER:
        toggleDealStatus(true);
        break;
      default:
        break;
    }

    // Tell CRM that the guest has signed
    // Also re-pass all it's attributes in case the user updated them during the session.
    if (currentDealUser.signed && currentDealUser.uid) {
      CRM.updateUser(
        {
          name: currentDealUser.fullName,
          job_title: currentDealUser.title,
          signed: true,
          ..._.pick(currentDealUser, ['email', 'phone', 'address', 'org']),
        },
        currentDealUser.uid
      );
    }

    // finally call callback either way (which hides siggy)
    onSignature();

    this.setState({ showModal: false, step: 'signed' });
  }

  render() {
    const {
      inline,
      deal,
      section,
      user,
      pdfElement,
      partyID,
      currentUser,
      onActionClick,
      signatureMaxHeight,
      readonly,
      signed,
    } = this.props;
    const { step, showModal } = this.state;

    if (!step || !deal) return null;

    const canSign = this.canSign;

    const className = cx(
      'siggy',
      step,
      { inline: !!inline },
      { 'ready-check': !!deal.readyCheck },
      { native: deal.native },
      { signable: canSign }
    );

    const stamp = section && user ? section.stamp(user) : null;
    const signatureData = this.signatureData;
    const cta = this.cta;
    // VariableView and DealUserView popovers show below element on 3PP deals
    const duPlacement = deal.isExternal ? 'bottom' : 'top';

    let ctaComponent = null;
    if (canSign) {
      ctaComponent = (
        <a className="sign-text-prompt" onClick={this.showModal} data-cy="sign-text-prompt">
          {this.cta}
        </a>
      );
    } else if (step === Steps.INCOMPLETE && this.canEdit) {
      ctaComponent = (
        <a className="sign-text-prompt" onClick={() => this.handleAction(SignatureActions.DETAILS)}>
          {this.cta}
        </a>
      );
    } else if (cta) {
      ctaComponent = <span className="sign-text-prompt">{this.cta}</span>;
    }

    const signatureStyle = signatureMaxHeight ? { maxHeight: signatureMaxHeight } : {};

    return (
      <>
        <div className={className} ref={this.refSelf}>
          <div className="siggy-content" data-cy="siggy-content">
            {ctaComponent}

            {step === Steps.SIGNED && signatureData && (
              <div className="signature-wrapper" data-cy="signature-wrapper">
                <img
                  className="signature"
                  src={signatureData}
                  alt={`${user.fullName} signature - ${stamp}`}
                  title={`${user.fullName} signature - ${stamp}`}
                  style={signatureStyle}
                />
              </div>
            )}
          </div>

          {!pdfElement && !readonly && (
            <SignatureMenu
              deal={deal}
              dealUser={user}
              handleAction={this.handleAction}
              onClick={onActionClick}
              id={`dd-${section ? section.id : pdfElement.key}`}
              partyID={partyID}
              ref={this.menu}
              user={currentUser}
              signed={signed}
            />
          )}

          {step !== Steps.SIGNED && (
            <>
              {user && this.canEdit && (
                <DealUserView
                  ref={this.editor}
                  deal={deal}
                  user={currentUser}
                  partyID={partyID}
                  dealUserKey={user.key}
                  placement={duPlacement}
                  signed={signed}
                />
              )}

              <DealUserView
                ref={this.newEditor}
                deal={deal}
                user={currentUser}
                partyID={partyID}
                placement={duPlacement}
                swapDealUser={user}
                signed={signed}
              />

              {user && <SendDeal ref={this.sharer} deal={deal} user={currentUser} du={user} placement="top" />}
            </>
          )}
        </div>

        <SigPad show={showModal} onHide={() => this.setState({ showModal: false })} onSignature={this.sign} />
      </>
    );
  }
}
