//TODO: For some reason when server.js imports Config, discoverEnvironment is not available

/* eslint-disable no-console */
import { isNil } from 'lodash';
import qs from 'query-string';

// returns either DEV or PROD depending on environment
// if run on server, uses environment var
// on client (browser), uses location

//updating default regex -- both prod and stage environments use live (prod) db
const prod = /^((stage|app)\.)?getoutlaw\.com$/gi;

export const discoverEnvironment = () => {
  //running in client
  if (typeof window === 'object' && window.location && window.location.hostname) {
    // Enable PROD when we're running the built version locally
    if (window.location.hostname === 'localhost' && window.location.port === '8081') {
      return 'PROD';
    } else if (prod.test(window.location.hostname) === true) {
      return 'PROD';
    } else return 'DEV';
  }

  //running on server
  if (process && typeof process.env === 'object') {
    if (process.env.SERVER_DEV) return 'DEV';
    if (process.env.NODE_ENV === 'production') return 'PROD';
    else return 'DEV';
  }

  // when in doubt, use DEV
  return 'DEV';
};

export const isProd = discoverEnvironment() === 'PROD';

export const isCypressRunning = typeof window !== 'undefined' && !!window.Cypress;

// In general, pixel ratio is reliable, but for some devices
// and especially when a laptop is connected to an other monitor
// it is likely that the browser cannot figure out the devicePixelRatio and return 1.
// In that case (mine), default to use 2x if it's not set or 1.
export const getDevicePixelRatio = () => {
  let ratio = window.devicePixelRatio;
  if (!ratio || ratio === 1) ratio = 2;

  return ratio;
};

// Global environment getter to detect whether we should present the dotvine version of the app
export const isVine = (() => {
  if (typeof window !== 'object') return false;

  // Support explicit instructions as "vine" param in querystring  (mainly for development)
  const params = qs.parse(window.location.search);
  if (!isNil(params.vine)) {
    // Support numeric params, ie vine=1
    const num = parseInt(params.vine);
    if (!isNaN(num)) return num === 1;
    // Support normal true/false, ie vine=true
    else return params.vine.toLowerCase() === 'true';
  }

  // Normal (production) case -- present as dotvine if running inside iframe on a filevine.com domain
  // https://stackoverflow.com/questions/3420004/access-parent-url-from-iframe
  // We can't actually directly access the true document.location.href of the parent window (security issue)
  // but we can confirm that we're running in an iframe (window.parent !== window)
  // and if so, the document.referrer will be the same value -- confirm that it's coming from a filevine server/page
  if (window.parent !== window) {
    return /\.filevine(dev|app|gov)?\.(com|ca)(\/|$)/i.test(document.referrer);
  }

  return false;
})();

// We need a *SHORT* (because it's used EVERYWHERE) const to return the title of this document type (dt),
// to start getting away from the assumption that everything is a contract.
// Eventually this may vary by Deal or Template or Team,
// but for the time being we'll continue to assume Contract unless running as dotvine
// We're adding multiple versions as a convenience for different case,
// to avoid needing '.toLowerCase()` (or css) everywhere
export const Dt = isVine ? 'Document' : 'Contract';
export const dt = isVine ? 'document' : 'contract';
export const Dts = isVine ? 'Documents' : 'Contracts';
export const dts = isVine ? 'documents' : 'contracts';

export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
