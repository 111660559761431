import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';

import { MAX_TOTAL_HITS } from '@core/models/SearchParamsES';
import { formatNumber } from '@core/utils';

import { ButtonIcon, Dropdown, MenuItem } from '@components/dmp';

@autoBindMethods
export default class DataTablePagniation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: props.page,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.page !== prevProps.page) {
      this.setState({ page: this.props.page });
    }
  }

  getSafePage(page) {
    if (Number.isNaN(page)) {
      page = this.props.page;
    }
    return Math.min(Math.max(page, 0), this.props.pages - 1);
  }

  changePage(page) {
    page = this.getSafePage(page);
    this.setState({ page });

    if (this.props.page !== page) {
      this.props.onPageChange(page);
    }
  }

  onPageSizeChange(pageSize) {
    this.props.onPageSizeChange(pageSize);
  }

  renderPageSizeSelector() {
    const { pageSize, showPageSizeOptions, pageSizeOptions, isTop, TopPaginationComponent } = this.props;

    if (isTop) {
      if (TopPaginationComponent) return <TopPaginationComponent {...this.props} />;
    }

    if (!showPageSizeOptions) return <div></div>;

    return (
      <div className="displaying-results">
        <span>Results per page </span>
        <Dropdown
          size="small"
          dmpStyle="link"
          id="dropdown-hits-per-page"
          title={pageSize}
          onSelect={this.onPageSizeChange}
        >
          {pageSizeOptions.map((option, idx) => (
            <MenuItem key={idx} eventKey={option} active={option === pageSize} data-cy="hits-per-page-option">
              {option}
            </MenuItem>
          ))}
        </Dropdown>
      </div>
    );
  }

  render() {
    const { pages, page, pageSize, canPrevious, canNext, className } = this.props;

    return (
      <div className={cx('data-table-pager', className)} data-cy="data-table-pager">
        {this.renderPageSizeSelector()}
        <div className="page-count">
          <ButtonIcon
            icon="chevronLeft"
            className="previous"
            onClick={() => {
              if (!canPrevious) return;
              this.changePage(page - 1);
            }}
            disabled={!canPrevious}
          />
          {pages * pageSize >= MAX_TOTAL_HITS ? (
            <div className="status">{formatNumber(page + 1)}</div>
          ) : (
            <div className="status">
              {formatNumber(page + 1)}
              <span> / </span>
              {formatNumber(pages)}
            </div>
          )}
          <ButtonIcon
            icon="chevronRight"
            className="next"
            onClick={() => {
              if (!canNext) return;
              this.changePage(page + 1);
            }}
            disabled={!canNext}
          />
        </div>
      </div>
    );
  }
}
