import { assign, filter, findIndex, findLastIndex, forEach, get, orderBy, pick } from 'lodash';

import SectionType from '../enums/SectionType';
import { ReadyCheckResult } from './ReadyCheck';

export const STEP_TYPES = [
  {
    locked: false,
    signable: false,
    checkpoint: false,
    title: 'Normal',
    description: 'Document variables & text are editable',
  },
  {
    locked: true,
    signable: false,
    checkpoint: false,
    title: 'Locked',
    description: 'Document is read-only',
  },
  {
    locked: true,
    signable: true,
    checkpoint: false,
    title: 'eSigning',
    description: 'Document is read-only and eSigning is enabled',
  },
  {
    locked: true,
    signable: false,
    checkpoint: true,
    title: 'Checkpoint',
    description: 'Apply checkpoint as workflow step',
  },
];

export const STEP_COLORS = [
  '#1B232E', // $gray-dark
  '#8C98A5', // $gray-2
  '#854AD3', // Outlaw "Signing" status ($outlaw)
  '#4480F1', // Outlaw "Review" status ($discuss)
  '#46CA8C', // Outlaw "Signed" status ($done)
  '#F66C1E', // Dark orange
  '#CA140F', // Dark red
  '#5A6573', // $gray-1
  '#2b3542', // $gray-0
  '#F2A5EF', // $color-pink
  '#67A0F8', // $color-blue
  '#49CDD1', // $color-teal
  '#FFAE10', // Lighter orange
  '#EE6662', // $color-red
];

export const CHECKPOINT_GROUP_APPROVAL_RULE = {
  UNANIMOUS: 'UNANIMOUS',
  FIRST: 'FIRST',
};

export const CHECKPOINT_GROUP_APPROVAL_RULE_DESCRIPTION = {
  UNANIMOUS: 'Unanimous approval required',
  FIRST: 'Approval determined by first response',
};

export default class WorkflowStep {
  name = null;
  key = null;
  description = null;
  color = 'black';
  locked = false;
  signable = false;
  checkpoint = false;
  checkpointGroupKey = null;
  checkpointSkippable = false;
  checkpointGroupApprovalRule = null;
  checkpointGroup = null;
  restricted = false;

  constructor(json, workflow) {
    assign(this, pick(json, ['name', 'key', 'description', 'restricted']));

    this.color = get(json, 'color', 'black');
    this.locked = get(json, 'locked', false);
    this.signable = get(json, 'signable', false);
    this.checkpoint = get(json, 'checkpoint', false);
    this.checkpointGroupKey = get(json, 'checkpointGroupKey', null);
    this.checkpointSkippable = get(json, 'checkpointSkippable', false);
    this.checkpointGroupApprovalRule = get(json, 'checkpointGroupApprovalRule', null);
    this.checkpointGroup = get(json, 'checkpointGroup', null);

    this.workflow = workflow;

    //an esigning (signable) WorkflowStep applied to a doc that has no signatures should simply function as a normal locked step instead
    if (this.signable && this.workflow.deal && !this.workflow.deal.requiresSigning) this.signable = false;
  }

  get json() {
    return pick(this, [
      'name',
      'key',
      'description',
      'color',
      'locked',
      'signable',
      'checkpoint',
      'checkpointGroupKey',
      'checkpointSkippable',
      'checkpointGroupApprovalRule',
      'restricted',
    ]);
  }

  get steps() {
    return get(this, 'workflow.steps', []);
  }

  get isAfterRestricted() {
    let isAfterRestricted = false;
    if (this.workflow.serviceProviders) {
      if (!this.restricted) {
        const currentIndex = findIndex(this.steps, {'key': this.key});
        const firstRestrictedIndex = findIndex(this.steps, {'restricted': true});
        //if we are looking at a non-restricted step that is AFTER one or more restricted steps
        //So if the step is not restricted and past the first found restricted step show the contract not overview section
        if (currentIndex > firstRestrictedIndex) {
          isAfterRestricted = true;
        }
      }
    }
    return isAfterRestricted;
  }

  get isDetatched() {
    return !this.workflow || !this.workflow.deal;
  }

  get index() {
    return findIndex(this.steps, { key: this.key });
  }

  get isFirst() {
    return this.index === 0;
  }

  get isLast() {
    return !!this.workflow && this.index + 1 === this.steps.length;
  }

  get firstCheckpointStepIndex() {
    return findIndex(this.steps, { checkpoint: true });
  }

  get lastCheckpointStepIndex() {
    return findLastIndex(this.steps, { checkpoint: true });
  }

  get lastSignableStepIndex() {
    return findLastIndex(this.steps, { signable: true });
  }

  get previousStep() {
    return this.isFirst ? null : this.steps[this.index - 1];
  }

  get nextStep() {
    return this.isLast ? null : this.steps[this.index + 1];
  }

  get behavior() {
    let status = 'enabled',
      tip = null;
    // If we're not looking at a Workflow that's not attached to a Deal instance (e.g., new Deal), all steps are enabled
    if (this.isDetatched) return { status, tip };

    // If we get here, we have both a Workflow instance and a Deal instance,
    // So we can use both as context to determine how this step should behave
    const wf = this.workflow,
      deal = wf.deal;

    // For external (PDF) deals, don't attempt to automate at all, in order to match legacy behavior
    if (deal.isExternal) return { status, tip };

    // The current step is disabled (already selected)
    const currentIndex = findIndex(wf.steps, {
      key: get(deal, 'info.status'),
    });
    if (this.index === currentIndex) return { status: 'selected', tip };

    const verb = this.index > currentIndex ? 'advance' : 'revert';

    if (this.index < currentIndex && this.index < this.lastCheckpointStepIndex) {
      status = 'enabled';
      tip = `Reverting to this step will reset any completed checkpoints after ${this.name} step`;
    }

    // If this is a locked step, it can only be activated if:
    // 1) All Variables in every Section are filled (Section.todo === 0)
    // 2) There is no open activity (i.e., comments and unresolved redlines)
    // This is similar to the logic in Siggy.discoverStep (though much cleaner),
    // to ensure that custom Workflows follow the same eSigning automation rules
    if (this.locked) {
      const sections = deal.applyConditions(deal.buildSource(true));
      const todo = filter(sections, (sec) => sec.todo > 0);
      const activity = deal.buildActivityStats(sections);

      if (todo.length > 0) {
        status = 'disabled';
        tip = `Can not ${verb} to ${this.name} until all fields are complete`;
      } else if (activity.open > 0 || activity.changes > 0) {
        status = 'disabled';
        tip = `Can not ${verb} to ${this.name} until all open issues are resolved`;
      }
    }

    // If THIS step is AFTER a step that's disabled, it's also disabled but no tip is necessary
    if (this.index > currentIndex && get(this.previousStep, 'behavior.status') === 'disabled') {
      status = 'disabled';
    }

    // If this is after a signing step and the deal isn't signed, disable
    if (get(this.previousStep, 'signable') && deal.requiresSigning && !deal.signed) {
      status = 'disabled';
      tip = `Can not ${verb} to ${this.name} until all signature boxes are signed.`;
    }

    if (deal.startedSigning && !this.locked) {
      status = 'disabled';
      tip = `Can not ${verb} to ${this.name} because one or more signatures are present.`;
    }

    if (this.previousStep?.checkpoint) {
      const checks = deal.checks?.filter(
        (check) => check.workflowCheckpointKey === `${this.previousStep?.key}:${this.previousStep?.checkpointGroupKey}`
      );
      const [latestCheck] = orderBy(checks, ['date'], ['desc']);

      if (latestCheck?.result !== ReadyCheckResult.PASSED && latestCheck?.result !== ReadyCheckResult.SKIPPED) {
        status = 'disabled';
        tip = `Can not advance to ${this.name} until the checkpoints in all the previous steps are passed.`;
      }
    }

    if (currentIndex >= this.lastSignableStepIndex) {
      const [signatureSection] = Object.values(deal.sections)?.filter(
        (section) => section.sectiontype === SectionType.SIGNATURE
      );

      if (signatureSection) {
        const hasSigs = Object?.keys(signatureSection?.sigs).length > 0;

        if (hasSigs && this.index < this.lastSignableStepIndex) {
          status = 'disabled';
          tip = 'Existing signatures need to be cleared in order to revert to prior status.';
        }
      }
    }

    if (this.index < currentIndex && deal.readyCheck) {
      status = 'disabled';
      tip = 'Checkpoint in progress.';
    }

    if (deal.signed && this.index <= this.lastSignableStepIndex) {
      status = 'disabled';
      tip = 'Contract is already signed.';
    }

    return { status, tip };
  }
}
