import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { Form as BSForm } from 'react-bootstrap';

import { classNamePrefixer } from '@core/utils';

const cl = classNamePrefixer('form');

// If onSubmit isn't set,
// make sure that we do not trigger a page refresh when hitting "return" in a form.
const defaultOnSubmit = (event) => event.preventDefault();

const Form = ({ onSubmit, className, ...passingProps }) => {
  const classNames = cx(cl(), className);

  return <BSForm className={classNames} onSubmit={onSubmit} {...passingProps} />;
};

Form.defaultProps = {
  className: null,
  onSubmit: defaultOnSubmit,
};

Form.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default Form;
