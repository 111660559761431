import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { classNamePrefixer } from '@core/utils';

const cl = classNamePrefixer('btn-toolbar');

const ButtonToolbar = React.forwardRef(({ children, className, align, fill, ...passingProps }, ref) => {
  const classNames = cx(cl(), className, { [cl(`align-${align}`)]: align }, { [cl('fill')]: fill });

  return (
    <div className={classNames} ref={ref} {...passingProps}>
      {children}
    </div>
  );
});

ButtonToolbar.displayName = 'ButtonToolbar';

ButtonToolbar.defaultProps = {
  align: 'left',
  fill: false,
};

ButtonToolbar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  align: PropTypes.oneOf(['between', 'center', 'right', 'left']),
  fill: PropTypes.bool,
};

const ButtonToolbarGroup = ({ className, ...passingProps }) => {
  const classNames = cx(cl('group'), className);

  return <div className={classNames} {...passingProps} />;
};

ButtonToolbar.displayName = 'ButtonToolbarGroup';

ButtonToolbarGroup.propTypes = {
  className: PropTypes.string,
};

ButtonToolbar.Group = ButtonToolbarGroup;

export default ButtonToolbar;
