// ES: This component was 99% AI generated using CodeGPT
// Layout/design is very basic/minimal and should be updated at some point, 
// but it provides the core functionality to update Vinnie's prompt knowledge via spreadsheet upload without any code changes

import React, { Component } from 'react';
import autoBindMethods from 'class-autobind-decorator';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';

import { Button, Loader } from '@components/dmp';

import API from '@root/ApiClient';
@autoBindMethods
export default class AssistantTab extends Component {
  static propTypes = {
    adminSearchParams: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      metadata: null,
      vectorStoreFiles: [],
      loading: false,
      sheetID: '',
    };
  }

  componentDidMount() {
    this.loadMetaData();
  }

  async loadMetaData() {
    this.setState({ loading: true });

    try {
      const metadata = await API.call('loadAssistant');
      this.setState({
        metadata,
        vectorStoreFiles: metadata.vectorStoreFiles || [],
        loading: false,
      });
    } catch (error) {
      console.error('Error loading metadata:', error);
      this.setState({ loading: false });
    }
  }

  async handleUpdatePrompts() {
    this.setState({ loading: true });

    // Function to extract the sheet ID from a URL or return the ID if already in the correct format
    const extractSheetID = (input) => {
      const urlPattern = /\/spreadsheets\/d\/([a-zA-Z0-9-_]+)/;
      const match = input.match(urlPattern);
      return match ? match[1] : input;
    };

    try {
      const sheetID = extractSheetID(this.state.sheetID);
      let sheet = await API.call('acquireKnowledge', { sheetID });
      await this.loadMetaData();
    } catch (error) {
      console.error('Error updating prompts:', error);
      this.setState({ loading: false });
    }
  }

  handleInputChange(event) {
    this.setState({ sheetID: event.target.value });
  }

  render() {
    const { metadata, vectorStoreFiles, loading, sheetID } = this.state;

    return (
      <div className="assistant-tab">
        {loading ? (
          <Loader />
        ) : (
          <div>
            {metadata && (
              <div>
                <h4>Assistant Information</h4>
                <p><strong>ID:</strong> {metadata.assistantMeta.id}</p>
                <p><strong>Name:</strong> {metadata.assistantMeta.name}</p>
                <p><strong>Model:</strong> {metadata.assistantMeta.model}</p>
                <p><strong>Description:</strong> {metadata.assistantMeta.description}</p>
                <p><strong>Instructions:</strong> {metadata.assistantMeta.instructions}</p>
                <p><strong>Tools:</strong></p>
                <ul>
                  {metadata.assistantMeta.tools.map((tool, index) => (
                    <li key={index}>{tool.type}</li>
                  ))}
                </ul>

                <h4>VectorStore Information</h4>
                <p><strong>ID:</strong> {metadata.vectorStoreMeta.id}</p>
                <p><strong>Name:</strong> {metadata.vectorStoreMeta.name}</p>
                <p><strong>Status:</strong> {metadata.vectorStoreMeta.status}</p>
                <p><strong>Usage (bytes):</strong> {metadata.vectorStoreMeta.usage_bytes}</p>
                <p><strong>Created At:</strong> {new Date(metadata.vectorStoreMeta.created_at * 1000).toLocaleString()}</p>
                <p><strong>Last Active At:</strong> {new Date(metadata.vectorStoreMeta.last_active_at * 1000).toLocaleString()}</p>
                <p><strong>File Counts:</strong></p>
                <ul>
                  <li>In Progress: {metadata.vectorStoreMeta.file_counts.in_progress}</li>
                  <li>Completed: {metadata.vectorStoreMeta.file_counts.completed}</li>
                  <li>Failed: {metadata.vectorStoreMeta.file_counts.failed}</li>
                  <li>Cancelled: {metadata.vectorStoreMeta.file_counts.cancelled}</li>
                  <li>Total: {metadata.vectorStoreMeta.file_counts.total}</li>
                </ul>

                <h4>Files in Vector Store</h4>
                <ul>
                  {vectorStoreFiles.map((file, index) => (
                    <li key={index}>
                      <p><strong>ID:</strong> {file.id}</p>
                      <p><strong>Status:</strong> {file.status}</p>
                      <p><strong>Usage (bytes):</strong> {file.usage_bytes}</p>
                      <p><strong>Created At:</strong> {new Date(file.created_at * 1000).toLocaleString()}</p>
                      {file.last_error && <p><strong>Last Error:</strong> {file.last_error}</p>}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <FormControl
              type="text"
              placeholder="Enter Google Sheet URL or ID"
              value={sheetID}
              onChange={this.handleInputChange}
            />
            <Button onClick={this.handleUpdatePrompts}>Update Prompts</Button>
          </div>
        )}
      </div>
    );
  }
}