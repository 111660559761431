import React from 'react';

import PropTypes from 'prop-types';

import { Button } from '@components/dmp';

import Auth from '@root/Auth';
import Dealer, { Category } from '@root/Dealer';

const SingleSignOnButton = (props) => {
  const { block, dealerLabel, domain, providerName, textTemplate, usePopup } = props;
  const identity = providerName.toLowerCase();
  const buttonText = textTemplate.replace('[PROVIDER]', providerName);

  const handleClick = () => {
    if (dealerLabel) {
      Dealer.call({ category: Category.USER, action: `auth-${domain}`, label: dealerLabel });
    }

    if (usePopup) {
      Auth.signInWithPopup(domain);
    } else {
      Auth.login(domain);
    }
  };

  return (
    <Button block={block} onClick={handleClick} className="sso-button" data-cy={`sso-${identity}`}>
      <img src={`/assets/svg/login-${identity}.svg`} alt={providerName} />
      <span>{buttonText}</span>
    </Button>
  );
};

SingleSignOnButton.defaultProps = {
  block: false,
  dealerLabel: null,
  textTemplate: '[PROVIDER]',
  usePopup: false,
};

SingleSignOnButton.propTypes = {
  block: PropTypes.bool,
  dealerLabel: PropTypes.string,
  domain: PropTypes.string,
  providerName: PropTypes.string,
  textTemplate: PropTypes.string,
  usePopup: PropTypes.bool,
};

export default SingleSignOnButton;
