import React, { Component, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import DealRole from '@core/enums/DealRole';
import InviteStatus from '@core/enums/InviteStatus';
import { dt } from '@core/utils';

import { ButtonToolbar, Dropdown, DropdownDots, Ellipsis, MenuItem } from '@components/dmp';

import DealUserView from '@components/DealUserView';
import DealPanelItem from '@components/deal/DealHeader/DealPanelItem';
import TooltipButton from '@components/editor/TooltipButton';
import Fire from '@root/Fire';

import LeaveDeal from './LeaveDeal';
import SendDeal from './SendDeal';

export const DEAL_ROLES = [
  { key: 'viewer', role: DealRole.VIEWER, title: 'Viewer', description: `User can only view the ${dt}` },
  {
    key: 'signer',
    role: DealRole.VIEWER,
    title: 'Signer',
    description: `User can fill in variables assigned to them and sign the ${dt}`,
  },
  { key: 'proposer', role: DealRole.PROPOSER, title: 'Proposer', description: 'User can redline to propose changes' },
  {
    key: 'editor',
    role: DealRole.EDITOR,
    title: 'Editor',
    description: `User can fill in all variables and edit the ${dt}`,
  },
  {
    key: 'owner',
    role: DealRole.OWNER,
    title: 'Owner',
    description: `User can fill in all variables, edit the ${dt} and manage sharing`,
  },
];

export const OBSERVER_ROLES = _.filter(DEAL_ROLES, ({ key }) => key !== 'signer');

const VIEWER_ROLE = DEAL_ROLES[0];

@autoBindMethods
export default class DealUserBlock extends Component {
  static defaultProps = {
    user: null,
    container: null,
    template: false,
    signed: false,
  };

  static propTypes = {
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    deal: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    du: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    container: PropTypes.objectOf(PropTypes.object),
    template: PropTypes.bool,
    signed: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      leave: false,
    };

    this.refSelf = createRef();
    this.refTarget = createRef();
    this.refSender = createRef();
    this.refEditor = createRef();
  }

  get isMe() {
    const { du, user } = this.props;
    return !!user && _.get(du, 'uid') === user.id;
  }

  get displayName() {
    const { du } = this.props;
    return du.fullName || du.email || 'Invited User';
  }

  get canEditInfo() {
    const { template, du, deal, signed } = this.props;

    if (template) {
      return true;
    } else if (this.isBundleChild) {
      return false;
    } else if (deal.isOwner || this.isMe) {
      // user can edit self as long as deal not signed or user is not in a party
      return !signed || !du.partyID;
    }

    return false;
  }

  get canInvite() {
    const { du, user } = this.props;
    if (this.isBundleChild) return false;
    if (du && user) return du.canBeInvitedBy(user);
    return false;
  }

  get canEditRole() {
    // user can edit roles if owner and not looking at self
    const { template, deal } = this.props;
    if (template) return true;
    if (this.isBundleChild) return false;
    return deal.isOwner && !this.isMe;
  }

  get canRemove() {
    const { template, deal, du, signed } = this.props;
    if (template) {
      return true;
    } else if (this.isBundleChild) {
      return false;
    } else if (deal.isOwner && !this.isMe) {
      return !signed || !du.partyID;
    }
    return false;
  }

  get roles() {
    // Depending on whether the current DealUser is in a party or not,
    // We want to exclude one of the names from being displayed
    return _.filter(DEAL_ROLES, (r) => (this.selectedParty ? r.key !== 'viewer' : r.key !== 'signer'));
  }

  get selectedParty() {
    const { du, deal } = this.props;
    return du.partyID ? deal.getPartyByID(du.partyID) : null;
  }

  get selectedRole() {
    const { du } = this.props;
    const role = _.find(this.roles, { role: du.role });
    return role || VIEWER_ROLE;
  }

  get isBundleChild() {
    const { deal } = this.props;
    return deal.isBundle && !deal.isBundleParent;
  }

  getStatusMessage(du) {
    switch (du.inviteStatus) {
      case InviteStatus.ADDED:
        return 'Not yet shared';
      case InviteStatus.REJECTED:
        // not sure if it's even possible to get here anymore
        return `${du.displayName} rejected invitation`;
      case InviteStatus.INVITED:
        return du.email ? `Shared with ${du.email}` : 'Shared';
      case InviteStatus.ACCEPTED:
      case InviteStatus.OWNED:
        // if user has accepted, use status to show their email
        return <Ellipsis>{du.email}</Ellipsis>;
      case InviteStatus.FAILED:
        return 'Invitation not sent';
      default:
        return '';
    }
  }

  handleUserAction(action, du) {
    switch (action) {
      case 'edit':
        if (this.refEditor.current && this.refTarget.current) {
          this.refEditor.current.show(null, true, this.refTarget.current);
        }
        break;
      case 'remove':
        this.removeUser(du);
        break;
      case 'leave':
        this.setState({ leave: true });
        break;
      default:
        break;
    }
  }

  showSender() {
    if (this.refSender.current && this.refTarget.current && this.refSelf.current) {
      this.refSender.current.show(this.refTarget.current, this.refSelf.current, 'bottom');
    }
  }

  updateParty(du, partyID) {
    Fire.saveDealUser(du, { partyID: partyID || null });
  }

  updateRole(du, role) {
    Fire.saveDealUser(du, { role });
  }

  removeUser(du) {
    Fire.deleteDealUser(du);
  }

  leaveContract(du) {
    const { history, deal } = this.props;
    this.removeUser(du);
    history.push('/dashboard/contracts', { leftDealfromDealView: true, dealName: deal.info.name });
  }

  renderPartyRole() {
    const { user, deal, du, template, signed } = this.props;

    return (
      <>
        <Dropdown
          size="small"
          disabled={this.isBundleChild || (!template && (!deal.isOwner || signed))}
          id={`dd-party-${user.id}`}
          className="dd-party"
          onSelect={(p) => this.updateParty(du, p)}
          title={_.get(this.selectedParty, 'displayName', 'None (not a signer)')}
          block={template}
          width={template ? 150 : undefined}
          dataCyToggle="party-dd-toggle"
        >
          <MenuItem active={!du.partyID} eventKey="">
            None (not a signer)
          </MenuItem>
          {!!_.get(deal, 'parties.length') && <MenuItem divider />}
          {deal.parties.map((p) => (
            <MenuItem
              active={du.partyID ? p.partyID === du.partyID : false}
              key={p.partyID}
              eventKey={p.partyID}
              ellipsis
            >
              {p.displayName}
            </MenuItem>
          ))}
        </Dropdown>

        <Dropdown
          size="small"
          id={`dd-role-${user.id}`}
          className="dd-role"
          disabled={!this.canEditRole}
          onSelect={(r) => this.updateRole(du, r)}
          title={this.selectedRole.title}
          pullRight
          block={template}
          menuWidth={!template ? 270 : undefined}
          dataCyToggle="role-dd-toggle"
        >
          {this.roles.map((role, idx) => (
            <MenuItem
              key={idx}
              eventKey={role.role}
              active={role.role === this.selectedRole.role}
              info={!template ? role.description : null}
            >
              {role.title}
            </MenuItem>
          ))}
        </Dropdown>
      </>
    );
  }

  render() {
    const { user, deal, du, template, signed } = this.props;
    const { leave } = this.state;

    const invited = du.inviteStatus === InviteStatus.INVITED;
    const failed = du.inviteStatus === InviteStatus.FAILED;

    // compile list of actions to be rendered based on user's permissions
    // if > 0 actions, these will be in a dot menu
    const actions = [];
    if (this.canEditInfo) {
      actions.push(
        <MenuItem key="edit" eventKey="edit" data-cy="edit-user">
          {template ? 'Edit default info' : 'Edit'}
        </MenuItem>
      );
    }
    if (this.canRemove) {
      actions.push(
        <MenuItem key="remove" eventKey="remove" data-cy="remove-user">
          Remove
        </MenuItem>
      );
    }
    if ((deal.users.filter((user) => user.role === 'owner').length > 1 || !deal.isOwner) && this.isMe) {
      actions.push(
        <MenuItem key="leave" eventKey="leave" data-cy="leave-contract">
          Leave contract
        </MenuItem>
      );
    }

    return (
      <>
        {leave && (
          <LeaveDeal
            onHide={() => {
              this.setState({ leave: false });
            }}
            onConfirm={() => this.leaveContract(du)}
          />
        )}
        <DealPanelItem
          className="deal-user-block"
          data-cy="deal-user-block"
          borderBottom
          ref={this.refSelf}
          disabled={this.isBundleChild}
        >
          <div className="topline">
            <Ellipsis className={`item-label name ${du.inviteStatus}`} ref={this.refTarget} data-cy="name">
              {this.displayName}
            </Ellipsis>
            {actions.length > 0 && (
              <DropdownDots
                className="du-actions"
                pullRight
                onClick={(e) => e.stopPropagation()}
                id={`dd-du-${du.key}`}
                onSelect={(action) => this.handleUserAction(action, du)}
                dataCyToggle="du-dd-toggle"
              >
                {actions}
              </DropdownDots>
            )}
          </div>

          <div className={cx('info', { ['can-invite']: this.canInvite })}>
            {du.titleOrg && (
              <div className="title-org" data-cy="title-org">
                {du.titleOrg}
              </div>
            )}

            <span className={`status ${du.inviteStatus}`}>
              {failed && (
                <span>
                  {this.getStatusMessage(du)}
                  {du.inviteStatusMessage && ': ' + du.inviteStatusMessage.toLowerCase()}
                </span>
              )}
              {!failed && this.getStatusMessage(du)}
              {this.canInvite ? ' - ' : ''}
            </span>

            {!template && failed && (
              <a className={cx('invite failed', { hide: !this.canInvite })} onClick={() => this.showSender()}>
                Retry?
              </a>
            )}

            {!template && !failed && (
              <a
                className={cx('invite', { hide: !this.canInvite })}
                onClick={() => this.showSender()}
                data-cy="invite-share"
              >
                {invited || du.guest ? 'Re-share' : 'Share'}
              </a>
            )}
          </div>

          <ButtonToolbar fill={!template}>{this.renderPartyRole()}</ButtonToolbar>

          {this.canEditInfo && (
            <DealUserView
              container={this.refSelf.current}
              template={template}
              ref={this.refEditor}
              deal={deal}
              user={user}
              dealUserKey={du.key}
              signed={signed}
            />
          )}

          <SendDeal deal={deal} du={du} placement="bottom" ref={this.refSender} user={user} />
        </DealPanelItem>
      </>
    );
  }
}
