import { extractTitle } from '../models/Content';
import { extractNumber } from '../models/DealNumberFormat';
import _ from 'lodash';

// Identify line ending delimiters that help signify the end of a paragraph
// includes a special case for sub roman numerals, which can frequently have the last one end with "; and"
export const rxParagraphEnder = /(; and)|([-;:."”])\s*$/;

export default class TextParagraph {

  x;
  y;
  page;
  value;

  lines = [];

  subType = null;

  constructor({x, y, page, value}) {
    _.assign(this, {x, y, page, value});
  }

  // Append line into the TextParagraph, and keep references to source TextLines
  // For potential later use in fragment highlighting, e.g., to overlay boxes on original pdf's
  push(textLine) {
    this.value += textLine.value + '\n';
    this.lines.push(textLine);

    // TODO: auto update width, height based on pushed fragments
    // TODO: handling multi-page paragraphs
  }

  // Utility for merging paragraphs
  combine(nextPara) {
    _.forEach(nextPara.lines, (line, i) => {
      this.push(line);
    });
  }

  get firstLine() {
    return this.lines[0];
  }

  get lastLine() {
    return this.lines[this.lines.length-1];
  }

  get hasTitle() {
    return !!extractTitle(this.firstLine.value);
  }

  get hasNumber() {
    return !!extractNumber(this.firstLine.value);
  }

  get startsWithCaps() {
    const firstChar = this.firstLine.value[0] || '';
    return !!firstChar.trim() && firstChar.toUpperCase() === firstChar;
  }

  get hasExplicitStart() {
    return (this.hasNumber || this.hasTitle/* || this.startsWithCaps*/);
  }

  get hasExplicitEnd() {
    return rxParagraphEnder.test(this.lastLine.value);
  }

  get json() {
    return {
        ..._.pick(this, [
          'x',
          'y',
          'page',
          'value',
        ]
      ),
      lines: _.map(this.lines, 'json'),
    };
  }

}