import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';

import User from '@core/models/User';

import { Alert, Button, Loader } from '@components/dmp';

import Fire from '@root/Fire';

@autoBindMethods
export default class UserInfoModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    user: PropTypes.instanceOf(User).isRequired,
    onHide: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      title: '',
      fullName: '',
      org: '',
      phone: '',
      address: '',
      addressProperties: {},
      splitAddress: false,
      errorFormFields: [],
      isSaving: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.populate(this.props);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  hasAddressProperties(addressProperties) {
    return (
      addressProperties &&
      Object.keys(addressProperties).length > 0 &&
      Object.values(addressProperties).some((val) => !!val)
    );
  }

  get errorAddresssFormFields() {
    const { addressProperties } = this.state;
    if (!this.hasAddressProperties(addressProperties)) {
      return [];
    }

    const requiredAddressProperties = ['line1', 'city', 'postalCode', 'country'];

    // Address is not required if no properties are populated
    const allRequiredAddressPropertiesEmpty = !requiredAddressProperties.some(
      (addField) => addressProperties[addField] && addressProperties[addField].trim().length > 0
    );

    if (allRequiredAddressPropertiesEmpty) {
      return [];
    }

    // if some are populated, error out if required are missing
    const missingRequiredAddressProperties = requiredAddressProperties.filter(
      (addField) => !addressProperties[addField] || !addressProperties[addField].trim().length > 0
    );
    return missingRequiredAddressProperties;
  }

  async save() {
    const { onHide, user } = this.props;
    const info = _.pick(this.state, ['org', 'title', 'fullName', 'phone', 'address', 'addressProperties']);

    if (this.hasAddressProperties(info.addressProperties) || this.hasAddressProperties(user.info.addressProperties)) {
      info.address = '';
    }

    await this.setState({ isSaving: true });
    await Fire.saveUserInfo(this.props.user, info);
    if (this._isMounted) this.setState({ isSaving: false, splitAddress: false, errorFormFields: [] });
    onHide();
  }

  onChange(e) {
    const { name, value } = e.target;
    if (name.includes('addressProperties')) {
      const [, addressProp] = name.split('.');
      if (addressProp) {
        this.setState((prevState) => {
          if (typeof prevState.addressProperties === 'object') {
            return {
              saved: false,
              addressProperties: { ...prevState.addressProperties, [addressProp]: value },
            };
          } else {
            return {
              saved: false,
              addressProperties: { [addressProp]: value },
            };
          }
        });
        return;
      }
    }
    const newState = { saved: false };
    newState[name] = value;
    this.setState(newState);
  }

  populate(props) {
    const info = props.user.info || {};

    this.setState({
      title: info.title || '',
      fullName: info.fullName || '',
      email: info.email || '',
      org: info.org || '',
      phone: info.phone || '',
      address: info.address || '',
      addressProperties: info.addressProperties || {},
      splitAddress: this.hasAddressProperties(info.addressProperties),
      errorFormFields: [],
    });
  }

  renderAddressForm() {
    const { addressProperties } = this.state;
    if (!this.hasAddressProperties(addressProperties) && this.state.address.length > 0 && !this.state.splitAddress) {
      return (
        <FormControl
          data-test="profile-input-address"
          name="address"
          componentClass="textarea"
          onChange={this.onChange}
          placeholder={`e.g., 61 Greenpoint Ave.
          Brooklyn, NY 11222`}
          type="text"
          value={this.state.address}
          disabled={this.state.isSaving}
        />
      );
    }

    return (
      <>
        <FormGroup>
          <FormControl
            type="text"
            name="addressProperties.line1"
            value={this.state.addressProperties?.line1 || ''}
            placeholder="Address Line 1"
            disabled={this.state.saving}
            onChange={this.onChange}
            data-cy="profile-input-address1"
          />
        </FormGroup>
        <FormGroup>
          <FormControl
            type="text"
            name="addressProperties.line2"
            value={this.state.addressProperties?.line2 || ''}
            placeholder="Address Line 2"
            disabled={this.state.saving}
            onChange={this.onChange}
            data-cy="profile-input-address2"
          />
        </FormGroup>
        <div className="address-properties">
          <FormGroup>
            <FormControl
              type="text"
              name="addressProperties.city"
              value={this.state.addressProperties?.city || ''}
              placeholder="City"
              disabled={this.state.saving}
              onChange={this.onChange}
              data-cy="profile-input-city"
            />
          </FormGroup>
          <FormGroup>
            <FormControl
              type="text"
              name="addressProperties.state"
              value={this.state.addressProperties?.state || ''}
              placeholder="State/Province"
              disabled={this.state.saving}
              onChange={this.onChange}
              data-cy="profile-input-state"
            />
          </FormGroup>
        </div>
        <div className="address-properties">
          <FormGroup>
            <FormControl
              type="text"
              name="addressProperties.postalCode"
              value={this.state.addressProperties?.postalCode || ''}
              placeholder="Zip/Postal Code"
              disabled={this.state.saving}
              onChange={this.onChange}
              data-cy="profile-input-code"
            />
          </FormGroup>
          <FormGroup>
            <FormControl
              type="text"
              name="addressProperties.country"
              value={this.state.addressProperties?.country || ''}
              placeholder="Country"
              disabled={this.state.saving}
              onChange={this.onChange}
              data-cy="profile-input-country"
            />
          </FormGroup>
        </div>
      </>
    );
  }

  render() {
    const { show, onHide } = this.props;
    const { isSaving, splitAddress, addressProperties } = this.state;

    return (
      <Modal
        className="modal-user-info"
        dialogClassName="team-creator"
        show={show}
        onHide={onHide}
        data-cy="modal-user-info"
      >
        <Modal.Header closeButton>
          <span className="headline">Update profile</span>
        </Modal.Header>

        <Modal.Body>
          <FormGroup>
            <ControlLabel>Name</ControlLabel>
            <FormControl
              data-cy="profile-input-fullName"
              name="fullName"
              onChange={this.onChange}
              placeholder="Enter name"
              type="text"
              value={this.state.fullName}
              disabled={isSaving}
            />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Email</ControlLabel>
            <FormControl
              data-cy="profile-input-email"
              name="email"
              placeholder="Enter email"
              type="text"
              value={this.state.email}
              disabled={true}
            />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Organization</ControlLabel>
            <FormControl
              data-cy="profile-input-org"
              name="org"
              type="text"
              value={this.state.org}
              onChange={this.onChange}
              placeholder="e.g., Startup Inc."
              disabled={isSaving}
            />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Title</ControlLabel>
            <FormControl
              data-cy="profile-input-title"
              name="title"
              onChange={this.onChange}
              placeholder="e.g., Partner"
              type="text"
              value={this.state.title}
              disabled={isSaving}
            />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Phone</ControlLabel>
            <FormControl
              data-cy="profile-input-phone"
              name="phone"
              onChange={this.onChange}
              placeholder="e.g., 833-668-8529"
              type="text"
              value={this.state.phone}
              disabled={isSaving}
            />
          </FormGroup>

          <FormGroup>
            <ControlLabel>Address</ControlLabel>
            <div className="address-input">
              {this.renderAddressForm()}
              {!this.hasAddressProperties(addressProperties) && this.state.address.length > 0 && !splitAddress && (
                <Alert dmpStyle="warning" className="split-address-alert">
                  Split address to individual fields for greater control across team & templates.
                  <a
                    onClick={() => {
                      this.setState((prevState) => {
                        return {
                          splitAddress: !prevState.splitAddress,
                          addressProperties: { ...prevState.addressProperties, line1: prevState.address },
                        };
                      });
                    }}
                  >
                    Convert
                  </a>
                </Alert>
              )}
            </div>
          </FormGroup>
        </Modal.Body>

        <Modal.Footer>
          {isSaving && <Loader />}
          <Button
            onClick={() => {
              this.setState({ splitAddress: false, errorFormFields: [] });
              onHide();
            }}
            disabled={isSaving}
          >
            Cancel
          </Button>
          <Button dmpStyle="primary" disabled={isSaving} onClick={this.save} data-cy="btn-update">
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
