// http://stackoverflow.com/questions/6108819/javascript-timestamp-to-relative-time-eg-2-seconds-ago-one-week-ago-etc-best
const msPerSecond = 1000;
const msPerMinute = 60 * 1000;
const msPerHour = msPerMinute * 60;
const msPerDay = msPerHour * 24;
const msPerMonth = msPerDay * 30;
const msPerYear = msPerDay * 365;

const Thresholds = [
  [msPerSecond, 'millisecond'],
  [msPerMinute, 'second'],
  [msPerHour, 'minute'],
  [msPerDay, 'hour'],
  [msPerMonth, 'day'],
  [msPerYear, 'month'],
  [msPerYear * 1000, 'year'],
];

export function timeDifference(previous) {
  const elapsed = new Date() - previous;

  //start at 1 to avoid counts in milliseconds
  for (var i = 1; i < Thresholds.length; i++) {
    if (elapsed < Thresholds[i][0]) {
      const divisor = i > 0 ? Thresholds[i - 1][0] : 1;
      //round up to at least 1 to avoid saying '0 seconds'
      const num = Math.max(Math.round(elapsed / divisor), 1);
      // return `${i >= 4 ? 'approximately ' : ''}${num} ${Thresholds[i][1]}${num > 1 ? 's' : ''} ago`
      return `${num} ${Thresholds[i][1]}${num > 1 ? 's' : ''} ago`;
    }
  }
}
