import React, { Component } from 'react';

import _ from 'lodash';

import { dt } from '@core/utils';

import { Button, Icon, Loader } from '@components/dmp';

import UserInfoModal from '@components/UserInfoModal';
import TooltipButton from '@components/editor/TooltipButton';
import API from '@root/ApiClient';

const STEPS = [
  {
    key: 'profile',
    title: 'Complete your Profile',
    image: '/assets/png/onboard-step1.png',
    instructions: 'Fill out your contact info and professional details',
    action: 'Complete Profile',
  },
  {
    key: 'team',
    title: 'Setup your Team',
    image: '/assets/png/onboard-step2.png',
    instructions: 'Fill out your organization’s details and branding',
    action: 'Setup Team',
    butFirst: 'Complete your profile first',
  },
  // {key: 'template', title: 'Create a Template', tip: ''},
  {
    key: 'demo',
    title: 'Explore the Demo',
    image: '/assets/png/onboard-step3.png',
    instructions: `Play with our interactive and seamless ${dt} experience`,
    action: 'Explore Demo',
    butFirst: 'Setup your Profile and Team first',
  },
  // {key: 'signed', title: 'Close a Deal', tip: ''}
];

const checkStep = (step, props) => {
  const { user, stats } = props;

  switch (step.key) {
    case 'profile':
      // profile is complete if user has entered name, email, org and title
      step.complete = user.complete;
      break;
    case 'team':
      // team setup is complete if user is a member of at least one team
      step.complete = !!user.team;
      break;
    case 'demo':
      // deal flow demo is complete if user owns at least 1 deal
      step.complete = stats != null && stats.dmp != null && stats.dmp.owner > 0;
      break;
    default:
      break;
  }
};

export const ONBOARD = (props) => {
  const steps = _.cloneDeep(STEPS);
  steps.map((step) => checkStep(step, props));
  return steps;
};

export default class Onboarding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editingProfile: false,
      loadingStep: null,
    };
  }

  get steps() {
    return ONBOARD(this.props);
  }

  takeAction(step) {
    const { history, user, toggleTeamCreation } = this.props;
    switch (step.key) {
      case 'profile':
        this.setState({ editingProfile: true });
        break;
      case 'team':
        toggleTeamCreation(true);
        break;
      case 'demo':
        this.setState({ loadingStep: step.key });

        API.call('demo', { teamID: user.team }, (demoID) => {
          if (demoID) {
            this.setState({ loadingStep: null });
            history.push(`/deals/${demoID}`);
          }
        });
        break;
      default:
        break;
    }
  }

  render() {
    const { loading, user } = this.props;
    const { editingProfile } = this.state;

    if (loading || _.filter(this.steps, { complete: true }).length == this.steps.length) return null;

    return (
      <div className="onboarding" data-cy="onboarding">
        <div className="onboarding-header">
          <h3>Get Started</h3>
          <p>Complete these steps to finish setting up your account</p>
        </div>
        <div className="onboarding-steps" data-cy="onboarding-steps">
          {this.steps.map((step, index) => this.renderStep(step, index))}
        </div>

        {editingProfile && (
          <UserInfoModal onHide={() => this.setState({ editingProfile: false })} show={editingProfile} user={user} />
        )}
      </div>
    );
  }

  renderStep(step, index) {
    const { loadingStep } = this.state;
    // We need to check all prior steps since Step #2 can be completed while Step #1 might not be.
    const priorsComplete = index === 0 || _.every(_.take(this.steps, index), 'complete');

    return (
      <div
        key={step.key}
        className={`step ${step.key} ${step.loading ? 'loading' : step.complete ? 'complete' : 'incomplete'}`}
        data-cy={step.key}
      >
        <div className="top">
          <img className="step-image" src={step.image} />
          <span className="step-number">STEP {index + 1}</span>
          <div className="step-title">{step.title}</div>
          <div className="step-instructions">{step.instructions}</div>
        </div>
        <div className="bottom">
          {(step.loading || step.complete) && (
            <div className="check">
              {step.loading && <Loader />}
              {step.complete && <Icon name="checkGreen" />}
            </div>
          )}
          {!step.loading && !step.complete && (
            <div className="step-action" data-cy={step.key}>
              {loadingStep == step.key && <Loader size="small" />}
              {priorsComplete ? (
                <Button onClick={() => this.takeAction(step)} disabled={loadingStep == step.key}>
                  {step.action}
                </Button>
              ) : (
                <TooltipButton tipID={`onboard-step-${index + 1}`} tip={step.butFirst} placement="bottom">
                  <Button className="disabled">{step.action}</Button>
                </TooltipButton>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
