import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Modal, ProgressBar } from 'react-bootstrap';

import { SIGNING_RESULT } from '@core/models/Signature';
import User from '@core/models/User';
import { Dt, dt } from '@core/utils';

import { Alert, Button } from '@components/dmp';

import API from '@root/ApiClient';

const STEPS = {
  NOT_STARTED: 'notStarted',
  PROCESSING: 'processing',
  DONE: 'done',
};

@autoBindMethods
export default class BulkSigner extends Component {
  static defaultProps = {};

  static propTypes = {
    user: PropTypes.instanceOf(User).isRequired,
    onHide: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      step: STEPS.NOT_STARTED,
      showing: false,
      signature: null,
      dealRecords: [],
      results: [],
    };
  }

  async start(signature, dealRecords) {
    await this.setState({
      showing: true,
      step: STEPS.PROCESSING,
      signature,
      dealRecords,
    });

    this.processQueue();
  }

  async processQueue() {
    const { user } = this.props;
    const { dealRecords, signature, results } = this.state;
    const intl = Intl.DateTimeFormat().resolvedOptions();
    const { locale, timeZone } = intl;

    _.forEach(dealRecords, async (dr) => {
      const result = await API.call('signDeal', {
        dealID: dr.dealID,
        bulk: true,
        data: signature,
        userOrigin: user.userOrigin,
        locale,
        timeZone,
      });

      results.push(result);
      await this.setState({ results });

      if (results.length === dealRecords.length) {
        await this.setState({ step: STEPS.DONE });
      }
    });
  }

  reset() {
    this.setState({
      step: STEPS.NOT_STARTED,
      signature: null,
      dealRecords: [],
      results: [],
    });
  }

  onHide() {
    const { onHide } = this.props;
    const { step } = this.state;

    if (step === STEPS.DONE) {
      this.reset();
      this.setState({ showing: false });
      onHide();
    }
  }

  renderProcessing() {
    const { results, dealRecords } = this.state;
    const percent = (results.length / (dealRecords.length || 1)) * 100;

    return (
      <Modal.Body>
        <div className="saving">
          <ProgressBar bsStyle="info" now={percent} />
          <div className="details">
            <div className="step">
              Signing {results.length} of {dealRecords.length}
            </div>
          </div>
        </div>
      </Modal.Body>
    );
  }

  renderResults() {
    const { results, dealRecords } = this.state;

    const successes = _.filter(results, (r) =>
      [SIGNING_RESULT.SUCCESS_DEAL, SIGNING_RESULT.SUCCESS_USER].includes(r.signingResult)
    );
    const errorAccess = _.filter(results, { signingResult: SIGNING_RESULT.ERROR_ACCESS });
    const errorIssues = _.filter(results, { signingResult: SIGNING_RESULT.ERROR_ISSUES });
    const errorSigned = _.filter(results, { signingResult: SIGNING_RESULT.ERROR_SIGNED });
    const errorUnviewed = _.filter(results, { signingResult: SIGNING_RESULT.ERROR_UNVIEWED });

    const totalErrors = results.length - successes.length;

    return (
      <Modal.Body>
        <div className="saving">
          <Alert dmpStyle={!!totalErrors ? 'warning' : 'success'}>
            Batch signing completed{!!totalErrors && ', but with some errors'}.
          </Alert>
        </div>
        <div className="signing-results">
          <div className="results-label">Results</div>
          <div className="results-details">
            <div className="results-success">
              <span className="result-count">
                {successes.length} {dt}
                {successes.length > 1 ? 's' : ''}
              </span>{' '}
              {successes.length > 1 ? 'were' : 'was'} signed successfully
            </div>
            {!!totalErrors && (
              <div className="results-errors">
                <div className="error-summary">
                  <span className="result-count">
                    {totalErrors} {!!totalErrors ? 'were' : 'was'} skipped
                  </span>{' '}
                  due to the following reasons:
                </div>
                {!!errorAccess.length && <div className="result-explanation">– You are not a signer on the {dt}</div>}
                {!!errorIssues.length && (
                  <div className="result-explanation">– {Dt} has issues to resolve before signing</div>
                )}
                {!!errorSigned.length && (
                  <div className="result-explanation">– You had already previously signed the {dt}</div>
                )}
                {!!errorUnviewed.length && (
                  <div className="result-explanation">
                    – {Dt} language has changed from template and must be viewed before signing
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
    );
  }

  render() {
    const { step, showing } = this.state;

    return (
      <Modal dialogClassName="batch-signer" show={showing} onHide={this.onHide}>
        <Modal.Header closeButton={this.canClose}>
          <span className="headline">
            {step === STEPS.PROCESSING && 'Batch signing in progress...'}
            {step === STEPS.DONE && 'Batch signing results'}
          </span>
        </Modal.Header>

        {step === STEPS.PROCESSING && this.renderProcessing()}
        {step === STEPS.DONE && this.renderResults()}

        <Modal.Footer>
          <Button disabled={step !== STEPS.DONE} onClick={this.onHide}>
            Close and refresh list
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
