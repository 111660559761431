import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';

import { FILEVINE_SERVICE } from '@core/enums/IntegrationServices';
import Deal from '@core/models/Deal';
import DealConnection, { buildHydratedFields } from '@core/models/DealConnection';
import Team from '@core/models/Team';
import { isStrictURL } from '@core/utils';

import { Button, Dropdown, MenuItem, ModalConfirm } from '@components/dmp';

import TooltipButton from '@components/editor/TooltipButton';
import CONFIG from '@root/Config';
import Fire from '@root/Fire';

const { DEFAULT_CONNECTIONS } = CONFIG;

@autoBindMethods
export default class ConnectionModal extends Component {
  static defaultProps = {
    onClose: _.noop,
    enabledServices: [],
  };

  static propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    deal: PropTypes.instanceOf(Deal).isRequired,
    dealTeam: PropTypes.instanceOf(Team),
    action: PropTypes.oneOf(['create', 'update', 'delete']),
    selectedConnection: PropTypes.object,
    enabledServices: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.state = {
      chosenService: null,
      source: '',
      objectType: null,
      sourceType: [{ key: 'url', name: 'Link', description: '' }],
      idFields: [],
      collectionIDs: {},
    };
  }

  componentDidUpdate(prevProps) {
    const { action } = this.props;
    if (prevProps.action !== action || prevProps.selectedConnection !== this.props.selectedConnection) {
      this.populate();
    }
  }

  populate() {
    const { action, selectedConnection, deal } = this.props;

    let chosenService = selectedConnection ? selectedConnection.service : null;

    if (action === 'create') {
      this.setState({
        chosenService: null,
        source: '',
        objectType: null,
        idFields: [],
        collectionIDs: {},
      });
    }
    if (action === 'update') {
      const collectionIDs = _.pick(selectedConnection.idFields, deal.fvCollections);
      // If we have no choosen service yet but we're in update mode, it means that it is a custom connection type/service, create a placeholder
      if (!chosenService) {
        chosenService = {
          key: selectedConnection.type,
          name: selectedConnection.name || selectedConnection.type,
          idFields: [{ key: 'id', label: 'ID', name: '', required: true }],
        };
      }

      this.setState({
        chosenService,
        idFields: buildHydratedFields(chosenService, selectedConnection.idFields),
        source: selectedConnection.source || '',
        objectType: selectedConnection.objectType,
        collectionIDs,
      });
    }
  }

  handleClose() {
    this.props.onClose();
    this.setState({
      chosenService: null,
      source: '',
      objectType: null,
    });
  }

  async saveConnection() {
    const { deal, selectedConnection } = this.props;
    const { chosenService, source, objectType, idFields, collectionIDs } = this.state;
    let id = '';

    const dealConnection = selectedConnection
      ? selectedConnection
      : new DealConnection(
          {
            type: chosenService.key,
            id,
            source,
            objectType,
          },
          deal
        );

    // Compile idFields and id from state and assign back into our existing (or new) DealConnection
    // So that the DealConnection.json getter pulls the latest values when saved
    const fieldObj = {},
      fields = _.filter(idFields, (field) => !!field.value);
    _.forEach(fields, (field) => {
      fieldObj[field.key] = field.value;
    });
    id = _.map(fields, 'value').join('_');

    _.forEach(collectionIDs, (val, key) => {
      if (!!val) fieldObj[key] = val;
    });

    _.assign(dealConnection, { id, source, idFields: fieldObj });

    try {
      await Fire.saveDealConnections(deal.dealID, [dealConnection.json]);
      this.handleClose();
    } catch (err) {
      console.log('err', err);
    }
  }

  async handleDelete() {
    const { deal, selectedConnection, onClose } = this.props;
    const { dealID } = deal;
    try {
      await Fire.deleteDealConnection({ dealID, connectionId: selectedConnection.key });
      onClose();
    } catch (err) {
      console.log('err', err);
    }
  }

  handleChange(key, e) {
    const { idFields } = this.state;
    const field = _.find(idFields, { key });
    field.value = e.target.value;
    this.setState({ idFields });
  }

  handleCollectionID(e) {
    const { collectionIDs } = this.state;
    collectionIDs[e.target.name] = e.target.value;
    this.setState({ collectionIDs });
  }

  handleSelectService(chosenService) {
    const idFields = buildHydratedFields(chosenService);
    this.setState({
      chosenService,
      idFields,
      objectType: _.get(chosenService.objectTypes, '[0].key', null),
    });
  }

  renderField({ key, label, required, value }, idx) {
    const { idFields } = this.state;
    const prevField = idx > 0 ? idFields[idx - 1] : null;
    const prevBlank = prevField && !prevField.value;

    return (
      <div className="wrapper" key={`${key}-${label}`}>
        <FormGroup>
          <ControlLabel>{label}</ControlLabel>
          <div className="contents deal-title">
            <FormControl
              name={key}
              type="text"
              value={value}
              placeholder={required ? 'Required' : 'Optional'}
              onChange={(e) => this.handleChange(key, e)}
              disabled={prevBlank}
              data-cy={`deal-connection-${key}-field`}
            />
          </div>
        </FormGroup>
      </div>
    );
  }

  renderCollectionField(collection, idx) {
    const { collectionIDs } = this.state;

    return (
      <div className="wrapper" key={idx}>
        <FormGroup>
          <ControlLabel>{collection} ID</ControlLabel>
          <div className="contents deal-title">
            <FormControl
              name={collection}
              type="text"
              value={_.get(collectionIDs, collection, '')}
              placeholder={'Optional'}
              onChange={this.handleCollectionID}
              data-cy={`deal-connection-${collection}-field`}
            />
          </div>
        </FormGroup>
      </div>
    );
  }

  get canSave() {
    const { idFields, source, chosenService } = this.state;

    if (!chosenService) return false;

    // Verify that all required id fields are populated
    const todo = _.filter(idFields, (field) => field.required && !field.value);
    if (todo.length > 0) return false;

    // If we have a source, verify that it's a valid url
    if (source && !isStrictURL(source)) return false;

    return true;
  }

  // Lookup objectType definition in IntegrationService based on the current value in state
  get selectedObjectType() {
    const { chosenService, objectType } = this.state;
    return _.find(_.get(chosenService, 'objectTypes', []), { key: objectType });
  }

  render() {
    const { show, action, deal, enabledServices, selectedConnection, onClose } = this.props;
    const { source, chosenService, idFields } = this.state;
    const connectionName = _.get(selectedConnection, 'service.name', '');
    const isFV = _.get(chosenService, 'key') === FILEVINE_SERVICE.key;

    if (action === 'delete')
      return (
        <ModalConfirm
          show={show}
          onHide={onClose}
          onConfirm={this.handleDelete}
          title="Delete connection?"
          body={`Are you sure you want to delete this ${connectionName} connection? This can not be undone.`}
        />
      );

    return (
      <Modal dialogClassName="add-connection" show={show} onHide={this.props.onClose} data-cy="add-connection-modal">
        <Modal.Header closeButton onHide={this.handleClose}>
          <span className="headline">{action === 'create' ? 'Add Connection' : 'Edit Connection'}</span>
        </Modal.Header>

        <Modal.Body>
          <div className="wrapper">
            <FormGroup>
              <ControlLabel>Type</ControlLabel>
              <div className="contents">
                <Dropdown
                  id="connect-dd-connection-type"
                  onSelect={this.handleSelectService}
                  title={chosenService ? chosenService.name : 'Select'}
                  disabled={action === 'update'}
                  block
                  dataCyToggle="connect-dd-connection-type"
                >
                  {enabledServices.map((service) => {
                    const canUseService = service.multi || !_.find(deal.connections, { type: service.key });
                    return (
                      <MenuItem
                        className={`status-${service.key}`}
                        active={service.key === _.get(chosenService, 'key')}
                        key={service.key}
                        eventKey={service}
                        info={service.description}
                        icon={service.icon || 'connect'}
                        disabled={!canUseService}
                      >
                        <TooltipButton
                          disabled={canUseService}
                          placement="right"
                          tip="This connection has already been added"
                        >
                          <div>{service.name || service.type}</div>
                        </TooltipButton>
                      </MenuItem>
                    );
                  })}
                </Dropdown>
              </div>
            </FormGroup>
          </div>

          {chosenService && chosenService.objectTypes && (
            <div className="wrapper">
              <FormGroup>
                <ControlLabel>Object Type</ControlLabel>
                <div className="contents deal-status">
                  <Dropdown
                    id="connect-dd-object-type"
                    title={_.get(this.selectedObjectType, 'name', 'Object Type')}
                    // Currently only 1 objectType is available per IntegrationService so it's not changeable
                    // But this will likely become dynamic soon
                    disabled={true}
                    block
                  >
                    {chosenService.objectTypes.map((type) => {
                      const menuItem = (
                        <MenuItem
                          className={`status-${type.key}`}
                          active={type.key === _.get(this.selectedObjectType, 'key')}
                          key={type.key}
                          eventKey={type}
                          info={type.description}
                        >
                          {type.name}
                        </MenuItem>
                      );

                      return menuItem;
                    })}
                  </Dropdown>
                </div>
              </FormGroup>
            </div>
          )}

          {idFields.map(this.renderField)}
          {isFV && deal.fvCollections.map(this.renderCollectionField)}

          <div className="wrapper">
            <FormGroup>
              <ControlLabel>Source</ControlLabel>
              <div className="contents deal-title form-control-with-dropdown">
                <FormControl
                  type="text"
                  value={source}
                  placeholder="Optional"
                  onChange={(e) => this.setState({ source: e.target.value })}
                  data-cy="deal-connection-source-field"
                />
                <div className="option place-under-field">
                  <small>Add URL to use as a clickable link (URL must include https://)</small>
                </div>
              </div>
            </FormGroup>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleClose} data-cy="btn-cancel-connection">
            Cancel
          </Button>

          <Button
            disabled={!this.canSave}
            dmpStyle="primary"
            onClick={this.saveConnection}
            data-cy="btn-save-connection"
          >
            Save Connection
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
