import React, { Component } from 'react';

import autobindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FormControl, FormGroup } from 'react-bootstrap';

import Deal from '@core/models/Deal';
import { ADVANCED_LENS_DEFAULT } from '@core/models/Lens';
import Lens from '@core/models/Lens';
import { getUniqueKey } from '@core/utils';

import { Alert, Button, ButtonIcon, Icon, Validator } from '@components/dmp';

import Fire from '@root/Fire';

@autobindMethods
class LensAdvancedEditor extends Component {
  static propTypes = {
    deal: PropTypes.instanceOf(Deal).isRequired,
    lens: PropTypes.instanceOf(Lens),
    onHide: PropTypes.func.isRequired,
    advancedFilter: PropTypes.object,
  };

  static defaultProps = {
    advancedFilter: null,
    lens: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      advancedFilter: {},
      title: '',
      deleting: false,
      question: '',
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.populate(this.props);
  }

  componentDidUpdate(prevProps) {
    if (!this._isMounted) return;
    if (prevProps.lens !== this.props.lens || prevProps.newType !== this.props.newType) {
      this.populate(this.props);
    }
  }

  populate(props) {
    const { lens, advancedFilter } = props;
    const { title, question } = this.state;

    this.setState({
      advancedFilter: advancedFilter
        ? advancedFilter
        : { id: getUniqueKey(), riskValue: 0, option: '', isDefault: false },
      title: lens.title || title,
      question: lens.question || question,
    });
  }

  validateRiskValue(riskValue) {
    return !isNaN(parseInt(riskValue));
  }

  async cancel(e) {
    const { lens, deal, onHide } = this.props;
    if (e) e.stopPropagation();
    if (lens.isCreating) await Fire.saveLens(deal.dealID, lens, true);
    onHide(false);
  }

  async save() {
    const { deal, lens, onHide } = this.props;
    const { advancedFilter, title, question } = this.state;
    const { id, option } = advancedFilter;

    if (this.editingFilter) {
      lens.advancedFilters[id] = advancedFilter;
      if (this.props.advancedFilter.id !== id) lens.advancedFilters[this.props.advancedFilter.id] = null;
    }
    if (this.addingFilter && !!id && option.length > 1) {
      lens.advancedFilters[id] = advancedFilter;
      lens.advancedFilters[id].timestamp = Date.now();
    }

    lens.question = question;
    lens.title = title;

    const isCreating = _.size(lens.advancedFilters) === 0;

    if (isCreating) lens.advancedFilters[ADVANCED_LENS_DEFAULT.id] = ADVANCED_LENS_DEFAULT;

    await Fire.saveLens(deal.dealID, lens);
    onHide();
  }

  renderDeletionAlert() {
    const alertText = 'Are you sure you want to delete this target range?';

    return (
      <Alert dmpStyle="danger" size="small">
        {alertText}
        <div className="alert-actions">
          <a className="cancel" onClick={() => this.setState({ deleting: false })}>
            Cancel
          </a>
          <a onClick={this.delete}>Delete</a>
        </div>
      </Alert>
    );
  }

  renderTitle() {
    const { title, question } = this.state;
    const { lens } = this.props;

    return (
      <div className="lens-property-block">
        <div className="lens-editior-title">
          <Icon name={lens.isCreating ? 'plus2' : 'edit'} className="condition-icon" />
          {lens.isCreating ? 'Advanced lens setup' : 'Edit advanced lens config'}
        </div>
        <div className="lens-property">
          <div className="lens-property-label">Lens title</div>
          <FormControl
            type="text"
            bsSize="small"
            value={title}
            placeholder="Enter Title"
            onChange={(e) => this.setState({ title: e.target.value })}
          />
        </div>
        <div className="lens-property">
          <div className="lens-property-label">Question</div>

          <div className="variable-options" data-cy="variable-options"></div>
          <FormGroup>
            <FormControl
              bsSize="small"
              componentClass="textarea"
              value={question}
              placeholder="Enter your question"
              onChange={(e) => this.setState({ question: e.target.value })}
            />
          </FormGroup>
        </div>
        {this.renderActions()}
      </div>
    );
  }

  renderPromptOptions() {
    const { lens } = this.props;
    const { advancedFilter } = this.state;
    const { option, riskValue, id, isDefault } = advancedFilter;

    return (
      <div className="lens-property-block">
        <div className="lens-editior-title">
          <Icon name={!this.editingFilter ? 'plus2' : 'edit'} className="condition-icon" />
          {!this.editingFilter ? 'Add scoring factor' : 'Edit scoring factor'}
          {this.editingFilter && !isDefault && (
            <ButtonIcon
              icon="trash"
              className="delete-target-range"
              size="default"
              onClick={() => this.setState({ deleting: true })}
            />
          )}
        </div>
        <div className="lens-property">
          <div className="lens-property-label">Option</div>

          <div className="variable-options">
            <FormControl
              bsSize="small"
              componentClass="textarea"
              value={option}
              placeholder="Enter an option"
              disabled={!!isDefault}
              onChange={(e) =>
                this.setState({
                  advancedFilter: { id, riskValue, riskValue, option: e.target.value, isDefault: !!isDefault },
                })
              }
              data-cy="lens-option"
            />
          </div>
        </div>
        <div className="lens-property">
          <div className="lens-property-label">Risk Score</div>

          <div className="variable-options" data-cy="variable-options">
            <FormGroup className="risk-value dmp-validator-container">
              <FormControl
                bsSize="small"
                value={riskValue}
                placeholder="Enter an integer"
                onChange={(e) =>
                  this.setState({ advancedFilter: { id, riskValue: e.target.value, option, isDefault: !!isDefault } })
                }
                data-cy="lens-risk-score"
              />
              <Validator
                validateEmpty
                value={riskValue}
                validate={this.validateRiskValue}
                onResult={_.noop}
                validTip="Valid risk value"
                invalidTip="Must be a valid integer"
              />
            </FormGroup>
          </div>
        </div>
        {this.renderActions()}
      </div>
    );
  }

  renderActions() {
    return (
      <div className="actions">
        <Button className="cancel" dmpStyle="link" size="small" onClick={this.cancel} data-cy="btn-cancel-var">
          Cancel
        </Button>

        <Button className="save" size="small" disabled={this.canSave} onClick={this.save} data-cy={'btn-save-lens'}>
          Save
        </Button>
      </div>
    );
  }

  async delete() {
    const { deal, lens, onHide } = this.props;
    const { advancedFilter } = this.state;

    delete lens.advancedFilters[advancedFilter.id];
    await Fire.saveLens(deal.dealID, lens);
    onHide();
  }

  get canSave() {
    const { lens, editingConfig, deal } = this.props;
    const { title, advancedFilter, question } = this.state;

    if (lens.isCreating || editingConfig) {
      return title.length < 1 || question.length < 1;
    } else {
      return !advancedFilter.id || advancedFilter.option?.length < 1;
    }
  }

  get editingFilter() {
    return !!this.props.advancedFilter;
  }

  get addingFilter() {
    return !this.props.advancedFilter;
  }

  render() {
    const { deleting } = this.state;
    const { lens, editingConfig } = this.props;
    const { isCreating } = lens;

    const editConfig = isCreating || editingConfig;

    return (
      <div
        key={lens.id}
        className={cx('edit-lens-container', {
          inline: !editConfig,
        })}
      >
        {editConfig && (
          <div className={cx('lens-editing-title')}>
            <Icon name={lens.title ? 'numbering' : 'lens'} />
            {lens.title || 'Add new lens'}
          </div>
        )}
        {deleting && this.renderDeletionAlert()}
        {editConfig && this.renderTitle()}
        {!editConfig && this.renderPromptOptions()}
      </div>
    );
  }
}

export default LensAdvancedEditor;
