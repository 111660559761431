import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FormControl, FormGroup } from 'react-bootstrap';

import Party from '@core/models/Party';

import { Button, DropdownDots, Form, Loader, MenuItem } from '@components/dmp';
import ButtonToolbar from '@components/dmp/ButtonToolbar';

import DealPanelItem from '@components/deal/DealHeader/DealPanelItem';
import TooltipButton from '@components/editor/TooltipButton';
import Fire from '@root/Fire';

@autoBindMethods
export default class DealUserBlock extends Component {
  static propTypes = {
    party: PropTypes.instanceOf(Party).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      saving: false,
      partyName: '',
    };
  }

  componentDidMount() {
    const { party } = this.props;
    this.setState({ partyName: party.displayName });
  }

  get variable() {
    const { party } = this.props;
    return party.deal.variables[party.partyID];
  }

  get removalTip() {
    const { party } = this.props;
    const { deal } = party;

    if (_.filter(deal.pdfElements, { variable: party.partyID }).length > 0) {
      return 'There are already eSignature fields assigned to this Party';
    }

    if (deal.getUsersByParty(party.partyID).length > 0) {
      return 'There are already users assigned to this Party';
    }

    return '';
  }

  get actions() {
    const { party } = this.props;
    const tip = this.removalTip;

    return (
      <DropdownDots pullRight className="party-actions" id={`dd-party-${party.key}`} onSelect={this.handleAction}>
        <MenuItem key="edit" eventKey="edit">
          Rename
        </MenuItem>
        <MenuItem key="delete" eventKey="delete" disabled={!!tip}>
          <TooltipButton tip={tip} placement="left" disabled={!tip}>
            <span>Remove</span>
          </TooltipButton>
        </MenuItem>
      </DropdownDots>
    );
  }

  handleAction(action) {
    switch (action) {
      case 'edit':
        this.setState({ editing: true });
        break;
      case 'delete':
        this.deleteParty();
        break;
    }
  }

  async updateParty(e) {
    const { party } = this.props;
    const partyName = this.state.partyName.trim();

    const json = this.variable.json;

    if ((!e || e.key === 'Enter') && partyName) {
      json.displayName = partyName;
      await this.setState({ saving: true });
      await Fire.saveVariableDefinition(party.deal, json);
      this.setState({ saving: false, editing: false });
    }
  }

  deleteParty() {
    const { party } = this.props;
    Fire.deleteVariable(party.deal, this.variable);
  }

  renderEditing() {
    const { partyName, saving } = this.state;

    return (
      <Form>
        <FormGroup>
          <FormControl
            onChange={(e) => this.setState({ partyName: e.target.value })}
            onKeyDown={this.updateParty}
            placeholder="e.g., Company"
            type="text"
            bsSize="small"
            value={partyName}
          />
          <ButtonToolbar align="between">
            <ButtonToolbar.Group>
              {saving && <Loader />}
              <div className="spacer" />
              <Button size="small" dmpStyle="link" onClick={() => this.setState({ editing: false })} disabled={saving}>
                Cancel
              </Button>
              <Button
                size="small"
                dmpStyle="primary"
                disabled={!partyName.trim() || saving}
                onClick={() => this.updateParty()}
              >
                Save
              </Button>
            </ButtonToolbar.Group>
          </ButtonToolbar>
        </FormGroup>
      </Form>
    );
  }

  renderViewing() {
    const { party } = this.props;

    return (
      <div className="topline">
        <div className="item-label name">{party.displayName}</div>
        <div className="topline-actions">{this.actions}</div>
      </div>
    );
  }

  render() {
    const { editing } = this.state;

    return (
      <DealPanelItem className="deal-party-block" borderBottom active={editing} data-cy="deal-party-block">
        {editing ? this.renderEditing() : this.renderViewing()}
      </DealPanelItem>
    );
  }
}
