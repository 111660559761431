import { isArray, isPlainObject, map, transform } from 'lodash';

import DealConnection from '../../models/DealConnection';
import { ValueType } from '../../models/Variable';

export const META_FIELD_TYPES = {
  BOOLEAN: 'b',
  DATE: 'dt',
  STRING: 's',
  TEXT: 't',
  OBJECT: 'o',
  NESTED: 'n',
  FLOAT: 'f',
};

const getMetaFieldType = (field) => field?.type || field;

export const convertMetaToES = (meta = {}, fields) => {
  if (!fields) throw new Error(`You must specify fields for: ${JSON.stringify(meta)}`);

  return transform(meta, (newMeta, value, key) => {
    //do not index fields that are not defined in the META_FIELDS in Deal, Team and User Records.
    if (fields[key]) {
      const metaField = fields[key];
      const newKey = `${key}_${getMetaFieldType(metaField)}`;
      let newValue = value;

      // Support nested values
      if (isPlainObject(value) && metaField.children) {
        newValue = convertMetaToES(newValue, metaField.children);
      } else if (isArray(value) && metaField.children) {
        newValue = map(value, (item) => {
          // Handle arrays of objects + deal connections... Not a isPlainObject
          if (isPlainObject(item) || item instanceof DealConnection) {
            return convertMetaToES(item, metaField.children);
          }

          return item;
        });
      }

      newMeta[newKey] = newValue;
    }
  });
};

export const convertMetaFromES = (meta) => {
  return transform(meta, (newMeta, value, key) => {
    const newKey = key.split('_')[0];
    let newValue = value;

    // Support nested values
    if (isPlainObject(value)) {
      newValue = convertMetaFromES(newValue);
    } else if (isArray(value)) {
      newValue = map(value, (item) => {
        // Handle arrays of objects
        if (isPlainObject(item)) {
          return convertMetaFromES(item);
        }

        return item;
      });
    }

    newMeta[newKey] = newValue;
  });
};

export const convertDealMetaFromES = (meta) => {
  let dealMeta = convertMetaFromES(meta);
  if (dealMeta.variables) {
    dealMeta.variables = map(dealMeta.variables, (variable) => {
      if (variable.valueType === ValueType.TABLE) {
        try {
          variable.value = JSON.parse(variable.value);
        } catch (err) {
          variable.value = null;
        }
      }
      return variable;
    });
  }

  return dealMeta;
};
