import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { sortableHandle } from 'react-sortable-hoc';

import { classNamePrefixer } from '@core/utils';

import { Icon } from '@components/dmp'

const cl = classNamePrefixer('drag-handle');

const DragHandle = sortableHandle(({ className, disabled }) => (
  <div className={cx(cl(), className, { disabled })}>
    <Icon name="dragndrop"/>
  </div>
));

DragHandle.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

DragHandle.defaultProps = {
  disabled: false,
};

export default DragHandle;
