import React from 'react';

import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { SafeAnchor } from 'react-bootstrap';

import { classNamePrefixer } from '@core/utils';

import { Button, Ellipsis, Icon } from '@components/dmp';

// This should be converted to a @dmp component and updated in the whole app.
import TooltipButton from '@components/editor/TooltipButton';

const cl = classNamePrefixer('dd-toggle');

const DropdownToggle = React.forwardRef(
  (
    {
      noCaret,
      open,
      useAnchor,
      className,
      icon,
      loading,
      title,
      width,
      iconOnly,
      tip,
      showErrorState,
      ...passingProps
    },
    ref
  ) => {
    const classNames = cx(
      'dropdown-toggle', // Required, to avoid altering border becaue of btn-group (bootstrap)
      cl(),
      className,
      { [cl('error')]: showErrorState }
    );

    // This is important, otherise, we won't get the proper Button styles.
    delete passingProps.bsClass;
    delete passingProps.bsRole;

    // Just use the button for now.
    const Component = useAnchor ? SafeAnchor : Button;
    const useCaret = !noCaret;

    // Props that only get passed to the Button component
    const extraProps = !useAnchor ? { icon, iconOnly } : {};

    const togglecomponent = (
      <Component
        {...passingProps}
        {...extraProps}
        ref={ref}
        role="button"
        className={classNames}
        loading={loading}
        aria-haspopup
        aria-expanded={open}
        style={{ width }}
      >
        {title ? <Ellipsis>{title}</Ellipsis> : null}
        {useCaret && <Icon className={cl('caret')} name="chevronSmall" />}
      </Component>
    );

    if (tip) {
      return <TooltipButton {...tip}>{togglecomponent}</TooltipButton>;
    }

    return togglecomponent;
  }
);

DropdownToggle.displayName = 'DropdownToggle';

DropdownToggle.defaultProps = {
  noCaret: false,
  open: false,
  useAnchor: false,
  bsRole: 'toggle',
  icon: null,
  iconOnly: false,
  loading: false,
  tip: null,
};

DropdownToggle.propTypes = {
  noCaret: PropTypes.bool,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  title: PropTypes.node,
  useAnchor: PropTypes.bool,
  icon: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), //100 or "100%"
  iconOnly: PropTypes.bool,
  // Copied from <TooltipButton />
  tip: PropTypes.shape({
    delay: PropTypes.number,
    disabled: PropTypes.bool,
    placement: PropTypes.string,
    positionTop: PropTypes.number,
    positionLeft: PropTypes.number,
    tip: PropTypes.node,
    tipID: PropTypes.string,
    trigger: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
};

export default DropdownToggle;
