import React, { Component } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';
import qs from 'query-string';

import { Redirect } from 'react-router-dom';

import SearchParams from '@core/models/SearchParams';
import { canHaveTeam } from '@core/models/User';

import Connect from '@components/connect/Connect';
import Dealer from '@root/Dealer';
import NavDash from '@root/nav/NavDash';
import DealsPage from '@routes/DealsPage';
import Teams from '@routes/Teams';
import TemplatesList from '@routes/TemplatesList';
import TotalBatch from '@routes/TotalBatch';
import WelcomePage from '@routes/WelcomePage';

export default class Dashboard extends Component {
  static defaultProps = {
    subscription: null,
    teams: [],
  };

  static propTypes = {
    subscription: PropTypes.object,
    teams: PropTypes.array,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  };

  constructor(props) {
    const { location, history } = props;

    super(props);

    this.state = {
      searchParams: new SearchParams(location, history),
    };
  }

  componentDidMount() {
    this.loadSearchParams();
  }

  componentDidUpdate(prevProps) {
    // Anytime querystring changes, generate new SearchParams and pass down to child components
    if (!_.isEqual(_.get(prevProps, 'location'), _.get(this.props, 'location'))) {
      this.loadSearchParams(prevProps);
    }
  }

  /*
    for instance users, the Dashboard page is disabled.
  */
  get canAccess() {
    const {
      match: { params },
      user,
    } = this.props;

    if (!params.page) {
      if (canHaveTeam(user)) {
        return true;
      } else if (user && !user.hasTeams) {
        return false;
      }
    }

    return true;
  }

  loadSearchParams(prevProps) {
    const { location, history, user } = this.props;
    const params = qs.parse(location.search);
    const prevParams = qs.parse(_.get(prevProps, 'location.search', ''));

    // If the filterID changes on the URL, load the stored SearchParams from that report in instead
    // This way we can just assign to the filterID param
    // Which will trigger another URL update (and rerender) below
    if (user && user.id && params.filterID && params.filterID !== _.get(prevParams, 'filterID')) {
      const filter = _.find(user.filters, { filterID: params.filterID });
      if (filter) {
        const path = `${location.pathname}?${qs.stringify(filter.searchParams)}`;

        // We want to use replace() instead of push() because the URL state with just the filterID is a trigger, not an endpoint
        // So hitting back should skip over it
        history.replace(path);

        // eslint-disable-next-line no-console
        console.log(`[SEARCH] - Loaded SearchParams into URL from Saved Filter [${params.filterID}] (${filter.title})`);

        // We can return here without setting state, because the replace() triggers another rerender.
        // On subsequent rerender this will not fire again because the prevParams.filterID will have already been set
        return;
      }
    }

    this.setState({ searchParams: new SearchParams(location, history) });
  }

  render() {
    const { user } = this.props;

    if (!_.get(user, 'id')) return null;

    return (
      <main className="dashboard" data-cy="dashboard">
        {/*On mobile this is already covered in global nav so only need on desktop*/}
        {!Dealer.mobile && <NavDash {...this.props} />}

        <div className="main contracts-list" data-cy="contracts-list">
          {this.renderPage()}
        </div>
      </main>
    );
  }

  renderPage() {
    const {
      match: { params },
    } = this.props;
    const { searchParams } = this.state;

    // If we're at the root of /dashboard
    if (!this.canAccess) {
      return <Redirect to={{ pathname: `/dashboard/contracts` }} />;
    }

    if (!params.page) {
      return <WelcomePage {...this.props} />;
    }

    switch (params.page) {
      case 'contracts':
        return <DealsPage {...this.props} searchParams={searchParams} />;
      case 'templates':
        return <TemplatesList {...this.props} searchParams={searchParams} />;
      case 'teams':
      case 'team':
        return <Teams {...this.props} />;
      case 'batch':
        return <TotalBatch {...this.props} />;
      case 'connect':
        return <Connect {...this.props} />;
      default:
        return null;
    }
  }
}
