import { assign, find, get, merge, pick } from 'lodash';

import DateFormatter from '../utils/DateFormatter';
import { ENTITY_TYPE } from './Content';

export default class Diff {
  // Similar to the pattern in Section.deal; giving a reference to the "parent" Section enables better encapsulation,
  // so the Diff can lookup things itself in getters below
  section = null;

  id = null;
  user = null;
  type = null;
  entityKey = null;

  // If this Diff is a rejection of another Diff
  // priorDiff will be the previous Diff's id for lookup in order to dynamically generate Diff.history
  priorDiff = null;

  // We're using a "factory" function here because the Diff class basically wraps DraftEntityInstance,
  // in order to decouple (or at least better manage the coupling between) Outlaw's model and DraftJS DraftEntityInstance.
  // We want to store DraftJS's entityKey *inside* of the Diff,
  // but that is not assigned until DraftJS creates the entity (/facepalm)
  // So, we create the Diff, use the data to create the entity (with data), then use mergeEntityData to push in the entityKey
  // IMPORTANT: this modifies the ContentState record passed in,
  // which is what we want, as it now includes the new DraftEntityInstance record in the entityMap...
  // BUT it does not *apply* the entity to any text, as doing so would require a SelectionState
  static create(section, { contentState, user, type, id = null, mutability = 'MUTABLE', priorDiff }) {
    const diff = new Diff({ user, type, priorDiff, id }, section);
    contentState = contentState.createEntity(type, mutability, diff.entityData);
    diff.entityKey = contentState.getLastCreatedEntityKey();
    contentState = contentState.mergeEntityData(diff.entityKey, {
      entityKey: diff.entityKey,
    });
    return diff;
  }

  // We're wrapping ContentState.getEntity() here, for two reasons:
  // 1. It throws if an entity is not found, which is annoying
  // 2. What we want back is an instance of our Diff model, instead of DraftEntityInstance,
  // in order to limit how much the calling component needs to interface directly with DraftJS
  static get(section, contentState, entityKey) {
    if (!entityKey) return null;
    let entity;
    try {
      entity = contentState.getEntity(entityKey);
      // Diffs saved prior to this Diff model existing will be missing a few properties in entityData,
      // and stored 'date' property instead of 'id' (even though it's the same)
      // but we can tack them on here... and for new Diffs, values will be the same anyway
      const id = get(entity, 'data.date') || get(entity, 'data.id');
      return new Diff(merge(entity.data, { type: entity.type, entityKey, id }), section);
    } catch (e) {
      return null;
    }
  }

  // https://github.com/facebook/draft-js/issues/998
  static getAll(section, contentState) {
    if (!contentState) return [];
    const diffs = [],
      keys = [];

    contentState.getBlockMap().forEach((block) => {
      block.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        if (entityKey) {
          if (keys.includes(entityKey)) return;
          const entity = contentState.getEntity(entityKey);
          if ([ENTITY_TYPE.DIFF_ADDED, ENTITY_TYPE.DIFF_REMOVED].includes(entity.type)) {
            const diff = Diff.get(section, contentState, entityKey);
            if (diff) {
              diffs.push(diff);
              keys.push(entityKey);
            }
          }
        }
      });
    });
    return diffs;
  }

  constructor({ user, type, id = null, entityKey = null, priorDiff = null }, section) {
    this.section = section;
    assign(this, { user, id, type, entityKey, priorDiff });

    // Diffs are assigned IDs based on timestamp
    if (!id) {
      this.id = new Date().getTime().toString();
    }

    // Normally a time-based ID would be fine to generate on creation,
    // but there is one edge case where 2 Diffs can be generated at the exact same time;
    // This is if a user highlights existing text and begins typing,
    // which simultaneously generates both an 'added' and 'removed' Diff,
    // and on a fast CPU it can happen at the same millisecond.
    // so this ensures uniqueness of ID
    if (!this.id.includes('|')) {
      this.id += `|${type}`;
    }
  }

  // Another reason for wrapping DraftEntityInstances is that they can never be changed after creation, which is super annoying
  // So we're making them all 'MUTABLE' to enable the actual text to change in certain cases
  // But really we enforce mutability here via logic:
  // A Diff is only mutable if it is new; i.e., currently being edited by the current user
  // There is a subtle edge case here which is why we're not using the isPending getter --
  // If user has already saved a Version with Diffs, but then goes in and edits again (prior to any responses to the Diff),
  // we want them to be able to still make further modifications to their original changes
  isMutable() {
    return this.isMine && this.history.length === 1;
  }

  // This is the stuff that actually gets stored in DraftEntityInstance.data
  // And enables us to reconstruct Diffs via Diff.get above
  get entityData() {
    return pick(this, ['id', 'user', 'type', 'entityKey', 'priorDiff']);
  }

  get date() {
    const time = this.id.split('|')[0];
    return new Date(parseInt(time));
  }

  // This is very similar to the Deal constructor, where parent/child relationships are dynamically established based on ids
  // This allows us to link Diffs together as threaded responses to each other via the piorDiff property
  get history() {
    let diff = this;
    const diffs = [];

    while (diff) {
      diffs.push(diff);
      if (diff.priorDiff) {
        diff = find(this.section.diffs, { id: diff.priorDiff });
      } else {
        diff = null;
      }
    }
    // Reverse order so that it's chronological, i.e., current last
    return diffs.reverse();
  }

  get displayAction() {
    return this.type;
  }

  get displayDate() {
    return `${DateFormatter.mdy(this.date)} @ ${DateFormatter.time(this.date)}`;
  }

  // Currently the only way to generate threaded history is to keep rejecting each other's Diffs
  // So the displayable action is always "rejection" regardless of the Diff.type...
  // but this can easily expand, e.g., to include comments or approvals
  get pendingAction() {
    return 'rejection';
  }

  // User can undo if it's either a new Diff or user's own diff that was saved without dispute
  get canUndo() {
    if (!this.isMine) return false;
    return !this.priorDiff;
  }

  get isMine() {
    const me = this.section.deal.currentDealUser;
    return me && this.user === me.uid;
  }

  get canApprove() {
    return this.section.deal.isOwner;
  }

  // Because Section.constructor examines all existing stored ContentState data in Version.body,
  // we can use this to know whether the current Diff has actually been saved or not
  get isPending() {
    return !find(this.section.diffs, { id: this.id });
  }

  // Reverting takes the place of Undo if this Diff was actually a response (i.e., a rejection) of a previous Diff
  get canRevert() {
    return this.priorDiff && this.user === get(this.section, 'deal.currentDealUser.uid');
  }

  get dealUser() {
    return this.section.deal.getUserByID(this.user);
  }

  get displayName() {
    const dealUser = this.dealUser;

    if (this.isMine) {
      return 'You';
    } else if (dealUser) {
      return dealUser.fullName || dealUser.email;
    } else {
      return 'A deleted user';
    }
  }
}
