import _ from 'lodash';

export default class BoundingBox {
  x;
  y;
  width;
  height;
  page;
  continuesAfter;
  continuesBefore;

  constructor({
    x, 
    y, 
    width, 
    height, 
    page, 
    continuesBefore = false, 
    continuesAfter = false,
  }) {
    _.assign(this, {x, y, width, height, page, continuesBefore, continuesAfter});
  }
}