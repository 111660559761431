const SectionType = {
  ROOT: 'ROOT',

  PAYMENT: 'PAYMENT',
  SCOPE: 'SCOPE',
  PARTIES: 'PARTIES',
  SUMMARY: 'SUMMARY',
  ITEM: 'ITEM',
  COLUMNS: 'COLUMNS',

  HEADER: 'HEADER',
  SOURCE: 'SOURCE',
  APPENDIX: 'APPENDIX',
  LIST: 'LIST',
  LINKED: 'LINKED',
  SIGNATURE: 'SIGNATURE',
  CAPTION: 'CAPTION',

  EXTERNAL: 'EXTERNAL',

  TEMPLATE_FOOTER: 'TEMPLATE_FOOTER',
  TEMPLATE_HEADER: 'TEMPLATE_HEADER',
};

export const HEADER_FOOTER_CONFIG_OPTIONS = {
  ALL_PAGES: 'allPages',
  NO_PAGES: 'noPages',
  FIRST_PAGE: 'firstPage',
  PAGE_BREAK: 'pageBreak',
};

export const HEADER_FOOTER_CONFIG_TYPES = ['default', 'section', 'appendix', 'signature'];

export const HEADER_FOOTER_CONFIG = [
  {
    key: 'allPages',
    displayName: 'From document start',
    type: 'default',
  },
  {
    key: 'noPages',
    displayName: 'Do not print',
    type: 'default',
  },
  {
    key: 'firstPage',
    displayName: 'First page only',
    type: 'default',
  },
];

export const HEADER_FOOTER_NUMBERING = [
  {
    key: 'unformatted',
    title: '1',
    type: 'single-number',
    format: (pageNumber) => {
      return pageNumber;
    },
  },
  {
    key: 'parentheses',
    title: '(1)',
    type: 'single-number',
    format: (pageNumber) => {
      return `(${pageNumber})`;
    },
  },
  {
    key: 'dashed',
    title: '-1-',
    type: 'single-number',
    format: (pageNumber) => {
      return `-${pageNumber}-`;
    },
  },
  {
    key: 'written',
    title: 'Page 1',
    type: 'single-number',
    format: (pageNumber) => {
      return `Page ${pageNumber}`;
    },
  },
  {
    key: 'unformatted',
    title: 'i',
    type: 'numeral',
    format: (pageNumber) => {
      return pageNumber === '#' ? 'i' : `${'i'.repeat(pageNumber)}`;
    },
  },
  {
    key: 'parentheses',
    title: '(i)',
    type: 'numeral',
    format: (pageNumber) => {
      return pageNumber === '#' ? '(i)' : `(${'i'.repeat(pageNumber)})`;
    },
  },
  {
    key: 'dashed',
    title: '-i-',
    type: 'numeral',
    format: (pageNumber) => {
      return pageNumber === '#' ? '-i-' : `-${'i'.repeat(pageNumber)}-`;
    },
  },
  {
    key: 'unformatted',
    title: '1/2',
    type: 'total-number',
    format: (pageNumber, totalPages) => {
      return `${pageNumber}/${totalPages}`;
    },
  },
  {
    key: 'of',
    title: '1 of 2',
    type: 'total-number',
    format: (pageNumber, totalPages) => {
      return `${pageNumber} of ${totalPages}`;
    },
  },
  {
    key: 'written',
    title: 'Page 1 of 2',
    type: 'total-number',
    format: (pageNumber, totalPages) => {
      return `Page ${pageNumber} of ${totalPages}`;
    },
  },
];

export const HEADER_FOOTER_SUB_SECTION_TYPES = {
  ONE_COL: 'ONE_COL',
  TWO_COL: 'TWO_COL',
  THREE_COL: 'THREE_COL',
};

export const CANDIDATE_TYPE = {
  SIGNATURE: 'SIGNATURE',
};

SectionType.summarySections = [SectionType.SUMMARY, SectionType.PAYMENT, SectionType.SCOPE, SectionType.PARTIES];

SectionType.sourceSections = [
  SectionType.SOURCE,
  SectionType.HEADER,
  SectionType.CAPTION,
  SectionType.LIST,
  SectionType.APPENDIX,
  SectionType.LINKED,
  SectionType.SIGNATURE,
];

// Types that are included in Contract output
// Note ITEM is not included, but those are nested inside of LIST and APPENDIX sections
SectionType.src = (type) => {
  return SectionType.sourceSections.includes(type);
};

// Types that are included in the Overview layer
SectionType.overview = (type) => {
  return SectionType.summarySections.includes(type);
};

// Types on which redlining is supported
SectionType.trackable = (type) => {
  return [SectionType.SOURCE, SectionType.APPENDIX, SectionType.LINKED, SectionType.ITEM, SectionType.CAPTION].includes(
    type
  );
};

// Types that are supported for indexing/lookup in Clause Library
SectionType.cl = (type) => {
  return [SectionType.SUMMARY, SectionType.SOURCE, SectionType.TEMPLATE_FOOTER, SectionType.TEMPLATE_HEADER].includes(
    type
  );
};

// Legacy
SectionType.ui = (type) => {
  return [SectionType.SCOPE, SectionType.PAYMENT].includes(type);
};

// Types that must occur contiguously at end of contract
SectionType.special = (type) => {
  return [SectionType.APPENDIX, SectionType.SIGNATURE].includes(type);
};

export default SectionType;
