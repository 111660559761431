import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import PropTypes from 'prop-types';

import { ControlLabel, FormGroup, Modal } from 'react-bootstrap';

import SectionType from '@core/enums/SectionType';
import Template from '@core/models/Template';
import { ValueType, VariableType } from '@core/models/Variable';
import { DateFormatter, formatNumber } from '@core/utils';
import { Dt } from '@core/utils';

import { Alert, Button } from '@components/dmp';

import API from '@root/ApiClient';

const COMPLEXITY_SCORING = {
  variables: 5,
  simpleVariables: 5,
  tableVariables: 20,
  calculatedVariables: 20,
  connectedVariables: 5,
  sections: 5,
  sourceSections: 5,
  listSections: 10,
  appendixSections: 10,
  sectionConditionals: 100,
};

const COMPLEXITY_SCORE_RISK = {
  LOW: { score: 0, displayStyle: 'info', icon: 'circleCheck' },
  MEDIUM: { score: 10000, displayStyle: 'warning', icon: 'exclamation' },
  HIGH: { score: 20000, displayStyle: 'danger', icon: 'exclamation' },
  PROBLEMATIC: {
    score: 50000,
    displayStyle: 'danger',
    icon: 'exclamation',
    message: (
      <div>
        <b>
          Your contract complexity score is too high!
          <br />
        </b>
        This will result in a really slow experience for you and your customers.
        <br />
        Please reduce the number of conditionals to lower your complexity score.
        <br />
        <br />
      </div>
    ),
  },
};

function copyInfo(text) {
  let textarea = document.createElement('textarea');
  textarea.textContent = text;
  document.body.appendChild(textarea);

  var selection = document.getSelection();
  var range = document.createRange();
  range.selectNode(textarea);
  selection.removeAllRanges();
  selection.addRange(range);
  document.execCommand('copy');
  selection.removeAllRanges();
  document.body.removeChild(textarea);
}

@autoBindMethods
export default class ContractInfo extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    deal: PropTypes.object.isRequired,
    team: PropTypes.object,
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      dateAndTime: null,
      complexity: null,
      instance: null,
      isCopied: false,
      templateTitle: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { deal, show, user } = this.props;
    const dealTeamID = deal.team;

    if (show && !prevProps.show) {
      this.setState({ isCopied: false });
      this.getInstance();
      this.getDateAndTime(new Date());
      if (user.isAdmin) this.getDealComplexity();
      if (deal && user.teams && user.teams[dealTeamID]) this.dealTemplate(dealTeamID);
    }
  }

  copyToClipBoard() {
    const { deal, user, team } = this.props;
    const { templateTitle, instance, dateAndTime } = this.state;
    const copyContent = [
      `Instance: ${instance}`,
      `Team: ${team ? team.info.name : ''}`,
      `Team ID: ${team ? deal.team : ''}`,
      `Template: ${templateTitle}`,
      `Template ID: ${deal.info.sourceTemplate}`,
      `${Dt} ID: ${deal.dealID}`,
      `User: ${user.email}`,
      `User ID: ${user.id}`,
      `IP Address: ${user.ip || 'n/a'}`,
      `Location: ${user.location || 'n/a'}`,
      `Date and time: ${dateAndTime}`,
    ].join('\n');

    copyInfo(copyContent);
    this.setState({ isCopied: true });
    setTimeout(() => {
      this.setState({ isCopied: false });
    }, 2000);
  }

  async dealTemplate(teamID) {
    const { deal } = this.props;
    API.call('getTeamTemplates', { teamID, activeOnly: true }, async (raw) => {
      const templates = _.map(raw, (json) => new Template(json, json.dealID));
      const matchedTemplate = _.find(templates, { key: deal.info.sourceTemplate });
      await this.setState({ templateTitle: matchedTemplate?.title });
    });
  }

  getInstance() {
    if (window.location != window.parent.location) {
      const referrer = document.referrer;
      const url = new URL(referrer);
      this.setState({
        instance: url.host,
      });
    } else {
      this.setState({
        instance: document.location.host,
      });
    }
  }

  getDateAndTime(date) {
    const offset = DateFormatter.getOffset(date) / 60000;
    const offsetString = `${offset > 0 ? '+' : '-'}${('0' + Math.abs(offset / 60)).slice(-2)}:${(
      '0' + Math.abs(offset % 60)
    ).slice(-2)}`;
    this.setState({
      dateAndTime: `${DateFormatter.mdy(date)} @ ${DateFormatter.time(date)} (${offsetString} GMT)`,
    });
  }

  getDealComplexity() {
    const { deal } = this.props;
    const complexity = { score: 0 };

    // Variables
    const variables = Object.values(deal.variables || {});
    const simpleVariables = variables.filter((variable) => variable.type === VariableType.SIMPLE);
    complexity.variables = variables.length;
    complexity.simpleVariables = simpleVariables.length;
    complexity.tableVariables = simpleVariables.filter((variable) => variable.valueType === ValueType.TABLE).length;
    complexity.calculatedVariables = variables.filter((variable) => variable.type === VariableType.CALCULATED).length;
    complexity.connectedVariables = variables.filter((variable) => variable.type === VariableType.CONNECTED).length;

    // Sections
    const sections = Object.values(deal.sections || {});
    complexity.sections = sections.length;
    complexity.sourceSections = sections.filter((section) => section.type === SectionType.SOURCE).length;
    complexity.listSections = sections.filter((section) => section.type === SectionType.LIST).length;
    complexity.appendixSections = sections.filter((section) => section.type === SectionType.APPENDIX).length;

    complexity.sectionConditionals = 0;
    sections.forEach((section) => {
      if (section.conditions) complexity.sectionConditionals += section.conditions.length;
    });

    // Build a score based on how complex the Contract structure is.
    // This is a V1 attempt, we will fine tune as we have a better idea about
    // what makes a Contract more or less complex.
    Object.entries(COMPLEXITY_SCORING).forEach(([scoreType, modifier]) => {
      complexity.score += complexity[scoreType] * modifier;
    });

    Object.entries(COMPLEXITY_SCORE_RISK).forEach(([scoreRisk, { score }]) => {
      if (complexity.score >= score) complexity.scoreRisk = scoreRisk;
    });

    this.setState({ complexity });
  }

  render() {
    const { show, close, deal, user, team } = this.props;
    const { isCopied, templateTitle, instance, dateAndTime, complexity } = this.state;

    if (!show) return null;

    return (
      <>
        <Modal dialogClassName="deal-info" show={show} onHide={close}>
          <Modal.Header closeButton>
            <span className="headline">{Dt} Info</span>
          </Modal.Header>
          <Modal.Body>
            <div className="wrapper">
              <FormGroup>
                <ControlLabel>Instance</ControlLabel>
                <div className="contents" data-cy="deal-info-instance">
                  {instance}
                </div>
              </FormGroup>

              <FormGroup>
                <ControlLabel>Team</ControlLabel>
                <div className="contents" data-cy="deal-info-team">
                  {team ? team.info.name : ''}
                  <small>{team ? deal.team : ''}</small>
                </div>
              </FormGroup>

              <FormGroup>
                <ControlLabel>Template</ControlLabel>
                <div className="contents" data-cy="deal-info-template">
                  {templateTitle}
                  <small>{deal.info.sourceTemplate}</small>
                </div>
              </FormGroup>

              <FormGroup>
                <ControlLabel>{Dt}</ControlLabel>
                <div className="contents" data-cy="deal-info-id">
                  {deal.dealID}
                </div>
              </FormGroup>

              <FormGroup>
                <ControlLabel>User</ControlLabel>
                <div className="contents" data-cy="deal-info-user">
                  {user.email}
                  <small>{user.id}</small>
                </div>
              </FormGroup>

              <FormGroup>
                <ControlLabel>IP address</ControlLabel>
                <div className="contents" data-cy="deal-info-ip">
                  {user.ip || 'n/a'}
                  <small>{user.location || 'n/a'}</small>
                </div>
              </FormGroup>

              <FormGroup>
                <ControlLabel>Date and time</ControlLabel>
                <div className="contents" data-cy="deal-info-date">
                  {dateAndTime}
                </div>
              </FormGroup>

              {user.isAdmin && complexity && (
                <Alert
                  inline
                  icon={COMPLEXITY_SCORE_RISK[complexity.scoreRisk].icon}
                  title={`Complexity score: ${formatNumber(complexity.score)}`}
                  dmpStyle={COMPLEXITY_SCORE_RISK[complexity.scoreRisk].displayStyle}
                  size="small"
                >
                  {COMPLEXITY_SCORE_RISK[complexity.scoreRisk].message || null}
                  Variables: {formatNumber(complexity.variables)}
                  <br />
                  Simple Variables: {formatNumber(complexity.simpleVariables)}
                  <br />
                  Table Variables: {formatNumber(complexity.tableVariables)}
                  <br />
                  Calculated Variables: {formatNumber(complexity.calculatedVariables)}
                  <br />
                  Connected Variables: {formatNumber(complexity.connectedVariables)}
                  <br />
                  Sections: {formatNumber(complexity.sections)}
                  <br />
                  Source Sections: {formatNumber(complexity.sourceSections)}
                  <br />
                  List Sections: {formatNumber(complexity.listSections)}
                  <br />
                  Appendix Sections: {formatNumber(complexity.appendixSections)}
                  <br />
                  Section Conditionals: {formatNumber(complexity.sectionConditionals)}
                </Alert>
              )}
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button data-cy="btn-copy-info" disabled={isCopied} onClick={this.copyToClipBoard}>
              {isCopied ? 'Copied' : 'Copy information to clipboard'}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
