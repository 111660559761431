import React, { Component, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ControlLabel, FormControl, FormGroup, InputGroup, Modal } from 'react-bootstrap';

import { FEATURES } from '@core/models/Team';
import { isAdmin } from '@core/models/User';
import { DateFormatter, Dt } from '@core/utils';

import { Button, Loader, ModalConfirm, Icon } from '@components/dmp';

import API from '@root/ApiClient';

@autoBindMethods
export default class APIKey extends Component {
  static defaultProps = {
    isOwner: false,
  };

  static propTypes = {
    isOwner: PropTypes.bool,
    user: PropTypes.object.isRequired,
    team: PropTypes.object.isRequired,
    reloadTeam: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.apiKeyField = createRef();

    this.state = {
      isActive: false,
      generateKeyError: null,
      isCopying: false,
      isGeneratingKey: false,
      isLoading: false,
      keyData: null,
      showModal: false,
      isDeleting: false,
      isActivating: false,
      showConfirmDelete: false,
      showConfirmDeactivate: false,
    };
  }

  async componentDidMount() {
    await this.setActiveState();
    await this.loadApiKey();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.team.teamID !== this.props.team.teamID) {
      await this.setActiveState();
      this.loadApiKey();
    }
  }

  async setActiveState() {
    const { team } = this.props;
    await this.setState({ isActive: team.isFeatureActive(FEATURES.API) });
  }

  async loadApiKey() {
    const { isOwner, team } = this.props;
    const { isActive } = this.state;

    if (!isActive || !isOwner) return;

    await this.setState({ isLoading: true });

    const apiKey = await API.call('getTeamApiKey', { teamID: team.teamID });

    this.setState({
      apiKey: apiKey || null,
      isLoading: false,
    });
  }

  async handleDelete() {
    const { team } = this.props;

    await this.setState({ isDeleting: true });

    try {
      await API.call('deleteTeamAPIKey', { teamID: team.teamID });
    } catch (err) {
      // Silently catching the error,
      // this only throw when the apiKey does not exist, which is ok.
    }

    this.setState({
      isDeleting: false,
      showConfirmDelete: false,
      apiKey: null,
    });
  }

  copyToClipBoard(ref) {
    ref.select();
    document.execCommand('copy');
    this.setState({ isCopying: true });
    setTimeout(() => {
      this.setState({ isCopying: false });
    }, 1000);
  }

  async generateKey(event) {
    const { team } = this.props;
    this.setState({ isGeneratingKey: true });
    event.stopPropagation();

    try {
      const apiKey = await API.call('createTeamAPIKey', { teamID: team.teamID });
      this.setState({ apiKey, isGeneratingKey: false });
    } catch (err) {
      const errorMessage = "Sorry, we're unable to generate an API key now. Please try again later";
      this.setState({ generateKeyError: errorMessage, isGeneratingKey: false });
    }
  }

  handleHideModal() {
    this.setState({ showModal: false });
  }

  handleConfig() {
    this.setState({ showModal: true });
  }

  async setFeature(isActive) {
    const { reloadTeam, team } = this.props;

    this.setState({ isActivating: true });
    await API.call('setTeamFeature', {
      teamID: team.teamID,
      name: FEATURES.API.key,
      isActive,
    });

    await reloadTeam();
    await this.setActiveState();
    this.setState({ isActivating: false });
  }

  async handleDeactivate() {
    await this.setState({ isActivating: true });
    await this.handleDelete();
    await this.setFeature(false);

    this.setState({ showConfirmDeactivate: false });
  }

  renderModal() {
    const { showModal, generateKeyError, isCopying, isGeneratingKey, apiKey } = this.state;

    const inputFieldValue = apiKey ? apiKey.key : '';
    const dateCreated = apiKey ? new Date(parseInt(apiKey.dateCreated)) : null;

    return (
      <Modal className="modal-apiKey-info" show={showModal} onHide={this.handleHideModal}>
        <Modal.Header closeButton>
          <span className="headline">Configure API Key</span>
        </Modal.Header>

        <Modal.Body>
          <FormGroup>
            <ControlLabel>API Key</ControlLabel>
            <div className="contents">
              <InputGroup>
                <FormControl
                  className="apiKey-secret"
                  inputRef={(key) => (this.apiKeyField = key)}
                  placeholder="Tap 'Generate' to create API Secret"
                  readOnly
                  type="text"
                  value={inputFieldValue}
                />
                <InputGroup.Button>
                  <Button
                    className="copy-button"
                    disabled={!apiKey}
                    onClick={() => this.copyToClipBoard(this.apiKeyField)}
                  >
                    <Icon name="copy"/>
                  </Button>
                </InputGroup.Button>
              </InputGroup>

              {apiKey && (
                <p className="apiKey-desc date-created">
                  {`Date Created: ${DateFormatter.date(dateCreated)} - ${DateFormatter.time(dateCreated)}`}
                </p>
              )}
              {generateKeyError && <p className="apiKey-desc error">{generateKeyError}</p>}
            </div>
          </FormGroup>
          {isCopying && <span className="copied">COPIED</span>}
        </Modal.Body>

        <Modal.Footer>
          {isGeneratingKey && <Loader inline />}
          {!!apiKey && (
            <Button dmpStyle="link-danger" onClick={() => this.setState({ showModal: false, showConfirmDelete: true })}>
              Delete
            </Button>
          )}
          <Button dmpStyle="primary" disabled={!!apiKey} onClick={this.generateKey}>
            Generate
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  renderDeleteModal() {
    const { showConfirmDelete, isDeleting } = this.state;

    return (
      <ModalConfirm
        isLoading={isDeleting}
        show={showConfirmDelete}
        onHide={() => this.setState({ showConfirmDelete: false })}
        onConfirm={this.handleDelete}
        title="Really delete API Key?"
        body={
          <>
            <p>
              This action removes the API Key associated with this team. Any external integrations binded with this key
              will be invalid.
              <br />
              <b>This action cannot be undone.</b>
            </p>
            <p>Are you sure you want to proceed?</p>
          </>
        }
      />
    );
  }

  renderDeactivateModal() {
    const { showConfirmDeactivate, isActivating } = this.state;

    return (
      <ModalConfirm
        isLoading={isActivating}
        show={showConfirmDeactivate}
        onHide={() => this.setState({ showConfirmDeactivate: false })}
        onConfirm={this.handleDeactivate}
        confirmText="Deactivate"
        title="Really deactivate API?"
        body={
          <>
            <p>
              This action deactivate API capabilities for this team.
              <br />
              This action removes the API Key associated with this team.
              <br />
              Any external integrations binded with this key will be invalid.
              <br />
              <b>This action cannot be undone.</b>
            </p>
            <p>Are you sure you want to proceed?</p>
          </>
        }
      />
    );
  }

  renderKey() {
    const { isOwner, user } = this.props;
    const { apiKey, isActive, isActivating, isLoading } = this.state;

    if (!isActive) {
      if (isAdmin(user)) {
        return (
          <div className="table-inner d-flex justify-content-between align-items-center table-inner-disabled">
            Open API is currently disabled for this team.
            {isActivating ? (
              <Loader inline />
            ) : (
              <Button size="small" onClick={() => this.setFeature(true)}>
                Enable
              </Button>
            )}
          </div>
        );
      } else {
        return (
          <div className="table-inner text-disabled" data-cy="api-disabled-msg">
            <em>
              Open API is an advanced feature.
              <br />
              If you want to enable it, please contact your Account Manager.
            </em>
          </div>
        );
      }
    }

    if (!isOwner) {
      <div className="table-inner text-disabled">
        <em>Only team owners can view and configure API Keys.</em>
      </div>;
    }

    let msg = 'You have not configured an API key yet.';
    const dateCreated = _.get(apiKey, 'dateCreated') ? DateFormatter.date(new Date(parseInt(apiKey.dateCreated))) : '';

    if (apiKey) {
      msg = `Generated by ${_.get(apiKey, 'fullName', 'Unknown')} on ${dateCreated}`;
    }

    return (
      <div className="table-inner">
        <div className="s-mob-half s-tab-onethird bold">Key</div>
        <div className="s-mob-half s-tab-twothird">
          <div className="message ellipsis">{isLoading ? <Loader inline /> : msg}</div>
        </div>
      </div>
    );
  }

  render() {
    const { isOwner, user } = this.props;
    const { isActive, isLoading } = this.state;

    return (
      <div className="setting-block api">
        <div className="block-header">
          <h4>API Key</h4>
          <span className="prompt">
            <p>Create, delete and update {Dt}s related to your Outlaw Team using our OpenAPI.</p>
          </span>
        </div>
        <div className="block-content">
          <div className="utility" style={{ minHeight: 33 }}>
            {isActive && isAdmin(user) && (
              <Button dmpStyle="link-danger" onClick={() => this.setState({ showConfirmDeactivate: true })}>
                Deactivate
              </Button>
            )}
            {isActive && isOwner && (
              <Button onClick={this.handleConfig} disabled={isLoading} data-cy="btn-api-config">
                Configure
              </Button>
            )}
          </div>
          <div className="table-outer">{this.renderKey()}</div>
        </div>
        {this.renderModal()}
        {this.renderDeleteModal()}
        {this.renderDeactivateModal()}
      </div>
    );
  }
}
