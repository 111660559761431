import React from 'react';

import { ButtonGroup, FormControl } from 'react-bootstrap';

import DealAction from '@core/enums/DealAction';
import { Triggers, Types, Units } from '@core/models/Payment';

import { Button, DropdownDots, MenuItem } from '@components/dmp';

import API from '@root/ApiClient';
import Fire from '@root/Fire';

import ActivitySection from './ActivitySection';

export default class PaymentSection extends ActivitySection {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      amount: '',
      type: '',
      trigger: 'start',
      unit: 'hour',
      custom: '',
    };
  }

  componentDidMount() {
    this.populate(this.props);
  }
  UNSAFE_componentWillReceiveProps(props) {
    this.populate(props);
  }

  populate(props) {
    const payment = props.payment;
    this.setState({
      editing: !payment.amount, //new payments start out with no amount set, which will show as todo
      amount: payment.amount || '',
      type: payment.type || '',
      trigger: payment.trigger || 'start',
      unit: payment.unit || 'hour',
      custom: payment.custom || '',
    });
  }

  handleAmount(e) {
    this.setState({ amount: e.target.value });
  }
  handleCustom(e) {
    this.setState({ custom: e.target.value });
  }
  handleType(type) {
    this.setState({ type });
  }
  handleUnit(unit) {
    this.setState({ unit });
  }
  handleTrigger(trigger) {
    this.setState({ trigger });
  }

  save(e) {
    e.stopPropagation();

    const payment = {
      type: this.state.type,
      amount: this.state.amount,
      trigger: this.state.type == 'fixed' ? this.state.trigger : null,
      unit: this.state.type == 'variable' ? this.state.unit : null,
      id: this.props.payment.id,
    };
    payment.custom = payment.trigger == 'custom' || payment.unit == 'custom' ? this.state.custom : null;

    const pmt = this.props.payment;

    Fire.savePayment(pmt, payment, async () => {
      this.setState({ editing: false });

      if (pmt.activity.length > 0 || pmt.deal.shared) {
        const activity = await Fire.addActivity(pmt, this.props.user, DealAction.UPDATE, null);
        //tell server to notify other parties of edit
        const args = {
          dealID: pmt.deal.dealID,
          logID: pmt.id,
          activityID: activity.id,
        };
        API.call('sendActivity', args, (r) => console.log(r));
      }
    });
  }
  delete() {
    Fire.deletePayment(this.props.payment);
  }

  cancel(e) {
    e.stopPropagation();
    //if we have a payment ID, just go back to viewing mode
    //if not, we need to remove this (temporary) Payment object from the Deal.payments array
    this.setState({ editing: false });
    if (!this.props.payment.amount) this.delete();
  }

  handleAction(action) {
    //get ref to deal as it's used for several actions
    const deal = this.props.payment.deal;

    switch (action) {
      case 'edit':
        if (this.canEdit && !deal.locked) this.setState({ editing: true });
        break;
      case 'delete':
        this.delete();
        break;
      case 'before':
      case 'after':
        let idx = deal.payments.indexOf(this.props.payment);
        if (action == 'after') idx += 1;
        this.props.create(idx);
        break;
      case 'up':
      case 'down':
        Fire.movePayment(this.props.payment, action);
        break;
      default:
        break;
    }
  }

  renderMenu() {
    const { payment } = this.props;
    const idx = payment.deal.payments.indexOf(payment);
    const count = payment.deal.payments.length;

    return (
      <DropdownDots
        pullRight
        id={`dd-${payment.id}`}
        className="section-menu source-menu"
        onSelect={(action) => this.handleAction(action)}
      >
        <MenuItem eventKey="edit">Edit payment</MenuItem>
        <MenuItem divider />
        <MenuItem eventKey="before">Add payment before</MenuItem>
        <MenuItem eventKey="after">Add payment after</MenuItem>
        <MenuItem divider />
        <MenuItem eventKey="up" disabled={idx <= 0}>
          Move payment up
        </MenuItem>
        <MenuItem eventKey="down" disabled={idx + 1 >= count}>
          Move payment down
        </MenuItem>
        <MenuItem divider />
        <MenuItem eventKey="delete">Delete</MenuItem>
      </DropdownDots>
    );
  }

  renderSourceMode() {
    const { number, payment, noActivity } = this.props;
    return (
      <div className="source-section payment">
        <div className="source-words">
          <div className="source-number" style={payment.deal.style.type.SectionNumber.css}>
            {number}.
          </div>
          <div className="source-text" style={payment.deal.style.type.SectionBody.css}>
            {payment.description}
          </div>
        </div>
        {!noActivity && this.renderActivity(this.refs.activity)}
      </div>
    );
  }

  render() {
    const { payment, user, overviewMode } = this.props;
    const canEdit = payment && user && this.canEdit && !payment.deal.locked;

    let displayClass = 'variable complete';
    if (!canEdit) displayClass += ' readonly';

    if (!overviewMode) return this.renderSourceMode();

    return (
      /*added editing-section class to the payment-section consistent with .source-section, .scope-section*/
      <div
        className={`${this.className} ${this.state.editing ? ' editing-section' : ''} `}
        style={{ marginBottom: payment.deal.style.layout.Section.web.bottom }}
        data-cy="payment-section"
      >
        {this.state.editing && (
          <div className="editing-payment">
            <div className="editing-elements">
              <ButtonGroup className="payment-type" data-cy="payment-type">
                {[...Types.entries()].map((t) => (
                  <Button
                    key={t[0]}
                    bsSize="small"
                    active={this.state.type === t[0]}
                    onClick={() => this.handleType(t[0])}
                    data-cy={`btn-${t[0]}`}
                  >
                    {t[1]}
                  </Button>
                ))}
              </ButtonGroup>

              {this.state.type && (
                <FormControl
                  className="payment-amount"
                  type="text"
                  bsSize="small"
                  value={this.state.amount}
                  placeholder={`e.g., ${this.state.type == 'fixed' ? '$10,000' : '$150'}`}
                  onChange={this.handleAmount.bind(this)}
                  data-cy="payment-amount"
                />
              )}

              {this.state.type == 'fixed' ? (
                <div className="floater">
                  <FormControl
                    className="payment-trigger"
                    componentClass="select"
                    placeholder="select"
                    bsSize="small"
                    value={this.state.trigger}
                    onChange={(e) => this.handleTrigger(e.target.value)}
                    data-cy="payment-trigger"
                  >
                    {[...Triggers.entries()].map((t) => (
                      <option key={t[0]} value={t[0]}>
                        {t[1]}
                      </option>
                    ))}
                  </FormControl>
                  {this.state.trigger == 'custom' && (
                    <FormControl
                      className="custom"
                      type="text"
                      bsSize="small"
                      value={this.state.custom}
                      placeholder=""
                      onChange={this.handleCustom.bind(this)}
                    />
                  )}
                </div>
              ) : this.state.type == 'variable' ? (
                <div className="floater">
                  <FormControl
                    className="payment-unit"
                    value={this.state.unit}
                    componentClass="select"
                    placeholder="select"
                    bsSize="small"
                    onChange={(e) => this.handleUnit(e.target.value)}
                  >
                    {[...Units.entries()].map((u) => (
                      <option key={u[0]} value={u[0]}>
                        {u[1]}
                      </option>
                    ))}
                  </FormControl>

                  {this.state.unit == 'custom' && (
                    <FormControl
                      className="custom"
                      type="text"
                      value={this.state.custom}
                      placeholder=""
                      bsSize="small"
                      onChange={this.handleCustom.bind(this)}
                    />
                  )}
                </div>
              ) : null}
              <div className="clearfix" />
            </div>
          </div>
        )}

        {!this.state.editing && (
          <div className="viewing">
            {this.state.type == 'fixed' && (
              <div>
                <span className={displayClass} onClick={() => this.handleAction('edit')}>
                  Fixed
                </span>
                <span> payment of </span>
                <span className={displayClass} onClick={() => this.handleAction('edit')}>
                  {payment.displayAmount}
                </span>
                <span> due at </span>
                <span className={displayClass} onClick={() => this.handleAction('edit')}>
                  {payment.trigger == 'custom' ? payment.custom : payment.trigger}
                </span>
              </div>
            )}
            {this.state.type == 'variable' && (
              <div>
                <span className={displayClass} onClick={() => this.handleAction('edit')}>
                  Variable
                </span>
                <span> payment of </span>
                <span className={displayClass} onClick={() => this.handleAction('edit')}>
                  {payment.displayAmount}
                </span>
                <span> per </span>
                <span className={displayClass} onClick={() => this.handleAction('edit')}>
                  {payment.unit == 'custom' ? payment.custom : payment.unit}
                </span>
              </div>
            )}
          </div>
        )}
        {this.state.editing && (
          <div className="payment-controls" data-cy="payment-controls">
            <Button dmpStyle="link" bsSize="small" onClick={(e) => this.cancel(e)}>
              Cancel
            </Button>
            <Button dmpStyle="default" bsSize="small" disabled={!this.state.amount} onClick={(e) => this.save(e)}>
              Save
            </Button>
          </div>
        )}
        {!this.state.editing && canEdit && this.renderMenu()}
        {this.renderActivity()}
      </div>
    );
  }
}
