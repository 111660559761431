import { assign } from 'lodash';

export default class Packet {
  constructor({ category, action, label, data }) {
    assign(this, { category, action, label, data });
  }

  get ga() {
    return {
      category: this.category,
      action: this.action,
      label: this.label,
      value: this.data || 0,
    };
  }
}
