import React from 'react';

import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Button as BSButton, utils } from 'react-bootstrap';

import { classNamePrefixer } from '@core/utils';

import { Icon, Loader } from '@components/dmp';
import { ICONS } from '@components/dmp/Icon';

utils.bootstrapUtils.addStyle(BSButton, 'filevine');

const cl = classNamePrefixer('btn');

const Button = React.forwardRef(
  (
    {
      children,
      className,
      dark,
      dmpStyle,
      icon,
      iconOnly,
      loading,
      noPadding,
      noUnderline,
      size,
      tool,
      ...passingProps
    },
    ref
  ) => {
    const isIconOnly = iconOnly || (icon && !children);

    // Support custom link-{type}
    let bsStyle = dmpStyle;
    if (bsStyle.includes('link-')) {
      bsStyle = 'link';
    }

    const bsSize = size !== 'default' ? size : null;

    const classNames = cx(
      cl(),
      className,
      cl(dmpStyle),
      { [cl('link')]: bsStyle === 'link' },
      { [cl(size)]: size !== 'default' },
      { [cl('no-padding')]: noPadding },
      { [cl('icon')]: icon },
      { [cl('icon-only')]: isIconOnly },
      { [cl('dark')]: dark },
      { [cl('no-underline')]: noUnderline },
      { [cl('tool')]: tool }
    );

    let IconComponent = null;
    if (icon) {
      const iconSize = size === 'default' && isIconOnly ? 'large' : 'default';
      if (ICONS[icon]) {
        IconComponent = <Icon name={icon} dark={dark} size={iconSize} />;
      } else {
        throw new Error(`@dmp/Button : <Icon name="${icon}"> does not exist.`);
      }
    }

    return (
      <BSButton bsStyle={bsStyle} bsSize={bsSize} className={classNames} ref={ref} {...passingProps}>
        {!loading && IconComponent}
        {loading && (
          <>
            <Loader size={size} dark={dark} />{' '}
          </>
        )}
        {children}
      </BSButton>
    );
  }
);

Button.displayName = 'Button';

Button.defaultProps = {
  icon: null,
  iconOnly: false,
  loading: false,
  noPadding: false,
  noUnderline: false,
  size: 'default',
  dmpStyle: 'default',
  dark: false,
  tool: false,
};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.string,
  iconOnly: PropTypes.bool,
  loading: PropTypes.bool,
  noPadding: PropTypes.bool,
  noUnderline: PropTypes.bool,
  tool: PropTypes.bool,
  size: PropTypes.oneOf(['xsmall', 'small', 'default', 'large']),
  dmpStyle: PropTypes.oneOf([
    'danger',
    'link',
    'link-danger',
    'link-primary',
    'link-info',
    'default',
    'primary',
    'filevine',
  ]),
  dark: PropTypes.bool,
};

const ButtonIcon = React.forwardRef(
  (
    {
      checkbox,
      checked,
      className,
      dark,
      disabled,
      faded,
      icon,
      onClick,
      size,
      tool,
      noFill,
      indicator,
      color,
      ...passingProps
    },
    ref
  ) => {
    const classNames = cx(
      'btn', // This is required for <a> tags button to behave like regular ones (e.g ButtonGroup)
      cl(),
      cl('icon'),
      cl('icon-only'),
      cl('icon-link'),
      cl(size),
      { [cl('dark')]: dark },
      { [cl('icon-checked')]: checked },
      { [cl('icon-checkbox')]: checkbox },
      { [cl('icon-disabled')]: disabled },
      { [cl('tool')]: tool },
      { [cl('no-fill')]: noFill },
      className
    );

    const handleClick = () => {
      if (disabled) {
        return;
      }
      onClick();
    };

    if (!ICONS[icon]) {
      throw new Error(`@dmp/ButtonIcon : <Icon name="${icon}"> does not exist.`);
    }

    return (
      <a className={classNames} onClick={handleClick} {...passingProps} ref={ref}>
        <Icon name={icon} dark={dark} faded={faded} size={size} noFill={noFill} color={color} />
        {!!indicator && indicator}
      </a>
    );
  }
);

ButtonIcon.displayName = 'ButtonIcon';

ButtonIcon.defaultProps = {
  // TODO: Checkbox mode is only working in dark mode right now
  // It was implemented during 3PP POC
  checkbox: false,
  checked: false,
  dark: false,
  disabled: false,
  faded: false,
  onClick: _.noop,
  size: 'large',
  tool: false,
  noFill: false,
  color: undefined,
};

ButtonIcon.propTypes = {
  checkbox: PropTypes.bool,
  checked: PropTypes.bool,
  className: PropTypes.string,
  dark: PropTypes.bool,
  disabled: PropTypes.bool,
  faded: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['xsmall', 'small', 'default', 'large']),
  tool: PropTypes.bool,
  noFill: PropTypes.bool,
  // TODO: Review this. I do not believe that it belongs here (ping @leohuettel)
  indicator: PropTypes.node,
};

const ButtonClose = ({ className, ...passingProps }) => (
  <ButtonIcon icon="close" className={cx(cl('close'), className)} {...passingProps} />
);

ButtonClose.propTypes = {
  className: PropTypes.string,
};

export { ButtonIcon, ButtonClose };

export default Button;
