import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';

import { FILEVINE_SERVICE } from '@core/enums/IntegrationServices';
import DealConnection from '@core/models/DealConnection';
import { dt } from '@core/utils';

import { Alert, Button, ButtonToolbar, Card, Dropdown, Icon, MenuItem, ModalConfirm } from '@components/dmp';

import TooltipButton from '@components/editor/TooltipButton';
import API from '@root/ApiClient';
import Fire from '@root/Fire';

@autoBindMethods
export default class FilevineTemplateConnection extends Component {
  loadedProjectTypes = false;
  loadedProjectType = null;

  static defaultProps = {
    connection: null,
  };

  static propTypes = {
    connection: PropTypes.instanceOf(DealConnection),
    dealID: PropTypes.string.isRequired,
    teamID: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
      isSaving: false,
      projectSectionData: null,
      projectType: null,
      projectTypeData: null,
      sectionName: null,
      showConfirmDelete: false,
      projectTypes: [],
      debugID: '',
      collectionIDs: {},
    };
  }

  componentDidMount() {
    this.populate(this.props);
  }

  async populate(props) {
    if (props.connection && props.connection.id) {
      const projectTypeId = _.get(props.connection, 'idFields.projectTypeId');
      const sectionSelector = _.get(props.connection, 'idFields.sectionSelector');
      const debugID = _.get(props.connection, 'debugID');
      const collectionIDs = _.pick(props.connection.idFields, props.connection.deal.fvCollections);

      await this.setState({
        projectType: projectTypeId || null,
        sectionName: sectionSelector || null,
        debugID: debugID || '',
        collectionIDs,
      });
    }

    this.loadData();
  }

  async loadData() {
    const { teamID } = this.props;
    const { projectType } = this.state;
    const newState = {};

    // Project Types are loaded once.
    if (!this.loadedProjectTypes) {
      await this.setState({ isLoadingProjectTypes: true });
      try {
        const projectTypes = await API.call('getFilevineData', { type: 'projectTypes', teamID });
        this.loadedProjectTypes = true;
        newState.projectTypes = projectTypes;
        newState.isLoadingProjectTypes = false;
      } catch (err) {
        console.error(err);
        newState.dataError = {
          type: 'projectTypes',
          message: 'Could not load Project Types. Please verify your Filevine Team settings.',
        };
        newState.isLoadingProjectTypes = false;
      }
    }

    // Project Types Sections can reload only if a Project Type is present and
    // Sections are not yet loaded or they Project Type changed.
    if (!projectType) {
      this.loadedProjectType = null;
      newState.sections = [];
    } else if (!newState.dataError && (!this.loadedProjectType || this.loadedProjectType !== projectType)) {
      await this.setState({ isLoadingSections: true });
      try {
        const sections = await API.call('getFilevineData', { type: 'sections', id: projectType, teamID });
        newState.sections = sections;
        newState.isLoadingSections = false;
        this.loadedProjectType = projectType;
      } catch (err) {
        console.error(err);
        newState.dataError = {
          type: 'sections',
          message: 'Could not load Project Type Sections. Please verify your Filevine Team settings.',
        };
        newState.isLoadingSections = false;
      }
    }

    this.setState(newState);
  }

  get isNew() {
    return !_.get(this.props, 'connection.id');
  }

  get canSave() {
    const { isSaving, projectType, sectionName } = this.state;
    return !isSaving && projectType && sectionName;
  }

  handleCollectionID(e) {
    const { collectionIDs } = this.state;
    collectionIDs[e.target.name] = e.target.value;
    this.setState({ collectionIDs });
  }

  async handleSelectProjectType(projectType) {
    await this.setState({ projectType });

    this.loadData();
  }

  async handleSave() {
    const { connection, dealID } = this.props;
    const { projectType, sectionName, debugID, collectionIDs } = this.state;
    const idFields = { ...connection?.idFields, ...collectionIDs };
    let error = null;

    await this.setState({ isSaving: true, error: false });

    try {
      await Fire.saveDealConnections(dealID, [
        {
          key: _.get(connection, 'key', null),
          type: FILEVINE_SERVICE.key,
          objectType: 'projectType',
          id: `${projectType}_${sectionName}`,
          debugID,
          idFields,
        },
      ]);
    } catch (err) {
      error = err;
    }

    this.setState({
      isSaving: false,
      isEditing: !!error,
      error,
    });
  }

  async handleDelete() {
    const { dealID, connection } = this.props;
    let error = null;

    await this.setState({ isSaving: true, error: false });
    try {
      await Fire.deleteDealConnection({ dealID, connectionId: connection.key });
    } catch (err) {
      error = err;
    }

    if (!error) {
      this.setState({
        isEditing: false,
        isSaving: false,
        projectType: null,
        sectionName: null,
        showConfirmDelete: false,
      });
    } else {
      this.setState({
        isSaving: false,
        isEditing: false,
        error,
      });
    }
  }

  renderEmpty() {
    return (
      <div className="connection-content">
        <small>Generate {dt}s using data stored in Filevine via merge codes.</small>
        <ButtonToolbar align="right">
          <Button size="small" onClick={() => this.setState({ isEditing: true })} data-cy="btn-connection">
            Set up connection
          </Button>
        </ButtonToolbar>
      </div>
    );
  }

  renderConnectionForm() {
    const {
      projectType,
      dataError,
      sectionName,
      isEditing,
      isSaving,
      showConfirmDelete,
      projectTypes,
      sections,
      isLoadingSections,
      isLoadingProjectTypes,
      debugID,
      collectionIDs,
    } = this.state;

    const { connection } = this.props;
    const projectTypeObject = _.find(projectTypes, { id: projectType });
    const sectionObject = _.find(sections, { selector: sectionName });
    let buttons = null;
    let collections = [];

    if (connection) {
      collections = connection.deal.fvCollections;
    }

    // If we were unable to load Project Types, there's nothing we can do, so render nothing
    if (dataError && dataError.type === 'projectTypes') {
      return (
        <Alert dmpStyle="danger" inline size="small">
          {dataError.message}
        </Alert>
      );
    }

    if (isEditing) {
      buttons = (
        <>
          <Button dmpStyle="link" size="small" onClick={() => this.setState({ isEditing: false })}>
            Cancel
          </Button>
          <Button size="small" disabled={!this.canSave} onClick={this.handleSave} data-cy="btn-update">
            {this.isNew ? 'Create' : 'Update'}
          </Button>
        </>
      );
    } else {
      buttons = (
        <>
          <Button
            dmpStyle="link"
            size="small"
            disabled={isSaving}
            onClick={() => this.setState({ showConfirmDelete: true })}
          >
            Delete
          </Button>
          <Button size="small" onClick={() => this.setState({ isEditing: true })} data-cy="btn-edit-connection">
            Edit connection
          </Button>
        </>
      );
    }

    return (
      <div className="connection-content" data-cy="connection-content">
        <FormGroup>
          <ControlLabel>Project Type</ControlLabel>
          <Dropdown
            id="dd-project-type"
            title={isLoadingProjectTypes ? 'Loading...' : _.get(projectTypeObject, 'name') || 'Choose'}
            onSelect={this.handleSelectProjectType}
            size="small"
            loading={isLoadingProjectTypes}
            disabled={isLoadingProjectTypes || isSaving || !isEditing}
            menuWidth={280}
            block
            dataCyToggle="dd-project-type-toggle"
          >
            <MenuItem key={null} eventKey={null}>
              Choose
            </MenuItem>
            {_.map(projectTypes, ({ id, name }) => (
              <MenuItem key={id} eventKey={id} data-cy="project-type-option">
                {name} [ID: {id}]
              </MenuItem>
            ))}
          </Dropdown>
        </FormGroup>

        <FormGroup>
          <ControlLabel>Section Name</ControlLabel>
          <Dropdown
            id="dd-section-name"
            title={isLoadingProjectTypes || isLoadingSections ? 'Loading...' : _.get(sectionObject, 'name') || 'Choose'}
            onSelect={(sectionName) => this.setState({ sectionName })}
            size="small"
            loading={isLoadingProjectTypes || isLoadingSections}
            disabled={!projectType || isLoadingSections || isSaving || !isEditing}
            menuWidth={280}
            block
            dataCyToggle="dd-section-name-toggle"
          >
            <MenuItem key={null} eventKey={null}>
              Choose
            </MenuItem>
            {_.map(sections, ({ selector, name }) => (
              <MenuItem key={selector} eventKey={selector} data-cy="section-name-option">
                {name}
              </MenuItem>
            ))}
          </Dropdown>
        </FormGroup>

        <FormGroup>
          <ControlLabel>
            <TooltipButton tip="Sample Project ID for previewing connected variables">
              <span>Proofing ID</span>
            </TooltipButton>
          </ControlLabel>
          <FormControl
            bsSize="small"
            placeholder="Optional"
            disabled={!projectType || isLoadingSections || isSaving || !isEditing}
            value={debugID}
            onChange={(e) => this.setState({ debugID: e.target.value })}
            data-cy="debugID"
          />
        </FormGroup>

        {collections.map((collection, idx) => (
          <FormGroup key={idx}>
            <ControlLabel>
              <TooltipButton tip={`Sample ${collection} ID for previewing connected variables`}>
                <span>{collection} ID</span>
              </TooltipButton>
            </ControlLabel>
            <FormControl
              name={collection}
              bsSize="small"
              placeholder="Optional"
              disabled={!projectType || isLoadingSections || isSaving || !isEditing}
              value={_.get(collectionIDs, collection, '')}
              onChange={this.handleCollectionID}
              data-cy="connect-collection"
            />
          </FormGroup>
        ))}

        {dataError && dataError.type === 'sections' && (
          <Alert dmpStyle="danger" inline size="small">
            {dataError.message}
          </Alert>
        )}

        <ButtonToolbar align="right">{buttons}</ButtonToolbar>

        <ModalConfirm
          isLoading={isSaving}
          show={showConfirmDelete}
          onHide={() => this.setState({ showConfirmDelete: false })}
          onConfirm={this.handleDelete}
          title="Really delete this connection?"
          body="Are you sure you want to delete the Filevine connection?"
        />
      </div>
    );
  }

  render() {
    const { connection } = this.props;
    const { isEditing } = this.state;

    return (
      <Card className="connection" condensed>
        <div className="header">
          <Icon name="logoFilevine" />
          Filevine
        </div>
        {connection || isEditing ? this.renderConnectionForm() : this.renderEmpty()}
      </Card>
    );
  }
}
