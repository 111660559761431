/* eslint-disable react/no-find-dom-node */
import React, { Component, createRef } from 'react';
import ReactDOM from 'react-dom';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FormControl } from 'react-bootstrap';

import FieldMapping from '@core/models/FieldMapping';
import { getCursor, setCursor } from '@core/utils';

import { Button } from '@components/dmp';

@autoBindMethods
export default class FieldMapper extends Component {
  static propTypes = {
    fieldMapping: PropTypes.instanceOf(FieldMapping),
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
    onAdd: PropTypes.func,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    onAdd: _.noop,
    onDelete: _.noop,
    disabled: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      outlaw: '',
      service: '',
    };
    this.refOutlaw = createRef();
    this.refService = createRef();
  }

  componentDidMount() {
    const { fieldMapping } = this.props;
    this.populate(fieldMapping);
  }

  componentDidUpdate() {
    const { fieldMapping } = this.props;
    const { outlaw, service } = this.state;

    if (!fieldMapping) return;

    if (outlaw !== _.get(fieldMapping, 'outlaw') || service !== _.get(fieldMapping, 'service')) {
      this.populate(fieldMapping);
    }
  }

  get isNew() {
    return !this.props.fieldMapping;
  }

  populate(fieldMapping) {
    this.setState({
      outlaw: _.get(fieldMapping, 'outlaw', ''),
      service: _.get(fieldMapping, 'service', ''),
    });
  }

  async updateField(field, value) {
    const { onUpdate, fieldMapping } = this.props;

    if (this.isNew) {
      this.setState({ [field]: value });
    } else {
      // Because we're using props instead of state to update values, cursor position was getting messed up.
      // But we can capture the new position (which has already fired here),
      // and then re-set it after the component renders again
      const el = ReactDOM.findDOMNode(field === 'outlaw' ? this.refOutlaw.current : this.refService.current);
      const nextPos = el ? getCursor(el) : -1;

      await onUpdate(fieldMapping, field, value);

      if (nextPos > -1) {
        setCursor(el, nextPos);
      }
    }
  }

  async addRow() {
    const { onAdd } = this.props;
    const { outlaw, service } = this.state;

    onAdd({ outlaw, service });

    await this.setState({ outlaw: '', service: '' });

    const el = ReactDOM.findDOMNode(this.refOutlaw.current);
    //el.focus(); // AAHRRRGHGHGGHGHGHGHGH WHHYY
  }

  render() {
    const { fieldMapping, onDelete, disabled } = this.props;
    const { outlaw, service } = this.state;

    return (
      <div className="field-mapper">
        <div className="outlaw">
          <FormControl
            ref={this.refOutlaw}
            value={outlaw}
            onChange={(e) => this.updateField('outlaw', e.target.value)}
            bsSize="small"
            disabled={disabled}
          />
        </div>

        <div className="service">
          <FormControl
            ref={this.refService}
            value={service}
            onChange={(e) => this.updateField('service', e.target.value)}
            bsSize="small"
            disabled={disabled}
          />
        </div>

        <div className="actions">
          {this.isNew ? (
            <Button
              disabled={!outlaw || !service}
              dmpStyle="default"
              bsSize="small"
              onClick={this.addRow}
              disabled={disabled}
            >
              Add
            </Button>
          ) : (
            <Button dmpStyle="link" bsSize="small" noPadding onClick={() => onDelete(fieldMapping)} disabled={disabled}>
              delete
            </Button>
          )}
        </div>
      </div>
    );
  }
}
