import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import PropTypes from 'prop-types';

import { Modal } from 'react-bootstrap';

import DealAction from '@core/enums/DealAction';
import Template from '@core/models/Template';
import User from '@core/models/User';
import { dt } from '@core/utils';

import { Button, Loader } from '@components/dmp';

import API from '@root/ApiClient';
import Dealer, { Category } from '@root/Dealer';
import Fire from '@root/Fire';

@autoBindMethods
export default class DeleteDeal extends Component {
  static propTypes = {
    deals: PropTypes.array.isRequired,
    onDelete: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    user: PropTypes.instanceOf(User),
    permanentlyDelete: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { deleting: false };
  }

  get labels() {
    const { deals, permanentlyDelete } = this.props;
    let title = '',
      type = '',
      info = '';

    // This component is used to delete both deals and templates so it will be passed either a DealRecord or a Template
    // Both have valid dealID's but the name property is in a different spot
    if (deals[0] instanceof Template) {
      // It's impossible to view both deals and templates at the same time, so just check the type of the first one
      title = `Delete '${deals[0].title}'?`;
      type = 'template';
      info = 'This template will be permanently deleted. This action cannot be undone.';
    } else {
      title = permanentlyDelete
        ? `Are you sure you want to permanently delete '${deals[0].name}'?`
        : `Are you sure you want to move ${
            deals.length === 1 ? `'${deals[0].name}'` : `${deals.length} ${dt}s`
          } to trash?`;
      type = deals.length === 1 ? dt : `${dt}s`;
      info = permanentlyDelete
        ? 'This action cannot be undone.'
        : `${deals.length === 1 ? `This ${dt}` : `These ${dt}s`} will be permanently deleted after 30 days.`;
    }
    return { title, type, info };
  }

  close() {
    const { onComplete, close } = this.props;
    if (this.state.deleting) return;
    else {
      close();
      onComplete();
    }
  }

  async deleteDeals() {
    const { onDelete, user, deals, permanentlyDelete } = this.props;
    if (!deals.length || !user) return;

    await this.setState({ deleting: true });

    Dealer.call({ category: Category.DEAL, action: DealAction.DELETE });

    try {
      for (let i = 0; i < deals.length; i++) {
        const deal = deals[i];

        if (deal instanceof Template) {
          await API.call('deleteTemplate', { dealID: deal.dealID });
        } else {
          permanentlyDelete
            ? await API.call('deleteDeal', { dealID: deal.dealID, hardDelete: true })
            : await API.call('deleteDeal', { dealID: deal.dealID });
        }
        // This will get called for EACH deleted deal in a batch context
        await onDelete(deal);
      }

      // This gets called once after ALL deletions are completed
      await this.setState({ deleting: false });
      this.close();
    } catch (error) {
      console.log('error delete test', error);
      this.setState({ deleting: false });
    }
  }

  render() {
    const { show, close, deals, permanentlyDelete } = this.props;
    if (!deals.length || !show) return null;
    const { title, type, info } = this.labels;

    return (
      <Modal dialogClassName="delete-deal" show={show} onHide={close} data-cy="modal-delete-deal">
        <Modal.Header closeButton>
          <span className="headline">{permanentlyDelete ? 'Confirm deletion' : 'Confirm move to trash'}</span>
        </Modal.Header>
        <Modal.Body>
          <div className="wrapper">
            <p className="bold">{title}</p>
            <p>{info}</p>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {this.state.deleting && <Loader />}
          <div className="spacer" />
          <Button onClick={close} disabled={this.state.deleting}>
            Cancel
          </Button>
          <Button dmpStyle="danger" disabled={this.state.deleting} onClick={this.deleteDeals} data-cy="btn-delete">
            {type === 'contract'
              ? permanentlyDelete
                ? 'Permanently delete'
                : 'Move to Trash'
              : type === 'contracts'
              ? 'Move to Trash'
              : `Delete ${type}`}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
