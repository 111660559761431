import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import PropTypes from 'prop-types';

import { ControlLabel, FormGroup, Modal } from 'react-bootstrap';

import SearchParams from '@core/models/SearchParams';
import Template from '@core/models/Template';
import User from '@core/models/User';

import { Alert, Button, Loader } from '@components/dmp';

import API from '@root/ApiClient';

@autoBindMethods
export default class DeleteTemplate extends Component {
  static propTypes = {
    template: PropTypes.instanceOf(Template),
    onDelete: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    user: PropTypes.instanceOf(User),
  };

  constructor(props) {
    super(props);
    this.state = { deleting: false, results: null, searchParams: null };
  }

  close() {
    if (this.state.deleting) return;
    else this.props.close();
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    const { template } = this.props;

    if (this._isMounted && template && template?.dealID !== prevProps.template?.dealID) {
      this.getTemplateDeals();
    }
  }

  async getTemplateDeals() {
    const { template, history, location } = this.props;

    location.pathname = '/dashboard/contracts';
    //Reset before the change of templates to remove disabling delete confirmation lag.
    this.setState({ results: null, searchParams: null });
    const json = { teamID: template.team, sourceTemplateKey: template.sourceTemplateKey };
    const searchParams = new SearchParams(location, history, json);
    const results = await API.call('getDeals', searchParams.apiArgs);
    this.setState({ results, searchParams });
  }

  async delete() {
    const { onComplete, close, template, onDelete } = this.props;
    await this.setState({ deleting: true });
    try {
      await API.call('deleteTemplate', { dealID: template.dealID });
      await onDelete(template);
      onComplete();
      await this.setState({ deleting: false });
      close();
    } catch (err) {
      console.log(err);
      await this.setState({ deleting: false });
    }
  }

  viewTemplate() {
    const { template, history } = this.props;
    let url = `/templates/${template.dealID}/editor`;
    history.push(url);
  }

  get alert() {
    const { template } = this.props;
    const { results } = this.state;

    const alert =
      results && results.nbHits > 0 ? (
        <>
          <b>{template.title}</b> template can not be deleted because there are contracts originating from it.{' '}
          <b>Delete all contracts first,</b> in order to delete this template.{' '}
        </>
      ) : (
        <>
          {' '}
          Are you sure you want to delete <b>{template.title}</b> template? Template data can not be recovered if
          deleted by mistake, this action is permanent and can not be undone.{' '}
        </>
      );
    return alert;
  }

  render() {
    const { show, close, template, user } = this.props;
    const { results, searchParams } = this.state;
    if (!template) return null;

    return (
      <Modal dialogClassName="delete-deal" show={show} onHide={close} data-cy="delete-deal">
        <Modal.Header closeButton>
          <span className="headline">Delete template confirmation</span>
        </Modal.Header>
        <Modal.Body>
          <div className="wrapper">
            <Alert dmpStyle="danger">{this.alert}</Alert>
            <FormGroup>
              <ControlLabel>Template</ControlLabel>
              <div className="contents">
                <div className="title" onClick={this.viewTemplate} data-cy="modal-template-title">
                  {template.title}
                </div>
                <div className="description" data-cy="modal-template-description">
                  {template.description}
                </div>
              </div>
            </FormGroup>
            {results && results.nbHits > 0 && (
              <FormGroup>
                <ControlLabel>Count #</ControlLabel>
                <div className="contents">
                  <div className="hits">
                    {results.nbHits} (
                    <span className="title" onClick={() => searchParams.go()} data-cy="modal-show-deal">
                      show all
                    </span>
                    )
                  </div>
                </div>
              </FormGroup>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          {this.state.deleting && <Loader />}
          <div className="spacer" />
          <Button data-cy="btn-cancel" onClick={this.props.close} disabled={this.state.deleting}>
            Cancel
          </Button>
          <Button
            dmpStyle="danger"
            disabled={(!results || this.state.deleting || results.nbHits > 0) && !user?.isAdmin}
            data-cy="btn-delete"
            onClick={this.delete}
          >
            Delete template
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
