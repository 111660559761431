/* eslint-disable no-case-declarations */
import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import { PDFDocument } from 'pdf-lib';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import { Alert, ControlLabel, FormGroup, Modal, ProgressBar } from 'react-bootstrap';

import { ACCEPTED_TYPES } from '@core/models/Attachment';
import { arrayToBase64 } from '@core/utils';

import { Button } from '@components/dmp';

import API from '@root/ApiClient';

// A few helper consts/methods, could be moved into props/component but it's highly unlikely that these will ever change
const types = [ACCEPTED_TYPES.PDF, ACCEPTED_TYPES.WORD];
const acceptedTypes = _.map(types, (t) => `.${t.extension}`);
const acceptedTypesDisplay = _.map(types, (t) => `.${t.display}`).join(', ');


@autoBindMethods
export default class AITrainingUploader extends Component {
  static defaultProps = {
    onClose: _.noop,
    onUpload: _.noop,
  };

  static propTypes = {
    show: PropTypes.bool.isRequired,
    onUpload: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    team: PropTypes.object,
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      error: null,
      errorInfo: null,
      progress: null,
      files: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  get ready() {
    const { files } = this.state;
    return files.length > 0;
  }

  handleEnter(e) {
    if (e.key === 'Enter' && this.ready) {
      this.tryUpload();
    }
  }

  async tryUpload() {
    const { arrayBuffer, file } = this.state;

    // PDF
    if (file && file.type === ACCEPTED_TYPES.PDF.mime) {
      // We are currently not supporting encrypted PDFs
      // Attempt to open it for editing, if it's encrypted, it'll fail and we'll tell
      // the user to decrypt it (until we support PDF decryption)
      try {
        const pdfBytes = new Uint8Array(arrayBuffer);
        await PDFDocument.load(pdfBytes);
      } catch (err) {
        this.setState({
          error: 'Encrypted PDF files are not supported on Outlaw.',
          errorInfo: (
            <div>
              To upload this file, remove its encryption first.{' '}
              <a
                href="https://filevine.zendesk.com/knowledge/articles/17344599317531/en-us?brand_id=4415356259995"
                target="_blank"
                rel="noreferrer"
              >
                Learn how
              </a>
            </div>
          ),
        });
        return;
      }
    }

    try {
      await this.upload();
    } catch (error) {
      this.setState({ error: _.get(error, 'message', 'An error occurred'), errorInfo: null });
    }
  }

  async upload() {
    const { team, onUpload } = this.props;
    const { saving, files } = this.state;

    // Avoid duplicate import/upload attempts; ensure we have all data/selections necessary to proceed!
    if (saving || !files.length) return;

    // Grab just the properties we need for temporary file upload/processing
    const examples = [];
    _.forEach(files, (fileInfo) => {
      examples.push(_.pick(fileInfo, ['base64', 'title', 'extension']));
    });

    let current = 0, total = 10;
    const onProgress = async (description) => {
      current += 1;
      total = Math.max(total, current + 1);
      this.setState({ saving: true, progress: { current, total, description } });
    }
    await onProgress('Uploading documents...');

    const result = await API.stream('trainAssistant', {
      teamID: team.teamID,
      examples
    }, onProgress);

    await this.setState({ saving: false });

    onUpload();
    this.onClose();
  }

  reset() {
    if (this._isMounted) {
      this.setState({
        saving: false,
        error: null,
        errorInfo: null,
        progress: null,
        files: [],
      });
    }
  }

  onClose() {
    if (this.canClose) {
      this.reset();
      this.props.onClose();
    }
  }

  renderSaving() {
    const progress = _.get(this.state, 'progress', { current: 0, total: 1, description: '' });
    const percent = (progress.current / progress.total) * 100;

    return (
      <Modal.Body>
        <div className="saving">
          <ProgressBar bsStyle="info" now={percent} />
          <div className="details">
            <div className="step">
              {progress.description}
            </div>
          </div>
        </div>
      </Modal.Body>
    );
  }

  renderError() {
    const { error, errorInfo } = this.state;

    return (
      <>
        <Modal.Body>
          <div className="saving error">
            <Alert bsStyle="danger">
              <strong>Upload failed</strong> {error}
            </Alert>
            {errorInfo}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.reset}>Try Again</Button>
        </Modal.Footer>
      </>
    );
  }

  get canClose() {
    const { saving, error } = this.state;
    return !saving || !!error;
  }

  async prepFile(file) {
    const reader = new FileReader();

    const fileInfo = {
      file,
      arrayBuffer: null,
      base64: null,
      title: null,
      extension: null,
    };

    const promise = new Promise((resolve) => {

      const onFile = (arrayBuffer) => {
        if (!this._isMounted || !arrayBuffer) return;
  
        try {
          fileInfo.arrayBuffer = arrayBuffer;
          fileInfo.extension = file.name.split('.').pop().toLowerCase();
          fileInfo.title = file.name.split('.')[0].replace(/[-_]/g, ' ');
          fileInfo.base64 = arrayToBase64(new Uint8Array(arrayBuffer));
        }
        catch (e) {
          console.log(e);
        }

        resolve();
      }
  
      reader.onload = (e) => onFile(e.target.result);
      reader.onerror = (e) => {
        console.log('FileReader error: ', e);
      }
      reader.readAsArrayBuffer(file);
    });

    await promise;
    return fileInfo;
  }

  removeFile(file) {
    let { files } = this.state;
    const idx = files.indexOf(file);
    if (idx > -1) {
      files.splice(idx, 1);
      this.setState({files});
    }
  }

  async onDropAccepted(files) {
    const preppedFiles = [];
    for (let i = 0; i < files.length; i++) {
      const fileInfo = await this.prepFile(files[i]);
      preppedFiles.push(fileInfo);
    }

    this.setState({ files: preppedFiles });
  }

  // Technically this will reject ALL selected files even if some are valid,
  // but it's not worth the UI dev to handle them granularly, so just stick to pdf/docx and we'll never hit this error state anyway
  onDropRejected(files) {
    const error = `Only .pdf and .docx files are supported`;
    _.forEach(files, (file) => this.removeFile(file));
    this.setState({ error });
  }

  renderFile(file, idx) {
    const { error } = this.state;

    return (
      <div className="hit-area" key={idx}>
        {this.icon}
        <div className="instructions">
        <h4>{file.file.name}</h4>
        <small>{file.extension.toUpperCase()} Document Format</small>
        {error && <small className="error">{error}</small>}
        </div>
        <Button className="clear" onClick={() => this.removeFile(file)}>
        ×
        </Button>
      </div>
    )
  }

  renderBody() {
    const { team } = this.props;
    const { files, error } = this.state;
    const existingRules = _.get(team, 'aiConfig.rules.length', 0);

    return (
      <>
        <Modal.Body>
          <div className="wrapper">
            {existingRules > 0 && (
              <Alert bsStyle="danger">
                <strong>Warning: </strong> This team already has {existingRules} existing AI drafting rules. Uploading new training documents will replace all existing rules.
              </Alert>
            )}
            <FormGroup>
              <ControlLabel>Select Docs</ControlLabel>
              <div className="contents">
                
              <Dropzone
                className="ai-up"
                activeClassName="uploader active"
                accept={acceptedTypes}
                disableClick={files.length > 0}
                onDropAccepted={this.onDropAccepted}
                onDropRejected={this.onDropRejected}
                data-cy="ai-up"
                multiple
              >
                <div className="existing-files">
                  {files.map(this.renderFile)}
                </div>

                {!files.length && (
                  <div className="hit-area">
                    {this.icon}
                    <div className="instructions">
                    <h4>Choose files or drag and drop</h4>
                    <small>Accepted: {acceptedTypesDisplay}.</small>
                    {error && <small className="error">{error}</small>}
                    </div>
                  </div>
                )}
                </Dropzone>


              </div>
            </FormGroup>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button disabled={!this.ready} dmpStyle="primary" onClick={this.tryUpload} data-cy="btn-upload-contract">
            Train
          </Button>
        </Modal.Footer>
      </>
    );
  }

  render() {
    const { show } = this.props;
    const { saving, error } = this.state;

    return (
      <Modal dialogClassName="contract-uploader" show={show} onHide={this.onClose} data-cy="contract-uploader">
        <Modal.Header closeButton={this.canClose}>
          <span className="headline">AI Training</span>
        </Modal.Header>

        {error ? this.renderError() : saving ? this.renderSaving() : this.renderBody()}
      </Modal>
    );
  }
}
