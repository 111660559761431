import React, { Component, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';

import CheckpointGroup from '@core/models/CheckpointGroup';
import Team from '@core/models/Team';
import User from '@core/models/User';
import { getSafeKey } from '@core/utils';

import { Button, Loader, Validator } from '@components/dmp';

import TeammateBlock from '@components/deal/TeammateBlock';
import TooltipButton from '@components/editor/TooltipButton';
import Fire from '@root/Fire';

import TeammateSelector from './TeammateSelector';

@autoBindMethods
export default class CheckpointGroupEditor extends Component {
  static propTypes = {
    user: PropTypes.instanceOf(User).isRequired,
    team: PropTypes.instanceOf(Team).isRequired,
    show: PropTypes.bool.isRequired,
    checkpointGroup: PropTypes.object,
    onSave: PropTypes.func,
    onHide: PropTypes.func,
    members: PropTypes.any,
  };

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      checkpointGroupKey: '',
      validKey: false,
      description: '',
      participants: [],
    };

    this.refModal = createRef();
    this.refSelf = createRef();

    // Placeholder for creating new checkpointGroups;
    this.newCheckpointGroup = null;
  }

  componentDidUpdate(prevProps) {
    const { checkpointGroup, show } = this.props;

    if (!_.isEqual(checkpointGroup, prevProps.checkpointGroup) || !_.isEqual(show, prevProps.show)) {
      this.populate(checkpointGroup);
    }
  }

  get isNew() {
    return !this.props.checkpointGroup;
  }

  get checkpointGroup() {
    return this.props.checkpointGroup || this.newCheckpointGroup;
  }

  populate(checkpointGroup) {
    if (!checkpointGroup) {
      this.newCheckpointGroup = new CheckpointGroup(_.merge({}));
    }

    this.setState({
      name: _.get(checkpointGroup, 'name') || '',
      checkpointGroupKey: _.get(checkpointGroup, 'checkpointGroupKey', '') || '',
      description: _.get(checkpointGroup, 'description', '') || '',
      participants: _.get(checkpointGroup, 'participants', '') || [],
      loading: false,
    });
  }

  handleChange(e, prop) {
    this.setState({ [prop]: e.target.value });
    if ((prop === 'name' || prop === 'checkpointGroupKey') && this.isNew) {
      const checkpointGroupKey = getSafeKey(e.target.value, false, true, '');
      this.setState({ checkpointGroupKey });
    }
  }

  focusInput() {
    if (this.refName) {
      this.refName.focus();
    }
  }

  validateKey(checkpointGroupKey) {
    const { team } = this.props;
    const valid = !!checkpointGroupKey && !_.find(team.checkpointGroups, { checkpointGroupKey });
    return valid;
  }

  async save() {
    const { team, onSave, onHide } = this.props;

    const checkpointGroup = _.pick(this.state, ['name', 'checkpointGroupKey', 'description', 'participants']);

    this.setState({ loading: true });

    await Fire.saveCheckpointGroup(team, checkpointGroup);

    onHide();
    onSave();
  }

  addAction(teammate) {
    this.setState((prevState) => {
      return {
        participants: [...new Set([...prevState.participants, teammate.id])],
      };
    });
  }

  removeAction(teammate) {
    this.setState((prevState) => {
      return {
        participants: prevState.participants.filter((id) => id !== teammate.id),
      };
    });
  }

  render() {
    const { onHide, show, team, members, user } = this.props;
    const { name, checkpointGroupKey, description, loading, validKey, participants } = this.state;
    const participantMembers = members.filter((member) => participants.includes(member.id));

    return (
      <Modal
        dialogClassName="checkpointGroup-editor"
        backdrop="static"
        show={show}
        onHide={onHide}
        onEnter={this.focusInput}
        data-cy="checkpoint-group-editor"
      >
        <Modal.Header closeButton>
          <span className="headline">{this.isNew ? 'New Checkpoint Group' : 'Update Checkpoint Group'}</span>
        </Modal.Header>
        <Modal.Body ref={this.refModal}>
          <FormGroup>
            <ControlLabel>Name</ControlLabel>
            <div className="contents">
              <FormControl
                type="text"
                bsSize="small"
                inputRef={(ref) => (this.refName = ref)}
                disabled={loading}
                value={name}
                placeholder="Enter Checkpoint group name"
                onChange={(e) => this.handleChange(e, 'name')}
                data-cy="checkpoint-name"
              />
            </div>
          </FormGroup>

          <FormGroup>
            <ControlLabel>Checkpoint Key</ControlLabel>
            <div className="contents checkpointGroup-key dmp-validator-container">
              <FormControl
                bsSize="small"
                readOnly={!this.isNew}
                type="text"
                value={checkpointGroupKey}
                placeholder="Required"
                onChange={(e) => this.handleChange(e, 'checkpointGroupKey')}
                data-cy="checkpoint-key"
              />
              {this.isNew && (
                <Validator
                  validate={this.validateKey}
                  validateEmpty
                  value={checkpointGroupKey}
                  onResult={(validKey) => this.setState({ validKey })}
                  validTip="Available"
                  invalidTip={
                    !checkpointGroupKey
                      ? 'Enter a unique key for this checkpointGroup'
                      : 'A checkpointGroup with this key already exists on this team'
                  }
                />
              )}
            </div>
          </FormGroup>

          <FormGroup>
            <ControlLabel>Description</ControlLabel>
            <div className="contents">
              <FormControl
                bsSize="small"
                type="text"
                value={description}
                placeholder="Optional"
                onChange={(e) => this.handleChange(e, 'description')}
                data-cy="checkpoint-description"
              />
            </div>
          </FormGroup>

          <FormGroup>
            <div className="fields participants-selector">
              <TeammateSelector
                existingUsers={participants}
                onSelect={this.addAction}
                team={team}
                menuWidth={200}
                user={user}
              />
              <div className="participants">
                <div className="col-headers">
                  <TooltipButton
                    placement="top"
                    tip="Participants that can approve the checkpoint"
                    tipID="tip-participants"
                  >
                    <ControlLabel>Participants</ControlLabel>
                  </TooltipButton>
                </div>
                <div className="participants-list" data-cy="participants-list">
                  {participantMembers.map((participant) => (
                    <TeammateBlock
                      key={participant.id}
                      teammate={participant}
                      onClick={() => this.removeAction(participant)}
                    />
                  ))}
                </div>
              </div>
            </div>
          </FormGroup>
        </Modal.Body>

        <Modal.Footer>
          {loading && <Loader />}
          <Button disabled={loading} onClick={onHide}>
            Cancel
          </Button>
          <Button
            dmpStyle="primary"
            disabled={loading || (this.isNew && !validKey) || participants?.length === 0}
            onClick={this.save}
            data-cy="btn-save-checkpoint"
          >
            {this.isNew ? 'Save' : 'Update'}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
