import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FormControl, Modal, Tab, Tabs } from 'react-bootstrap';

import DealRole from '@core/enums/DealRole';
import { OUTLAW_SERVICE } from '@core/enums/IntegrationServices';
import Automation from '@core/models/Automation';
import DealRecord from '@core/models/DealRecord';
import { DEAL_USER_PROPERTIES } from '@core/models/DealUser';
import Integration from '@core/models/Integration';
import Template from '@core/models/Template';
import User, { isOutlaw } from '@core/models/User';

import { Alert, Button, Checkbox, Loader, Setting } from '@components/dmp';

import API from '@root/ApiClient';
import Fire from '@root/Fire';
import { convertDealMetaFromES } from '@root/server/search/meta';

const USER_PROPERTIES = [...Object.values(DEAL_USER_PROPERTIES), 'partyID', 'role'];

const VALIDATIONS = [
  {
    code: '[NOT_FOUND]',
    test: (data) => {
      return !!data.value;
    },
  },
  {
    code: '[UNKNOWN_USER_PROPERTY]',
    test: (data) => {
      const [prefix, userProperty] = data.key.split('.');
      return userProperty ? Object.values(USER_PROPERTIES).includes(userProperty) : true;
    },
  },
  {
    code: '[UNKNOWN_USER_ROLE]',
    test: (data) => {
      const [prefix, roleProperty] = data.key.split('.');
      if (roleProperty === 'role') {
        return Object.values(DealRole).includes(roleProperty);
      }
      return true;
    },
  },
  {
    code: '[UNKNOWN_PARTY_ID]',
    test: (data) => {
      const [prefix, partyProperty] = data.key.split('.');
      if (partyProperty === 'partyID') {
        return _.find(data.template.parties, { partyID: data.value });
      }
      return true;
    },
  },
];

@autoBindMethods
export default class AutomationTester extends Component {
  static propTypes = {
    user: PropTypes.instanceOf(User).isRequired,
    onClose: PropTypes.func.isRequired,
    template: PropTypes.instanceOf(Template),
    integration: PropTypes.instanceOf(Integration),
    automation: PropTypes.instanceOf(Automation),
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: null,
      meta: null,
      triggerData: null,
      error: null,
      objectId: '',
      dealID: '',
      triggeredBy: '',
      dryRun: true,
    };
  }

  get isOutlawTrigger() {
    return this.props.automation.origin === OUTLAW_SERVICE.key;
  }

  async runTest() {
    const { automation, template } = this.props;
    const { objectId, dealID, triggeredBy, dryRun } = this.state;
    let testData = {};

    const params = { objectType: automation.objectType };
    if (objectId) params.objectId = objectId;
    if (triggeredBy) params.triggeredBy = triggeredBy;

    await this.setState({
      loading: true,
      data: null,
      meta: null,
      triggerData: null,
      error: null,
    });

    // If we're triggering from Outlaw, we must fetch the deal first
    if (this.isOutlawTrigger) {
      try {
        const updatedDeal = await Fire.getDeal(dealID);
        const dealRecord = new DealRecord(updatedDeal);
        testData = convertDealMetaFromES(dealRecord.meta);
      } catch (err) {
        this.setState({
          loading: false,
          data: null,
          triggerData: null,
          error: 'Error fetching the deal, please revise the dealID nd make sure that you have access.',
          meta: null,
        });
      }
    }

    try {
      const { data, triggerData, error, meta } = await API.call('connect', {
        teamID: template.team,
        templateKey: template.key,
        service: automation.service,
        trigger: automation.trigger,
        origin: automation.origin,
        data: testData,
        params,
        dryRun,
        automationKey: automation.key,
      });

      this.setState({ loading: false, data, triggerData, error, meta });
    } catch (err) {
      const error = _.get(err, 'response.error', 'Unknown Error.');
      this.setState({ loading: false, data: null, triggerData: null, error, meta: null });
    }
  }

  renderData() {
    const { automation, template } = this.props;
    const { data, error, triggerData } = this.state;

    if (error) {
      return <Alert dmpStyle="danger">{error}</Alert>;
    }

    // Build a fieldMap key to found value object
    const fieldMapped = {};
    // loop validations
    _.forEach(automation.fieldMap, (fieldMap) => {
      const key = automation.origin === 'outlaw' ? fieldMap.service : fieldMap.outlaw;
      const valueKey = automation.origin === 'outlaw' ? fieldMap.outlaw : fieldMap.service;
      const value = _.get(triggerData, valueKey, null);

      // Support both outlaw and salesforce
      fieldMapped[key] = value;
      // Loop validations and test each ones, in case one fail, stop the loop and apply the error code to the value.
      _.forEach(VALIDATIONS, (validation) => {
        if (!validation.test({ key, value, template })) {
          fieldMapped[key] = validation.code;
          // Returning false on a lodash forEach breaks it
          return false;
        }
      });
    });

    return (
      <Tabs defaultActiveKey={0}>
        <Tab eventKey={0} title="Data">
          <pre>
            <code>{JSON.stringify(data, undefined, 2)}</code>
          </pre>
        </Tab>
        <Tab eventKey={1} title="Trigger Data">
          <pre>
            <code>{JSON.stringify(triggerData, undefined, 2)}</code>
          </pre>
        </Tab>
        <Tab eventKey={2} title="Mapped Data">
          <pre>
            <code>{JSON.stringify(fieldMapped, undefined, 2)}</code>
          </pre>
        </Tab>
      </Tabs>
    );
  }

  render() {
    const { automation, onClose, user } = this.props;
    const { loading, objectId, dealID, error, data, triggeredBy, dryRun } = this.state;

    if (!automation) return null;

    let body = "Click 'Run'";

    if (error) body = <Alert dmpStyle="danger">{error}</Alert>;
    else if (data) body = this.renderData();

    if (loading) body = <Loader />;

    return (
      <Modal className="modal-automation-tester" show={true} onHide={onClose}>
        <Modal.Header>
          <span className="headline">Test Automation</span>
        </Modal.Header>
        <Modal.Body>
          <div className="wrapper">
            {this.isOutlawTrigger && (
              <Setting title="Deal ID" subtitle="Deal ID of the trigger">
                <FormControl
                  disabled={loading}
                  value={dealID}
                  onChange={(e) => this.setState({ dealID: e.target.value })}
                  bsSize="small"
                />
              </Setting>
            )}
            <Setting title="Object ID" subtitle="e.g Salesforce Opportunity ID">
              <FormControl
                disabled={loading}
                value={objectId}
                onChange={(e) => this.setState({ objectId: e.target.value })}
                bsSize="small"
              />
            </Setting>
            <Setting title="Triggered By" subtitle="e.g Salesforce User ID">
              <FormControl
                disabled={loading}
                value={triggeredBy}
                onChange={(e) => this.setState({ triggeredBy: e.target.value })}
                bsSize="small"
              />
            </Setting>
            <Setting title="Dry run" subtitle="Always use this!">
              <Checkbox
                id={'dry-run'}
                disabled={!(user.isAdmin && isOutlaw(user))}
                checked={dryRun}
                onChange={() => this.setState({ dryRun: !dryRun })}
              />
            </Setting>
            <div style={{ border: '1px solid #EFEFEF', margin: '30px 0', padding: 20 }}>{body}</div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {loading && <Loader />}
          <div className="spacer" />
          <Button onClick={onClose} disabled={loading}>
            Done
          </Button>
          <Button dmpStyle="primary" onClick={this.runTest} disabled={loading}>
            Run
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
