import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { classNamePrefixer } from '@core/utils';

const cl = classNamePrefixer('ellipsis');

const Ellipsis = React.forwardRef(({ className, children, lines, ...restProps }, ref) => {
  const isMultiline = !!lines;
  const classNames = cx({ [cl()]: !isMultiline }, { [cl('multiline')]: isMultiline }, className);
  const style = {};
  if (isMultiline) style.WebkitLineClamp = lines;

  return (
    <div {...restProps} className={classNames} style={style} ref={ref}>
      {children}
    </div>
  );
});

Ellipsis.displayName = 'Ellipsis';

Ellipsis.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  lines: PropTypes.number,
};

export default Ellipsis;
