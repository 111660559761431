import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { classNamePrefixer } from '@core/utils';

import { ButtonIcon, Dropdown, MenuItem } from '@components/dmp';

const cl = classNamePrefixer('multi-select');

@autoBindMethods
export default class MultiSelect extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.node.isRequired,
        text: PropTypes.string.isRequired,
        selected: PropTypes.bool,
      }).isRequired
    ),
    size: PropTypes.string,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    placeholder: 'Choose...',
  };

  constructor(props) {
    super(props);
    this.state = {
      selected: [],
    };
  }

  componentDidMount() {
    //if we have saved selected values reload them when initating.
    const { options } = this.props;

    const savedSelections = _.filter(options, (option) => {
      return option.selected;
    });

    this.setState({
      selected: savedSelections,
    });
  }

  componentDidUpdate(prevProps) {
    const { options } = this.props;

    if (options !== prevProps.options) {
      const savedSelections = _.filter(options, (option) => {
        return option.selected;
      });

      this.setState({
        selected: savedSelections,
      });
    }
  }

  onSelect(item) {
    const { selected } = this.state;
    const { options, onSelect } = this.props;

    const selection = _.find(options, { key: item });

    selected.push(selection);

    this.setState({ selected });
    onSelect(selection);
  }

  onRemove(item) {
    const { selected } = this.state;
    const { onRemove } = this.props;

    const updatedSelections = _.filter(selected, (selection) => {
      return selection.key !== item;
    });

    const removedItem = _.find(selected, { key: item });

    this.setState({ selected: updatedSelections });
    onRemove(removedItem);
  }

  render() {
    const { selected } = this.state;
    const { id, options, size, placeholder } = this.props;

    return (
      <div className={cx(cl())}>
        <Dropdown id={id} title={placeholder} size={size} onSelect={this.onSelect} block>
          {options.map((option, idx) => (
            <MenuItem key={idx} eventKey={option.key} ellipsis disabled={!!_.find(selected, { key: option.key })}>
              {option.text}
            </MenuItem>
          ))}
        </Dropdown>
        <div className={cx(cl('list'))}>
          {selected.map((option, idx) => (
            <div className={cx(cl('list-item'))} key={idx}>
              <span className="header">{option.text}</span>
              <ButtonIcon icon="close" size="default" className="close" onClick={() => this.onRemove(option.key)} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}
