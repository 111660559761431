import React, { Component, createRef } from 'react';

import autobindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FormControl, FormGroup, Overlay } from 'react-bootstrap';

import { SAVEABLE_VAR_FIELDS } from '@core/models/Variable';
import { getUniqueKey } from '@core/utils';

import { Button, Form, Popover } from '@components/dmp';

import Fire from '@root/Fire';

@autobindMethods
class FootnoteEditor extends Component {
  static propTypes = {
    footnote: PropTypes.object,
    rootClose: PropTypes.bool,
    candidate: PropTypes.object,
    container: PropTypes.object,
    deal: PropTypes.object,
    target: PropTypes.object,
    hide: PropTypes.func,
    onSave: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      value: '',
      name: '',
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.populate(this.props);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.populate(newProps);
  }

  populate(props) {
    const { footnote } = props;

    this.setState({ value: footnote.value, name: footnote.name });
  }

  async save(e) {
    const { deal, hide } = this.props;

    if (e) e.stopPropagation();

    //take all savable variable properties from state
    const variable = _.pick(this.state, SAVEABLE_VAR_FIELDS);

    await Fire.saveVariableDefinition(deal, variable);
    hide();
  }

  cancel(e) {
    if (e) e.stopPropagation();
    this.props.hide();
  }

  render() {
    const { target, hide, container, rootClose } = this.props;

    if (!this._isMounted) return null;

    return (
      <Overlay container={container} onHide={hide} placement="bottom" rootClose={rootClose} show={true} target={target}>
        <Popover
          className="popover-footnote-editor"
          id="footnote-editor"
          data-cy="popover-footnote-editor"
          onMouseDown={(e) => e.stopPropagation()}
          onMouseUp={(e) => e.stopPropagation()}
        >
          {this.renderEditor()}
        </Popover>
      </Overlay>
    );
  }

  renderEditor() {
    const { value } = this.state;
    const { footnote } = this.props;

    return (
      <Form>
        <FormGroup className="id">
          <span className={cx('element-name', 'FOOTNOTE')}>{`Footnote (${footnote.ftNumber})`}</span>
        </FormGroup>
        <FormGroup>
          <FormControl
            bsSize="small"
            type="text"
            className="variable-value"
            componentClass="textarea"
            placeholder={'Value'}
            value={value}
            onChange={(e) => this.setState({ value: e.target.value })}
            data-cy="variable-editor-value"
          />
        </FormGroup>
        <FormGroup className="actions">
          <Button className="cancel" dmpStyle="link" size="small" onClick={this.cancel} data-cy="btn-cancel-var">
            Cancel
          </Button>

          <Button className="save" disabled={!value} size="small" onClick={this.save} data-cy={'btn-save-var'}>
            Save
          </Button>
        </FormGroup>
      </Form>
    );
  }
}

export default FootnoteEditor;
