import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { Modal } from 'react-bootstrap';

import { CUSTOMER_STATUS } from '@core/models/StripeCustomer';
import { Dt } from '@core/utils';

import { Alert, Button } from '@components/dmp';

export default class UpgradePrompt extends Component {
  static defaultProps = {
    subscription: null,
  };

  static propTypes = {
    subscription: PropTypes.object,
    user: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      roadblock: true,
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { subscription } = this.props;
    let component = null;

    // Don't render anything until we know subscription status
    if (!subscription) return null;

    if ([CUSTOMER_STATUS.FREE, CUSTOMER_STATUS.TRIAL_MEMBER].includes(subscription.status)) {
      component = this.renderBanner();
    }

    if (subscription.status === CUSTOMER_STATUS.SEATS_EXCEEDED) {
      component = this.renderRoadblock();
    }

    return <div className="upgrade-wrapper">{component}</div>;
  }

  //banner is used for to prompt free users to signup
  renderBanner() {
    const { subscription } = this.props;

    if (subscription.status == CUSTOMER_STATUS.PAID) return null;

    return (
      <div className="upgrade-prompt" key="prompt">
        <div className="upgrade-image"></div>

        <div className="main-contents">
          <div className="top-section">
            <div className="product">
              <h5>Ready to Activate?</h5>
              <h3>Outlaw Subscription</h3>
            </div>
          </div>
          <div className="features-section">
            <div className="features">
              <div className="feature">Advanced template editor</div>
              <div className="feature">Encrypted smart fields</div>
              <div className="feature">Unlimited template creation & storage</div>
              <div className="feature">Teams dashboard</div>
              <div className="feature">Secure cloud storage</div>
              <div className="feature">{Dt} templates library</div>
            </div>
          </div>
          <p>
            Contact{' '}
            <a className="text-link" href="mailto:sales@getoutlaw.com">
              sales@getoutlaw.com
            </a>{' '}
            or call{' '}
            <a className="text-link" href="tel:1-833-668-8529">
              +1 (833) 668 8529
            </a>
            .
          </p>
        </div>
      </div>
    );
  }

  renderRoadblock() {
    const { subscription } = this.props;
    const { roadblock } = this.state;

    if (roadblock) {
      return (
        <Modal
          show={roadblock}
          onHide={() => this.setState({ roadblock: false })}
          dialogClassName="upgrade-modal"
          key="roadblock"
          data-cy="upgrade-modal"
        >
          <Modal.Header closeButton>
            <span className="headline" data-cy="upgrade-modal-headline">
              Subscription notice
            </span>
          </Modal.Header>
          <Modal.Body>
            <div className="wrapper text-center">
              <Alert data-cy="upgrade-modal-alert">
                To continue using Outlaw, reach out to your sales representative or contact{' '}
                <a href="mailto:sales@getoutlaw.com">sales@getoutlaw.com</a> or call{' '}
                <a href="tel:1-833-668-8529">+1 (833) 668 8529</a>.
              </Alert>
              <div className="comparison" data-cy="comparison">
                <div className="paid">
                  <span>Licenses paid</span>
                  <h3>{subscription.seatsPaid}</h3>
                </div>
                <div className="using" data-cy="using">
                  <span>Licenses in use</span>
                  <h3>{subscription.seatsUsed}</h3>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      );
    }

    return this.renderStrip();
  }

  //strip is abbreviated banner that shows inlin on page after roadblock is closed
  renderStrip() {
    return (
      <div>
        <Alert>
          <b>Your subscription does not have enough licenses.</b> Reach out to your sales representative or contact{' '}
          <a href="mailto:sales@getoutlaw.com">sales@getoutlaw.com</a> or call{' '}
          <a href="tel:1-833-668-8529">+1 (833) 668 8529</a>.
        </Alert>
      </div>
    );
  }
}
