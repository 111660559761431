import TextLine from '../parsing/TextLine';
import _ from 'lodash';

import Section from '../models/Section';
import { rxDefinedTerm } from '../models/Content';
import BoundingBox from '../parsing/BoundingBox';
import { capitalize } from '../parsing/Legalese';
import DefinedTerm from '../parsing/DefinedTerm';

export const PARSE_TYPE = {
  OCR: 'ocr',
  PDFJS: 'pdfjs',
};

export const SUB_TYPE = {
  DOC_TITLE: 'docTitle',
  PARAGRAPH: 'paragraph',
  TABLE: 'table',
  FORM: 'form',
}

export default class ExternalSection extends Section {

  lines = [];
  flatFrags = [];
  pageStart;
  pageEnd;

  subType = SUB_TYPE.PARAGRAPH;

  // Set in PDFDeal constructor
  isPreamble = false;

  constructor(json, deal) {
    super(json, deal);

    this.raw = json;

    // Instantiate typed classes 
    this.lines = _.map(json.lines, (tl) => new TextLine(tl));

    if (json.subType) this.subType = json.subType;

    // Create a flattened version that we can use for reverse lookups
    // to map ranges of text back to their source fragments 
    const frags = [];
    let lineCursor = 0;
    let flatCursor = 0;

    _.forEach(this.lines, (line) => {
      lineCursor = flatCursor;

      _.forEach(line.fragments, (frag, indexWithinLine) => {
        if (!frag) return;
        lineCursor = flatCursor + parseInt(indexWithinLine);
        frag.flatIndex = lineCursor;
        // lineCursor += frag.value.length;
        frags.push(frag);
      });
  
      // Capture the spaces that get added in between lines when we flatten
      flatCursor += line.value.length + 1;
    });
    this.flatFrags = frags;

    this.pageStart = _.min(_.map(this.lines, 'page'));
    this.pageEnd = _.max(_.map(this.lines, 'page'));

    if (isNaN(this.pageStart)) {
      // console.log(_.map(this.lines, 'page'));
    }
  }

  getBoundingBox(start, end) {

    let startFrag = _.find(this.flatFrags, (frag) => {
      return  (frag.flatIndex === start ||
              frag.flatIndex + frag.value.length > start) &&
              !frag.isSpacer;
    });
    let endFrag = _.find(this.flatFrags, (frag) => {
      return frag.flatIndex + frag.value.length >= end;
    });

    let endX;

    if (endFrag && !startFrag) startFrag = endFrag;

    // TODO: estimate partial box size when box starts or ends within a fragment
    const textInEndFrag = endFrag.value.slice(0, end - endFrag.flatIndex);
    
    if (textInEndFrag.length < endFrag.value.length) {
      // Special case for a hanging dot, which seems to be fairly common
      // (fragments get started with a dot)
      if (textInEndFrag === '.') {
        endX = endFrag.x + 3;
      }
      else {
        endX = endFrag.x + (textInEndFrag.length / endFrag.value.length) * endFrag.width;
      }
    }
    else {
      endX = endFrag.endX;
    }

    // DEBUG UTIL
    if (this.displayname === 'Access Right.') {
      // console.log({start, end, textInEndFrag});
      // console.log(endFrag);
      // console.log(textInEndFrag);
      // console.log(this.flatValue);
      // console.log(_.map(this.flatFrags, (f) => _.pick(f, ['flatIndex', 'value'])));
    }

    if (startFrag && endFrag) {
      // TODO: convert into multiple if it spans multiple lines
      return new BoundingBox({
        x: startFrag.x,
        y: startFrag.y,
        width: endX - startFrag.x,
        height: startFrag.line.height,
        page: startFrag.page,
      });
    }
    
    return null;
  }

  get flatValue() {
    return _.map(this.lines, 'value').join(' ');
  }

  get definedTerms() {
    let terms = [];
    const text = this.flatValue;
    let match;

    while ((match = rxDefinedTerm.exec(text)) !== null) {
      // console.log(match);
      const dt = new DefinedTerm({
        value: match[1],
        index: match.index + match[0].indexOf(match[1]),
      }, this);
      // console.log(match, dt);
      terms.push(dt);
    }
    return terms.length > 0 ? terms : null;
  }

  // Cleanup of displayTitle, which is the raw title text parsed from PDF
  // But may have 
  get namedClause() {
    if (!this.displayTitle) return null;
    const text = this.displayTitle.replace(/[:.-]\s*$/g, '');
    return capitalize(text);
  }

  get preamble() {
    const text = this.flatValue;
    // const rxPreamble = /This ([\w\d\s]+)+ \(/;
    return text.match(/This ([\w\d\s]+)+ \(/);
    // const terms = this.definedTerms;

    // if (pre && terms && pre.index < terms[0].index) {
    //   return true;
    // }
    // return false;
  }

  /* 
  //these were just used for debug/logging... 
  //commenting out to avoid confusion with stored Section properties (.content)

  get numberTitle() {
    const num = this.externalNumber;
    const title = this.externalTitle;

    // If we have either a number or a title, ensure that there's exactly 1 space in between
    if (num || title) {
      return `${num.trim()} ${title.trim()}`.trim();
    } else {
      return '';
    }
  }

  get fullContent() {
    let s = this.numberTitle;
    if (s) s += '\n';
    if (this.content) s += this.content;
    return s;
  }
  */

  // TODO: update these to always parse directly from true raw content (?)
  get externalNumber() {
    return _.get(this.raw, 'scrapedNumber', '');
  }
  get externalTitle() {
    return _.get(this.raw, 'displayname', '');
  }

  get boxNumber() {
    const externalNumber = this.externalNumber;
    if (!externalNumber) return null;

    const start = this.flatValue.indexOf(externalNumber);
    if (start < 0) return null;
    const end = start + externalNumber.length;

    return this.getBoundingBox(start, end);
  }

  get boxTitle() {
    const externalTitle = this.externalTitle;
    if (!externalTitle) return null;

    const start = this.flatValue.indexOf(externalTitle);
    if (start < 0) return null;
    const end = start + externalTitle.length;

    return this.getBoundingBox(start, end);
  }

  getBoxSection(page) {
    // TODO: specify page. sections that span multiple pages will mess this up
    if (page !== this.pageStart && page !== this.pageEnd) return null;

    const lines = _.filter(this.lines, {page});
    // console.log(lines)
    const x = _.min(_.map(lines, 'x'));
    const y = _.min(_.map(lines, 'y'));
    const width = _.max(_.map(lines, 'endX')) - x; 
    const height = _.max(_.map(lines, 'endY')) - y;

    return new BoundingBox({
      x, 
      y, 
      width, 
      height, 
      page, 
      continuesAfter: this.pageEnd > page,    //TODO: add column support
      continuesBefore: this.pageStart < page, //TODO: add column support
    });
  }

}
