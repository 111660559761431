import React, { Component } from 'react';

import axios from 'axios';
import autobindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Modal } from 'react-bootstrap';

import Deal, { ASPECTS } from '@core/models/Deal';
import User from '@core/models/User';
import { generateExportURL } from '@core/utils';

import { Button, Loader } from '@components/dmp';

import Fire from '@root/Fire';

@autobindMethods
export default class PDFPreview extends Component {
  static propTypes = {
    show: PropTypes.bool,
    close: PropTypes.func.isRequired,
    deal: PropTypes.instanceOf(Deal).isRequired,
    user: PropTypes.instanceOf(User).isRequired,
    timelineExportSettings: PropTypes.object,
  };

  static defaultProps = {
    show: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      appendActivity: false,
      emptyBlocks: false,
      exporting: false,
      previewURL: '',
      loading: false,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { show } = newProps;
    if (show) {
      this.generatePreviewURL();
    }
  }

  async generatePreviewURL() {
    const { emptyBlocks, appendActivity } = this.state;
    const { deal } = this.props;
    await this.setState({ loading: true });

    const generatePDFPreview = async (token, docType, options) => {
      const url = await generateExportURL({ deal, token, type: docType, options });
      const blob = await axios.get(url, { responseType: 'blob' });
      const file = new Blob([blob.data], { type: 'application/pdf' });
      const previewURL = URL.createObjectURL(file);
      this.setState({ previewURL });
    };

    const aspects = [ASPECTS.CONTRACT];
    if (appendActivity) aspects.push(ASPECTS.ACTIVITY);
    const token = await Fire.token();
    const options = { aspects: aspects.join(','), emptyBlocks };
    if (this.props.timelineExportSettings) {
      const stringified = JSON.stringify(this.props.timelineExportSettings);
      const encoded = encodeURIComponent(stringified);
      options.timelineExportSettings = encoded;
    }
    await generatePDFPreview(token, 'pdf', options);
    this.setState({ loading: false });
  }

  renderCloseAction() {
    const { close } = this.props;

    return (
      <div className="action-wrapper">
        <div className="action passive" data-cy="pdf-preview-action">
          <div className="info">
            <div className="title" data-cy="pdf-preview-title">
              PDF Preview
            </div>
            <div className="sub inactive" data-cy="pdf-preview-sub">
              Editing is disabled
            </div>
          </div>
          <Button className="preview-action" dmpStyle="primary" onClick={close} data-cy="btn-pdf-preview-action">
            Close
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const { show, close } = this.props;
    const { previewURL, loading } = this.state;

    return (
      <Modal dialogClassName="pdf-preview" show={show} onHide={close} data-cy="pdf-preview">
        <Modal.Body className="outer-paper-layout">
          {!loading && <object data={`${previewURL}#toolbar=0&navpanes=0`} type="application/pdf" />}
          {this.renderCloseAction()}
          {loading && <Loader centered size="large" />}
        </Modal.Body>
      </Modal>
    );
  }
}
