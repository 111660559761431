import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import axios from 'axios';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import jump from 'jump.js';
import _ from 'lodash';
import PropTypes from 'prop-types';
import qs from 'qs';

import { ButtonGroup } from 'react-bootstrap';

import DealAction from '@core/enums/DealAction';
import DealRole from '@core/enums/DealRole';
import DealStatus from '@core/enums/DealStatus';
import { FILEVINE_SERVICE } from '@core/enums/IntegrationServices';
import InviteStatus from '@core/enums/InviteStatus';
import SectionType from '@core/enums/SectionType';
import Deal, { DEAL_TYPE } from '@core/models/Deal';
import DealRecord from '@core/models/DealRecord';
import { ELEMENT_TYPE } from '@core/models/PDFElement';
import Party from '@core/models/Party';
import { FEATURES } from '@core/models/Team';
import Variable from '@core/models/Variable';
import { hasRestrictedWorkflowAccess, isServiceProvider } from '@core/models/Workflow';
import { Dt, arrayToBase64, generateExportURL, getDataURL, isVine } from '@core/utils';

import {
  Button,
  ButtonIcon,
  Dropdown,
  DropdownDots,
  Ellipsis,
  Icon,
  Loader,
  MenuItem,
  ToastContainer,
  toast,
} from '@components/dmp';

import ConnectionView from '@components/ConnectionView';
import DraftFlowSwitcher from '@components/DraftFlowSwitcher';
import PDFPreview from '@components/PDFPreview';
import PDFReview from '@components/PDFReview';
import Tagger from '@components/Tagger';
import ABC from '@components/deal/DealHeader/ABC';
import AiBlocksView from '@components/deal/DealHeader/AiBlocksView';
import AuditView from '@components/deal/DealHeader/AuditView';
import BundleView from '@components/deal/DealHeader/BundleView';
import Checkpoint from '@components/deal/DealHeader/Checkpoint';
import ContractExporter from '@components/deal/DealHeader/ContractExporter';
import CopyMachine from '@components/deal/DealHeader/CopyMachine';
import DealAttachments from '@components/deal/DealHeader/DealAttachments';
import DealInfo from '@components/deal/DealHeader/DealInfo';
import DealUserManager from '@components/deal/DealHeader/DealUserManager';
import DealVersions from '@components/deal/DealHeader/DealVersions';
import ElementsView from '@components/deal/DealHeader/ElementsView';
import VersionHistory from '@components/deal/DealHeader/VersionHistory';
import DealIntegrations from '@components/deal/DealIntegrations';
import DealSettings from '@components/deal/DealSettings';
import DealStatusSelector from '@components/deal/DealStatusSelector';
import UpgradeGuestModal from '@components/deal/UpgradeGuestModal';
import TooltipButton from '@components/editor/TooltipButton';
import { getMenuOptions } from '@components/editor/VariableSuggest';
import PDFHeader from '@components/pdf-editor/PDFHeader';
import TimelineDealExporter from '@components/timevine/TimelineDealExporter';
import TimelineDealHeaderControls from '@components/timevine/TimelineDealHeaderControls';
import TimelineDealHeaderTabs from '@components/timevine/TimelineDealHeaderTabs';
import API from '@root/ApiClient';
import CRM from '@root/CRM';
import CONFIG from '@root/Config';
import Dealer, { Category } from '@root/Dealer';
import Fire from '@root/Fire';
import trackEvent from '@utils/EventTracking';

const { DEFAULT_CONNECTIONS } = CONFIG;

const DEAL_MODES = [
  {
    path: 'overview',
    title: 'Overview',
    test: ({ deal }) => {
      return !deal.workflow.serviceProviders;
    },
  },
  {
    path: 'casedata',
    title: 'Custom',
    test: ({ deal }) => {
      return deal.workflow.serviceProviders && !deal.currentWorkflowStep.isAfterRestricted;
    },
  },
  {
    path: 'contract',
    title: Dt,
    test: ({ deal }) => {
      return true;
    },
  },
  {
    path: 'eventdata',
    title: 'Event Data',
    test: ({ deal }) => {
      return !!deal.timeline;
    },
  },
  {
    path: 'timeline',
    title: 'Timeline',
    test: ({ deal }) => {
      return !!deal.timeline;
    },
  },
];

export const PANELS = {
  pdfreview: {
    key: 'pdfreview',
    title: 'Analysis',
    icon: 'docScan',
    test: ({ deal }) =>
      (deal.hasLenses || deal.documentAI) &&
      [DealRole.OWNER, DealRole.EDITOR].includes(_.get(deal, 'currentDealUser.role')),
  },

  readyCheck: {
    key: 'readyCheck',
    title: 'Checkpoints',
    icon: 'checkpoint',
    test: ({ deal }) => {
      const du = deal.currentDealUser;
      //never show ReadyCheck panel to anonymous users
      if (du == null || du.guest) return false;
      //don't show while deal has no status (e.g., INGESTED/Draft)
      if (!deal.info.status) return false;

      //if current user is not a contract owner they can't issue a new ReadyCheck
      //so if there are also no prior ReadyChecks that they've been a part of, AND there is not one in progress,
      //don't even bother showing the icon because panel will be completely empty
      if (du.role != DealRole.OWNER && deal.readyCheck == null && !deal.readyCheck?.votes[du.uid]) {
        return false;
      }

      //all other users can see the panel (though its contents will vary by role)
      return true;
    },
    className: ({ deal }) => (deal.readyCheck ? ` checking ${deal.readyCheck.result.data}` : ''),
  },
  integrations: {
    key: 'integrations',
    title: 'Integrations',
    icon: 'integrations',
    //test: ({user}) => !!user.isAdmin,
    test: () => false,
  },
  elements: {
    key: 'elements',
    title: 'Variables',
    icon: 'elements',
    // Only show elements panel to owners
    test: ({ deal }) => [DealRole.OWNER, DealRole.EDITOR].includes(_.get(deal, 'currentDealUser.role')) && !isVine,
  },
  audit: {
    key: 'audit',
    title: `${Dt} Activity`,
    icon: 'audit',
    test: () => true,
  },

  versions: {
    key: 'versions',
    title: `${Dt} Versions`,
    icon: 'versions',
    // Versions are desktop only, and editors/owners only
    test: ({ deal }) =>
      !Dealer.mobile && [DealRole.OWNER, DealRole.EDITOR].includes(_.get(deal, 'currentDealUser.role')) && !isVine,
    className: ({ deal }) => (deal.pendingPDFChanges.length > 0 ? 'checking incomplete' : null),
  },

  attachments: {
    key: 'attachments',
    title: 'Attachments',
    icon: 'attachment',
    // Attachments are desktop only, and must be either owner/editor (to manage) or have existing Attachments
    test: ({ deal }) =>
      !Dealer.mobile &&
      (deal.attachedFiles.length > 0 ||
        [DealRole.OWNER, DealRole.EDITOR].includes(_.get(deal, 'currentDealUser.role'))) &&
      !isVine,
  },

  users: {
    key: 'users',
    title: `${Dt} Users`,
    icon: 'users',
    test: () => true,
    className: ({ deal }) => {
      const hasUserError = deal.users.filter((u) => u.inviteStatus === InviteStatus.FAILED).length > 0;

      return hasUserError ? 'checking failed' : null;
    },
  },

  connect: {
    key: 'connect',
    title: 'Connections',
    icon: 'connect',
    test: ({ deal }) => deal.isOwner,
  },

  info: {
    key: 'info',
    title: 'Document Info',
    icon: 'info',
    test: () => true,
  },

  aiblocks: {
    key: 'ai-blocks',
    title: 'AI Blocks',
    icon: '',
    test: () => false,
  },
};

const ZOOM_OPTIONS = {
  50: 0.5,
  75: 0.75,
  100: 1,
  150: 1.5,
  200: 2,
};

const QUICK_EXPORTS = [
  {
    type: 'preview',
    title: 'Preview',
  },
  {
    type: 'divider',
    title: '',
  },
  {
    type: 'docx',
    title: 'Download DOCX',
  },
  {
    type: 'pdf',
    title: 'Download PDF',
  },
  {
    type: 'docxToDocs',
    title: 'Save DOCX to Docs',
  },
  {
    type: 'pdfToDocs',
    title: 'Save PDF to Docs',
  },
];

@autoBindMethods
export default class DealHeader extends Component {
  static defaultProps = {
    compact: false,
    scale: 1,
    pdfEditMode: null,
    pdfField: null,
    pdf: null,
    onPanelChange: _.noop,
    signed: false,
  };

  static propTypes = {
    compact: PropTypes.bool,
    deal: PropTypes.instanceOf(Deal),
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    onZoom: PropTypes.func,
    scale: PropTypes.number,
    onPDFEditMode: PropTypes.func,
    pdfEditMode: PropTypes.string,
    pdfField: PropTypes.oneOfType([PropTypes.instanceOf(Party), PropTypes.instanceOf(Variable)]),
    onPanelChange: PropTypes.func,
    pdf: PropTypes.object,
    onDeleteDeal: PropTypes.func,
    signed: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      copying: false,
      dataURL: null,
      dealRecord: null,
      editingTitle: false,
      exporting: false,
      timelineExportSettings: null,
      isShowingInfo: false,
      isUpgradeGuestVisible: false,
      mode: props.match.params.mode || null,
      panel: null, //key of one of the PANELS above; if populated panel will show
      readyCheck: null,
      revertingFlow: false,
      settings: false,
      integrations: false,
      tagging: false,
      title: '',
      downloadURL: null,
      downloading: false,
      preview: false,
      dealTeam: null,
      delete: false,
    };

    this.dealHeaderRef = React.createRef();
    this.refDotMenu = React.createRef();
    this.refDotMenuMobile = React.createRef();
    this.refVersions = React.createRef();
    this.refDealUserManager = React.createRef();
    this.senderRef = React.createRef();
    this.abcContainerRef = React.createRef();
    this.draftStepTargetRef = React.createRef();
    this.actionRef = React.createRef();

    this.refBtns = {};
    _.forEach(PANELS, (panel) => {
      this.refBtns[panel.key] = React.createRef();
    });
    this.refToolbarPanels = React.createRef();

    this.downloadLink = React.createRef();

    if (props.deal.isExternal) {
      this.onHitEsc = (event) => {
        if (event.keyCode === 27) {
          this.props.onPDFEditMode(null);
        }
      };
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    // Display the Guest upgrade prompt if the a Guest just signed.
    // Give it 1.5secs to avoid visual lag since all Deal components are updating at the same time. (smoother experience)
    if (
      _.get(this.props, 'deal.currentDealUser.guest', false) &&
      _.get(newProps, 'deal.currentDealUser.signed', false) === true &&
      _.get(this.props, 'deal.currentDealUser.signed', false) === false
    ) {
      this.showUpgradeGuestInterval = setTimeout(() => this.setState({ isUpgradeGuestVisible: true }), 1500);
    }

    this.setState({ mode: newProps.match.params.mode || null });
  }

  componentDidMount() {
    this._isMounted = true;

    if (this.onHitEsc) document.addEventListener('keydown', this.onHitEsc, false);

    this.getDealTeam();

    this.setDownloadURL();
    window.addEventListener('keyup', this.findShortcut);

    const searchParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    if (searchParams?.panel) this.handlePanel(searchParams.panel);
  }

  findShortcut(e) {
    if (e.ctrlKey && e.shiftKey && e.code === 'KeyP') {
      this.setState({ preview: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.deal.currentVersion.key !== this.props.deal.currentVersion.key ||
      prevProps.deal.isExternal !== this.props.deal.isExternal
    ) {
      this.setDownloadURL();
    }
    if (!_.isEqual(prevProps.panel, this.props.panel)) {
      this.setState({ panel: this.props.panel });
    }
  }

  // Find the Team model corresponding to this Deal, which can be different from the team passed in props
  // Because that is an app-wide context variable stored in LocalStorage
  async getDealTeam() {
    const { deal, teams } = this.props;

    const dealTeam = _.find(teams, { teamID: deal.team });

    if (dealTeam) {
      this.setState({ dealTeam });
    }
  }

  setDownloadURL() {
    const {
      deal: { currentVersion },
    } = this.props;

    if (!currentVersion.documentBucketPath) return;

    // For nonOwners looking at an external deal, allow downloading the current version PDF
    // since there is no DealVersions panel to download it from.
    // load dl url, put in state
    Fire.storage
      .ref(currentVersion.documentBucketPath)
      .getDownloadURL()
      .then((downloadURL) => {
        if (this._isMounted) {
          this.setState({ downloadURL });
        }
      });
  }

  UNSAFE_componentWillUnmount() {
    this._isMounted = false;
    if (this.showUpgradeGuestInterval) clearInterval(this.showUpgradeGuestInterval);
    if (this.onHitEsc) document.removeEventListener('keydown', this.onHitEsc, false);
    window.removeEventListener('keyup', this.findShortcut);
  }

  setViewMode(mode) {
    switch (mode) {
      case 'pdf':
        //pdf's pop a separate window

        return;
      case 'variables':
        //anything other than null/empty string will cause variable editor to pop!
        this.props.editVariable(' ');
        return;
      default:
        const { compact, location, history, deal } = this.props;
        let p;
        if (compact) {
          p = location.pathname;
          if (mode == 'contract') p += '/contract';
          else p = p.replace(/\/contract$/, '');
          if (location.search) p += location.search;
          // this.setState({mode});
          history.push(p);
        } else {
          //all other modes use URL to invoke appropriate component
          p = `/${deal.template ? 'templates' : 'deals'}/${deal.dealID}`;
          if (mode) p += `/${mode}`;
          //also ensure that any open panels are closed
          //this makes sure CRM bubble won't show up on top of a panel
          //plus URL changes anyway, so flipping should reset panel state
          this.setState({ mode, panel: null });
          const label = mode || 'default';
          Dealer.call({ category: Category.DEAL, action: DealAction.READ, label });
          history.push(p);
        }
        break;
    }
  }

  getViewMode(path) {
    var mode = {};
    _.forOwn(DEAL_MODES, (m) => {
      if (m.path == path) mode = m;
    });
    return mode;
  }

  editTitle(title) {
    if (!this.canEditTitle) return;
    this.setState({ editingTitle: true, title });
    setTimeout(() => ReactDOM.findDOMNode(this.refs.editTitle).focus(), 100);
  }

  get canEditTitle() {
    return this.props.deal.currentDealUser && this.props.deal.currentDealUser.canEdit && !this.props.deal.locked;
  }

  get isAdmin() {
    const { deal, user } = this.props;
    return user && deal.can(user.id, 'admin');
  }

  // We need an old-school DOM reference to the topbar to use as a container for tooltips (TooltipButtons)
  // This is necessary so that they don't scroll, but can still use position="top" (and still show up as visible)
  // The .top-bar is a sibling div to this component, with higher z-index... so this really is the only way
  get topbar() {
    if (!document) return null;
    return document.getElementsByClassName('top-bar')[0] || null;
  }

  get canConnect() {
    return _.some(
      [Object.keys(this.state.dealTeam?.integrations || {}), this.props.deal.connections, DEFAULT_CONNECTIONS],
      (connectionTypes) => !!_.get(connectionTypes, 'length', 0)
    );
  }

  async handlePanel(eventKey) {
    const { deal, mobile, user, onPanelChange, onDeleteDeal } = this.props;

    const { downloadURL } = this.state;

    let action = eventKey;
    let zoomScale = 1;

    if (action.includes('zoom')) {
      [action, zoomScale] = action.split('-');
    }

    switch (action) {
      case 'zoom':
        this.handleZoom(ZOOM_OPTIONS[zoomScale] || 1, null);
        break;
      case 'settings':
        this.setState({ settings: true });
        break;
      case 'revertFlow':
        this.setState({ revertingFlow: true });
        break;
      case 'preview':
        this.setState({ preview: true });
        break;
      case 'copy':
        this.setState({ copying: true });
        break;
      case 'tagging':
        // For tagging to work we a DealRecord
        // Because Tagger requires it in props
        const dealRecord = new DealRecord(deal, user.id);
        this.setState({ dealRecord, tagging: true });
        break;
      case 'info':
        this.setState({ isShowingInfo: true });
        break;
      case 'export':
      case 'docx':
      case 'pdf':
        this.setState({ exporting: true });
        break;
      case 'download':
        if (downloadURL) {
          await this.setState({ downloading: true });
          // This is annoying: the href download attribute (for pretty filenames) only works
          // when the file (or data) is coming from current/same domain
          // Since it's hosted on Firebase storage, this will never be the case, so we need to download the full file locally,
          // and then push it through a data URL in order to achieve this (or, alternatively, proxy it through our API)
          // Since the file can be large, we may want to add a loading state, because this can take a few seconds...
          try {
            const raw = await API.call('getDocumentData', { dealID: deal.info.dealID });
            const arrayBuffer = new Uint8Array(raw.data.data).buffer;
            const dataURL = getDataURL(arrayBuffer);
            await this.setState({ dataURL, downloading: false });
            this.downloadLink.current.click();
          } catch (err) {
            console.log(err);
          }
        }
        break;
      //panels
      case 'elements':
      case 'audit':
      case 'users':
      case 'integrations':
      case 'readyCheck':
      case 'versions':
      case 'connect':
      case 'attachments':
      case 'pdfreview':
      case 'ai-blocks':
        // Toggling again hides panel
        if (action === this.state.panel) {
          this.setState({ panel: null });
          if (!mobile) CRM.show(20);
          onPanelChange(null);
        }
        // Normal case, show panel
        else {
          this.setState({ panel: action });
          if (!mobile) {
            CRM.hide();
          }
          if (action == 'readyCheck') {
            this.setState({ readyCheck: deal.readyCheck });
          }
          onPanelChange(action);
        }
        break;
      case 'dealVersions':
        if (this.refVersions.current) {
          await this.setState({ panel: 'versions' });
          this.refVersions.current.openCommit();
        }
        break;
      case 'delete':
        onDeleteDeal && onDeleteDeal();
        break;
      default:
        break;
    }
  }

  async handlePDFParty(party) {
    const { panel } = this.state;
    const { onPDFEditMode } = this.props;

    switch (party) {
      // A null value passed in means we're adding a new party
      case null:
        if (panel !== 'users') {
          await this.handlePanel('users');
        }
        this.refDealUserManager.current.addParty();
        break;
      // Otherwise we've got a valid party and need to pass that through props to pre-populate on PDFElements
      default:
        onPDFEditMode(ELEMENT_TYPE.SIGNATURE, party);
        break;
    }
  }

  async handleChangeStep(step) {
    const { deal, user } = this.props;
    this.setState({ updatingStatus: true });

    try {
      const prevWorkflowStep = deal.currentWorkflowStep;
      await API.call('updateDealStatus', { dealID: deal.dealID, step: step.key });
      const filevineConnection = _.find(deal.connections, { type: FILEVINE_SERVICE.key });
      if (deal.workflow.serviceProviders && filevineConnection) {
        //sync service providers on the filevine side each step change.
        API.call('syncConnectedUsers', {
          dealID: deal.dealID,
          step: step.key,
          connection: filevineConnection.json,
        });
      }

      // TODO: use Dealer.call instead and work Pendo tracking into that pattern
      const isProviderWF = _.get(deal, 'workflow.serviceProviders', false),
        partner = _.get(deal, 'workflow.serviceProviderName', null);

      const teamID = deal.team;
      const isUserServiceProvider = user?.teamMemberships[teamID] && user?.teamMemberships[teamID].serviceProvider;

      //if the user is a provider and is changing a step to a non-restricted step, notify requester
      if (isProviderWF && partner && isUserServiceProvider && step.isAfterRestricted && prevWorkflowStep.restricted) {
        const dealConnection = _.find(deal.connections, { type: FILEVINE_SERVICE.key });
        const projectId = _.get(dealConnection, 'idFields.projectId', null);
        if (dealConnection && projectId) {
          const fvProject = await API.call('getConnectProject', {
            dealID: deal.dealID,
            dealConnection: dealConnection.json,
          });

          if (fvProject?.projectUrl) {
            const ownerDealUsers = deal.users?.filter((du) => du.role === DealRole.OWNER)?.map((du) => du?.uid);
            await API.call('notifyRequesters', {
              users: ownerDealUsers,
              teamID: deal.team,
              dealName: deal.name,
              dealID: deal.dealID,
              serviceProviderName: partner,
              fvProjectURL: fvProject.projectUrl,
            });
          }
        }
      }

      if (isProviderWF && partner) {
        const eventData = {
          serviceType: 'Demand', //should eventually be enumerated somewhere for additional types
          docID: deal.dealID,
          step: step.key,
          user: user.email,
          teamID: deal.team,
          partner,
        };
        await trackEvent('ProviderStepChange', eventData);
      }
    } catch (err) {
      // Catch the error, log it and do nothing else since it shouldn't be allowed to
      // update an invalid or disabled step in the first place.
      console.log(err);
    }

    this.setState({ updatingStatus: false });
  }

  handleZoom(value = null, dir) {
    const { scale, onZoom } = this.props;
    let newScale = scale;

    if (value) {
      newScale = value;
    }

    onZoom(newScale);
  }

  getPanelProps(panelConfig) {
    const { pdfView } = this.props;

    return {
      title: panelConfig.title,
      id: panelConfig.key,
      show: this.state.panel === panelConfig.key,
      onHide: () => this.handlePanel(panelConfig.key),
      target: Dealer.mobile && !isVine ? this.refDotMenuMobile.current : this.refToolbarPanels.current,
      container: this.dealHeaderRef.current,
      pdfView,
      dealTeam: this.state.dealTeam,
    };
  }

  get action() {
    const { deal, mode } = this.props;
    //weird null error on currentDealUser, probably only when logging out (before deal listener is removed?)
    //but good to avoid js error
    if (!deal || !deal.currentDealUser || deal.readyCheck != null) return null;

    // A newly created Deal does not actually have a stored status;
    // Ensure that we start at the beginning
    const status = deal.currentWorkflowStep.isLast ? 'complete' : deal.info.status || deal.workflow.steps[0].key;

    switch (status) {
      case 'todo':
        return null;
      case 'draft':
        //see if deal hasn't been sent to anyone yet
        if (!deal.workflow.isOutlaw) {
          return null;
        }
        const unsent = _.filter(deal.users, { inviteStatus: InviteStatus.ADDED });
        return unsent.length > 0 ? `Share with ${unsent[0].displayName}` : 'Share';
      case 'review':
      case 'signing':
        const unsigned = deal.currentDealUser.partyID != null && !deal.currentDealUser.signed;
        if (unsigned) {
          if (mode != 'contract') return 'Review Full Document';
          //else if (mode == 'contract' && activity != null && (activity.open > 0 || activity.changes > 0)) return 'Review Issues';
          //else return 'Sign Contract';
          else return null; // We're removing the "scrollTo" signatue section due to react-vritualize
        } else {
          //if user has already signed and contract is still in signing, there's nothing to do for now
          return null;
        }
      case 'signed':
      case 'complete':
        // Don't show a link to View PDF if we're already looking at a PDF :-)
        return deal.isExternal ? null : 'View PDF';
      default:
        return null;
    }
  }

  handleAction(refs) {
    const { compact, mode, history, deal, source, activity, toggleDealStatus, location } = this.props;

    // A newly created Deal does not actually have a stored status;
    // Ensure that we start at the beginning
    const status = deal.currentWorkflowStep.isLast ? 'complete' : deal.info.status || deal.workflow.steps[0].key;

    switch (status) {
      case 'todo':
        break;
      case 'draft':
        // const placement = (target == this.refs.action) ? 'top' : 'bottom';
        // this.refs.sender.show(target, this.refs.abc, placement);

        //The above was an attempt to show the SendDeal popover attached to the action button
        //in the event that the sharing action originated from there
        //But in some cases that action button disappears after sending, which caused a React crash
        //so we'll just keep it up top regardless of where the share action originated from
        this.senderRef.current.show(this.draftStepTargetRef.current, this.abcContainerRef.current, 'bottom');
        break;
      case 'review':
      case 'signing':
        //if review/signing and not looking at contract view, flip there
        //if we are already on contract view, scroll down to bottom
        if (mode != 'contract') {
          const path = location.pathname.replace(/\/overview$/, '') + '/contract';
          history.push(path);
        }
        //if we're already on contract view but there are open issues
        //find the first section with issues and "deep link" to it
        //DealView will handle scrolling and visibility via deep link
        else if (!compact && mode == 'contract' && (activity.open > 0 || activity.changes > 0)) {
          let sec;
          for (var i = 0; i < source.length; i++) {
            //review is for change tracking; discuss is for open comments
            if ([DealStatus.REVIEW, DealStatus.DISCUSS].indexOf(source[i].status) > -1) {
              sec = source[i];
              break;
            }
          }

          //special case for scope item sections which aren't actually included in source array
          //find first one that's open
          if (!sec) {
            const item = _.find(
              deal.sections,
              (s) => s.sectiontype == SectionType.ITEM && [DealStatus.REVIEW, DealStatus.DISCUSS].indexOf(s.status) > -1
            );
            if (item) sec = item;
          }
          //finally, we should have found an open section by this point
          if (sec) history.push(`/deals/${deal.dealID}/contract/${sec.id}`);
        } else {
          //jump('.siggy.signing', {duration: 500, offset: -300});

          // vomit time !
          jump(100000);
        }

        break;
      case 'signed':
      case 'complete':
        Fire.token((token) => window.open(generateExportURL({ deal, token })));
        break;
    }
    //always hide deal status after an action
    if (typeof toggleDealStatus == 'function') toggleDealStatus(false);
  }

  renderMobileMenu(options = {}) {
    const { compact, selectedVersion, user } = this.props;
    const { downloadURL, downloading } = this.state;

    if (!Dealer.mobile || compact || isVine) {
      return null;
    }

    const downloadItems = [];
    if (options.download) {
      downloadItems.push(
        <MenuItem key="download" eventKey="download" disabled={!downloadURL || downloading}>
          Download PDF
        </MenuItem>
      );
      downloadItems.push(<MenuItem divider />);
    }

    return (
      <DropdownDots
        pullRight
        id="dd-deal-more"
        className="deal-dots"
        ref={this.refDotMenuMobile}
        onSelect={this.handlePanel}
        dark={!selectedVersion.isCurrent}
        faded={false}
      >
        {downloadItems}
        {_.map(PANELS, (p) => {
          if (!p.test(this.props)) return null;
          return (
            <MenuItem key={p.key} eventKey={p.key}>
              {p.title}
            </MenuItem>
          );
        })}
        {!user.anonymous && (
          <MenuItem key="tagging" eventKey="tagging">
            Edit tags
          </MenuItem>
        )}
        {this.isAdmin && (
          <MenuItem key="settings" eventKey="settings">
            Settings
          </MenuItem>
        )}
      </DropdownDots>
    );
  }

  renderModes() {
    const { deal, summary } = this.props;
    const dealModes = _.filter(DEAL_MODES, (mode) => mode.test(deal));

    if (dealModes.length > 1) {
      return dealModes.map((mode, idx) => {
        // Make sure not to render an Overview option if none are defined
        // This is a new edge case due to the addition of Timeline view mode
        if (mode.path === 'overview' && !summary.length) return null;

        return (
          <Button
            key={idx}
            className={cx('view-mode-custom', { active: this.state.mode === mode.path }, mode.path)}
            onClick={() => this.setViewMode(mode.path)}
            data-cy={mode.title}
          >
            {mode.title === 'Custom' ? deal.workflow.collectionStepName : mode.title}
          </Button>
        );
      });
    }
  }

  renderZoom() {
    const { selectedVersion, scale } = this.props;
    const zoomItems = [];
    let title = null;

    const className = cx('toolbar-group', 'zoom', { 'no-border': selectedVersion.isCurrent });

    _.forEach(ZOOM_OPTIONS, (scaleOption, displayName) => {
      zoomItems.push(
        <MenuItem key={`zoom-${displayName}`} eventKey={displayName} data-cy="zoom-item">
          {displayName}%
        </MenuItem>
      );
      if (scaleOption === scale) {
        title = `${displayName}%`;
      }
    });

    return (
      <div className={className}>
        <Dropdown
          noUnderline
          dmpStyle="link"
          id="zoom-dropdown"
          title={title}
          onSelect={(option) => this.handleZoom(ZOOM_OPTIONS[option])}
          icon="search"
          size="small"
          menuWidth={100}
          dark={!selectedVersion.isCurrent}
        >
          {zoomItems}
        </Dropdown>
      </div>
    );
  }

  renderPanelButtons() {
    const { panel, dealTeam } = this.state;
    const { selectedVersion, deal } = this.props;

    const variablesForReview = deal?.extracted?.variables
      ? _.filter(deal.extracted.variables, { needsReview: true })
      : [];

    return _.map(PANELS, (p) => {
      // Not displaying connect panel button because the panel appears through the dot dropdown section
      if (p.key === 'connect') return null;
      if (!p.test({ deal, team: dealTeam })) return null;

      // Not diplaying deal info in panel if not FVDA
      if (p.key === 'info' && !isVine) return null;

      const disabled = selectedVersion.isCurrent ? false : p.key !== 'versions';
      const className = cx(
        'settings-button',
        p.key,
        { active: panel === p.key },
        typeof p.className === 'function' ? p.className(this.props) : null
      );

      const indicator =
        p.key === 'pdfreview' && deal.isProcessingAI ? (
          <Loader size="small" className="loader-indidcator" />
        ) : p.key === 'pdfreview' && variablesForReview.length > 0 ? (
          <div className="needsReview" />
        ) : null;

      return (
        <TooltipButton
          tipID={`tip-${p.key}`}
          tip={disabled ? `${p.title} disabled for previous version` : p.title}
          placement="bottom"
          key={p.key}
          container={this.dealHeaderRef.current}
        >
          <span>
            <ButtonIcon
              tool
              className={className}
              onClick={() => (disabled ? null : this.handlePanel(p.key))}
              ref={this.refBtns[p.key]}
              dark={!selectedVersion.isCurrent}
              icon={p.icon}
              disabled={disabled}
              data-cy={p.key}
              size={isVine ? 'default' : 'large'}
              indicator={indicator}
            />
          </span>
        </TooltipButton>
      );
    });
  }

  renderQuickExports() {
    return (
      <div className="dd-vine-actions-container">
        <Dropdown
          className="dd-vine-actions"
          dmpStyle="default"
          size="small"
          onSelect={this.handleVineAction}
          id="dd-vine-actions"
          data-cy="dd-vine-actions"
          icon="document"
          pullRight
          menuWidth={170}
        >
          {_.map(QUICK_EXPORTS, ({ type, title }) =>
            type !== 'divider' ? (
              <MenuItem key={type} eventKey={type} className={type}>
                {type !== 'preview' && (
                  <>
                    <Icon name={`fv_${type}`} /> {title}
                  </>
                )}
                {type === 'preview' && title}
              </MenuItem>
            ) : (
              <MenuItem divider />
            )
          )}
        </Dropdown>
      </div>
    );
  }

  async handleVineAction(type) {
    const { deal } = this.props;
    switch (type) {
      case 'preview':
        this.setState({ preview: true });
        break;
      case 'pdf':
        Fire.token((token) => window.open(generateExportURL({ deal, token, type })));
        break;
      case 'docx':
        Fire.token((token) => window.open(generateExportURL({ deal, token, type })));
        break;

      case 'pdfToDocs':
      case 'docxToDocs':
        const documentType = type === 'pdfToDocs' ? 'pdf' : 'docx';

        // TODO: do we need to add the timeline options from above?

        // Generate download URL and get data
        const token = await Fire.token();
        const documentURL = generateExportURL({ deal, token, type: documentType });
        const documentData = await axios.get(documentURL, { responseType: 'arraybuffer' });

        // Convert to base64
        const dataEncoded = this.arrayBufferToBase64(documentData.data);

        const result = await API.call('createFilevineDocument', {
          data: dataEncoded,
          dealID: deal.info.dealID,
          teamID: deal.info.sourceTeam,
          documentType,
        });

        const documentLabel = documentType.toUpperCase();
        let toastType = 'success';
        let toastMessage = `${documentLabel} successfully exported to Docs.`;

        if (!result?.documentId) {
          toastType = 'error';
          toastMessage = `${documentLabel} failed to export to Docs.`;
        }

        toast(toastMessage, { type: toastType });
        break;
    }
  }

  arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }

  renderPanels() {
    const {
      deal,
      history,
      location,
      subscription,
      team,
      teams,
      toggleHistory,
      user,
      versionHistory,
      selectedVersion,
      signed,
    } = this.props;
    const { documentAI } = deal;

    const {
      copying,
      dealRecord,
      exporting,
      isShowingInfo,
      isUpgradeGuestVisible,
      revertingFlow,
      settings,
      tagging,
      preview,
      dealTeam,
    } = this.state;

    const lens = dealTeam && dealTeam.isFeatureActive(FEATURES.LENS);

    return (
      <>
        {/* This is used to make sure that the Panels are always rendered on the far right */}
        <div ref={this.refToolbarPanels} />

        <ElementsView {...this.props} {...this.getPanelProps(PANELS.elements)} />

        {this.props.blockID && <AiBlocksView {...this.props} {...this.getPanelProps(PANELS.aiblocks)} />}

        <AuditView deal={deal} {...this.getPanelProps(PANELS.audit)} />

        <PDFPreview show={preview} close={() => this.setState({ preview: false })} deal={deal} user={user} />

        <Checkpoint
          {...this.props}
          {...this.getPanelProps(PANELS.readyCheck)}
          toggle={(show) => this.setState({ panel: show ? 'readyCheck' : null })}
          readyCheck={deal.readyCheck}
        />

        <DealUserManager
          ref={this.refDealUserManager}
          deal={deal}
          team={team}
          teams={teams}
          user={user}
          history={history}
          signed={signed}
          {...this.getPanelProps(PANELS.users)}
        />

        <DealVersions
          ref={this.refVersions}
          deal={deal}
          user={user}
          history={history}
          location={location}
          selectedVersion={selectedVersion}
          {...this.getPanelProps(PANELS.versions)}
        />

        <DealAttachments
          ref={this.refAttachments}
          deal={deal}
          user={user}
          history={history}
          location={location}
          {...this.getPanelProps(PANELS.attachments)}
        />

        {dealTeam && <ConnectionView deal={deal} dealTeam={dealTeam} {...this.getPanelProps(PANELS.connect)} />}

        <DealIntegrations deal={deal} {...this.getPanelProps(PANELS.integrations)} />

        <DealSettings
          deal={deal}
          team={team}
          show={settings}
          onHide={() => this.setState({ settings: false })}
          onSave={() => this.setState({ settings: false })}
        />

        {(lens || documentAI) && (
          <PDFReview deal={deal} dealTeam={dealTeam} {...this.getPanelProps(PANELS.pdfreview)} user={user} />
        )}

        <VersionHistory close={toggleHistory} onRestore={toggleHistory} section={versionHistory} user={user} />

        {!deal.isExternal && (
          <DraftFlowSwitcher
            {...this.props}
            show={revertingFlow}
            deal={deal}
            close={() => this.setState({ revertingFlow: false })}
          />
        )}

        {!deal.hasTimeline && !deal.isExternal && (
          <ContractExporter
            show={exporting}
            close={() => this.setState({ exporting: false })}
            deal={deal}
            user={user}
            subscription={subscription}
          />
        )}
        {deal.hasTimeline && (
          <TimelineDealExporter
            show={exporting}
            deal={deal}
            user={user}
            exportSettings={this.state.timelineExportSettings}
            onClose={() => this.setState({ exporting: false })}
          />
        )}

        {!deal.isExternal && copying && (
          <CopyMachine user={user} deal={deal} onClose={() => this.setState({ copying: false })} />
        )}

        {tagging && (
          <Tagger
            dealRecord={dealRecord}
            user={user}
            container={this.dealHeaderRef.current}
            target={this.refDotMenu.current}
            onHide={() => this.setState({ tagging: false })}
            onTagsUpdated={(updatedDealRecord) => this.setState({ dealRecord: updatedDealRecord })}
          />
        )}

        {!deal.isExternal && dealTeam && (
          <DealInfo
            show={isShowingInfo}
            deal={deal}
            user={user}
            team={dealTeam}
            close={() => this.setState({ isShowingInfo: false })}
          />
        )}

        {isUpgradeGuestVisible && (
          <UpgradeGuestModal
            user={user}
            currentDealUser={deal.currentDealUser}
            history={history}
            onHide={() => this.setState({ isUpgradeGuestVisible: false })}
          />
        )}
      </>
    );
  }

  renderNative() {
    const {
      deal,
      summary,
      showDealStatus,
      toggleDealStatus,
      compact,
      history,
      onToggleBundle,
      setDarkMode,
      dark,
      user,
    } = this.props;
    const { updatingStatus } = this.state;

    if (Dealer.mobile && dark) setDarkMode(false);

    if (!deal || !deal.currentVersion) return null;

    const action = this.action;

    //special case for a ReadyCheck that's in progress
    //if we find one, we actually want to dim all the steps and instead render a generic "ReadyCheck in progress" tip
    const rc = deal.readyCheck;

    // A newly created Deal does not actually have a stored status;
    // Ensure that we start at the beginning
    const status = deal.info.status || deal.workflow.steps[0].key;

    // If a "wrapper" template is used to act as Bundle parent, it will not have any signatures
    // So we want to hide ABC from (confusingly) showing the "SETUP --> DONE" flow
    // We also want to hide the flow steps when in Timeline Mode.
    const suppressABC = (deal.isBundleParent && !deal.requiresSigning) || deal.hasTimeline;

    // For Timelines we handle mode changes using the <TimelineDealHeaderTabs> component (see below), allowing
    // us to implement a custom design for Timeline Deals, without messing up the legacy system.
    const supressRenderModesButtons = deal.isTimeline;

    return (
      <div className="toolbar">
        <div
          className={cx('toolbar-section', { 'mobile-sub': Dealer.mobile && !isVine })}
          data-cy="toolbar-section-deal-status"
        >
          {deal.hasTimeline && <TimelineDealHeaderTabs onModeClicked={this.setViewMode} />}

          {deal.bundle && (
            <BundleView
              deal={deal}
              history={history}
              container={this.dealHeaderRef.current}
              onToggle={onToggleBundle}
            />
          )}

          {deal.workflow.isOutlaw ? (
            <ABC
              {...this.props}
              show={showDealStatus}
              toggle={toggleDealStatus}
              status={status}
              share={() => this.handlePanel('users')}
              pdf={() => this.setViewMode('pdf')}
              suppressABC={suppressABC}
              action={action}
              handleAction={this.handleAction}
              senderRef={this.senderRef}
              abcContainerRef={this.abcContainerRef}
              draftStepTargetRef={this.draftStepTargetRef}
              actionRef={this.actionRef}
            />
          ) : (
            <DealStatusSelector
              className="btn-tool"
              steps={deal.workflow.steps}
              onSelect={this.handleChangeStep}
              currentStep={deal.currentVersion.step}
              loading={updatingStatus}
              disabled={deal.currentDealUser?.role != DealRole.OWNER}
              toolbar
              isServiceProvider={isServiceProvider(deal, user)}
            />
          )}

          {!compact && action && !rc && !deal.timeline && (
            <div className="action-wrapper">
              <div className="action passive" data-cy="action-passive">
                <div className="info">
                  <div className="title">{action === 'View PDF' ? 'Document finalized' : action}</div>
                  {action === 'View PDF' && <div className="sub inactive">Editing is disabled</div>}
                </div>
                <TooltipButton tipID="tip-vote-contract-action" tip={`Tap to ${action}`}>
                  <Button
                    className={`contract-action ${action === 'View PDF' ? 'view-pdf' : ''}`}
                    dmpStyle="primary"
                    onClick={() => this.handleAction()}
                  >
                    {action !== 'View PDF' ? action?.split(' ')[0] : action}
                  </Button>
                </TooltipButton>
              </div>
            </div>
          )}
        </div>

        {/*only render view mode toggle if there's a Overview defined (otherwise it's always just the contract)*/}
        {!supressRenderModesButtons &&
          (summary?.length > 0 || deal.workflow.serviceProviders) &&
          deal.currentVersion.isOriginal && (
            <ButtonGroup className={cx('view-mode', { compact }, { dotvine: isVine })} data-cy="btn-group-view-mode">
              {this.renderModes()}
            </ButtonGroup>
          )}

        {this.renderMobileMenu()}

        <div className="toolbar-section">
          {deal.hasTimeline && (
            <TimelineDealHeaderControls
              onPdfExportClick={() => {
                const timelineExportSettings = this.props.refTimevine.current?.getExportSettings();
                this.setState({ exporting: true, timelineExportSettings });
              }}
            />
          )}
          {isVine && !deal.hasTimeline && (
            <div className="deal-settings toolbar-group right quick-exports">{this.renderQuickExports()}</div>
          )}
          {(!Dealer.mobile || isVine) && !compact && (
            <div className="deal-settings toolbar-group right" data-cy="deal-settings-group">
              {this.renderPanelButtons()}
              {!isVine && this.renderDotMenu()}
            </div>
          )}
        </div>
      </div>
    );
  }

  renderExternal() {
    const { compact, deal, pdf, setDarkMode, dark, signed } = this.props;
    const { dataURL, updatingStatus } = this.state;

    if (Dealer.mobile && dark) setDarkMode(false);

    const isOwner = _.get(deal, 'currentDealUser.role') === DealRole.OWNER;
    const isEditor = _.get(deal, 'currentDealUser.role') === DealRole.EDITOR;

    return (
      <>
        <PDFHeader
          title={deal.info.name}
          pageCount={pdf ? pdf.numPages : 0}
          editable={isOwner}
          onRename={() => this.handlePanel('settings')}
          canCommit={isOwner && deal.pendingPDFChanges.length > 0}
          onCommit={() => this.handlePanel('dealVersions')}
        />
        <div className="toolbar">
          <div className="toolbar-section external-tools">
            <div className="toolbar-group deal-status">
              <DealStatusSelector
                className="btn-tool"
                steps={deal.workflow.steps}
                onSelect={this.handleChangeStep}
                currentStep={deal.currentVersion.step}
                disabled={!isOwner}
                loading={updatingStatus}
                toolbar
              />
            </div>

            {(isOwner || isEditor) && !signed && this.renderPDFControls()}
            <div className="spacer" />
            <div className="zoom">{this.renderZoom()}</div>
          </div>

          {this.renderMobileMenu({ download: true })}

          <div className="toolbar-section">
            {!Dealer.mobile && !compact && (
              <div className="deal-settings toolbar-group right">
                {this.renderPanelButtons()}
                {this.renderDotMenu()}
              </div>
            )}
          </div>

          <a className="hide" ref={this.downloadLink} href={dataURL} download={deal.currentVersion.filename}>
            {deal.currentVersion.filename}
          </a>
        </div>
      </>
    );
  }

  renderDotMenu() {
    const { selectedVersion, user, deal, teams } = this.props;
    const { downloadURL, downloading } = this.state;
    const native = selectedVersion.isOriginal;
    const canRevertFlow = this.isAdmin && [DEAL_TYPE.INGESTED, DEAL_TYPE.BESPOKE].indexOf(deal.dealType) > -1;

    const menuItems = [];
    const canDuplicate = _.find(user.getTeamsAllowingNewDocCreation(teams), { teamID: deal.team });

    if (selectedVersion.isCurrent && !native) {
      menuItems.push(
        <MenuItem key="download" eventKey="download" disabled={!downloadURL || downloading}>
          Download File
        </MenuItem>
      );
    }

    if (deal.isOwner) {
      menuItems.push(
        <MenuItem key="settings" eventKey="settings" icon="settingsCog">
          Settings
        </MenuItem>
      );

      if (this.canConnect) {
        menuItems.push(
          <MenuItem key="connect" eventKey="connect" icon="connect">
            Connections ({deal.connections.length})
          </MenuItem>
        );
      }
    }

    if (native) {
      if (!deal.hasTimeline) {
        menuItems.push(
          <MenuItem key="export" eventKey="export" icon="export">
            Export as...
          </MenuItem>
        );

        menuItems.push(
          <MenuItem eventKey="preview" key="preview" icon="preview">
            PDF preview
          </MenuItem>
        );
      }

      if (deal.isOwner && canDuplicate)
        menuItems.push(
          <MenuItem key="copy" eventKey="copy" icon="duplicate">
            Duplicate
          </MenuItem>
        );
      if (canRevertFlow)
        menuItems.push(
          <MenuItem key="revertFlow" eventKey="revertFlow" icon="draft">
            Revert to Draft
          </MenuItem>
        );
    }

    if (!user.anonymous) {
      menuItems.push(
        <MenuItem key="tagging" eventKey="tagging" icon="tag">
          Edit tags
        </MenuItem>
      );
    }

    if (native) {
      menuItems.push(
        <MenuItem eventKey="info" key="info" icon="info">
          {Dt} info
        </MenuItem>
      );
    }
    if (deal.isOwner) {
      menuItems.push(
        <MenuItem eventKey="delete" key="delete" icon="trash">
          Move to trash
        </MenuItem>
      );
    }

    if (!menuItems.length) return null;

    return (
      <DropdownDots
        pullRight
        id="dd-deal-more"
        className="btn-tool settings-button dd-deal-more"
        ref={this.refDotMenu}
        onSelect={this.handlePanel}
        dark={!selectedVersion.isCurrent}
        faded={false}
      >
        {menuItems}
      </DropdownDots>
    );
  }

  renderPDFControls() {
    const { onPDFEditMode, pdfEditMode, pdf, deal } = this.props;
    const { parties } = deal;
    const fieldOptions = getMenuOptions(deal);

    return (
      <div className="toolbar-group pdf-controls">
        <TooltipButton tip="Place text field" placement="top" container={this.topbar}>
          <Dropdown
            dmpStyle="link"
            className={cx('btn-tool', { active: pdfEditMode === ELEMENT_TYPE.VARIABLE })}
            id="dd-pdf-add-var"
            onSelect={(varName) => onPDFEditMode(varName ? ELEMENT_TYPE.VARIABLE : ELEMENT_TYPE.SIMPLE, varName)}
            disabled={!pdf || deal.canEdit || Dealer.mobile}
            icon="fieldsEdit"
            size="small"
          >
            <MenuItem eventKey={null}>Plain text field</MenuItem>
            {fieldOptions.length > 0 && <MenuItem divider />}
            {fieldOptions.length > 0 && fieldOptions}
          </Dropdown>
        </TooltipButton>

        <TooltipButton tip="Place signature field" placement="top" container={this.topbar}>
          <Dropdown
            className={cx('btn-tool', { active: pdfEditMode === ELEMENT_TYPE.SIGNATURE })}
            id="dd-pdf-add-sig"
            onSelect={this.handlePDFParty}
            disabled={!pdf || deal.canEdit || Dealer.mobile}
            dmpStyle="link"
            icon="signatureScript"
            size="small"
          >
            {!parties.length && <MenuItem disabled>No parties defined</MenuItem>}
            {parties.map((party, idx) => (
              <MenuItem key={idx} eventKey={party} ellipsis>
                {party.displayName} eSignature
              </MenuItem>
            ))}
            <MenuItem divider />
            <MenuItem key="new-party" eventKey={null}>
              Add new party
            </MenuItem>
          </Dropdown>
        </TooltipButton>
      </div>
    );
  }

  renderPreviousVersion() {
    const { deal, history, selectedVersion, setDarkMode, dark } = this.props;

    if (Dealer.mobile && !dark && !isVine) setDarkMode(true);

    return (
      <>
        <div className="previous-header">
          <Ellipsis className="deal-name">{deal.info.name}</Ellipsis>
          <div className="deal-version">Version {selectedVersion.ordinal}</div>
        </div>

        <div className="toolbar">
          <div className="toolbar-section external-tools">
            <Button
              dmpStyle="link"
              className="btn-back-to-current"
              onClick={() => history.push(deal.currentVersion.deepLink)}
              icon="filterCollapse"
              noUnderline
              dark
              size="small"
            >
              {/* <Icon name="chevronRight" /> */}
              Back to latest version
            </Button>
            <div className="spacer" />
            {!selectedVersion.isOriginal && this.renderZoom()}
          </div>

          {this.renderMobileMenu()}

          <div className="deal-settings toolbar-group right">
            {!Dealer.mobile && this.renderPanelButtons()}
            {!Dealer.mobile && this.renderDotMenu()}
          </div>
        </div>
      </>
    );
  }

  render() {
    const { deal, selectedVersion, user } = this.props;

    if (!deal || !selectedVersion) return null;

    //if the deal's workflow supports service providers check the access on the step
    //unless we are on setup then we will only show the fv/non provider user the case data.
    if (
      deal.workflow.serviceProviders &&
      (!hasRestrictedWorkflowAccess(deal, user) ||
        (deal.currentWorkflowStep.key === 'todo' && !isServiceProvider(deal, user)))
    ) {
      return null;
    }

    const className = cx(
      'deal-header',
      deal.info.status || deal.workflow.steps[0].key,
      { previous: !selectedVersion.isCurrent },
      { dotvine: isVine }
    );

    return (
      <div className={className} ref={this.dealHeaderRef}>
        <ToastContainer id="toast-export-doc" autoClose={5000} />
        {!selectedVersion.isCurrent
          ? this.renderPreviousVersion()
          : !selectedVersion.isOriginal
          ? this.renderExternal()
          : this.renderNative()}
        {this.renderPanels()}
      </div>
    );
  }
}
