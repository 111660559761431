import React from 'react';

import PropTypes from 'prop-types';

import { ButtonIcon } from '@components/dmp/Button';

const TimelineDealHeaderControls_propTypes = {
  onPdfExportClick: PropTypes.func.isRequired,
};
export default function TimelineDealHeaderControls({ onPdfExportClick }) {
  return (
    <div style={{ display: 'flex', marginRight: '10px' }}>
      <ButtonIcon icon="pdfExport" noFill onClick={onPdfExportClick} />
    </div>
  );
}
TimelineDealHeaderControls.propTypes = TimelineDealHeaderControls_propTypes;
