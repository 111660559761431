import { compact, isObject, merge, reduce } from 'lodash';

export const convertPointsToPixels = (points, scale = 1) => {
  //return Math.round(points * 96 / 72) * scale;
  return Math.round(points * scale);
};

export const convertPixelsToPoints = (pixels, scale = 1) => {
  //return Math.round((pixels / scale) * 72 / 96);
  return Math.round(pixels / scale);
};

export const getFullName = (firstName, lastName) => {
  return compact([firstName, lastName]).join(' ');
};

export const flattenedKeysObject = (obj, path = []) => {
  return !isObject(obj)
    ? { [path.join('.')]: obj }
    : reduce(obj, (cum, next, key) => merge(cum, flattenedKeysObject(next, [...path, key])), {});
};

export const convertHexToRgb = (color) => {
  let colorHex = parseInt(color, 16);
  let r = (colorHex >> 16) & 255;
  let g = (colorHex >> 8) & 255;
  let b = colorHex & 255;
  return `rgb(${r}, ${g}, ${b})`;
};
