import { cloneDeep, forOwn, isArray, isEmpty, isNaN, isNull, isObject, isString, isUndefined, pull } from 'lodash';

/*
  Remove all null, undefined, NaN, empty String, empty array and empty object values recursively.
  From Stackoverflow: https://stackoverflow.com/a/26202058/2179494
*/
export const pruneEmpty = (obj) => {
  return (function prune(current) {
    forOwn(current, function (value, key) {
      if (
        isUndefined(value) ||
        isNull(value) ||
        isNaN(value) ||
        (isString(value) && isEmpty(value)) ||
        (isObject(value) && isEmpty(prune(value)))
      ) {
        delete current[key];
      }
    });
    // remove any leftover undefined values from the delete
    // operation on an array
    if (isArray(current)) pull(current, undefined);
    return current;
  })(cloneDeep(obj)); // Do not modify the original object, create a clone instead
};

export const formatNumber = (num) => {
  return (num && num.toLocaleString('en-US')) || 0;
};
